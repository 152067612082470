import React, { useEffect } from 'react';
import { ERROR_TYPES, SNACKBAR_SELF_CLOSING_DELAY } from './emplifi-wrapper';

interface Props {
    snackbarType: string;
    messageLabel: string;
    onSnackbarButtonClick: (e: any) => void;
    actionLabel: string;
    isVisible: boolean;
    closeAction: () => void;
    retryMessageAriaLabel: string;
}
const ChatbotSnackbar = (props: Props) => {
    useEffect(() => {
        if (
            props.snackbarType === ERROR_TYPES.SELF_CLOSING &&
            props.isVisible
        ) {
            const timer = setTimeout(() => {
                props.closeAction();
            }, SNACKBAR_SELF_CLOSING_DELAY);

            return () => clearTimeout(timer);
        }
    }, [props.isVisible, props.snackbarType, props.closeAction]);
    return (
        <div
            className={`chatbot__snackbar ${
                props.isVisible ? 'chatbot__snackbar--open' : ''
            }`}
        >
            <span aria-live="assertive">{props.messageLabel}</span>
            {props.snackbarType === ERROR_TYPES.ACTIONABLE && (
                <button
                    className="chatbot__button chatbot__button--snackbar-action"
                    onClick={props.onSnackbarButtonClick}
                    aria-label={props.retryMessageAriaLabel}
                >
                    {props.actionLabel}
                </button>
            )}
        </div>
    );
};

export default ChatbotSnackbar;
