import ServerSideService from '../server-side-service/server-side-service';

export default class MicrosoftService {
    private win: any;

    private microSoftObject: Promise<object>;

    constructor() {
        if (ServerSideService.isClientSide()) {
            this.win = window;
        }

        this.microSoftObject = new Promise<object>((resolve) => {
            if (this.getMicrosoftObject()) {
                resolve({});
            }
        });
    }

    public getMicrosoftObject = () => {
        return this.win?.Microsoft;
    };

    public getMapsObject = (): any | undefined => {
        return this.win?.Microsoft?.Maps;
    };

    public getMicrosoftLocation = (lat: number, long: number): any => {
        return this.win?.Microsoft?.Maps?.Location?.parseLatLong(
            `${lat},${long}`
        );
    };
}
