import React, { useEffect, useState } from 'react';
import AuthenticationService from '@services/authentication-service/authentication-service';
import './eu-login.scss';
import { SignIn } from '@models/experiencefragments/header';
import serverSideService from '@services/server-side-service/server-side-service';

const userLoggedOut = './icons/user-profile-loggedout-outline.svg';
const userLoggedIn = './icons/user-profile-loggedin-outline.svg';

interface Props {
    content: SignIn;
}

export const EuLogin = (props: Props) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const authService = new AuthenticationService();

    const determineUserIcon = () => {
        let icon = userLoggedOut;
        if (serverSideService.isClientSide() && isAuthenticated)
            icon = userLoggedIn;
        return icon;
    };

    const [userIcon, setUserIcon] = useState<string>(determineUserIcon);

    useEffect(() => {
        authService.onIsAuthenticated().then((isAuthenticated: boolean) => {
            setIsAuthenticated(isAuthenticated);
            if (isAuthenticated) {
                setUserIcon(determineUserIcon());
            }
        });
    }, []);

    const toggleDropdownAndOverlay = () => {
        document
            .getElementsByClassName('eu-login-dropdown')[0]
            .classList.toggle('active');
        document
            .getElementsByClassName('eu-login-button')[0]
            .classList.toggle('active');
        document
            .getElementsByClassName('main-overlay')[0]
            .classList.toggle('open');
    };

    if (serverSideService.isClientSide()) {
        window.onresize = () => {
            setUserIcon(determineUserIcon());
        };
        document.body.onclick = (e: MouseEvent) => {
            const eventSource = e.target as HTMLElement;
            const loginDropdown =
                document.getElementsByClassName('eu-login-dropdown')[0];
            if (
                !eventSource.closest('.eu-login-container') &&
                loginDropdown &&
                loginDropdown.classList.contains('active')
            )
                toggleDropdownAndOverlay();
        };
    }

    const loginText = () => {
        return isAuthenticated
            ? props.content.fordAccountLabel
            : props.content.signInTitle;
    };

    const toggleDisplayOrLogin = () => {
        if (isAuthenticated) {
            toggleDropdownAndOverlay();
        } else authService.login();
    };

    return (
        <>
            {props.content.fordAccountLabel && (
                <div className="eu-login-container">
                    <button
                        className="eu-login-button"
                        onClick={toggleDisplayOrLogin}
                    >
                        <img src={userIcon} alt="user-profile"></img>
                        <i className="icon linc1638-lw-icons-38px-c16-expanded-06 hide-me" />
                        <span className="eu-login-text">{loginText()}</span>
                    </button>
                    {props.content.signOutTitle && (
                        <div
                            data-testid="eu-login-dropdown"
                            className="eu-login-dropdown"
                        >
                            <ul className="eu-login-dropdown-list">
                                <li className="eu-login-dropdown-list-item">
                                    <button onClick={authService.logout}>
                                        {props.content.signOutTitle}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
