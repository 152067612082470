import { NUMBER_OF_TRACKER_STEPS } from '@constants';
import React from 'react';
import { StepMarkerProps } from '@views/vehicle-order-tracking-status-view/components/tracker/tracker';

const StepMarker = (props: StepMarkerProps) => {
    return (
        <li
            className="vehicle-order-tracking__tracker--graph-segment"
            data-testid={`step-marker-${props.stepNumber}-container`}
        >
            <div className="dot">
                <span
                    className={`vehicle-order-tracking__tracker--graph-segment-marker ${
                        props.isCurrentStep && props.stepNumber !== 6
                            ? 'is-current-status'
                            : ''
                    }`}
                    data-testid={`step-marker-${props.stepNumber}`}
                >
                    {props.isCurrentStep || !props.isPreviouslyCompletedStep
                        ? props.stepNumber
                        : ''}
                </span>
                {props.isPreviouslyCompletedStep && (
                    <span
                        className="fds-icon fds-icon--32 fds-icon--offset-right-sm fds-font--ford-icons__success"
                        data-testid={`success-icon-${props.stepNumber}`}
                    ></span>
                )}
            </div>
            {props.stepNumber < NUMBER_OF_TRACKER_STEPS && (
                <hr
                    className={`segment--${props.stepNumber} ${
                        props.isPreviouslyCompletedStep
                            ? 'highlighted-step'
                            : ''
                    }`}
                />
            )}
        </li>
    );
};

export default StepMarker;
