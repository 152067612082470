import React, { useEffect, useState } from 'react';
import './notification.scss';
import notificationIconInfo from '@assets/notification-icon-info.svg';
import notificationIconSuccess from '@assets/notification-icon-success.svg';
import notificationIconError from '@assets/notification-icon-error.svg';
import { useAnalytics } from '@hooks/use-analytics';
import { NotificationType } from '@contexts/notificationContext';
import DOMPurify from 'dompurify';
import { LinkTargetValues } from '@/constants';

const cancelCloseIcon = './icons/cancel-close.svg';

interface Props {
    status: string;
    mainCopy: string;
    subCopy?: string;
    ctaText?: string;
    ctaHref?: string;
    ctaAriaLabel?: string;
    analyticsEvent?: string;
    hideBorder?: boolean;
    hideAfterTimeout?: boolean;
    onHideNotification?: () => void;
    rteField?: boolean;
}

const NOTIFICATION_STATUS_MAP = {
    [NotificationType.Info]: {
        icon: notificationIconInfo,
        bgColorClass: 'info-status',
    },
    [NotificationType.Success]: {
        icon: notificationIconSuccess,
        bgColorClass: 'success-status',
    },
    [NotificationType.Error]: {
        icon: notificationIconError,
        bgColorClass: 'error-status',
    },
};
export const Notification = (props: Props) => {
    const [fireAnalytics] = useAnalytics();
    const [closeButtonClicked, setCloseButtonClicked] =
        useState<boolean>(false);
    const showNotification =
        !closeButtonClicked &&
        !!props.mainCopy &&
        props.status !== NotificationType.None;

    const { icon, bgColorClass } =
        NOTIFICATION_STATUS_MAP[props.status] ||
        NOTIFICATION_STATUS_MAP[NotificationType.Info];

    const rteFieldContent = props.rteField
        ? DOMPurify.sanitize(props.subCopy)
        : '';

    useEffect(() => {
        if (closeButtonClicked && props.onHideNotification) {
            props.onHideNotification();
        }
    }, [closeButtonClicked]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (props.hideAfterTimeout) {
            timeoutId = setTimeout(() => {
                setCloseButtonClicked(true);
            }, 10000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [props.hideAfterTimeout]);

    return (
        <>
            {showNotification && (
                <div
                    className={`notification-container ${
                        props.rteField ? 'rte' : ''
                    } ${bgColorClass} ${props.hideBorder ? 'no-border' : ''} ${
                        !props.subCopy ? 'no-sub-copy' : ''
                    }`}
                    data-testid="notification-container"
                >
                    <img
                        className="notification-icon"
                        src={icon}
                        alt="notification icon"
                        data-testid="notification-icon"
                    />
                    <div className="notification-content">
                        <div
                            className="notification-main-copy"
                            data-testid="notification-main-copy"
                        >
                            {props.mainCopy}
                        </div>
                        {props.subCopy && (
                            <>
                                {props.rteField ? (
                                    <div
                                        className="notification-sub-copy"
                                        data-testid="notification-sub-copy"
                                        dangerouslySetInnerHTML={{
                                            __html: rteFieldContent,
                                        }}
                                    ></div>
                                ) : (
                                    <div
                                        className="notification-sub-copy"
                                        data-testid="notification-sub-copy"
                                    >
                                        {props.subCopy}{' '}
                                        {props.ctaText && props.ctaHref ? (
                                            <a
                                                href={props.ctaHref}
                                                target={LinkTargetValues.BLANK}
                                                className="notification-cta-text"
                                                data-testid="notification-cta-text"
                                                aria-label={props.ctaAriaLabel}
                                                onClick={() => {
                                                    if (props.analyticsEvent) {
                                                        fireAnalytics(
                                                            props.analyticsEvent
                                                        );
                                                    }
                                                }}
                                            >
                                                {props.ctaText}
                                            </a>
                                        ) : null}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <button
                        data-testid="notification-close-button"
                        onClick={() => setCloseButtonClicked(true)}
                        aria-label={'Close Notification'}
                        className="notification-close-button"
                    >
                        <img src={cancelCloseIcon} alt="close-icon"></img>
                    </button>
                </div>
            )}
        </>
    );
};
