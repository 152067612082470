import React from 'react';

export interface GreetingProps {
    brand: string;
    firstName: string;
    lastName: string;
    testId: string;
    mastheadContent?: any;
}
export const Greeting = (props: GreetingProps) => {
    const {
        brand,
        firstName,
        lastName,
        testId,
        mastheadContent,
    }: GreetingProps = props;
    const isFord = brand === 'ford';
    const isLincoln = brand === 'lincoln';
    const greeting = mastheadContent?.goodMorningGreeting;

    return (
        <>
            {isFord ? (
                <h1 className="greeting__name" data-testid={testId}>
                    {firstName} {lastName}
                </h1>
            ) : null}
            {isLincoln && firstName && greeting ? (
                <h3
                    className="fmc-type--heading1 fds-color__text--white fds-align--center fmc-mb-3-xs fmc-pb-1-xs"
                    data-testid={testId}
                >
                    {`${greeting} ${firstName}`}
                </h3>
            ) : null}
        </>
    );
};
