import { useContent } from '@hooks/use-server-content';

export interface HelpCardsProps {
    hide: boolean;
    myFordVehicleHelpCardTitle: string;
    contactUsHelpCardTitle: string;
    findVehicleHelpCardTitle: string;
    myFordVehicleHelpCardAriaLabel: string;
    contactUsHelpCardAriaLabel: string;
    findVehicleHelpCardAriaLabel: string;
    myFordVehicleHelpCardURL: string;
    contactUsHelpCardURL: string;
    findVehicleHelpCardIcon: string;
    contactUsHelpCardIcon: string;
    findVehicleHelpCardURL: string;
    myFordVehicleHelpCardIcon: string;
    myFordVehicleHelpCardTarget: string;
    contactUsHelpCardTarget: string;
    findVehicleHelpCardTarget: string;
}
export const useHelpCardsContent = (): HelpCardsProps => {
    const [content, getValueByName] = useContent('common', 'help-cards');
    const contentFragment: any = {};
    content?.elements.forEach((element) => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });

    return contentFragment as HelpCardsProps;
};
