import React, { useEffect, useState } from 'react';
import { SecondaryButton } from '@common/index';
import { LinkTargetValues } from '@constants';
import HeartIcon from '@common/image-card/components/heart-icon';
import { generateAccessoriesCardBackground } from '@common/image-card/utils/gradient-util';
import './image-card.scss';
import GradientBackgroundImageCard from '@common/image-card/components/gradient-background-image-card';
import { useWindowSize } from '@hooks/use-window-size';
import { useNavigate } from 'react-router-dom';
import { findPathByAlias } from '@routes/routesList';
import { useAnalytics } from '@/hooks/use-analytics';

export interface ImageCardProps {
    imageCardType: string;
    imageCardTitle: string;
    imageCardCtaLabelText: string;
    imageCardCtaAriaLabel: string;
    imageCardCtaHref: string;
    imageCardBackgroundImage?: string;
    imageCardCount: number;
}

export enum IMAGE_CARD_TYPE {
    ACCESSORIES = 'accessories',
    SAVED_ITEMS = 'saved-items',
    ORDER_HISTORY = 'order-history',
}

const ImageCard = (props: ImageCardProps) => {
    const isAccessoriesCard =
        props.imageCardType === IMAGE_CARD_TYPE.ACCESSORIES;
    const isSavedItemsCard =
        props.imageCardType === IMAGE_CARD_TYPE.SAVED_ITEMS;
    const isOrderHistoryCard =
        props.imageCardType === IMAGE_CARD_TYPE.ORDER_HISTORY;
    const size = useWindowSize();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [fireAnalytics] = useAnalytics();

    const backgroundStyleAccessoriesCard: React.CSSProperties = {
        background: generateAccessoriesCardBackground(
            props.imageCardBackgroundImage
        ),
        backgroundBlendMode: 'overlay',
        backgroundSize: '841px',
        backgroundPositionX: '46%',
        backgroundPositionY: '30%',
    };

    const generateImageCardContentClass = (): string => {
        if (isSavedItemsCard) {
            return 'saved-items';
        }

        return '';
    };

    const generateBorderRadius = (): number => {
        const isTwoCardsInDesktop: boolean =
            props.imageCardCount === 2 && !isMobile;

        if (isMobile || isTwoCardsInDesktop) {
            return 8;
        }

        return 16;
    };

    const handleClick = (): void => {
        if (isOrderHistoryCard) {
            fireAnalytics('imageCardOrderHistoryOnclickEvent');
            navigate(findPathByAlias('OrderHistoryView'));
        }
        if (isAccessoriesCard) {
            fireAnalytics('imageCardAccessoriesOnclickEvent');
        }
        if (isSavedItemsCard) {
            fireAnalytics('imageCardSavedItemsOnclickEvent');
        }
    };

    const generateLink = (): string => {
        let link: string = props.imageCardCtaHref;

        if (isOrderHistoryCard) {
            link = null;
        }

        return link;
    };

    const generateLinkTarget = (): string => {
        let linkTarget: string = LinkTargetValues.BLANK;

        if (isOrderHistoryCard) {
            linkTarget = LinkTargetValues.SELF;
        }

        return linkTarget;
    };

    useEffect(() => {
        if (size.width <= 935) {
            setIsMobile(true);
        }
        if (size.width > 935) {
            setIsMobile(false);
        }
    }, [size]);

    return (
        <div
            className={`image-card__container ${
                !isAccessoriesCard ? 'blue-gradient-card' : ''
            }`}
            data-testid="image-card-container"
            style={isAccessoriesCard ? backgroundStyleAccessoriesCard : {}}
        >
            {!isAccessoriesCard && (
                <div className="image-card__overlay">
                    <GradientBackgroundImageCard
                        height="100%"
                        width="100%"
                        radius={generateBorderRadius()}
                    />
                </div>
            )}

            <div className="image-card__content">
                <h2
                    className={`image-card__content--title ${generateImageCardContentClass()}`}
                >
                    {isSavedItemsCard && (
                        <span className="heart-icon__container">
                            <HeartIcon width={38} height={40} color="white" />
                        </span>
                    )}{' '}
                    <span className="title">{props.imageCardTitle}</span>
                </h2>

                <SecondaryButton
                    className="image-card__content--cta"
                    dataTestId="image-card-cta"
                    aria-label={props.imageCardCtaAriaLabel}
                    link={generateLink()}
                    linkTarget={generateLinkTarget()}
                    onClick={handleClick}
                >
                    {props.imageCardCtaLabelText}
                </SecondaryButton>
            </div>
        </div>
    );
};

export default ImageCard;
