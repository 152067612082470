import { PrimaryButton } from '@/components/common';
import { RadioButtonGroup } from '@/components/common/form-fields/form-fields';
import { LinkButton } from '@/components/common/modal/link-button/link-button';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';
import { useCommunicationPreferencesContent } from '@/views/communication-preferences-view/hooks/use-communication-preference-content';
import React, { useEffect, useState } from 'react';
import { IMG_COUNTRIES, MX_COUNTRY, NA_COUNTRIES } from '@constants';
import CommunicationPreferencesModal from '../communication-preference-modal/communication-preferences-modal';
import './email-communication-preferences-form.scss';
import { CacheService } from '@/services/cache-service/cache-service';
import UserCommunicationPreferenceService from '@/services/communication-preference-service/communication-preference-service';
import UserEmailChannelPreferenceService from '@/services/email-channel-preference-service/email-channel-preference-service';
import HttpService from '@/services/http-service/http-service';
import { useAnalytics } from '@/hooks/use-analytics';

type ModalType = 'success' | 'confirmation' | '';

interface Props {
    displayForm: (
        flag: boolean,
        bannerType: string,
        bannerText: string,
        marketingEmailSubscribed: boolean
    ) => void;
    defaultSelection: boolean;
    isUnauthPage?: boolean;
    consumerId?: string;
    countryCode?: string;
    email?: string;
}

const EmailCommunicationPreferencesFormSection = (props: Props) => {
    const [fireAnalytics] = useAnalytics();
    const appConfig = new AppConfigurationService();
    const brand = appConfig.brand;
    const isLincoln: boolean = brand === 'lincoln';
    const service = new UserCommunicationPreferenceService();
    const serviceEmailChannel = new UserEmailChannelPreferenceService(
        HttpService
    );
    const communicationPreferenceLabels = useCommunicationPreferencesContent();
    const [modalType, setModalType] = useState<ModalType>('');
    const [modalState, setModalState] = useState<boolean>(false);
    const getAppConfiguration = appConfig.getAppConfiguration();
    const isNa = NA_COUNTRIES.includes(getAppConfiguration.countryCode);
    const isImg = IMG_COUNTRIES.includes(getAppConfiguration.countryCode);
    const isMx = MX_COUNTRY.includes(getAppConfiguration.countryCode);
    const [userPreferences, setUserPreferences] = useState<boolean>();
    const [changeSaved, setChangeSaved] = useState<boolean>(false);

    const cacheService = new CacheService();

    interface CommunicationOptions {
        name: string;
        value: boolean | number;
        displayName: string;
        isChecked: boolean;
    }

    const communicationOptions: CommunicationOptions[] = [
        {
            name: 'yes',
            value: 1,
            displayName: communicationPreferenceLabels?.yesSubscribe,
            isChecked: props.defaultSelection,
        },
        {
            name: 'no',
            value: 0,
            displayName: communicationPreferenceLabels?.noUnSubscribe,
            isChecked: props.defaultSelection,
        },
    ];

    // handle user input change
    const handleChange = (values) => {
        if (values === '1') {
            setUserPreferences(true);
        } else {
            setUserPreferences(false);
        }
    };

    const handleSave = async () => {
        fireAnalytics('emailSaveOnclickEvent');
        const isSuccessModal = userPreferences ? 'success' : 'confirmation';
        setModalType(isSuccessModal);
        if (userPreferences && (isNa || isMx)) {
            // invalidate cache after updating communication preference
            cacheService.evictProfileServiceCache();
            const response = await service.updateNaCommunicationPreference(
                userPreferences
            );
            if (response) {
                props.displayForm(false, 'Success', 'subscribe', true);
            } else {
                props.displayForm(false, 'Error', 'error', null);
            }
        } else if (userPreferences && isImg) {
            cacheService.evictProfileServiceCache();

            const response = await service.updateImgCommunicationPreference(
                userPreferences
            );

            if (response) {
                props.displayForm(false, 'Success', 'subscribe', true);
            } else {
                props.displayForm(false, 'Error', 'error', null);
            }
        } else {
            setModalState(true);
        }
    };

    //save form method for email channel preferences
    const handleSaveUnauth = async () => {
        const isSuccessModal = userPreferences ? 'success' : 'confirmation';
        setModalType(isSuccessModal);
        if (userPreferences) {
            const response =
                await serviceEmailChannel.updateEmailChannelPreference({
                    consumerId: props.consumerId,
                    countryCode: props.countryCode,
                    email: props.email,
                    emailPermission: userPreferences,
                });
            if (response) {
                props.displayForm(false, 'Success', 'subscribe', true);
            } else {
                props.displayForm(false, 'Error', 'error', null);
            }
        } else {
            setModalState(true);
        }
    };

    const handleClose = () => {
        setModalState(false);
    };

    // handle unsubscribe user confirmation
    const handleConfirmationModalConfirmation = async () => {
        fireAnalytics('confirmUnsubscribeOnclickEvent');
        // invalidate cache after updating communication preference
        cacheService.evictProfileServiceCache();
        handleClose();

        if (isNa || isMx) {
            const response = await service.updateNaCommunicationPreference(
                userPreferences
            );
            if (response) {
                props.displayForm(false, 'Success', 'unsubscribe', false);
            } else {
                props.displayForm(false, 'Error', 'error', null);
            }
        } else if (isImg) {
            const response = await service.updateImgCommunicationPreference(
                userPreferences
            );
            if (response) {
                setModalType('success');
                props.displayForm(false, 'Success', 'unsubscribe', false);
            } else {
                props.displayForm(false, 'Error', 'error', null);
            }
        }
    };

    //user unsubscribe confirmation for email channel page
    const handleConfirmationModalConfirmationUnauth = async () => {
        handleClose();

        const response = await serviceEmailChannel.updateEmailChannelPreference(
            {
                consumerId: props.consumerId,
                countryCode: props.countryCode,
                email: props.email,
                emailPermission: userPreferences,
            }
        );
        if (response) {
            props.displayForm(false, 'Success', 'unsubscribe', false);
        } else {
            props.displayForm(false, 'Error', 'error', null);
        }
    };

    useEffect(() => {
        if (props.defaultSelection === true) {
            setUserPreferences(true);
        } else {
            setUserPreferences(false);
        }
    }, []);

    useEffect(() => {
        if (props.isUnauthPage && userPreferences == false && !changeSaved) {
            handleSaveUnauth();
            setChangeSaved(true);
        }
    });

    return (
        <>
            <CommunicationPreferencesModal
                isModalVisible={modalState}
                modalContentFragments={communicationPreferenceLabels}
                modalType={modalType}
                handleClose={handleClose}
                handleConfirmationModalConfirmation={
                    props.isUnauthPage
                        ? handleConfirmationModalConfirmationUnauth
                        : handleConfirmationModalConfirmation
                }
            />
            <div
                className={`user-account-edit-container fmc-mb-5-xs fmc-mb-0 ${
                    isLincoln
                        ? 'fmc-px-3-xs fmc-px-3-sm fmc-px-9-md fmc-px-11'
                        : ''
                } fmc-pt-7 fmc-pb-9`}
                data-testid="communication-preferences-form"
                id="communication-preferences-form"
            >
                <form className={`${isLincoln ? 'fmc-px-1' : ''}`}>
                    <fieldset className="input-fieldset">
                        <h3 className="fmc-type--heading6 fmc-mb-5">
                            {communicationPreferenceLabels?.emailOptionHeader}
                        </h3>
                    </fieldset>
                    <div className={'email-communication-user-input'}>
                        <RadioButtonGroup
                            RadioButtonOptions={communicationOptions}
                            defaultCheckedOption={props.defaultSelection}
                            radioGroupNameClass={
                                'fmc-type--heading6 fmc-pb-4 fmc-mb-1'
                            }
                            handleChange={handleChange}
                            aria-labelledby={
                                'communication-preferences-options'
                            }
                        />
                    </div>
                    {appConfig.brand === 'ford' && (
                        <div className="btn-container">
                            <LinkButton
                                label={communicationPreferenceLabels?.btnCancel}
                                dataTestId="communication-preferences-cancel-button"
                                class="user-edit-cancel-btn"
                                onClick={() =>
                                    props.displayForm(
                                        false,
                                        '',
                                        '',
                                        props.defaultSelection
                                    )
                                }
                                ariaLabel={
                                    communicationPreferenceLabels?.cancelButtonAriaLabel
                                }
                                aria-labelledby={
                                    communicationPreferenceLabels?.cancelButtonAriaLabel
                                }
                                id={
                                    communicationPreferenceLabels?.cancelButtonAriaLabel
                                }
                            />
                            <div className={`communication-btn-wrapper`}>
                                <PrimaryButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.isUnauthPage
                                            ? handleSaveUnauth()
                                            : handleSave();
                                    }}
                                    className={`communication-save-btn`}
                                >
                                    {communicationPreferenceLabels?.btnSave}
                                </PrimaryButton>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </>
    );
};

export default EmailCommunicationPreferencesFormSection;
