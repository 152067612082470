import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    DynamicMastheadGreetingStyle,
    DynamicMastheadProperties,
} from '@sections/account-portal/components/vehicle-tabs/hooks/use-dynamic-masthead-content';
import SecondaryButton from '@common/secondary-button/secondary-button';
import { VehicleAttributes } from '@models/vehicle-attributes';
import { useVehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import { useAnalytics } from '@hooks/use-analytics';
import './dynamic-banner.scss';
import { useWindowSize } from '@hooks/use-window-size';

interface Props {
    dynamicMastheadProperties: DynamicMastheadProperties;
    selectedDynamicMasthead: DynamicMastheadGreetingStyle;
    vehiclesData?: VehicleAttributes[];
    setDynamicMastheadProperties: Dispatch<
        SetStateAction<DynamicMastheadProperties>
    >;
    setSelectedDynamicMasthead: Dispatch<
        SetStateAction<DynamicMastheadGreetingStyle>
    >;
    backgroundImageDesktop: string;
    backgroundImageMobile: string;
}

export const DynamicBanner = (props: Props) => {
    const vehicleContentFragments = useVehicleCarouselContent();
    const [fireAnalytics] = useAnalytics();
    const size = useWindowSize();
    const TABLET_MOBILE_BREAKPOINT = 768;
    const [backgroundImage, setBackgroundImage] = useState('');
    const dynamicMastheadClasses: string =
        props.selectedDynamicMasthead?.orientation === 'greeting-left'
            ? 'dynamic-masthead-state dynamic-masthead-state__orientation--left'
            : 'dynamic-masthead-state dynamic-masthead-state__orientation--right';

    useEffect(() => {
        const configureLayout = () => {
            const isMobile =
                props.dynamicMastheadProperties?.hasDynamicMasthead &&
                size.width < TABLET_MOBILE_BREAKPOINT;

            if (isMobile) {
                setBackgroundImage(props.backgroundImageMobile);
            } else {
                setBackgroundImage(props.backgroundImageDesktop);
            }
        };
        configureLayout();
    }, [size.width, props.backgroundImageMobile, props.backgroundImageDesktop]);

    const cardDataProps = props.vehiclesData.map((data) => {
        const { model, vin, year, nickName, fuelType } = data;
        return {
            title: model,
            nickname: nickName,
            description: vin,
            ctaLabel: vehicleContentFragments?.carddetailscta,
            year: year,
            ctaHref: vehicleContentFragments?.carddetailsctaPath + vin,
            fuelType: fuelType,
            vehicleAttributes: data,
        };
    });

    return (
        <>
            {props.dynamicMastheadProperties?.hasDynamicMasthead &&
            props.selectedDynamicMasthead ? (
                <div className={dynamicMastheadClasses}>
                    <div className="dynamic-masthead">
                        <div
                            className="background-image"
                            style={{
                                backgroundImage: `url('${backgroundImage}')`,
                            }}
                        >
                            <div className="dynamic-masthead-content-wrapper">
                                <div
                                    className="dynamic-masthead-content"
                                    data-testid="dynamic-masthead-content"
                                >
                                    <h4
                                        className="fmc-type-heading4"
                                        data-testid="dynamic-masthead-title"
                                    >
                                        {props.selectedDynamicMasthead.title}
                                    </h4>
                                    <p
                                        className="fmc-type-body1"
                                        data-testid="dynamic-masthead-description"
                                    >
                                        {
                                            props.selectedDynamicMasthead
                                                .description
                                        }
                                    </p>
                                    <SecondaryButton
                                        className={`fmc-button ${
                                            cardDataProps.length > 0
                                                ? ''
                                                : 'fmc-button--outlined'
                                        }`}
                                        link={
                                            props.selectedDynamicMasthead
                                                .ctaHref
                                        }
                                        onClick={() =>
                                            fireAnalytics(
                                                'mastheadOnClickEvent'
                                            )
                                        }
                                        dataDark={true}
                                        internal={false}
                                        dataTestId="dynamic-masthead-cta"
                                    >
                                        {props.selectedDynamicMasthead.ctaText}
                                    </SecondaryButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
