import React, { ReactElement, useEffect } from 'react';
import './create-return-modal.scss';
import {
    ModalContextProps,
    useModalContext,
} from '../../../contexts/modalContext';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import {
    OrderDetailsContent,
    useOrderDetailsContent,
} from '@/views/order-details-view/hooks/use-order-details-content';
import {
    OmsOrderDetailsResponse,
    OrderLineDetails,
} from '@/services/oms-service/oms-service';
import OrderDetailsItem from '@/views/order-details-view/components/order-details-item/order-details-item';
import { useOrderStatusContent } from '@/views/order-details-view/hooks/use-nvc-order-status-content';

const className = 'create-return';
interface ModalContentInterface {
    isModalVisible: boolean;
    modalContentFragments: OrderDetailsContent;
    selectedItems: OrderLineDetails[];
    handleClose: () => void;
    orderDetails: OmsOrderDetailsResponse;
    returnReason: string;
    onError: (errorFlag: boolean) => void;
    onConfirm: () => void;
}

const CreateReturnModal = (props: ModalContentInterface): ReactElement => {
    let ariaLabel: string;

    const contentLabels = props.modalContentFragments;
    const { setContext, resetContext } = useModalContext();
    const appConfig = new AppConfigurationService();
    const isLincoln = appConfig.brand === 'lincoln';
    const orderDetailsContent = useOrderDetailsContent();
    const orderStatusContent = useOrderStatusContent();

    const modalContent = () => (
        <div>
            <h2
                className={`fmc-type--heading6 ${
                    isLincoln
                        ? 'fds-color__text--primary fds-align--center'
                        : ''
                }`}
            >
                {contentLabels?.returnsModalTitle}
            </h2>
            <p
                className={`fmc-type--body2 ${
                    isLincoln ? 'fds-color__text--gray3 fds-align--center' : ''
                }`}
            >
                {contentLabels?.returnsModalSubtitle}
            </p>
            {props.selectedItems?.map((item: OrderLineDetails) => (
                <OrderDetailsItem
                    key={item.orderLineKey}
                    itemData={item}
                    orderDetailsContent={orderDetailsContent}
                    orderStatusContent={orderStatusContent}
                    returnDisabled={false}
                    return={true}
                />
            ))}
        </div>
    );

    const createReturnModalProps: ModalContextProps = {
        modalType: {
            name: 'create-return-modal',
            'aria-label': ariaLabel || 'Create return modal',
            primaryButtonLabel: contentLabels?.confirmReturn,
            secondaryButtonLabel: contentLabels?.changeSelection,
            role: 'dialog',
            onPrimaryButtonClick: () => {
                props.onConfirm();
                resetContext();
            },
            onSecondaryButtonClick: () => {
                props.handleClose();
                resetContext();
            },
            onAfterClose: () => {
                props.handleClose();
                resetContext();
            },
            modalContentClass: `${className}-modal-content`,
            modalFooterClass: `${className}-modal-footer`,
            children: modalContent(),
        },
    };

    useEffect(() => {
        if (props.isModalVisible) {
            setContext(createReturnModalProps);
        }
    }, [props]);

    return <></>;
};

export default CreateReturnModal;
