import { EU_BING_BASE_URL, EU_BING_KEY } from '@constants';
import HttpService from '@services/http-service/http-service';
import { encodeParameters } from '@services/google-map-service/google-map-service';
import { EUDealer } from '@models/preferred-dealer';

export default class EuBingService {
    static searchDealersByProperties = async (
        limit: number,
        callback: (dealers: EUDealer[]) => void,
        matchParameters: any
    ): Promise<EUDealer[]> => {
        const baseUrl = EU_BING_BASE_URL + '?';
        const select = '$select=*',
            matchArr = [];
        let filter = '&$filter=';
        const maxResults: string = '&$top=' + limit,
            format = '&$format=json',
            lineCount = '&$inlinecount=allpages',
            key: string = '&key=' + EU_BING_KEY;

        encodeParameters(matchParameters, matchArr);

        if (matchArr.length) {
            filter += matchArr.join('%20And%20');
        } else {
            throw new Error('Must specify at least one match parameter.');
        }

        const url: string =
            baseUrl + select + filter + maxResults + lineCount + format + key;
        const response = await HttpService.get(url, true);

        if (response) {
            return response.data.d.results as EUDealer[];
        }
    };
}
