import React from 'react';
import ChatbotCard from './ChatbotCard';
interface Props {
    cards: [any];
    id: any;
    chatbotSentAccessibilityMessage: string;
}
const ChatbotCards = (props: Props) => {
    const isDealerLocator =
        props.cards[0][0].buttons[0].payload.indexOf('tel:') === 0;
    return (
        <div
            className={`chatbot__message--cards ${
                isDealerLocator ? 'chatbot__message--dealer-locator' : ''
            }`}
        >
            <ul className="chatbot__cards-container">
                {props.cards[0].map((card: any, index: number) => (
                    <ChatbotCard
                        card={card}
                        isDealerLocator={
                            props.cards[0][index].buttons[0].payload.indexOf(
                                'tel:'
                            ) === 0
                        }
                        key={`${props.id}-${index}`}
                    />
                ))}
            </ul>
        </div>
    );
};

export default ChatbotCards;
