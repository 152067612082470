import React from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '@common/primary-button/primary-button';
import { ConnectedServicesErrorContent } from '@views/connected-services-view/hook/use-connected-services-error-content';
import { findPathByAlias } from '@routes/routesList';

export interface ConnectedServicesErrorComponentProps {
    connectedServicesErrorContent: ConnectedServicesErrorContent;
}

const ConnectedServicesErrorComponent = ({
    connectedServicesErrorContent,
}: ConnectedServicesErrorComponentProps) => {
    const navigate = useNavigate();

    return (
        <div
            className="connected-services-error-content"
            data-testid="connected-services-error-content"
        >
            {connectedServicesErrorContent.vinNotFoundInUserGarageText && (
                <h2
                    className="fmc-type--heading3 fds-color__text--primary fds-align--center"
                    data-testid="connected-services-error-message"
                >
                    {connectedServicesErrorContent.vinNotFoundInUserGarageText}
                </h2>
            )}

            {connectedServicesErrorContent.primaryButtonLabelText && (
                <PrimaryButton
                    className="fds-align--center"
                    id="connectedServicesPrimaryButton"
                    testId="connected-services-primary-button"
                    ariaLabel={
                        connectedServicesErrorContent.primaryButtonAriaLabel
                    }
                    onClick={() =>
                        navigate(findPathByAlias('AccountDashboardView'))
                    }
                >
                    {connectedServicesErrorContent.primaryButtonLabelText}
                </PrimaryButton>
            )}
        </div>
    );
};

export default ConnectedServicesErrorComponent;
