import React from 'react';

interface MemberIdProps {
    memberIdFieldLabel: string;
    memberIdNumber: string;
    className?: string;
}

export const MemberId = (props: MemberIdProps) => {
    const { memberIdFieldLabel, memberIdNumber, className } = props;

    if (memberIdFieldLabel && memberIdNumber) {
        return (
            <div
                data-testid="member-id"
                className={`${
                    className
                        ? className
                        : 'fmc-type--content2 fds-color__text--white fmc-mb-2 fmc-pb-1 '
                }fds-align--center`}
            >{`${memberIdFieldLabel} ${memberIdNumber}`}</div>
        );
    }
    return null;
};
