import React, { ReactNode } from 'react';

interface Props {
    href: string;
    className?: string;
    children: ReactNode;
    onClick?: (e?: MouseEvent | TouchEvent) => void;
    target?: string;
    tabIndex?: number;
}

const Link = (props: Props & React.HTMLProps<HTMLAnchorElement>) => (
    <a
        {...props}
        tabIndex={props.tabIndex || 0}
        aria-label={props['aria-label']}
        rel="noopener noreferrer"
    >
        {props.children}
    </a>
);

export default Link;
