import React, { useEffect, useState } from 'react';
import { CX740_CARD, ORDER_STATUS_CODES } from '@constants';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import EncryptionService from '@services/encryption-service/encryption-service';
import { VehicleCarouselContent } from '../../vehicle-tabs/hooks/use-vehicle-carousel-content';
import { Card } from '../../card/card';
import { useAnalytics } from '@hooks/use-analytics';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';

interface Props {
    data: OrderedVehiclesItem;
    identification?: number;
    cardContentFragments?: VehicleCarouselContent;
}

export enum ORDER_TYPE {
    ORDER = 'ORDER',
    ORDERED = 'ORDERED',
    CREATED = 'CREATED',
    AMENDED = 'AMENDED',
    RESERVATION = 'RESERVATION',
    CONTRACT_REQUESTED = 'CONTRACT_REQUESTED',
    COMPLETED = 'COMPLETED',
}

export const VehicleOrderCard = ({
    data,
    cardContentFragments,
    identification,
}: Props) => {
    const encryptionService = new EncryptionService(
        process.env.REACT_APP_CVOT_ENCRYPTION_KEY
    );
    const appConfig: AppConfigurationService = new AppConfigurationService();
    const [fireAnalytics] = useAnalytics();
    const isOrderCard = !!data.status;
    const orderCardAnalyticEventType: string =
        data.id?.length > 4 ? 'ecom' : 'cvot';
    const showOrderCardCTA: boolean =
        (data.id?.length > 4 &&
            cardContentFragments.eComVehicleCardCtaHrefECOM?.length > 0) ||
        (data.id?.length === 4 &&
            cardContentFragments.eComVehicleCardCtaHrefCVOT?.length > 0);
    let hasCvotOrderNumber = data?.id?.length <= 4;
    //TODO remove the line below when new CVOT is ready for launch
    hasCvotOrderNumber = false;
    const reservationCardCTAHref =
        data?.model == CX740_CARD
            ? `${cardContentFragments.eComVehicleCardCtaHrefECOMAlternate}?reservationId=${data?.id}`
            : `${cardContentFragments.eComVehicleCardCtaHrefECOM}?reservationId=${data?.id}`;
    const orderCardEncryptedVin: string = encryptionService.encrypt(data?.vin);
    const orderCardEncryptedDealerOrderNumber: string =
        encryptionService.encrypt(data?.id);
    const ctaHrefForOrderCard: string =
        data?.id?.length > 4
            ? reservationCardCTAHref
            : `${cardContentFragments.eComVehicleCardCtaHrefCVOT}?vin=${orderCardEncryptedVin}&don=${orderCardEncryptedDealerOrderNumber}`;
    const [reservationCardTitle, setReservationCardTitle] =
        useState<string>(null);
    const [reservationNumberLabel, setReservationNumberLabel] =
        useState<string>(null);
    const activeOrderCardTitle: string = reservationCardTitle
        ? cardContentFragments.eComVehicleCardReservationTitle
        : cardContentFragments.eComVehicleCardOrderTitle;
    const activeOrderCardNumberLabel: string = reservationNumberLabel
        ? cardContentFragments.eComVehicleCardReservationNumberLabel
        : cardContentFragments.eComVehicleCardOrderNumberLabel;
    const [orderStatusAlertType, setOrderStatusAlertType] =
        useState<string>('');
    const [orderStatusAlertText, setOrderStatusAlertText] =
        useState<string>('');
    const isEu: boolean = appConfig.getAppConfiguration().fmaRegion === 'eu';
    const marketStatusCodes = ORDER_STATUS_CODES[isEu ? 'eu' : 'us'];
    const orderedVehicleCardClasses: string = isOrderCard
        ? 'ordered-vehicle-card'
        : '';

    const chooseOrderStatusClass = (orderStatus: string) => {
        if (orderStatus && marketStatusCodes) {
            const ordered: boolean =
                data.orderType?.toUpperCase() === ORDER_TYPE.ORDER &&
                (!data.vehicleStatusCode || !data.vin) &&
                !data.readyToOrder;
            const reservation: boolean =
                data.orderType?.toUpperCase() === ORDER_TYPE.RESERVATION &&
                !data.readyToOrder;
            const placeOrder: boolean =
                data.orderType?.toUpperCase() === ORDER_TYPE.RESERVATION &&
                data.readyToOrder;
            const inProduction: boolean =
                marketStatusCodes.inProduction.includes(orderStatus);
            const shipped: boolean =
                marketStatusCodes.shipped.includes(orderStatus);
            const purchaseCompleted: boolean =
                marketStatusCodes.purchaseCompleted.includes(orderStatus);
            const delivered: boolean =
                marketStatusCodes.delivered.includes(orderStatus);

            if (inProduction) {
                setOrderStatusAlertType('fmc-cards__alert--standard');
                setOrderStatusAlertText(
                    cardContentFragments.eComVehicleCardStatusInProduction
                );
            } else if (shipped) {
                setOrderStatusAlertType('fmc-cards__alert--success');
                setOrderStatusAlertText(
                    cardContentFragments.eComVehicleCardStatusShipped
                );
            } else if (purchaseCompleted) {
                setOrderStatusAlertType('fmc-cards__alert--standard');
                setOrderStatusAlertText(
                    cardContentFragments.eComVehicleCardCompletedPurchase
                );
            } else if (delivered) {
                setOrderStatusAlertType('fmc-cards__alert--success');
                setOrderStatusAlertText(
                    cardContentFragments.eComVehicleCardDelivered
                );
            } else if (ordered) {
                setOrderStatusAlertType('fmc-cards__alert--success');
                setOrderStatusAlertText(
                    cardContentFragments.eComVehicleCardStatusOrdered
                );
            } else if (reservation) {
                setReservationCardTitle(
                    cardContentFragments.eComVehicleCardReservationTitle
                );
                setReservationNumberLabel(
                    cardContentFragments.eComVehicleCardReservationNumberLabel
                );
                setOrderStatusAlertType('fmc-cards__alert--success');
                setOrderStatusAlertText(
                    cardContentFragments.eComVehicleCardStatusReservation
                );
            } else if (placeOrder) {
                setReservationCardTitle(
                    cardContentFragments.eComVehicleCardReservationTitle
                );
                setReservationNumberLabel(
                    cardContentFragments.eComVehicleCardReservationNumberLabel
                );
                setOrderStatusAlertType('fmc-cards__alert--standard');
                setOrderStatusAlertText(
                    cardContentFragments.eComVehicleCardStatusPlaceOrder
                );
            }
        }
    };

    useEffect(() => {
        chooseOrderStatusClass(data.vehicleStatusCode || data.status);
    }, [data.vehicleStatusCode, data.status]);

    return (
        <Card
            data={data}
            identification={identification}
            cardContentFragments={cardContentFragments}
            cardTitle={activeOrderCardTitle}
            isLoading={false}
            displayCTA={showOrderCardCTA}
            ctaLabel={cardContentFragments.eComVehicleCardCtaLabel}
            activeOrderCardNumberLabel={activeOrderCardNumberLabel}
            orderStatusAlertType={orderStatusAlertType}
            orderStatusAlertText={orderStatusAlertText}
            onCTAClick={() =>
                fireAnalytics('orderCardCTA', '', {
                    orderType: orderCardAnalyticEventType,
                })
            }
            cardClass={orderedVehicleCardClasses}
            secondaryBtnLink={ctaHrefForOrderCard}
            hasCvotOrderNumber={hasCvotOrderNumber}
        />
    );
};
