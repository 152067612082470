import {
    EventProperty,
    AnalyticsService,
} from '../analytics-service/analytics-service';
import { LogService } from '../log-service/log-service';

export class DataLayerService {
    private static persistentProperties: EventProperty[] = [];

    public static async setProperties(
        properties: EventProperty[],
        on: any,
        recursive = false,
        persistent = false
    ) {
        try {
            if (persistent) {
                DataLayerService.addPersistentProperties(properties);
            }
            await DataLayerService.asyncForEach(
                properties,
                async (eventProperty: EventProperty) => {
                    let property = eventProperty.propertyDescriptor;
                    const currentProperty =
                        property.substring(0, property.indexOf('.')) ||
                        property;
                    property =
                        currentProperty !== property
                            ? property.substring(property.indexOf('.') + 1)
                            : '';

                    if (property.length > 0) {
                        if (on[currentProperty] === undefined)
                            on[currentProperty] = {};
                        await this.setProperties(
                            [
                                {
                                    propertyDescriptor: property,
                                    value: eventProperty.value,
                                },
                            ],
                            on[currentProperty],
                            true
                        );
                    } else {
                        on[currentProperty] = eventProperty.value;
                    }
                }
            );
            if (!recursive) {
                LogService.log(
                    'DataLayer',
                    `Data Layer Populated${
                        persistent ? ' (Persistent Properties)' : ''
                    }`,
                    properties
                );
            }
        } catch (e) {
            throw new Error(
                `Failed while trying to populate data layer. Error: ${e.message}`
            );
        }
    }

    public static getProperty(property: string, from: any): string | undefined {
        const currentProperty =
            property.substring(0, property.indexOf('.')) || property;
        if (from[currentProperty]) {
            property =
                currentProperty !== property
                    ? property.substring(property.indexOf('.') + 1)
                    : '';
            if (property.length > 0) {
                return this.getProperty(property, from[currentProperty]);
            } else {
                return from[currentProperty];
            }
        }
    }

    public static async getPersistentConfigProperties(): Promise<
        EventProperty[] | null
    > {
        const config = await new AnalyticsService().getAnalyticsConfig();
        if (config) {
            const persistentConfig = config['persistentConfig'];
            if (persistentConfig) {
                return persistentConfig['eventProperties'] as EventProperty[];
            }
        }
        return null;
    }

    private static addPersistentProperties(eventProperties: EventProperty[]) {
        eventProperties.forEach((eventProperty) => {
            const existingIndex =
                DataLayerService.persistentProperties.findIndex(
                    (property) =>
                        property.propertyDescriptor ===
                        eventProperty.propertyDescriptor
                );
            if (existingIndex > -1) {
                DataLayerService.persistentProperties[existingIndex] =
                    eventProperty;
            } else {
                DataLayerService.persistentProperties.push(eventProperty);
            }
        });
    }

    public static async populatePersistentProperties() {
        const persistentProperties = await this.getPersistentConfigProperties();
        if (persistentProperties) {
            DataLayerService.addPersistentProperties(persistentProperties);
        }
        const processedPersistentEventProperties: EventProperty[] =
            await new AnalyticsService().handleShortcodes(
                DataLayerService.persistentProperties
            );
        await DataLayerService.setProperties(
            processedPersistentEventProperties,
            (window as any).digitaldata,
            false,
            true
        );
    }

    private static async asyncForEach<T>(array: T[], callback: Function) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }
}
