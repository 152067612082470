import React, { useEffect, ReactNode, useRef, useState } from 'react';

import { useWindowSize } from '@hooks/use-window-size';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { DynamicMastheadProperties } from '@sections/account-portal/components/vehicle-tabs/hooks/use-dynamic-masthead-content';

import './banner.scss';

export interface BannerBottom {
    component: ReactNode;
    marginTop: number | string;
}

interface BannerProps {
    topComponent?: ReactNode;
    bottomComponent?: ReactNode;
    topMasthead?: ReactNode;
    backgroundImageDesktop?: string;
    backgroundImageMobile?: string;
    addClass?: string;
    dynamicMastheadProperties?: DynamicMastheadProperties;
    hasVehicles?: boolean;
}

export const ViewBanner = (props: BannerProps) => {
    const size = useWindowSize();
    const TABLET_BREAKPOINT = 768;
    const MOBILE_BREAKPOINT = 414;
    const DEFAULT_HEIGHT_VALUE = 0;
    const topDivRef = useRef<HTMLDivElement>(null);
    const bottomDivRef = useRef<HTMLDivElement>(null);
    const [backgroundImage, setBackgroundImage] = useState('');
    const [topDivHeight, setTopDivHeight] = useState(DEFAULT_HEIGHT_VALUE);
    const [bottomDivHeight, setBottomDivHeight] =
        useState(DEFAULT_HEIGHT_VALUE);
    const appConfig = new AppConfigurationService();
    const euClass = appConfig.isEUCountry() ? 'search-eu' : '';

    useEffect(() => {
        const configureLayout = () => {
            const bottomDivHeightValue =
                bottomDivRef?.current?.clientHeight || DEFAULT_HEIGHT_VALUE;
            setBottomDivHeight(bottomDivHeightValue / 2);

            const topDivHeightValue =
                topDivRef?.current?.clientHeight ||
                DEFAULT_HEIGHT_VALUE ||
                topDivHeight;
            setTopDivHeight(topDivHeightValue);

            const isMobile = props.dynamicMastheadProperties?.hasDynamicMasthead
                ? size.width <= MOBILE_BREAKPOINT
                : size.width <= TABLET_BREAKPOINT;

            if (isMobile) setBackgroundImage(props.backgroundImageMobile);
            else setBackgroundImage(props.backgroundImageDesktop);
        };

        configureLayout();
    }, [size.width, props.backgroundImageMobile, props.backgroundImageDesktop]);

    const isLincoln = appConfig.brand === 'lincoln';

    return (
        <div className="banner">
            {props.topMasthead && (
                <div
                    className={`top ${
                        bottomDivHeight === 0 ? 'no-bottom' : ''
                    } ${euClass}`}
                    style={
                        isLincoln
                            ? {
                                  backgroundImage: `url('${backgroundImage}')`,
                              }
                            : null
                    }
                >
                    <div className="cta" ref={topDivRef}>
                        {props.topComponent}
                    </div>
                    <div
                        className={`${props.addClass} vehicle-tabs fds-display--flex fds-flex-direction--column`}
                    >
                        {props.topMasthead}
                    </div>
                </div>
            )}
            {props.bottomComponent && (
                <div className="bottom-container">
                    <div className="bottom" ref={bottomDivRef}>
                        {props.bottomComponent}
                    </div>
                </div>
            )}
        </div>
    );
};
