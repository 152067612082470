import React from 'react';
import { formatDate } from '@smart-tiles/smart-tile-fpr/smart-tile-fpr';

export interface ExpiringSoonAlertProps {
    pointsExpirationSignal: string;
    expirationDateText: string;
    expirationDate: string;
    countryCode: string;
}

const ExpiringSoonAlert = (props: ExpiringSoonAlertProps) => {
    const {
        pointsExpirationSignal,
        expirationDateText,
        expirationDate,
        countryCode,
    }: ExpiringSoonAlertProps = props;

    return (
        <div
            className={`fpr__points-expiration ${pointsExpirationSignal}`}
            data-testid="expiring-soon-alert"
        >
            <span>{expirationDateText} </span>
            <span>{formatDate(expirationDate, countryCode)}</span>
        </div>
    );
};

export default ExpiringSoonAlert;
