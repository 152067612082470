import React from 'react';

interface PersonIconProps {
    color: string;
    testId: string;
}

const PersonIcon = (props: PersonIconProps) => {
    const { color, testId }: PersonIconProps = props;

    return (
        <span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="38"
                viewBox="0 0 31 38"
                fill="none"
                data-testid={testId}
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.2026 17.7333C20.1426 17.7333 24.0693 13.8067 24.0693 8.86667C24.0693 3.92667 20.1426 0 15.2026 0C10.2626 0 6.33594 3.92667 6.33594 8.86667C6.33594 13.8067 10.2626 17.7333 15.2026 17.7333ZM15.2005 15.2C11.6539 15.2 8.86719 12.4133 8.86719 8.86666C8.86719 5.31999 11.6539 2.53333 15.2005 2.53333C18.7472 2.53333 21.5339 5.31999 21.5339 8.86666C21.5339 12.4133 18.7472 15.2 15.2005 15.2ZM30.4 38H0V30.4C0 24.8267 4.56 20.2667 10.1333 20.2667H20.2667C25.84 20.2667 30.4 24.8267 30.4 30.4V38ZM27.8646 35.4667V30.4C27.8646 26.22 24.4446 22.8 20.2646 22.8H10.1313C5.95125 22.8 2.53125 26.22 2.53125 30.4V35.4667H27.8646Z"
                    fill={color}
                    data-testid="person-icon-path"
                />
            </svg>
        </span>
    );
};

export default PersonIcon;
