import { DSL_BASE_URL, DSL_API_PATH } from '@constants';
import HttpService from '../http-service/http-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';

interface UpdateUserResponse {
    httpStatus: string;
    status: string;
    requestStatus: string;
    error: {
        message: string;
        statusCode: string;
        statusContext: string;
    };
    lastRequested: string;
    version: string;
    statusCode: string | null;
    message: string | null;
}
interface UpdateEmailResponse {
    responseStatus: {
        status: string;
    };
}

export default class UserEmailChannelPreferenceService {
    private httpService: any;

    constructor(httpService?: HttpService) {
        this.httpService = httpService;
    }

    public async updateEmailChannelPreference(
        payload
    ): Promise<UpdateUserResponse> {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.UPDATE_ECP_PREFERENCE;
        return HttpService.put<any>(url, payload, {}).then((response) => {
            return response.data;
        });
    }
    public async getEmailChannelPreferences(
        consumerId: string | number,
        countryCode: string
    ): Promise<UpdateUserResponse> {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url =
            dslUrl +
            DSL_API_PATH.GET_ECP_PREFERENCES +
            `?consumerId=${consumerId}&countryCode=${countryCode}`;
        return this.httpService.get(url, false, {}).then((response) => {
            return response.data;
        });
    }

    public async updateEmailAddress(
        consumerId: string,
        countryCode: string,
        existingEmail: string,
        email: string
    ): Promise<UpdateEmailResponse> {
        const payload = {
            consumerId,
            countryCode,
            existingEmail,
            email,
        };
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.UPDATE_EMAIL;
        return await this.httpService.put(url, payload);
    }
}
