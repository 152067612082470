import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    CommunicationCenterContent,
    PermissionOption,
} from '@models/communication-center';
import './permission.scss';
import PermissionEdit from '@views/communication-center-view/components/permission/permission-edit/permission-edit';
import PermissionDisplay from '@views/communication-center-view/components/permission/permission-display/permission-display';
import { SecondaryButton } from '@common/index';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { NotificationType } from '@contexts/notificationContext';
import { ProfileWithVehiclesResponse } from '@models/profile-with-vehicles';
import { useWindowSize } from '@hooks/use-window-size';

export interface PermissionProps {
    communicationCenterContent: CommunicationCenterContent;
    profileData: any;
    getProfileData: () => Promise<ProfileWithVehiclesResponse>;
    permissionOption: PermissionOption;
    scrollUtil: ScrollUtil;
    setNotificationStatus: Dispatch<SetStateAction<NotificationType>>;
    isLincoln?: boolean;
}

const Permission = (props: PermissionProps) => {
    const {
        communicationCenterContent,
        profileData,
        getProfileData,
        permissionOption,
        scrollUtil,
        setNotificationStatus,
        isLincoln,
    }: PermissionProps = props;
    const { preferenceOptions } = permissionOption;
    const isEmailPermission: boolean =
        permissionOption.apiVariable === 'EmailPermission';
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const size = useWindowSize();

    useEffect(() => {
        if (size.width < 992 && !isMobile) {
            setIsMobile(true);
        } else if (size.width >= 992 && isMobile) {
            setIsMobile(false);
        }
    }, [size.width]);

    return (
        <div
            className="permission__container"
            data-testid="permission-container"
        >
            <div className="permission-header__container">
                <h4 className="email-preferences-header">
                    {communicationCenterContent?.emailPreferences}
                </h4>
                <SecondaryButton
                    className={`permission__edit-button ${
                        isLincoln ? 'fmc-text-button' : ''
                    } ${isEdit ? 'hide' : ''}`}
                    onClick={() => setIsEdit(true)}
                    aria-label={communicationCenterContent?.editButtonAriaLabel}
                    dataTestId="permission-edit-button"
                >
                    {communicationCenterContent?.editButton}
                </SecondaryButton>
            </div>

            <hr className="email-preferences-divider" />

            {isEdit ? (
                <PermissionEdit
                    profileData={profileData}
                    getProfileData={getProfileData}
                    permissionOption={permissionOption}
                    isEmailPermission={isEmailPermission}
                    preferenceOptions={preferenceOptions}
                    communicationCenterContent={communicationCenterContent}
                    setIsEdit={setIsEdit}
                    setNotificationStatus={setNotificationStatus}
                    scrollUtil={scrollUtil}
                    isMobile={isMobile}
                    isLincoln={isLincoln}
                />
            ) : (
                <PermissionDisplay
                    permissionOption={permissionOption}
                    communicationCenterContent={communicationCenterContent}
                    profileData={profileData}
                />
            )}
        </div>
    );
};

export default Permission;
