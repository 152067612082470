import { useContent } from '@hooks/use-server-content';

export interface UnitsOfMeasurementLabels {
    unitsOfMeasurement: string;
    distanceSectionHeader: string;
    tirePressureSectionHeader: string;
    distanceFieldLabel: string;
    tirePressureFieldLabel: string;
    kilometers: string;
    miles: string;
    bar: string;
    kPa: string;
    psi: string;
    editUnitsOfMeasurementAriaLabel: string;
    btnEdit: string;
    btnCancel: string;
    btnUpdate: string;
    fahrenheit: string;
    celsius: string;
    temperatureFieldLabel: string;
    temperatureSectionHeader: string;
    kilos: string;
    pounds: string;
    weightFieldLabel: string;
    weightSectionHeader: string;
}
export const useUnitsOfMeasurementContent =
    (): UnitsOfMeasurementLabels | null => {
        const [content, getValueByName] = useContent(
            'common',
            'units-of-measurement'
        );
        const contentFragment: any = {};
        if (content) {
            content?.elements.forEach((element) => {
                contentFragment[`${element.name}`] = getValueByName(
                    `${element.name}`
                );
            });
        }

        if (!Object.keys(contentFragment).length) return null;

        return contentFragment as UnitsOfMeasurementLabels;
    };
