import { AuthenticationState } from '@hooks/use-authentication';
import serverSideService from '@services/server-side-service/server-side-service';

const pushEvent = (event?: string) => {
    if (serverSideService.isClientSide() && (window as any).dataLayer) {
        (window as any).dataLayer.push({ event });
    }
};
const authenticationPushEvent = (authenticationState: number) => {
    if (authenticationState === AuthenticationState.Authenticated) {
        pushEvent('user-authenticated');
    } else {
        pushEvent('user-unauthenticated');
    }
};
export { pushEvent, authenticationPushEvent };
