import React from 'react';
import { PreferenceOption } from '@models/communication-center';
import './communication-center-tooltip.scss';

export interface CommunicationCenterTooltipProps {
    preferenceOption: PreferenceOption;
}

const CommunicationCenterTooltip = (props: CommunicationCenterTooltipProps) => {
    const { preferenceOption } = props;

    return (
        <div
            className="communication-center__tooltip"
            data-testid="communication-center-tooltip"
        >
            <div className="communication-center__tooltip-content">
                <h4
                    className="communication-center__tooltip-header"
                    data-testid="tooltip-header"
                >
                    {preferenceOption?.tooltipHeader}
                </h4>
                <p
                    className="communication-center__tooltip-text"
                    data-testid="tooltip-body"
                    dangerouslySetInnerHTML={{
                        __html: preferenceOption?.tooltipBody,
                    }}
                />
            </div>
        </div>
    );
};

export default CommunicationCenterTooltip;
