import { useContent } from '@hooks/use-server-content';

export interface CarouselSlideContent {
    vinLabelText: string;
    mileageLabelText: string;
    orderNumberLabelText: string;
    reservationNumberLabelText: string;
    orderCardPrimaryCTALabelText: string;
    orderCardPrimaryCTALabelHref: string;
    vehicleSlidePrimaryCTALabelText: string;
    vehicleSlidePrimaryCTALabelHref: string;
    vehicleSlideRemoveVehicleButtonText: string;
    vehicleSlideRemoveVehicleButtonAriaLabel: string;
    orderStatusLabelText: string;
    vehicleHealthServicesNeededText: string;
    backgroundImageSUVsAndCarsDesktop: string;
    backgroundImageSUVsAndCarsMobile: string;
    backgroundImageTrucksAndVansDesktop: string;
    backgroundImageTrucksAndVansMobile: string;
    backgroundImageElectrifiedDesktop: string;
    backgroundImageElectrifiedMobile: string;
    backgroundImagePerformanceDesktop: string;
    backgroundImagePerformanceMobile: string;
}

export const useCarouselSlideContent = (): CarouselSlideContent | null => {
    const [content, getValueByName] = useContent('common', 'carousel-slide');
    const contentFragment: any = {};
    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    return contentFragment as CarouselSlideContent;
};
