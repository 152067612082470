import React, { ReactNode } from 'react';
import './slide.scss';
import { CarouselSlideContent } from '@sections/account-portal/components/vehicle-carousel/slides/hooks/use-carousel-slide-content';
import { useWindowSize } from '@hooks/use-window-size';
import { useVehicleImage } from '@sections/account-portal/hooks/use-vehicle-image';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { CustomerBooking } from '@services/gcct-service/gcct-service';
import { ModalContextProps, useModalContext } from '@contexts/modalContext';
import { VehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import { CVOT_AUTHENTICATED_ORDER_STORAGE_KEY, NA_COUNTRIES } from '@constants';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { useAnalytics } from '@hooks/use-analytics';
import { Notification } from '@sections/account-portal/components/notification-message/notification';
import { NotificationType } from '@contexts/notificationContext';
import { useNavigate } from 'react-router-dom';
import { findPathByAlias } from '@routes/routesList';
import trashBin from '@assets/Trashbin.svg';

interface SlideProps {
    data: any;
    orderStatusAlertText?: string;
    hasCvotOrderNumber?: boolean;
    secondaryButtonLink?: string;
    onCTAClick?: () => void;
    booking?: CustomerBooking;
    children?: ReactNode;
    slideContent: CarouselSlideContent;
    carouselContentFragment: VehicleCarouselContent;
    isOrderCard?: boolean;
    isLoading?: boolean;
    isReservation?: boolean;
    onCardRemove?: () => void;
    onModalClose?: () => void;
    showCta?: boolean;
}
const Slide = ({
    data,
    children,
    slideContent,
    isOrderCard,
    carouselContentFragment,
    orderStatusAlertText,
    isReservation,
    onCardRemove,
    onModalClose,
    isLoading,
    secondaryButtonLink,
    booking,
    hasCvotOrderNumber,
    showCta,
}: SlideProps) => {
    const appConfig = new AppConfigurationService();
    const isNA: boolean = NA_COUNTRIES.includes(
        appConfig.get2LetterCountryCode()
    );
    const [fireAnalytics] = useAnalytics();
    const { setContext } = useModalContext();
    const navigate = useNavigate();
    const imageUrl = data?.imageUrl ? data?.imageUrl : null;
    const hasStatusAndVin: boolean = data?.status && data.vin;
    const isCVOTOrder: boolean = hasStatusAndVin && data.id.length === 4;
    const isLincoln = appConfig.brand === 'lincoln';
    const isFord = appConfig.brand === 'ford';

    const vinForVehicleImageLookup =
        isOrderCard && data.vin
            ? { vin: data.vin, year: data.year }
            : data?.vehicleAttributes;
    const vehicleImage = useVehicleImage(
        vinForVehicleImageLookup,
        imageUrl,
        'undefined',
        appConfig.current3LetterCountryCapitalizedCode
    );
    const cvotOrEcomm = (secondaryButtonLink) => {
        secondaryButtonLink.includes('vehicleordertracking') ||
        secondaryButtonLink.includes('vehicle-order-tracking')
            ? fireAnalytics('vehicleOrderStatusOnclickEvent')
            : fireAnalytics('fvOrderDetailsOnclickEvent');
    };
    const size = useWindowSize();
    const isMobile: boolean = size.width < 768;
    const gvtCtaHref: string = booking
        ? `${carouselContentFragment.gvtCTAHref + booking.bookingId}`
        : '';

    const deleteVehicleModalProps: ModalContextProps = {
        modalType: {
            name: 'delete-vehicle-modal',
            isVisible: true,
            onPrimaryButtonClick: () => onCardRemove(),
            onSecondaryButtonClick: () => onModalClose(),
            onAfterClose: () => onModalClose(),
            primaryButtonLabel: carouselContentFragment?.btnConfirm,
            primaryButtonAriaLabel:
                carouselContentFragment?.btnConfirmAriaLabel,
            secondaryButtonLabel: carouselContentFragment?.btnCancel,
            secondaryButtonAriaLabel:
                carouselContentFragment?.deleteVehicleBtnCancelAriaLabel,
            closeButtonAriaLabel:
                carouselContentFragment?.modalCloseButtonAriaLabel,
            children: (
                <>
                    <h2
                        className={`fmc-type--heading5 ${
                            isLincoln
                                ? 'fds-color__text--primary delete-vehicle-modal-header'
                                : ''
                        }`}
                    >
                        {carouselContentFragment?.deletevehiclemodalheader}
                    </h2>
                    {isFord && (
                        <p className="fmc-type--body1 delete-vehicle-modal-body1">
                            {carouselContentFragment.deleteVehicleModalBody}
                        </p>
                    )}
                </>
            ),
        },
    };
    return (
        <>
            {isLoading && (
                <ActivityIndicator
                    className={'fds-activity-indicator__center'}
                />
            )}
            <div
                className={`slide__container ${
                    isOrderCard ? 'order-slide' : ''
                } ${!vehicleImage ? 'center-content-without-image' : ''}`}
            >
                <div className="slide__content-area">
                    {!isOrderCard && (
                        <button
                            className={
                                slideContent.vehicleSlidePrimaryCTALabelText ||
                                carouselContentFragment.carddetailscta
                                    ? 'slide__secondary-button'
                                    : 'slide__secondary-button no-padding'
                            }
                            data-testid="delete-vehicle-button"
                            aria-label={
                                !isNA
                                    ? carouselContentFragment.vehicleCardDeleteButtonAriaLabel
                                    : slideContent.vehicleSlideRemoveVehicleButtonAriaLabel
                            }
                            onClick={() => {
                                fireAnalytics('deleteTooltipOnclickEvent');
                                sessionStorage.setItem(
                                    'isEditId',
                                    data.description
                                );
                                setContext(deleteVehicleModalProps);
                            }}
                        >
                            <span className="slide__secondary-button--icon">
                                <img src={trashBin} alt="" />
                            </span>
                        </button>
                    )}
                    <div
                        className={`vehicle-container${
                            showCta ? '' : '-no-cta'
                        }`}
                    >
                        <div className="ymm-and-vin">
                            <h3 className="ymm">
                                {' '}
                                {isOrderCard
                                    ? `${data.year} ${data.model}`
                                    : data?.vehicleType ||
                                      `${data?.vehicleAttributes?.year} ${data?.vehicleAttributes?.model}`}
                            </h3>
                            {isOrderCard && (
                                <p className="order-and-reservation-number">
                                    {`${
                                        isReservation
                                            ? slideContent.reservationNumberLabelText ||
                                              carouselContentFragment.eComVehicleCardReservationNumberLabel
                                            : slideContent?.orderNumberLabelText ||
                                              carouselContentFragment.eComVehicleCardOrderNumberLabel
                                    }: ${data.id}`}
                                </p>
                            )}
                            {!isOrderCard && (
                                <div className="vin-and-mileage__container">
                                    <div className="vin__container">
                                        <span className="vin__label">
                                            {isNA
                                                ? slideContent.vinLabelText
                                                : carouselContentFragment.vinText}
                                            {': '}
                                        </span>
                                        <span className="vin__value">
                                            {data?.vin ||
                                                data?.vehicleAttributes?.vin}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {vehicleImage ? (
                            <div
                                className={`slide__vehicle-image--container${
                                    showCta ? '' : '-no-cta'
                                }`}
                            >
                                <img
                                    alt=""
                                    className="slide__vehicle-image"
                                    src={vehicleImage}
                                    crossOrigin="anonymous"
                                />
                            </div>
                        ) : null}
                    </div>
                    {!!booking && (
                        <div className="slide__notification-container">
                            <Notification
                                status={NotificationType.Info}
                                mainCopy={carouselContentFragment.gvtMainCopy}
                                subCopy={carouselContentFragment.gvtSubCopy}
                                ctaText={carouselContentFragment.gvtCTAText}
                                ctaHref={gvtCtaHref}
                                ctaAriaLabel={
                                    carouselContentFragment.gvtCTAAriaLabel
                                }
                                analyticsEvent={'gvtOnclickEvent'}
                            />
                        </div>
                    )}
                    <div
                        className={`order-number-and-vin-box ${
                            hasStatusAndVin ? 'has-both' : ''
                        } ${isCVOTOrder ? 'four-digit-order-number' : ''}`}
                    >
                        {hasStatusAndVin && (
                            <p className="" data-testid="order-card-vin">
                                {!isNA
                                    ? carouselContentFragment.vinText
                                    : slideContent?.vinLabelText}
                                {': '}
                                {data.vin}
                            </p>
                        )}
                    </div>
                    {data?.status && (
                        <div
                            className="order-status-box"
                            data-testid="ordered-vehicle-status-box"
                        >
                            <p>
                                {!isNA
                                    ? carouselContentFragment.eComOrderStatusBoxText
                                    : slideContent?.orderStatusLabelText}
                            </p>
                            <p data-testid="order-vehicle-status-alert-text">
                                {orderStatusAlertText}
                            </p>
                        </div>
                    )}

                    {children}

                    <div
                        className={`slide__button-container${
                            showCta ? '' : '-no-cta'
                        }`}
                    >
                        {isOrderCard &&
                        (slideContent.orderCardPrimaryCTALabelText ||
                            carouselContentFragment.eComVehicleCardCtaLabel) ? (
                            <>
                                {hasCvotOrderNumber ? (
                                    <button
                                        className="slide__primary-button"
                                        aria-label={data.ctaAriaLabel}
                                        data-testid="order-slide-cvot-cta"
                                        onClick={() => {
                                            localStorage.setItem(
                                                CVOT_AUTHENTICATED_ORDER_STORAGE_KEY,
                                                JSON.stringify({
                                                    customerOrderNumber:
                                                        data.id,
                                                    vin: data.vin,
                                                })
                                            );
                                            fireAnalytics(
                                                'vehicleOrderStatusOnclickEvent'
                                            );
                                            navigate(
                                                findPathByAlias(
                                                    'VehicleOrderTrackingStatusView'
                                                )
                                            );
                                        }}
                                    >
                                        {slideContent.orderCardPrimaryCTALabelText ||
                                            carouselContentFragment.eComVehicleCardCtaLabel}
                                    </button>
                                ) : (
                                    <a
                                        href={secondaryButtonLink}
                                        target={data.ctaTarget}
                                        className="slide__primary-button"
                                        onClick={() => {
                                            cvotOrEcomm(secondaryButtonLink);
                                        }}
                                        aria-label={data.ctaAriaLabel}
                                    >
                                        {slideContent.orderCardPrimaryCTALabelText ||
                                            carouselContentFragment.eComVehicleCardCtaLabel}
                                    </a>
                                )}
                            </>
                        ) : null}

                        {!isOrderCard &&
                        carouselContentFragment.goToVehicleLabel ? (
                            <div
                                className={`go-to-vehicle-button${
                                    showCta ? '' : '-no-cta'
                                }`}
                            >
                                <a
                                    href={secondaryButtonLink}
                                    target={data.ctaTarget}
                                    className="slide__primary-button"
                                    aria-label={data.goToVehicleAriaLabel}
                                    onClick={() => {
                                        fireAnalytics(
                                            'viewVehicleDetailsOnclickEvent'
                                        );
                                    }}
                                >
                                    {carouselContentFragment.goToVehicleLabel}
                                </a>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Slide;
