import { useEffect, useState } from 'react';
import AuthenticationService from '@services/authentication-service/authentication-service';
import ServiceHandler from '@services/service-handler';
import { RewardPointsBalance } from '@services/loyalty-points-service/loyalty-points-service.interface';

export const useLoyaltyPointsDataForTile = (): RewardPointsBalance | null => {
    const [loyaltyPointsDataForTile, setLoyaltyPointsDataForTile] =
        useState<RewardPointsBalance | null>(null);

    useEffect(() => {
        new AuthenticationService()
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    ServiceHandler.LoyaltyPointsService.request().then(
                        (points) => {
                            setLoyaltyPointsDataForTile(points);
                        }
                    );
                } else {
                    setLoyaltyPointsDataForTile(null);
                }
            });
    }, []);

    return loyaltyPointsDataForTile;
};
