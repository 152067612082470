import React from 'react';
import './api-response-page.scss';

interface ApiResponsePageProps {
    deleteAccountTitle: string;
    deleteAccountIcon: string;
    deleteAccountDescription: string;
}

const ApiResponsePage = (props: ApiResponsePageProps) => {
    const className = 'delete-account';
    return (
        <div
            className={`${className}-container fds-align--center`}
            data-testid="api-response-page"
        >
            <div>
                <h1
                    className={`${className}-title fmc-type--heading5 fds-color__text--primary fds-p--b-2 fds-p--t-4`}
                    data-testid="error-field-title"
                >
                    {props.deleteAccountTitle ? props.deleteAccountTitle : ''}
                </h1>
                <div className="add-address-text fmc-text-button">
                    <img
                        alt=""
                        src={
                            process.env.REACT_APP_AEM_BASE_URL +
                            props.deleteAccountIcon
                        }
                        className="add-address-icon-img"
                    />
                </div>
                <div
                    className={`${className}-description fmc-type--body1 fds-color__text--gray3 fds-p--b-4 fds-p--t-1`}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props.deleteAccountDescription
                                ? props.deleteAccountDescription
                                : '',
                        }}
                        className={'fds-align--center'}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default ApiResponsePage;
