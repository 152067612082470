import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { VoiBannerContent } from '@sections/voi-banner/hooks/use-voi-banner-content';
import PrimaryButton from '@common/primary-button/primary-button';
import { SecondaryButton } from '@/components/common';

import './voi-banner.scss';
import MnvService, { MnvServiceData } from '@services/mnv-service/mnv-service';
import { DIG_PROD_URL, DIG_QA_URL } from '@constants';
import { useAnalytics } from '@hooks/use-analytics';

export interface VoiBannerProps {
    voiBannerContent: VoiBannerContent;
    mnvService: MnvService;
    setIsVoiBannerVisible: Dispatch<SetStateAction<boolean>>;
}

export interface FpsResponse {
    SpecificVehicleView: SpecificVehicleView[];
}

export interface SpecificVehicleView {
    count?: number;
    createdOn?: Date;
    displayName?: string;
    on?: Date;
    returnURL?: string;
    score?: number;
    suffix?: string;
    _PAcode?: string;
    _UID?: string; //can be VIN or config token
    _ZIP?: string;
    _appID?: string;
    _brand?: string;
    _nameplate?: string;
    _trim?: string;
    _year?: string;
}

const VoiBanner = (props: VoiBannerProps) => {
    const { voiBannerContent, mnvService, setIsVoiBannerVisible } = props;
    const [fireAnalytics] = useAnalytics();
    const [mnvData, setMnvData] = useState<MnvServiceData>(null);
    const [hasFiredOnLoadEvent, setHasFiredOnLoadEvent] =
        useState<boolean>(false);
    const digBaseUrl =
        process.env.REACT_APP_ACTIVE_PROFILE === 'prod'
            ? DIG_PROD_URL
            : DIG_QA_URL;
    const digImageType = 'HD-TILE';
    const areRequiredMnvFieldsPresent =
        (mnvData?.dealerUrl || mnvData?.recommendedOfferUrl) &&
        mnvData?.dealerName &&
        mnvData?.recommendedVehicleToken?.year &&
        mnvData?.recommendedVehicleToken?.make &&
        mnvData?.recommendedVehicleToken?.model &&
        mnvData?.recommendedVehicleToken?.trimName &&
        mnvData?.recommendedVehicleToken?.imageToken;

    const buildYmmString = (): string => {
        const ymmString = `${mnvData.recommendedVehicleToken.year} ${mnvData.recommendedVehicleToken.make}`;
        if (
            mnvData.recommendedVehicleToken.trimName.includes(
                mnvData.recommendedVehicleToken.model
            )
        ) {
            return `${ymmString} ${mnvData.recommendedVehicleToken.trimName}`;
        } else {
            return `${ymmString} ${mnvData.recommendedVehicleToken.model} ${mnvData.recommendedVehicleToken.trimName}`;
        }
    };

    const buildVehicleImageUrl = (): string => {
        return `${digBaseUrl}${mnvData.recommendedVehicleToken.make}/${mnvData.recommendedVehicleToken.model}/${mnvData.recommendedVehicleToken.year}/${digImageType}/${mnvData.recommendedVehicleToken.imageToken}/EXT/1/vehicle.png`;
    };

    const getMNVDataUsingFPSData = (fpsData: FpsResponse[]) => {
        let fpsDataUnwrapped: SpecificVehicleView;
        if (fpsData?.length > 0 && fpsData[0].SpecificVehicleView?.length > 0) {
            fpsDataUnwrapped = fpsData[0].SpecificVehicleView[0];
        }

        if (
            fpsDataUnwrapped?._year &&
            fpsDataUnwrapped?._brand &&
            fpsDataUnwrapped?._nameplate &&
            fpsDataUnwrapped?._trim &&
            fpsDataUnwrapped?._ZIP
        ) {
            mnvService
                .getMnvOffer(
                    fpsDataUnwrapped._year,
                    fpsDataUnwrapped._brand,
                    fpsDataUnwrapped._nameplate,
                    fpsDataUnwrapped._trim,
                    fpsDataUnwrapped._ZIP
                )
                .then((mnvData) => {
                    setMnvData(mnvData);
                });
        }
    };

    const getFPSData = () => {
        window['FPS']?.get(
            [
                {
                    SpecificVehicleView: {
                        max: 1,
                    },
                },
            ],
            {
                success: function (data: FpsResponse[]) {
                    getMNVDataUsingFPSData(data);
                },
                error: function (error) {
                    console.log('Error fetching FPS data: ', error);
                },
            }
        );
    };

    useEffect(() => {
        getFPSData();
    }, []);

    useEffect(() => {
        if (voiBannerContent && areRequiredMnvFieldsPresent) {
            setIsVoiBannerVisible(true);
            if (!hasFiredOnLoadEvent) {
                fireAnalytics('ownerAccountVoiBanner');
                setHasFiredOnLoadEvent(true);
            }
        } else {
            setIsVoiBannerVisible(false);
        }
    }, [voiBannerContent, areRequiredMnvFieldsPresent]);

    return (
        <>
            {voiBannerContent && areRequiredMnvFieldsPresent ? (
                <div
                    className="voi-banner__container"
                    data-testid="voi-banner-container"
                >
                    <div className="voi-banner__inner-container">
                        <h3 className="voi-banner__header-text">
                            {voiBannerContent.headerText}
                        </h3>

                        <div className="voi-banner__vehicle-info">
                            <h4
                                className="voi-banner__ymm"
                                data-testid="voi-banner-ymm"
                            >
                                {buildYmmString()}
                            </h4>
                            <p
                                className="voi-banner__dealer-note"
                                data-testid="voi-banner-dealer-note"
                            >
                                {voiBannerContent.dealerNoteText}{' '}
                                <span>{mnvData.dealerName}</span>
                            </p>
                            <p
                                className="voi-banner__vin"
                                data-testid="voi-banner-vin"
                            >
                                {voiBannerContent.vinText}:{' '}
                                {mnvData.recommendedVehicleToken.vin.value}
                            </p>
                        </div>
                        <div className="voi-banner__buttons-container">
                            <PrimaryButton
                                className="voi-banner__explore-vehicles-button"
                                testId="voi-banner-explore-vehicles-button"
                                aria-label={
                                    voiBannerContent.primaryButtonAriaLabel
                                }
                                onClick={() => {
                                    fireAnalytics(
                                        'voiExploreVehicleOnclickEvent'
                                    );
                                    window.open(
                                        mnvData.dealerUrl
                                            ? mnvData.dealerUrl
                                            : mnvData.recommendedOfferUrl,
                                        '_blank'
                                    );
                                }}
                            >
                                {voiBannerContent.primaryButtonLabel}
                            </PrimaryButton>

                            <SecondaryButton
                                className="voi-banner__view-other-vehicles-button fmc-text-button"
                                link={voiBannerContent.secondaryButtonHref}
                                linkTarget="_blank"
                                aria-label={
                                    voiBannerContent.secondaryButtonAriaLabel
                                }
                                onClick={() => {
                                    fireAnalytics(
                                        'voiViewOtherVehiclesOnclickEvent'
                                    );
                                }}
                            >
                                {voiBannerContent.secondaryButtonLabel}
                            </SecondaryButton>
                        </div>

                        <div className="voi-banner__carousel-container">
                            <img
                                src={buildVehicleImageUrl()}
                                alt={`Image of a ${buildYmmString()}`}
                                data-testid="voi-banner-vehicle-image"
                                className="voi-banner__vehicle-image"
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default VoiBanner;
