import { useContent } from '@hooks/use-server-content';

export interface ConnectedServicesModalContentProps {
    modalHeader: string;
    vinLabel: string;
    btnSelect: string;
    btnCancel: string;
    paidSubscriptionsActiveText?: string;
    freeTrialSubscriptionsActiveText?: string;
    paidSubscriptionsExpiringText?: string;
    freeTrialSubscriptionsExpiringText?: string;
}

export const useConnectedServicesModalContent =
    (): ConnectedServicesModalContentProps | null => {
        const [content, getValueByName] = useContent(
            'common',
            'subscription-modal'
        );
        let contentFragment: any = {};
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
        if (contentFragment) {
            if (contentFragment.hide) {
                contentFragment = null;
            }
        }

        if (!contentFragment || !Object.keys(contentFragment).length)
            return null;

        return contentFragment as ConnectedServicesModalContentProps;
    };
