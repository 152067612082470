import * as THREE from 'three';
import React, { SetStateAction } from 'react';
import HttpService from '@services/http-service/http-service';

export default class View360ImageService {
    public async checkIfImageExists(url: string) {
        try {
            const response = await HttpService.get(url, false);
            return response.status >= 200 && response.status <= 299;
        } catch (error) {
            return false;
        }
    }
    public initialize360ViewerOnRef(
        refContainer: React.MutableRefObject<any>,
        imageUrl: string,
        onLoad?: React.Dispatch<SetStateAction<boolean>>
    ) {
        let camera: THREE.PerspectiveCamera;
        let scene: THREE.Scene;
        let renderer: THREE.WebGLRenderer;
        const cameraTarget = new THREE.Vector3(0, 0, 0);

        // Keeps track of the number of animations and requests, so we can control how often we animate
        let animations = 0;
        let animation_requests = 0;
        const updates_to_skip = 3;

        // Sets the dimensions of the canvas
        const width = window.innerWidth;
        const height = window.innerHeight;

        // Keeps track of the current state of operations
        let is_zooming = false;
        let is_translating = false;

        // Keeps track of our current position
        let on_mouse_down_x = 0,
            on_mouse_down_y = 0,
            lon = 180,
            on_mouse_down_lon = 0,
            lat = -10,
            on_mouse_down_lat = 0,
            phi = 0,
            theta = 0;

        // used for translation acceleration. Currently disabled
        const delta_lon = 0;
        const delta_lat = 0;
        let delta_fov = 0;

        // Is used by non-IOS mobile browsers track touch locations when they first appear (for manual zoom scale calculation purposes)
        let on_touch_start_locations = [];

        // Tells us what the minimum and maximum zoom (field of view) settings are
        const min_fov = 52;
        const max_fov = 75;

        // It is used, so we can know when someone has finished using the mouse wheel
        // This lets us know when we can safely stop updating the frame
        let mouse_wheel_timeout = null;

        const loader = new THREE.TextureLoader();

        // Load the image texture first, then do everything once it finishes loading
        const texture = loader.load(imageUrl, function () {
            if (onLoad) onLoad(false);
            init360();
            animate();
        });
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(1, 1);
        texture.colorSpace = THREE.SRGBColorSpace;
        texture.generateMipmaps = false;
        texture.minFilter = THREE.LinearFilter;

        function init360() {
            camera = new THREE.PerspectiveCamera(75, width / height, 1, 1000);
            scene = new THREE.Scene();

            createScene(texture);
            createRenderer(refContainer);
            addEventListeners(refContainer);
            setRequestAnimationFrameCompatibilityVersion();
        }

        function createRenderer(refContainer: React.MutableRefObject<any>) {
            renderer = new THREE.WebGLRenderer();
            renderer.setSize(width, height);
            renderer.setPixelRatio(window.devicePixelRatio);
            refContainer?.current?.appendChild(renderer.domElement);
            refContainer.current.tabIndex = 0; // Make the element focusable
            refContainer.current.focus(); // Explicitly set focus to the div
        }

        function createScene(texture: THREE.Texture) {
            const geometry = new THREE.SphereGeometry(500, 120, 120);
            geometry.applyMatrix4(new THREE.Matrix4().makeScale(-1, 1, 1));

            const material = new THREE.MeshBasicMaterial({
                map: texture,
            });

            const mesh = new THREE.Mesh(geometry, material);

            scene.add(mesh);
        }

        function addEventListeners(refContainer: React.MutableRefObject<any>) {
            refContainer.current.addEventListener(
                'mousedown',
                onDocumentMouseDown,
                false
            );
            refContainer.current.addEventListener(
                'keydown',
                onDocumentKeyDown,
                false
            );
            refContainer.current.addEventListener(
                'mousemove',
                onDocumentMouseMove,
                false
            );
            refContainer.current.addEventListener(
                'mouseup',
                onDocumentMouseUp,
                false
            );
            refContainer.current.addEventListener(
                'mousewheel',
                onDocumentMouseWheel,
                false
            );
            refContainer.current.addEventListener(
                'DOMMouseScroll',
                onDocumentMouseWheel,
                false
            );
            // We use touch events for translation purposes on all mobile browsers
            // We also use it for non-iOS mobile browser zooming
            refContainer.current.addEventListener(
                'touchstart',
                onDocumentTouchStart,
                false
            );
            refContainer.current.addEventListener(
                'touchend',
                onDocumentTouchEndOrCancel,
                false
            );
            refContainer.current.addEventListener(
                'touchcancel',
                onDocumentTouchEndOrCancel,
                false
            );
            refContainer.current.addEventListener(
                'touchleave',
                onDocumentTouchEndOrCancel,
                false
            );
            refContainer.current.addEventListener(
                'touchmove',
                onDocumentTouchMove,
                false
            );

            // We use the gesture events for iOS, they don't work anywhere else right now
            refContainer.current.addEventListener(
                'gesturestart',
                onDocumentGestureStart,
                false
            );
            refContainer.current.addEventListener(
                'gesturechange',
                onDocumentGestureChange,
                false
            );
            refContainer.current.addEventListener(
                'gestureend',
                onDocumentGestureEnd,
                false
            );
            window.addEventListener('resize', onWindowResize, false);
        }

        function setRequestAnimationFrameCompatibilityVersion() {
            window['requestAnimFrame'] = (function () {
                return (
                    window.requestAnimationFrame ||
                    window['webkitRequestAnimationFrame'] ||
                    window['mozRequestAnimationFrame'] ||
                    window['oRequestAnimationFrame'] ||
                    window['msRequestAnimationFrame'] ||
                    function (callback: TimerHandler) {
                        window.setTimeout(callback, 1000 / 30);
                    }
                );
            })();
        }

        function wereControlsTouched(event: any): boolean {
            return event?.path?.some(
                (element: any) => element.id === 'controls'
            );
        }

        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            renderer.setSize(window.innerWidth, window.innerHeight);
            update();
        }

        function onDocumentMouseDown(event?: MouseEvent) {
            event?.preventDefault();
            if (wereControlsTouched(event)) {
                return;
            }

            handleTranslateStart(event);
        }

        function onDocumentMouseMove(event: MouseEvent) {
            event.preventDefault();
            if (wereControlsTouched(event)) {
                return;
            }

            handleTranslate(event);
        }
        function onDocumentKeyDown(event: KeyboardEvent) {
            switch (event.key) {
                case 'ArrowLeft':
                    lon -= 5;
                    break;
                case 'ArrowUp':
                    lat += 5;
                    break;
                case 'ArrowRight':
                    lon += 5;
                    break;
                case 'ArrowDown':
                    lat -= 5;
                    break;
            }
            update();
        }

        function onDocumentMouseUp(event: MouseEvent) {
            event.preventDefault();
            handleTranslateEnd();
        }
        function onDocumentMouseWheel(event: WheelEvent) {
            is_zooming = true;
            handleZoom(event);
            if (!mouse_wheel_timeout) {
                mouse_wheel_timeout = setTimeout(function () {
                    is_zooming = false;
                    mouse_wheel_timeout = null;
                }, 50);
            }
        }

        function onDocumentTouchStart(event: TouchEvent) {
            if (wereControlsTouched(event)) {
                return;
            }

            if (isTranslateEvent(event)) {
                handleTranslateStart(event);
            } else {
                handleZoomStart(event);
            }
        }

        function onDocumentTouchEndOrCancel(event: any) {
            if (isTranslateEvent(event)) {
                handleTranslateEnd();
            } else {
                handleZoomEnd();
            }
        }
        function onDocumentTouchMove(event: any) {
            if (wereControlsTouched(event)) {
                return;
            }

            if (isTranslateEvent(event)) {
                handleTranslate(event);
            } else {
                handleZoom(event);
            }
        }

        function onDocumentGestureStart() {
            is_zooming = true;
        }

        function onDocumentGestureEnd() {
            is_zooming = false;
        }

        function onDocumentGestureChange(event: any) {
            is_zooming = true;
            event.preventDefault();

            handleScale(event.scale);
        }

        function handleScale(scale: number) {
            const new_scale = 1 + (1 - scale) / 20;

            camera.fov *= new_scale;

            updateCameraFov();
        }

        function getEventSingleLocation(event: MouseEvent | TouchEvent) {
            if (
                window.TouchEvent &&
                event instanceof TouchEvent &&
                event.touches
            ) {
                return { x: event.touches[0].pageX, y: event.touches[0].pageY };
            } else if (event instanceof MouseEvent) {
                return { x: event.clientX, y: event.clientY };
            }
        }

        function isTranslateEvent(event: TouchEvent) {
            return !(event.touches && event.touches.length >= 2);
        }

        function updateCameraFov() {
            if (camera.fov > max_fov) {
                camera.fov = max_fov;
                delta_fov = 0;
            } else if (camera.fov < min_fov) {
                camera.fov = min_fov;
                delta_fov = 0;
            }

            camera.updateProjectionMatrix();
        }

        let firstStart = true;
        function handleTranslateStart(event: MouseEvent | TouchEvent) {
            if (firstStart) {
                firstStart = false;
            }

            is_translating = true;

            const location = getEventSingleLocation(event);

            on_mouse_down_x = location.x;
            on_mouse_down_y = location.y;

            on_mouse_down_lon = lon;
            on_mouse_down_lat = lat;
        }

        function handleTranslateEnd() {
            is_translating = false;
        }

        function handleTranslate(event: MouseEvent) {
            if (is_translating === true) {
                const location = getEventSingleLocation(event);
                let speed: number;
                if (camera.fov < 20) {
                    speed = 0.02;
                } else if (camera.fov <= 30) {
                    speed = 0.05;
                } else if (camera.fov <= 45) {
                    speed = 0.1;
                } else if (camera.fov <= 65) {
                    speed = 0.2;
                } else {
                    speed = 0.3;
                }

                lon =
                    (on_mouse_down_x - location.x) * speed + on_mouse_down_lon;
                lat =
                    (location.y - on_mouse_down_y) * speed + on_mouse_down_lat;
            }
        }

        function handleZoom(event: WheelEvent | TouchEvent) {
            is_zooming = true;
            if (
                window.TouchEvent &&
                event instanceof TouchEvent &&
                event.touches.length == 2
            ) {
                const cur_dist = getMultiTouchDistance(event.touches);
                const prev_dist = getMultiTouchDistance(
                    on_touch_start_locations
                );
                const scale = cur_dist / prev_dist;
                handleScale(scale);
            } else if (event instanceof WheelEvent) {
                if (event.deltaY) {
                    camera.fov -= event.deltaY * 0.05;
                    // Opera / Explorer 9
                } else if (event.detail) {
                    camera.fov += event.detail;
                }
            }

            updateCameraFov();
        }

        // Used by touch events
        function handleZoomStart(event: any) {
            is_zooming = true;
            on_touch_start_locations = event.touches;
        }

        // Used by touch events
        function handleZoomEnd() {
            is_zooming = false;
            on_touch_start_locations = [];
        }

        function getMultiTouchDistance(touches: any) {
            if (touches.length < 2) {
                return 0;
            }

            const delta_y = touches[1].pageY - touches[0].pageY;
            const delta_x = touches[1].pageX - touches[0].pageX;

            return Math.sqrt(delta_y * delta_y + delta_x * delta_x);
        }

        function animate() {
            window['requestAnimFrame'](animate);

            if (
                animations <= 10 ||
                is_zooming ||
                is_translating ||
                delta_lon ||
                delta_lat ||
                delta_fov
            ) {
                if (animation_requests % updates_to_skip == 0) {
                    update();
                    animations++;
                }
            }

            animation_requests++;
        }

        function update() {
            lon += delta_lon;
            lat += delta_lat;

            if (delta_fov != 0) {
                camera.fov += delta_fov;
                updateCameraFov();
            }

            lat = Math.max(-85, Math.min(85, lat));
            phi = THREE.MathUtils.degToRad(90 - lat);
            theta = THREE.MathUtils.degToRad(lon);

            cameraTarget.x = 500 * Math.sin(phi) * Math.cos(theta);
            cameraTarget.y = 500 * Math.cos(phi);
            cameraTarget.z = 500 * Math.sin(phi) * Math.sin(theta);

            camera.lookAt(cameraTarget);
            renderer.render(scene, camera);
        }
    }
}
