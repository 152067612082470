import React, { useContext, useEffect, useState } from 'react';
import {
    useVehicleOrderTrackingContent,
    VehicleOrderTrackingContent,
} from '@views/vehicle-order-tracking-view/hooks/use-vehicle-order-tracking-content';
import VehicleOrderTrackingComponent from '@views/vehicle-order-tracking-view/components/vehicle-order-tracking-component';
import {
    AddVehicleContent,
    useAddVehicleContent,
} from '@sections/add-vehicle/hooks/use-add-vehicle';

import './vehicle-order-tracking-view.scss';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import {
    cvotErrors,
    CvotErrors,
} from '@views/vehicle-order-tracking-view/components/vehicle-order-tracking-errors';
import { useAnalytics } from '@/hooks/use-analytics';
import ServerContext from '@/contexts/serverContext';
import { useAppInformationContent } from '@/components/sections/breadcrumbs/hook/use-breadcrumb-content';
import seoService from '@/services/seo-service/seo-service';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';

const VehicleOrderTrackingView = () => {
    useAnalytics('ownerCvot');
    const addVehicleContent: AddVehicleContent = useAddVehicleContent();
    const vehicleOrderTrackingContent: VehicleOrderTrackingContent =
        useVehicleOrderTrackingContent();
    const [vin, setVin] = useState<string>('');
    const [customerOrderNumber, setCustomerOrderNumber] = useState<string>('');
    const [recaptchaToken, setRecaptchaToken] = useState<string>(null);
    const [errors, setErrors] = useState<CvotErrors>(cvotErrors);
    const serverContext = useContext(ServerContext);
    const appInformationContent = useAppInformationContent();
    const appConfig = new AppConfigurationService();
    const isCanada = appConfig.get2LetterCountryCode() === 'ca';

    seoService.set(
        {
            pageTitle: appInformationContent?.cvotPageTitle,
            applicationName: appInformationContent?.applicationName,
            pageDescription: appInformationContent?.cvotPageDescription,
            pageKeywords: appInformationContent?.cvotPageMetaKeywords,
        },
        serverContext
    );

    useEffect(() => {
        new ScrollUtil().scrollPageToTop();
    }, []);

    return (
        <>
            {vehicleOrderTrackingContent && (
                <div
                    className="vehicle-order-tracking__view"
                    data-testid="vehicle-order-tracking-view"
                >
                    <VehicleOrderTrackingComponent
                        addVehicleContent={addVehicleContent}
                        vehicleOrderTrackingContent={
                            vehicleOrderTrackingContent
                        }
                        vin={vin}
                        setVin={setVin}
                        customerOrderNumber={customerOrderNumber}
                        setCustomerOrderNumber={setCustomerOrderNumber}
                        recaptchaToken={recaptchaToken}
                        setRecaptchaToken={setRecaptchaToken}
                        errors={errors}
                        setErrors={setErrors}
                        isCanada={isCanada}
                    />
                </div>
            )}
        </>
    );
};

export default VehicleOrderTrackingView;
