export const generateAccessoriesCardBackground = (
    image: string
): string => `url(${
    process.env.REACT_APP_AEM_BASE_URL + image
}), linear-gradient(
                        4.04deg,
                        rgba(0, 0, 0, 0.6) 13.97%,
                        rgba(0, 0, 0, 0) 27.66%
                    ),
                    linear-gradient(
                        182.46deg,
                        rgba(0, 0, 0, 0.56) 45.91%,
                        rgba(0, 0, 0, 0) 59.15%
                    )`;
