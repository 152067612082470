import React from 'react';

export enum Direction {
    LEFT = 'left',
    RIGHT = 'right',
}
interface CarouselIndicatorProps {
    className?: string;
    backgroundColor: string;
    caretColor: string;
    direction: Direction;
    onClick: () => void;
    disabled: boolean;
    fromCvot?: boolean;
}

const CarouselIndicator = ({
    backgroundColor,
    className,
    caretColor,
    direction,
    onClick,
    disabled,
    fromCvot,
}: CarouselIndicatorProps) => {
    return (
        <>
            {direction === 'left' && (
                <div className="fmc-carousel-indicator__container--left">
                    <button
                        className={`${className} fmc-carousel-indicator__button--left`}
                        data-testid="carousel-indicator-left"
                        onClick={() => onClick()}
                        disabled={disabled}
                        tabIndex={0}
                    >
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24Z"
                                fill={backgroundColor}
                            />
                            <path
                                d="M16.3202 24L27.8402 12.48L29.1842 13.824L19.0082 24L29.1842 34.176L27.8402 35.52L16.3202 24Z"
                                fill={caretColor}
                                stroke={caretColor}
                            />
                        </svg>
                        {fromCvot && <span>Click to navigate left</span>}
                    </button>
                </div>
            )}
            {direction === 'right' && (
                <div className="fmc-carousel-indicator__container--right">
                    <button
                        className={`${className} fmc-carousel-indicator__button--right`}
                        data-testid="carousel-indicator-right"
                        onClick={() => onClick()}
                        disabled={disabled}
                    >
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24Z"
                                fill={backgroundColor}
                            />
                            <path
                                d="M31.68 23.9999L20.16 35.5199L18.816 34.1759L28.992 23.9999L18.816 13.8239L20.16 12.4799L31.68 23.9999Z"
                                fill={caretColor}
                                stroke={caretColor}
                            />
                        </svg>
                        {fromCvot && <span>Click to navigate right</span>}
                    </button>
                </div>
            )}
        </>
    );
};

export default CarouselIndicator;
