import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '@services/http-service/http-service';

export interface AddDeletedVehicleResponse {
    updateStatus: string;
    recordExists: boolean;
}

export interface GetDeletedVehiclesResponse {
    userGuid: string;
    deletedVehicles: DeletedVehicle[];
}

export interface DeletedVehicle {
    vin: string;
    deleteAttempts: number;
    orderStatus: string;
}

export default class DeletedVehicleService {
    private httpService: any;
    private appConfig = new AppConfigurationService();
    private dslUrl = this.appConfig.getAppConfiguration().dslUrl
        ? this.appConfig.getAppConfiguration().dslUrl
        : DSL_BASE_URL;
    private readonly config: any;

    constructor(httpService: HttpService) {
        this.httpService = httpService;

        this.config = {
            headers:
                this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
        };
    }

    public async addDeletedVehicleToDB(
        vin: string,
        orderStatus: string
    ): Promise<AddDeletedVehicleResponse> {
        const url = this.dslUrl + DSL_API_PATH.ADD_DELETED_VEHICLE;
        let response;

        try {
            response = await this.httpService.post(
                url,
                { vin, orderStatus },
                this.config
            );
        } catch (e) {
            return null;
        }

        return response.data;
    }

    public async getDeletedVehicles(): Promise<GetDeletedVehiclesResponse> {
        const url = this.dslUrl + DSL_API_PATH.GET_DELETED_VEHICLES;
        let response;

        try {
            response = await this.httpService.get(url, false, this.config);
        } catch (e) {
            return null;
        }

        return response.data;
    }
}
