import React, { useEffect, useState } from 'react';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { CONNECTED_SERVICES_COUNTRY_SELECTOR } from '@constants';
import CountrySelectorContent from '@views/country-selector-view/country-selector-content/country-selector-content';
import { CountrySelectorOption } from '@models/connected-vehicle';
import { useAnalytics } from '@hooks/use-analytics';
import { useLocation } from 'react-router-dom';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';

import './country-selector-view.scss';

const CountrySelectorView = () => {
    const [fireAnalytics] = useAnalytics();
    const { setNotificationContext } = useNotificationContext();
    const hasQrcParameter = useLocation().search.includes('qrc');
    const [searchValue, setSearchValue] = useState<string>('');
    const filteredCountries: CountrySelectorOption[] =
        searchValue === ''
            ? CONNECTED_SERVICES_COUNTRY_SELECTOR.options
            : CONNECTED_SERVICES_COUNTRY_SELECTOR.options.filter((country) =>
                  country.displayName
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
              );

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
        new ScrollUtil().scrollPageToTop();
    }, []);

    useEffect(() => {
        hasQrcParameter && fireAnalytics('ownerCountrySelector');
    }, [hasQrcParameter]);

    return (
        <div
            className="country-selector-view"
            data-testid="country-selector-view"
        >
            <CountrySelectorContent
                filteredCountries={filteredCountries}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        </div>
    );
};

export default CountrySelectorView;
