import React, { ReactElement } from 'react';
import './error-page.scss';
import { useServiceUnavailableContent } from './hooks/error-page-content';

const className = 'error-page';

interface ErrorProps {
    title?: string;
    description?: string;
}

const ErrorPage = (props: ErrorProps): ReactElement => {
    const content = useServiceUnavailableContent();
    return (
        <div className={`${className}-container fds-align--center`}>
            <div>
                <h1
                    className={`${className}-title fmc-type--heading5 fds-color__text--primary fds-p--b-2 fds-p--t-4`}
                    data-testid="error-field-title"
                >
                    {props.title
                        ? props.title
                        : content?.serviceUnavailableFieldTitle}
                </h1>
                <div
                    className={`${className}-description fmc-type--body1 fds-color__text--gray3 fds-p--b-4 fds-p--t-1`}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props.description
                                ? props.description
                                : content?.serviceUnavailableFieldTitleDescription,
                        }}
                        className={'fds-align--center'}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
