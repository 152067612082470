import React from 'react';

export interface StarIconProps {
    color: string;
}
const StarIconFilled = (props: StarIconProps) => {
    return (
        <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.5007 0.5L8.10083 7.41284L0.5 8.52139L5.89848 13.7012L4.62474 21.5L11.5007 17.9114L18.3766 21.5L17.1029 13.7012L22.5 8.52139L14.8992 7.41284L11.5007 0.5Z"
                fill={props.color}
            />
        </svg>
    );
};

export default StarIconFilled;
