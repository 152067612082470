export interface OrderedVehiclesItem {
    id: string;
    imageUrl?: string;
    catalogId?: string;
    make: string;
    model: string;
    orderType: string;
    purchaseDate: string;
    readyToOrder: boolean;
    status: string;
    vehicleStatusCode: string;
    vin: string;
    year: string;
    showroomJourneyType: boolean;
}

export interface RegionalIdsItem {
    regionalIdType: string;
    regionalIdValue: string;
}

export interface GobOrdersItem {
    ecomId: string;
    engModelYear: string;
    exist: boolean;
    gobId: string;
    guid: string;
    make: string;
    model: string;
    orderingDealerCountryCode: string;
    ptvl: string;
    regionalIds: RegionalIdsItem[];
    saleDate: string;
    source: string;
    state: string;
    vin: string;
}

export interface SapMarketingIdentity {
    brand: string;
    marketingIdentityNamePlate: string;
    modelYear: string;
    nameplate: string;
    salesMarket: string;
    segment: string;
}

export interface SapProduct {
    catalogId: string;
    imageUrl: string;
    marketingIdentity: SapMarketingIdentity;
}

export interface SapEntry {
    displayStatus: string;
    product: SapProduct;
    readyToOrder: boolean;
    vehicleStatusCode: string;
}

export interface SapOrdersItem {
    code: string;
    entries: SapEntry[];
    orderType: string;
    reservationDate: string;
    showroomJourneyType: boolean;
    status: string;
    vin: string;
}

export const CONFIG_FOR_ORDERS_TO_FILTER_OUT = [
    {
        // PROD account #1
        userGuid: 'B879A9EE-A78A-465E-BF47-C92AEC410D46',
        vin: 'WF0TK3SU4MMA42906',
    },
    {
        // PROD account #2
        userGuid: 'C932D62D-22C2-492D-BC86-A9E7A51EE4F3',
        vin: 'WF0TK1EM1MMA31040',
    },
    {
        // Test account PERF
        userGuid: '96AFDF54-554F-43F1-AD2D-F4A6BBCD156B',
        vin: 'WF0TK3SU9NMA48041',
    },
    {
        // INC000293960492
        userGuid: '154e4cc4-5f81-44be-a68e-a2c5daa756a9',
        vin: 'WF0TK1E75MMA31102',
    },
    {
        // REQ000006447168/WO0000003009020
        userGuid: '53271E8C-CAAC-E627-A507-60992D5C7FEF',
        vin: '1FMCU9JZ6PUA15734',
    },
    {
        // REQ000006576039/WO0000003070260
        userGuid: '96B1802D-B0E9-4750-8568-B8300041A3F4',
        vin: '1FTFW1ET1ET1EKE35',
    },
    {
        // REQ000006551493/WO0000003052480
        userGuid: 'C97BC53F-65E1-4E6E-A4F5-6783F1B7DA2D',
        vin: '3FMTK3R7XMMA59533',
    },
    {
        // INC000294298109
        userGuid: '74790970-C420-407B-AFC4-A83831615AF2',
        vin: 'WF0TK1E75MMA31052',
    },
];
