import { DSL_BASE_URL, DSL_API_PATH } from '@constants';
import httpAdapter from 'axios/lib/adapters/http';
import { proxyAgent } from '../vehicle-list-service/vehicle-list-base-service';
import AuthenticationService from '../authentication-service/authentication-service';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import HttpService from '@services/http-service/http-service';

export interface PrivacyPreference {
    preferenceType: string;
    preferenceCode: number;
}

export default class UserCommunicationPreferenceService {
    private httpService: any = HttpService;
    private appConfig = new AppConfigurationService();
    private authenticationService = new AuthenticationService();
    private dslUrl = this.appConfig.getAppConfiguration().dslUrl
        ? this.appConfig.getAppConfiguration().dslUrl
        : DSL_BASE_URL;

    public async updateNaCommunicationPreference(
        userPreference: boolean
    ): Promise<boolean> {
        const url =
            this.dslUrl +
            DSL_API_PATH.UPDATE_NA_PRIVACY_PREFERENCE +
            '?sendMarketingEmails=' +
            userPreference +
            '&countryCode=' +
            this.appConfig
                .getAppConfiguration()
                .threeLetterCountryCode.toUpperCase();
        const authenticationService = new AuthenticationService();
        const payloadRegion = {};
        return this.httpService
            .post(url, payloadRegion, {
                headers:
                    this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
            })
            .then((response: any) => {
                return response.data;
            })
            .catch((e: any) => {
                if (e?.response?.status === 401) {
                    console.error(`User Timeout`);
                    authenticationService.login();
                } else return false;
            });
    }

    public async updateImgCommunicationPreference(
        userPreference: boolean
    ): Promise<boolean> {
        const url = this.dslUrl + DSL_API_PATH.UPDATE_IMG_PRIVACY_PREFERENCE;
        const payloadRegion = {
            optIn: userPreference,
            countryCode: this.appConfig.current3LetterCountryCapitalizedCode,
            region: 'img',
        };
        return this.httpService
            .post(url, payloadRegion, {
                headers:
                    this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
            })
            .then((response: any) => {
                return response.data;
            })
            .catch((e: any) => {
                if (e.response.status === 401) {
                    console.error(`User Timeout`);
                    this.authenticationService.login();
                } else return false;
            });
    }

    public async updatePrivacyPreferencesV3(
        preferences: PrivacyPreference[]
    ): Promise<boolean> {
        const url = this.dslUrl + DSL_API_PATH.UPDATE_PRIVACY_PREFERENCE_V3;
        const body = {
            countryCode: this.appConfig.current3LetterCountryCapitalizedCode,
            privacyPreferences: preferences,
        };
        let response: any;
        try {
            response = await this.httpService.post(url, body, {
                headers:
                    this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),

                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
            });
        } catch (error) {
            if (error?.response?.status === 401) {
                this.authenticationService.login();
            }
            return null;
        }
        return response?.data;
    }
}
