import { AnalyticsService } from '@services/analytics-service/analytics-service';

class GdprCookieConfigurationService {
    public static setAnalyticsCookieConfiguration = (): void => {
        const digitalData: any = (window as any).digitaldata;
        const retrievedCookieConfig = localStorage.getItem(
            'cookie-configuration'
        );
        if (retrievedCookieConfig === null) {
            return;
        }
        const cookieSettings = JSON.parse(retrievedCookieConfig).value;
        const analyticSettings: string[] = [];
        for (const key in cookieSettings) {
            const value = cookieSettings[key];
            const analyticValue = value ? 'yes' : 'no';
            analyticSettings.push(`${key}:${analyticValue}`);
        }
        const userOptInStatus = analyticSettings.join('|');
        new AnalyticsService().setProperties(
            [
                {
                    propertyDescriptor: 'user.optInStatus',
                    value: userOptInStatus,
                },
            ],
            digitalData,
            false,
            true
        );
    };
}

export default GdprCookieConfigurationService;
