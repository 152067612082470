import React from 'react';
import './toggle.scss';

export interface ToggleProps {
    handleChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
    isToggleChecked: boolean;
    isLincoln?: boolean;
}

const Toggle = (props: ToggleProps) => {
    const { isToggleChecked, handleChange, isLincoln } = props;

    const generateIconPath = (): string => {
        const checkmarkIcon = `./icons/toggle--checkmark-icon${
            isLincoln ? '-lincoln' : ''
        }.svg`;
        const xIcon = `./icons/toggle--x-icon${
            isLincoln ? '-lincoln' : ''
        }.svg`;
        return isToggleChecked ? checkmarkIcon : xIcon;
    };
    return (
        <div className="toggle__container">
            <label
                htmlFor="toggle"
                className="switch"
                data-testid="toggle"
                aria-label="Toggle"
            >
                <input
                    id="toggle"
                    type="checkbox"
                    name="toggle"
                    data-testid="toggle-switch"
                    checked={isToggleChecked}
                    onChange={(e) => {
                        handleChange && handleChange(e);
                    }}
                    value={isToggleChecked ? 'on' : 'off'}
                />
                <span className="slider round">
                    <span className="slider-knob">
                        <img src={generateIconPath()} alt="" />
                    </span>
                </span>
            </label>
        </div>
    );
};

export default Toggle;
