import React from 'react';
import SmartTile, { SmartTileProps } from './../smart-tile/smart-tile';

import './smart-tile-icon.scss';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

interface Props {
    header: string;
    ctaHref: string;
    ctaTitle: string;
    iconPath: string;
    staticSubCopy: string;
    isMobile?: boolean;
}

const SmartTileIcon = (props: Props & SmartTileProps) => {
    const appConfig = new AppConfigurationService();
    const isFord = appConfig.brand === 'ford';
    const {
        staticTitle,
        staticCTAPath,
        staticCTATitle,
        staticSubCopy,
        iconPath,
    } = props;
    const isPrivacySettings =
        props.type === 'privacy-settings' ? 'privacy-settings-tile' : '';
    const isReservationsAndOrders =
        props.type === 'reservations' ? 'reservations-and-orders-tile' : '';
    const isFordPassRewards =
        props.type === 'ford-pass-rewards' ? 'fordpass-rewards-tile' : '';
    const isFordInsure = !props.type ? 'ford-insure-tile' : '';

    return (
        <SmartTile
            {...props}
            header={staticTitle}
            ctaHref={staticCTAPath}
            ctaTitle={staticCTATitle}
            className="smart-tile-icon"
        >
            <img
                className={`icon ${isFordInsure}`}
                alt=""
                src={process.env.REACT_APP_AEM_BASE_URL + iconPath}
            />
            {isFord ? (
                <div className="smart-tile__content--container">
                    <div className="smart-tile__text--container">
                        <h6
                            className={`fmc-type--subtitle1 ${isPrivacySettings} ${isReservationsAndOrders}`}
                        >
                            {staticTitle ? staticTitle : ''}
                        </h6>
                        <h6 className={`fmc-type--heading6`}>
                            {staticTitle ? staticTitle : ''}
                        </h6>
                    </div>
                    <img src={'./icons/smart-tile-caret--right.svg'} alt="" />
                </div>
            ) : (
                <>
                    <h6
                        className={`fmc-type--subtitle1 ${isPrivacySettings} ${isReservationsAndOrders}`}
                    >
                        {staticTitle ? staticTitle : ''}
                    </h6>
                    <h6 className={`fmc-type--heading6 `}>
                        {staticTitle ? staticTitle : ''}
                    </h6>

                    <div
                        className={`${
                            isFordPassRewards
                                ? 'fmc-type--heading5'
                                : 'fmc-type--body1'
                        }`}
                    >
                        {staticSubCopy}
                    </div>
                </>
            )}
        </SmartTile>
    );
};

export default SmartTileIcon;
