export interface GuestLoginComponentFieldError {
    status: boolean;
    message: string;
}
export interface GuestLoginComponentErrors {
    [key: string]: GuestLoginComponentFieldError;
}

export const guestLoginComponentErrors: GuestLoginComponentErrors = {
    orderNumber: {
        status: false,
        message: '',
    },
    emailAddress: {
        status: false,
        message: '',
    },
};
