import React from 'react';
import SecondaryButton from '@common/secondary-button/secondary-button';
import { LinkTargetValues } from '@constants';
import { FordPassRewardsContent } from '@sections/ford-pass-rewards/hooks/use-ford-pass-rewards-content';
import TertiaryButton from '@common/tertiary-button/tertiary-button';
import ExpiringSoonAlert from '@sections/ford-pass-rewards/components/expiring-soon-alert';

import './ford-pass-rewards.scss';
import { useAnalytics } from '@/hooks/use-analytics';

export interface FordPassRewardsProps {
    appConfig: any;
    points: number | string;
    tier: string;
    expirationDate: string;
    fordPassRewardsContent: FordPassRewardsContent;
    isMobile: boolean;
    hasBeenActivated: boolean;
}

const FordPassRewards = (props: FordPassRewardsProps) => {
    const {
        points,
        tier,
        expirationDate,
        isMobile,
        appConfig,
        hasBeenActivated,
        fordPassRewardsContent,
    }: FordPassRewardsProps = props;
    const countryCode: string = appConfig.currentCountryCode;
    const language: string = appConfig.currentLanguageRegionCode.split('-')[0];
    const languageClass: string = language !== 'en' ? 'not-english' : '';
    const [fireAnalytics] = useAnalytics();
    const showActivateCta: boolean =
        !hasBeenActivated &&
        !!fordPassRewardsContent?.activateCtaHref &&
        !!fordPassRewardsContent?.activateCtaText;
    const showManageCta: boolean =
        !!fordPassRewardsContent?.goToRewardsCtaText &&
        !!fordPassRewardsContent?.goToRewardsCtaHref &&
        hasBeenActivated &&
        points >= 0;
    const showRedeemCta: boolean =
        !!fordPassRewardsContent?.redeemCtaText &&
        !!fordPassRewardsContent?.redeemCtaHref &&
        points > 0;

    const daysUntilExpiration = (): number => {
        const dateNow: number = new Date().getTime();
        const dateOfExpiration: number = new Date(expirationDate).getTime();
        const millisecondsInADay: number = 1000 * 60 * 60 * 24;

        return (dateOfExpiration - dateNow) / millisecondsInADay;
    };
    const generateTierText = (): string => {
        if (tier && fordPassRewardsContent.tierText && language === 'en') {
            return `${tier + ' ' + fordPassRewardsContent.tierText}`;
        } else if (
            tier &&
            fordPassRewardsContent.tierText &&
            language !== 'en'
        ) {
            return `${fordPassRewardsContent.tierText + ' ' + tier}`;
        }

        return fordPassRewardsContent.defaultTierText;
    };
    const showExpireText: boolean =
        daysUntilExpiration() > 0 && daysUntilExpiration() <= 90;
    let pointsExpirationSignal = '';
    if (daysUntilExpiration() <= 90) {
        pointsExpirationSignal =
            daysUntilExpiration() > 30
                ? 'fpr__points-expiration--caution'
                : 'fpr__points-expiration--danger';
    }

    return (
        <>
            {props.fordPassRewardsContent ? (
                <section
                    className={`ford-pass-rewards__section ${
                        points > 0 ? 'has-points' : ''
                    } ${showExpireText ? 'expiring-soon' : ''}`}
                    data-testid="ford-pass-rewards"
                >
                    <div className="ford-pass-rewards__content">
                        <div className="row-1">
                            <h5
                                className="ford-pass-rewards__content--title"
                                data-testid="ford-pass-rewards-title"
                                dangerouslySetInnerHTML={{
                                    __html: fordPassRewardsContent.title,
                                }}
                            ></h5>
                            {countryCode === 'us' && (
                                <>
                                    <span className="divider">|</span>

                                    <p
                                        className="ford-pass-rewards__content--tier"
                                        data-testid="ford-pass-rewards-tier-text"
                                    >
                                        {generateTierText()}
                                    </p>
                                </>
                            )}
                        </div>
                        <div className="row-2">
                            <p
                                className="ford-pass-rewards__content--points"
                                data-testid="ford-pass-rewards-points"
                            >
                                {points || 0}{' '}
                                {fordPassRewardsContent.pointsText}
                            </p>

                            {showExpireText && isMobile && (
                                <ExpiringSoonAlert
                                    pointsExpirationSignal={
                                        pointsExpirationSignal
                                    }
                                    expirationDateText={
                                        fordPassRewardsContent.expirationDateText
                                    }
                                    expirationDate={expirationDate}
                                    countryCode={countryCode}
                                />
                            )}
                        </div>
                        <div className="row-3">
                            <div
                                className={`ford-pass-rewards__content--actions ${languageClass}`}
                            >
                                {showManageCta && (
                                    <SecondaryButton
                                        className={`ford-pass-rewards__content--manage-cta`}
                                        dataTestId="ford-pass-rewards-manage-cta"
                                        link={
                                            fordPassRewardsContent.goToRewardsCtaHref
                                        }
                                        linkTarget={LinkTargetValues.BLANK}
                                        onClick={() => {
                                            const fprButton = 'go to rewards';
                                            fireAnalytics(
                                                'fordPassRewardsButtonOnclickEvent',
                                                '',
                                                { fprButton }
                                            );
                                        }}
                                        aria-label={
                                            fordPassRewardsContent.goToRewardsCtaAriaLabel
                                        }
                                    >
                                        {
                                            fordPassRewardsContent.goToRewardsCtaText
                                        }
                                    </SecondaryButton>
                                )}

                                {showRedeemCta && (
                                    <TertiaryButton
                                        internal={false}
                                        chevronDirection="right"
                                        dataTestId="ford-pass-rewards-redeem-cta"
                                        labelText={
                                            fordPassRewardsContent.redeemCtaText
                                        }
                                        link={
                                            fordPassRewardsContent.redeemCtaHref
                                        }
                                        linkTarget={LinkTargetValues.BLANK}
                                        onClick={() => {
                                            const fprButton = 'redeem';
                                            fireAnalytics(
                                                'fordPassRewardsButtonOnclickEvent',
                                                '',
                                                { fprButton }
                                            );
                                        }}
                                        ariaLabel={
                                            fordPassRewardsContent.redeemCtaAriaLabel
                                        }
                                    />
                                )}

                                {showActivateCta && (
                                    <SecondaryButton
                                        className="ford-pass-rewards__content--activate-cta"
                                        dataTestId="ford-pass-rewards-activate-cta"
                                        link={
                                            fordPassRewardsContent.activateCtaHref
                                        }
                                        linkTarget={LinkTargetValues.BLANK}
                                        onClick={() => {
                                            const fprButton = 'activate';
                                            fireAnalytics(
                                                'fordPassRewardsButtonOnclickEvent',
                                                '',
                                                { fprButton }
                                            );
                                        }}
                                        aria-label={
                                            fordPassRewardsContent.activateCtaAriaLabel
                                        }
                                    >
                                        {fordPassRewardsContent.activateCtaText}
                                    </SecondaryButton>
                                )}
                            </div>
                        </div>
                        {showExpireText && !isMobile && (
                            <div className="row-4">
                                <ExpiringSoonAlert
                                    pointsExpirationSignal={
                                        pointsExpirationSignal
                                    }
                                    expirationDateText={
                                        fordPassRewardsContent.expirationDateText
                                    }
                                    expirationDate={expirationDate}
                                    countryCode={countryCode}
                                />
                            </div>
                        )}
                    </div>
                </section>
            ) : null}
        </>
    );
};

export default FordPassRewards;
