import React from 'react';

import { Link } from '@common/index';
import { BetaWrapper } from '@utils/beta-wrapper/beta-wrapper';
import LincolnChevronLight from '@assets/arrow-forward-graphite.svg';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import './hero-tile.scss';
export interface HeroTileProps {
    title: string;
    link: string;
    ariaLabel: string;
    linkTarget?: string;
    imageUrl: string;
    onClick?: () => void;
}
export const HeroTile = (props: HeroTileProps) => {
    const appConfig = new AppConfigurationService();
    return (
        <Link
            href={props.link}
            className="hero-tile"
            target={props.linkTarget}
            aria-label={props.ariaLabel}
            onClick={props.onClick}
        >
            {appConfig.brand === 'lincoln' ? (
                <div className={'coral-border'}></div>
            ) : null}

            <img className="tile-icon" src={props.imageUrl} alt="" />
            <div className="content">
                <BetaWrapper text={props.title} />
                {appConfig.brand === 'lincoln' && (
                    <img src={LincolnChevronLight} alt="" />
                )}
            </div>
        </Link>
    );
};
