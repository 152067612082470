import { useContent } from '@hooks/use-server-content';

export interface ErrorsProps {
    hide: string;
    errorTitle404: string;
    errorDescription404: string;
    errorButtonLabel404: string;
    errorButtonLink404: string;
    errorBackgroundImageDesktop: string;
    errorBackgroundImageMobile: string;
    errorDescription403: string;
}
export const useErrorsContent = (): ErrorsProps | null => {
    const [content, getValueByName] = useContent('common', 'errors');
    let contentFragment: any = {};
    content?.elements.forEach((element) => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide) {
            contentFragment = null;
        }
    }
    return contentFragment as ErrorsProps;
};
