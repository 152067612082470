import React from 'react';

import './order-totals.scss';
import { OrderDetailsContent } from '@views/order-details-view/hooks/use-order-details-content';
import { OmsOrderDetailsResponse } from '@services/oms-service/oms-service';

export interface OrderTotalsProps {
    orderDetailsContent: OrderDetailsContent;
    orderDetailsData: OmsOrderDetailsResponse;
}

const OrderTotals = (props: OrderTotalsProps) => {
    return props.orderDetailsContent ? (
        <div
            className="order-totals__container"
            data-testid="order-totals__container"
        >
            <div className="order-totals__grid">
                <div className="order-totals__grid--item">
                    <p className="subtotal__label" data-testid="subtotal-label">
                        {props.orderDetailsContent.orderSubTotal}
                    </p>
                    <p className="subtotal__value" data-testid="subtotal-value">
                        ${props.orderDetailsData.overallTotals.lineSubTotal}
                    </p>
                </div>
                <div className="order-totals__grid--item">
                    <p className="shipping__label" data-testid="shipping-label">
                        {props.orderDetailsContent.orderShipping}
                    </p>
                    <p className="shipping__value" data-testid="shipping-value">
                        ${props.orderDetailsData.overallTotals.hdrCharges}
                    </p>
                </div>
                <div className="order-totals__grid--item">
                    <p className="tax__label" data-testid="tax-label">
                        {props.orderDetailsContent.orderSalesTax}
                    </p>
                    <p className="tax__value" data-testid="tax-value">
                        ${props.orderDetailsData.overallTotals.grandTax}
                    </p>
                </div>
                <div className="order-totals__grid--item">
                    <hr className="order-totals__divider" />
                </div>
                <div className="order-totals__grid--item">
                    <p
                        className="grand-total__label"
                        data-testid="grand-total-label"
                    >
                        {props.orderDetailsContent.orderTotal}
                    </p>
                    <p
                        className="grand-total__value"
                        data-testid="grand-total-value"
                    >
                        ${props.orderDetailsData.overallTotals.grandTotal}
                    </p>
                </div>
            </div>
        </div>
    ) : null;
};

export default OrderTotals;
