import { useContent } from '@hooks/use-server-content';

export interface SearchContentProps {
    hide: boolean;
    searchTitle: string;
    searchBodyText: string;
    searchBarHelperText: string;
    searchLink: string;
    searchBackgroundImageDesktop: string;
    searchBackgroundImageMobile: string;
    ctaTargetBlank: boolean;
    searchBarAriaLabel: string;
    suggestedResultsListAriaLabel: string;
    searchButtonAriaLabel: string;
    noResultsText: string;
    submitSearchAriaLabel: string;
}
export const useSearchContent = (): SearchContentProps | null => {
    const [content, getValueByName] = useContent('common', 'search');
    const contentFragment: any = {};
    content?.elements.forEach((element) => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });

    return contentFragment as SearchContentProps;
};
