import { useContent } from '@hooks/use-server-content';

export interface AppInformationProps {
    hide: string;
    applicationName: string;
    accountSettingsPageTitle: string;
    accountSettingsPageDescription: string;
    accountDashboardPageTitle: string;
    accountDashboardPageDescription: string;
    breadcrumbStartingValue: string;
    communicationPreferencesPageTitle: string;
    communicationPreferencesPageDescription: string;
    connectedServicesPageTitle: string;
    connectedServicesPageDescription: string;
    walletPageTitle: string;
    walletPageDescription: string;
    changeEmailPageTitle: string;
    changeEmailPageDescription: string;
    cvotPageTitle: string;
    cvotPageDescription: string;
    cvotPageMetaKeywords: string;
}
export const useAppInformationContent = (): AppInformationProps | null => {
    const [content, getValueByName] = useContent('common', 'app-information');
    let contentFragment: any = {};
    content?.elements.forEach((element) => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.applicationName) {
            contentFragment = null;
        }
    }
    return contentFragment as AppInformationProps;
};
