import { useContent } from '@hooks/use-server-content';

export interface MastheadContentProps {
    goodMorningGreeting: string;
    goodAfternoonGreeting: string;
    goodEveningGreeting: string;
    mastheadImageDesktop: string;
    mastheadImageMobile: string;
    withVehicleDescription: string;
    noVehicleDescription: string;
    memberId: string;
    hide: boolean;
    hideImage: boolean;
    morningImageDesktop: string;
    morningImageMobile: string;
    afternoonImageDesktop: string;
    afternoonImageMobile: string;
    eveningImageDesktop: string;
    eveningImageMobile: string;
    overrideModelNameImage: boolean;
    authMastheadImageDesktop: string;
    authMastheadImageMobile: string;
}
export const useMastheadContent = (): MastheadContentProps | null => {
    const [content, getValueByName] = useContent('common', 'masthead');
    const contentFragment: any = {};
    if (content) {
        content?.elements?.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    return contentFragment as MastheadContentProps;
};
