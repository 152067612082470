import React, { useState } from 'react';
import ServiceHandler from '@services/service-handler';
import { Link } from '@common/index';
import {
    SupportedLanguage,
    SupportedLanguageOverride,
} from '@services/app-configuration-service/app-configuration-service';

import './language-toggle.scss';

interface Props {
    className?: string;
    tabIndex: number;
    languageText?: LanguageItem[];
    dataTestId?: string;
}

type LanguageItem = {
    languageUrl?: string;
    id?: string;
    displayName?: string;
};

export const LanguageToggle = (props: Props) => {
    const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);

    const appConfigurationService = ServiceHandler.AppConfigurationService;
    const { currentLanguage, currentDomain, currentRoot } =
        appConfigurationService;

    const [languages] = useState<SupportedLanguage[]>(
        appConfigurationService
            .getSupportedLanguages()
            .filter((l) => l.domain !== currentDomain)
    );

    function getUrlForLanguage(language: SupportedLanguage): string {
        const pathname = appConfigurationService.getPathname();
        const currentPathWithoutBase =
            pathname.indexOf(currentRoot) === 0
                ? pathname.substr(currentRoot.length)
                : '';
        const url = `${window.location.protocol}//${language.domain}${
            window.location.port && ':' + window.location.port
        }${language.root}${currentPathWithoutBase}`;
        return url;
    }

    const languageList = () => {
        if (
            appConfigurationService.getAppConfiguration().environment !==
            'fallback'
        ) {
            return languages.map((language: SupportedLanguage) => (
                <li key={language.domain}>
                    <Link
                        href={`${getUrlForLanguage(language)}`}
                        className="language-option"
                        tabIndex={props.tabIndex}
                    >
                        {language.name}
                    </Link>
                </li>
            ));
        } else {
            return appConfigurationService
                .getLanguageOverrides()
                .map((language: SupportedLanguageOverride) => (
                    <li key={`nodomain-${language.languageRegionCode}`}>
                        <button
                            className="language-option"
                            onClick={() => {
                                (window as any).languageOverride(
                                    language.languageName,
                                    language.languageRegionCode,
                                    language.countryCode,
                                    language.threeLetterCountryCode
                                );
                                window.location.reload();
                            }}
                        >
                            {language.languageName}
                        </button>
                    </li>
                ));
        }
    };

    return (
        <div className="language-toggle-container">
            <button
                tabIndex={props.tabIndex}
                className={`language-toggle ${props.className || ''}`}
                onClick={() => {
                    setPanelIsOpen(!panelIsOpen);
                }}
            >
                <span className="toggle-text">{currentLanguage}</span>
            </button>
            {panelIsOpen && (
                <div className="language-panel">
                    <ul>
                        <li key={currentDomain}>
                            <span className="language-option">
                                {currentLanguage}
                            </span>
                        </li>
                        {languageList()}
                    </ul>
                </div>
            )}
        </div>
    );
};

export const ImgLanguageToggle = (props: Props) => {
    const { dataTestId, tabIndex, languageText } = props;

    return (
        <div
            className="language-toggle-container language-toggle-container__th"
            data-testid={dataTestId}
        >
            <div>
                <Link
                    href={languageText[0].languageUrl}
                    className={`language-option selected-language`}
                    tabIndex={tabIndex}
                    data-testid={'selected-language'}
                >
                    {languageText[0].displayName}
                </Link>
            </div>

            {languageText.slice(1).map((lang, index) => (
                <>
                    <span className={`language-separator`}>|</span>

                    <div key={index}>
                        <Link
                            href={lang.languageUrl}
                            className={`language-option`}
                            tabIndex={tabIndex}
                            data-testid={'unselected-language'}
                        >
                            {lang.displayName}
                        </Link>
                    </div>
                </>
            ))}
        </div>
    );
};
