import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

import {
    CardData,
    VehicleCard,
} from '@sections/account-portal/components/vehicle-carousel/vehicle-card/vehicle-card';
import { VehicleCarouselContent } from '../vehicle-tabs/hooks/use-vehicle-carousel-content';
import { DynamicMastheadProperties } from '../vehicle-tabs/hooks/use-dynamic-masthead-content';
import { SwipePoint, useSwipe } from './hooks/use-swipe';
import { AddVehicleContent } from '../../../add-vehicle/hooks/use-add-vehicle';

import './vehicle-carousel.scss';
import { CustomerBooking } from '@services/gcct-service/gcct-service';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import { VehicleAttributes } from '@models/vehicle-attributes';
import VehicleSlide from '@sections/account-portal/components/vehicle-carousel/slides/vehicle-slide/vehicle-slide';
import { AddVehicleCard } from '@sections/account-portal/components/vehicle-carousel/add-vehicle-card/add-vehicle-card';
import { VehicleOrderCard } from '@sections/account-portal/components/vehicle-carousel/vehicle-order-card/vehicle-order-card';
import CarouselIndicator, {
    Direction,
} from '@sections/account-portal/components/vehicle-carousel/carousel-indicator/carousel-indicator';
import { useCarouselSlideContent } from '@sections/account-portal/components/vehicle-carousel/slides/hooks/use-carousel-slide-content';
import OrderSlide from '@sections/account-portal/components/vehicle-carousel/slides/order-slide/order-slide';

export interface Props {
    appConfig: any;
    addVehicleContent: AddVehicleContent;
    title?: string;
    subtitle?: string;
    cards: CardData[];
    vehiclesOnOrder: OrderedVehiclesItem[];
    updateVehiclesData: (cacheable: boolean) => void;
    carouselText: VehicleCarouselContent;
    onRemove?: () => void;
    myLabel?: string;
    windowSize?: any;
    isMobile?: boolean;
    isTablet: boolean;
    isLincoln?: boolean;
    setIsLoading: any;
    currentVehiclesCount: number;
    setCurrentVehiclesCount: Dispatch<SetStateAction<number>>;
    onOrderStatusVin: string[];
    dynamicMastheadProperties: DynamicMastheadProperties;
    customerBookings: CustomerBooking[];
    garageVehicles: VehicleAttributes[];
    deliveredVehicles: OrderedVehiclesItem[];
    showCarousel: boolean;
    setIsNewVehicleAdded: Dispatch<SetStateAction<boolean>>;
    currentCard: (value: number) => void;
}

export const VehicleCarousel = (props: Props) => {
    const {
        appConfig,
        cards,
        vehiclesOnOrder,
        updateVehiclesData,
        carouselText,
        onRemove,
        isMobile,
        setIsLoading,
        customerBookings,
        isTablet,
        currentCard,
    } = props;
    const isFord: boolean = appConfig.brand === 'ford';
    const isLincoln: boolean = appConfig.brand === 'lincoln';
    const isEu: boolean = appConfig.getAppConfiguration().fmaRegion === 'eu';
    const visibleCards: number = isMobile || appConfig.brand === 'ford' ? 1 : 3;
    const slideContent = useCarouselSlideContent();
    const [cardsPositionX, setCardsPositionX] = useState<number>(0);
    const [swipeOffsetX, setSwipeOffsetX] = useState<number>(0);
    const [swipeStartX, setSwipeStartX] = useState<number | null>(null);
    const [currentCardIndex, setCurrentCardIndex] = useState<number>(0);
    const cardRef = useRef<HTMLLIElement>(null);
    const carouselTransition = {
        left: cardsPositionX + swipeOffsetX,
        transition: isFord ? 'left 500ms linear' : 'left 300ms linear',
        willChange: 'left',
    };
    const totalCards: number =
        cards.length + (props.vehiclesOnOrder.length || 0);
    const totalIterableCards: (CardData | OrderedVehiclesItem)[] = [
        ...cards,
        ...vehiclesOnOrder,
    ];
    const showAddVehicleCard: boolean =
        isLincoln &&
        ((totalCards <= 3 &&
            (props.dynamicMastheadProperties?.hasDynamicMasthead ||
                (!isMobile && !props.isTablet))) ||
            (props.isTablet && totalCards <= 2));
    const cardAddNew = {
        title: carouselText?.addvehiclecardheader,
        description: carouselText?.addvehiclecarddescription,
    };
    const totalCardsWithAddVehicleCardModifier: number = showAddVehicleCard
        ? totalCards + 1
        : totalCards;
    const viewableCardRange: number =
        (isFord && 1) ||
        currentCardIndex + 3 < totalCardsWithAddVehicleCardModifier
            ? currentCardIndex + 3
            : totalCardsWithAddVehicleCardModifier;
    const isNextButtonDisabled: boolean =
        (totalCards === 1 && isMobile) ||
        currentCardIndex + 1 === totalCardsWithAddVehicleCardModifier;
    const showIndicators: boolean =
        (isMobile && totalCards > 1) ||
        (!isMobile && totalCards >= 3) ||
        (props.isTablet && totalCards >= 2) ||
        (isFord && !isMobile && totalCards > 1);

    const handleSwipeMove = useCallback(
        (swipePoint: SwipePoint) => {
            if (swipeStartX) {
                setSwipeOffsetX(swipePoint.x - swipeStartX);
            }
        },
        [swipeStartX, setSwipeOffsetX]
    );

    const handleSwipeEnd = useCallback(() => {
        let closestCardIndex = 0;
        let closestCardX = 0;
        for (let cardIndex = 0; cardIndex <= totalCards - 1; cardIndex++) {
            const cardPosX =
                cardIndex *
                (cardRef.current?.getBoundingClientRect().width || 0);
            if (
                Math.abs(cardsPositionX + cardPosX + swipeOffsetX) <
                Math.abs(cardsPositionX + closestCardX + swipeOffsetX)
            ) {
                closestCardIndex = cardIndex;
                closestCardX = cardPosX;
            }
        }
        setCurrentCardIndex(closestCardIndex);
        currentCard(closestCardIndex + 1);
        setSwipeStartX(null);
        setSwipeOffsetX(0);
    }, [
        cardRef,
        swipeOffsetX,
        setSwipeStartX,
        setCurrentCardIndex,
        totalCards,
        cardsPositionX,
        currentCard,
    ]);

    const handleSwipeStart = useCallback(
        (swipePoint: SwipePoint) => {
            setSwipeStartX(swipePoint.x);
        },
        [setSwipeStartX]
    );

    const containerRef = useSwipe(
        isMobile,
        handleSwipeStart,
        handleSwipeMove,
        handleSwipeEnd
    );

    const updateCardPositions = useCallback(() => {
        setCardsPositionX(
            -currentCardIndex * cardRef.current?.getBoundingClientRect().width
        );
    }, [
        cardRef,
        currentCardIndex,
        setCardsPositionX,
        props.currentVehiclesCount,
    ]);

    const addGradientToNextCard = (
        currentCardIndex: number,
        index: number
    ): boolean => {
        return currentCardIndex + (isMobile || isTablet ? 1 : 2) === index;
    };

    function moveToNextCard() {
        if (currentCardIndex < totalCards) {
            const newIndex = currentCardIndex + 1;
            setCurrentCardIndex(newIndex);
            currentCard(newIndex + 1);
        }
    }
    function moveToPreviousCard() {
        if (currentCardIndex > 0) {
            const newIndex = currentCardIndex - 1;
            setCurrentCardIndex(newIndex);
            currentCard(newIndex + 1);
        }
    }
    const handleCardDelete = (cardIndex) => {
        onRemove();
        props.setCurrentVehiclesCount(props.currentVehiclesCount - 1);

        if (
            (!isMobile ||
                (isMobile &&
                    props.dynamicMastheadProperties?.hasDynamicMasthead)) &&
            totalCards === visibleCards &&
            cardIndex === totalCards - 1
        ) {
            cardIndex = 0;
        } else if (cardIndex > 0) {
            cardIndex = cardIndex - 1;
        }

        setTimeout(() => {
            cardRef.current = document.getElementById(
                `carousel-cards-${cardIndex}`
            ) as HTMLLIElement;
            setCurrentCardIndex(cardIndex);
        }, 2500);
    };

    useLayoutEffect(() => {
        updateCardPositions();
    }, [
        currentCardIndex,
        isMobile,
        props.updateVehiclesData,
        updateCardPositions,
        props.currentVehiclesCount,
    ]);

    useEffect(() => {
        const congratsModal = document.querySelector(
            '.congrats-modal'
        ) as HTMLDivElement;

        if (cards.length && congratsModal?.style.display === 'block') {
            document.body.classList.add('open');
        }
    }, [cards.length, currentCardIndex]);

    return (
        props.showCarousel && (
            <div
                className={`fmc-carousel-container carousel-container ${
                    totalIterableCards.length > 1
                        ? 'multiple-vehicles-state'
                        : ''
                }`}
                data-testid="featured-articles-carousel-id"
            >
                <div
                    id="Carousel"
                    className="js-fmc-carousel-container"
                    ref={containerRef}
                >
                    <ul
                        className="fmc-carousel fmc-cards"
                        style={
                            cardRef
                                ? carouselTransition
                                : { left: cardsPositionX }
                        }
                    >
                        {isFord && (
                            <>
                                {isEu &&
                                    props.vehiclesOnOrder?.map(
                                        (card, index) => {
                                            const isNextSlide =
                                                addGradientToNextCard(
                                                    currentCardIndex,
                                                    index
                                                );

                                            return (
                                                <li
                                                    ref={cardRef}
                                                    key={`${card.id}-${index}`}
                                                    data-testid="carousel-cards"
                                                    id={`carousel-cards-${index}`}
                                                    className={
                                                        isNextSlide
                                                            ? 'carousel-gradient'
                                                            : ''
                                                    }
                                                >
                                                    <div className="card-container">
                                                        <OrderSlide
                                                            data={card}
                                                            identification={
                                                                index + 1
                                                            }
                                                            key={`${index}-${card.id}`}
                                                            carouselContentFragment={
                                                                carouselText
                                                            }
                                                            slideContent={
                                                                slideContent
                                                            }
                                                        />
                                                    </div>
                                                </li>
                                            );
                                        }
                                    )}
                                {cards.map((card, index) => {
                                    const actualIndex =
                                        index + (vehiclesOnOrder.length || 0);
                                    let uniqueBooking: CustomerBooking;
                                    const isNextVehicleSlide: boolean =
                                        addGradientToNextCard(
                                            currentCardIndex,
                                            actualIndex
                                        );

                                    if (customerBookings) {
                                        const vinSpecificBookings: CustomerBooking[] =
                                            customerBookings.filter(
                                                (booking) =>
                                                    booking.vin ===
                                                    card.description
                                            );

                                        uniqueBooking =
                                            vinSpecificBookings.length &&
                                            vinSpecificBookings.reduce(
                                                (bookingA, bookingB) =>
                                                    Date.parse(
                                                        bookingA.bookingStartTime
                                                    ) >
                                                    Date.parse(
                                                        bookingB.bookingStartTime
                                                    )
                                                        ? bookingB
                                                        : bookingA
                                            );
                                    }

                                    return (
                                        <li
                                            ref={(instance) =>
                                                (cardRef.current = instance)
                                            }
                                            key={`${card.description}-${actualIndex}`}
                                            data-testid="carousel-cards"
                                            id={`carousel-cards-${actualIndex}`}
                                            className={
                                                isNextVehicleSlide
                                                    ? 'carousel-gradient'
                                                    : ''
                                            }
                                        >
                                            <div className="card-container">
                                                <VehicleSlide
                                                    data={card}
                                                    identification={
                                                        actualIndex + 1
                                                    }
                                                    key={`${actualIndex}-${card.description}`}
                                                    updateVehiclesData={
                                                        updateVehiclesData
                                                    }
                                                    carouselContentFragment={
                                                        carouselText
                                                    }
                                                    slideContent={slideContent}
                                                    onRemove={() =>
                                                        handleCardDelete(
                                                            actualIndex
                                                        )
                                                    }
                                                    onTabNavigation={(index) =>
                                                        setCurrentCardIndex(
                                                            index
                                                        )
                                                    }
                                                    booking={uniqueBooking}
                                                    deliveredVehicles={
                                                        props.deliveredVehicles
                                                    }
                                                />
                                            </div>
                                        </li>
                                    );
                                })}
                            </>
                        )}

                        {isLincoln && (
                            <>
                                {props.vehiclesOnOrder?.map((card, index) => {
                                    return (
                                        <li
                                            ref={cardRef}
                                            key={`${card.id}-${index}`}
                                            data-testid="carousel-cards"
                                            id={`carousel-cards-${index}`}
                                        >
                                            <div className="card-container">
                                                <VehicleOrderCard
                                                    data={card}
                                                    identification={index + 1}
                                                    key={`${index}-${card.id}`}
                                                    cardContentFragments={
                                                        carouselText
                                                    }
                                                />
                                            </div>
                                        </li>
                                    );
                                })}

                                {cards.map((card, index) => {
                                    const actualIndex =
                                        index + (vehiclesOnOrder.length || 0);
                                    let uniqueBooking: CustomerBooking;

                                    if (customerBookings) {
                                        const vinSpecificBookings: CustomerBooking[] =
                                            customerBookings.filter(
                                                (booking) =>
                                                    booking.vin ===
                                                    card.description
                                            );

                                        uniqueBooking =
                                            vinSpecificBookings.length &&
                                            vinSpecificBookings.reduce(
                                                (bookingA, bookingB) =>
                                                    Date.parse(
                                                        bookingA.bookingStartTime
                                                    ) >
                                                    Date.parse(
                                                        bookingB.bookingStartTime
                                                    )
                                                        ? bookingB
                                                        : bookingA
                                            );
                                    }

                                    return (
                                        <li
                                            ref={(instance) =>
                                                (cardRef.current = instance)
                                            }
                                            key={`${card.description}-${actualIndex}`}
                                            data-testid="carousel-cards"
                                            id={`carousel-cards-${actualIndex}`}
                                        >
                                            <div className="card-container">
                                                <VehicleCard
                                                    data={card}
                                                    identification={
                                                        actualIndex + 1
                                                    }
                                                    key={`${actualIndex}-${card.description}`}
                                                    updateVehiclesData={
                                                        updateVehiclesData
                                                    }
                                                    cardContentFragments={
                                                        carouselText
                                                    }
                                                    onRemove={() =>
                                                        handleCardDelete(
                                                            actualIndex
                                                        )
                                                    }
                                                    onTabNavigation={(index) =>
                                                        setCurrentCardIndex(
                                                            index
                                                        )
                                                    }
                                                    booking={uniqueBooking}
                                                />
                                            </div>
                                        </li>
                                    );
                                })}
                                {showAddVehicleCard && isLincoln ? (
                                    <li>
                                        <div className="card-container">
                                            <AddVehicleCard
                                                addVehicleContent={
                                                    props.addVehicleContent
                                                }
                                                data={cardAddNew}
                                                displayCTA={Boolean(
                                                    carouselText?.carddetailscta &&
                                                        carouselText?.carddetailsctaPath
                                                )}
                                                updateVehiclesData={
                                                    updateVehiclesData
                                                }
                                                cardContentFragments={
                                                    carouselText
                                                }
                                                setIsLoading={setIsLoading}
                                                currentVehiclesCount={
                                                    props.currentVehiclesCount
                                                }
                                                setCurrentVehiclesCount={
                                                    props.setCurrentVehiclesCount
                                                }
                                                onOrderStatusVin={
                                                    props.onOrderStatusVin
                                                }
                                                vehiclesOnOrder={
                                                    props.vehiclesOnOrder
                                                }
                                                garageVehicles={
                                                    props.garageVehicles
                                                }
                                                setIsNewVehicleAdded={
                                                    props.setIsNewVehicleAdded
                                                }
                                            />
                                        </div>
                                    </li>
                                ) : null}
                            </>
                        )}
                    </ul>
                </div>
                {showIndicators && !isMobile && isFord ? (
                    <div className="fmc-carousel-indicator__container">
                        <CarouselIndicator
                            direction={Direction.LEFT}
                            backgroundColor="black"
                            caretColor="white"
                            onClick={moveToPreviousCard}
                            disabled={currentCardIndex === 0}
                        />
                        <CarouselIndicator
                            direction={Direction.RIGHT}
                            backgroundColor="black"
                            caretColor="white"
                            onClick={moveToNextCard}
                            disabled={isNextButtonDisabled}
                        />
                    </div>
                ) : null}
            </div>
        )
    );
};
