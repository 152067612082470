import { useContent } from '@hooks/use-server-content';

export interface ChangeEmailContent {
    title: string;
    requiredFieldsText: string;
    existingEmailFieldLabel: string;
    newEmailFieldLabel: string;
    confirmEmailFieldLabel: string;
    existingEmailAriaLabel: string;
    newEmailAriaLabel: string;
    confirmEmailAriaLabel: string;
    emailPatternRules: string;
    errorNonEmpty: string;
    errorEmailInvalid: string;
    errorEmailsDontMatch: string;
    errorInvalidExistingEmail: string;
    errorIcon: string;
    thankYouHeader: string;
    thankYouDescription: string;
    thankYouSuccessIcon: string;
    thankYouSuccessIconAriaLabel: string;
    submitButton: string;
}

export const useChangeEmailContent = (): ChangeEmailContent | null => {
    const [content, getValueByName] = useContent('common', 'change-email');
    const contentFragment: any = {};
    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    return contentFragment as ChangeEmailContent;
};
