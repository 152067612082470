import { useContent } from '@hooks/use-server-content';

export interface SecurityContent {
    returnButtonText: string;
    brandLogo: string;
    signInCredentialsSectionHeader: string;
    emailSectionLabel: string;
    passwordSectionLabel: string;
    MFASectionHeader: string;
    MFADescription: string;
    MFAToggleHeader: string;
    MFAToggleOnLabelText: string;
    MFAToggleOffLabelText: string;
    MFAEnableModalHeaderText: string;
    MFAEnableModalBodyText: string;
    MFAEnableModalConfirmButtonLabelText: string;
    MFAEnableModalConfirmButtonAriaLabel: string;
    MFAEnableModalCancelButtonLabelText: string;
    MFAEnableModalCancelButtonAriaLabel: string;
    MFAEnableModalCloseButtonAriaLabel: string;
    MFADisableModalHeaderText: string;
    MFADisableModalBodyText: string;
    MFADisableModalConfirmButtonLabelText: string;
    MFADisableModalConfirmButtonAriaLabel: string;
    MFADisableModalCancelButtonLabelText: string;
    MFADisableModalCancelButtonAriaLabel: string;
    MFADisableModalCloseButtonAriaLabel: string;
    MFAEmailAuthenticationSectionLabel: string;
    MFAEmailAuthenticationDescriptionText: string;
    MFAAppAuthenticationSectionLabel: string;
    MFAAppAuthenticationDescriptionText: string;
    MFASmsAuthenticationSectionLabel: string;
    MFASmsAuthenticationDescriptionText: string;
    editButtonText: string;
    addButtonText: string;
    removeButtonText: string;
    mfaGetOptionsErrorNotificationMessage: string;
    mfaUpdateUserLevelFlagErrorNotificationMessage: string;
    mfaDeleteOptionErrorNotificationMessage: string;
    errorPageReturnButtonText: string;
    errorPageReturnButtonAriaLabel: string;
    errorPageAlertIcon: string;
    errorPageHeaderText: string;
    errorPageDescriptionText: string;
    errorPageCancelButtonText: string;
    errorPageCancelButtonAriaLabel: string;
}

export const useSecurityContent = (): SecurityContent | null => {
    const [content, getValueByName] = useContent('common', 'security');
    const contentFragment: any = {};

    if (content) {
        content?.elements?.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }

    if (!Object.keys(contentFragment).length) return null;

    return contentFragment as SecurityContent;
};
