const possibleRoutes: string[] = [
    '/myaccount/',
    '/myaccount',
    '/myaccount/account-dashboard',
    '/myaccount/account-dashboard?addvehicle=true',
    '/myaccount/account-dashboard?connectedservices=true',
    '/myaccount/account-settings',
    '/myaccount/communication-preference',
    '/myaccount/account-dashboard/',
    '/myaccount/account-settings/',
    '/myaccount/communication-preference/',
    '/en/myaccount/account-dashboard',
    '/en/myaccount/account-dashboard?addvehicle=true',
    '/en/myaccount/account-dashboard?connectedservices=true',
    '/en/myaccount/account-settings',
    '/en/myaccount/communication-preference',
    '/en/myaccount/account-dashboard/',
    '/en/myaccount/account-settings/',
    '/en/myaccount/communication-preference/',
    '/en/myaccount/',
    '/en/myaccount',
    '/account-dashboard',
    '/account-dashboard?addvehicle=true',
    '/account-dashboard?connectedservices=true',
    '/account-settings',
    '/communication-preference',
    '/account-dashboard/',
    '/account-settings/',
    '/communication-preference/',
    '/ford/th-th',
    '/ford/th-th/account-dashboard',
    '/ford/th-th/account-dashboard?addvehicle=true',
    '/ford/th-th/account-settings',
    '/ford/th-th/communication-preference',
    '/ford/th-th/account-dashboard?connectedservices=true',
    '/ford/th-th/',
    '/ford/th-th/account-dashboard/',
    '/ford/th-th/account-settings',
    '/ford/th-th/communication-preference',
    '/ford/en-th',
    '/ford/en-th/account-dashboard',
    '/ford/en-th/account-dashboard?addvehicle=true',
    '/ford/en-th/account-settings',
    '/ford/en-th/communication-preference',
    '/ford/en-th/account-dashboard?connectedservices=true',
    '/ford/en-th/',
    '/ford/en-th/account-dashboard/',
    '/ford/en-th/account-settings',
    '/ford/en-th/communication-preference',
];

export const remapDragonflySyndicatedHeaderLinks = (
    language: string,
    domain: string,
    pathname: string,
    search: string
) => {
    const href = `https://${domain}${pathname}${search}`;
    const fullPathname: string = pathname + (search || '');

    const languageToggleLink = getLanguageToggleLink(
        language,
        href,
        fullPathname,
        pathname
    );
    const redirectUrl = getRedirectUrl(pathname, fullPathname, search);

    const languageToggleLinks = document.querySelectorAll('.lang-links li a');
    const buttonsAndIconsLinks = document.querySelectorAll(
        '.icon-close2, .icon-reserve.profile-accordion, .cta-button, .icon-profile-fds.profile-accordion, .icon-dotted-menu.profile-accordion'
    );

    if (languageToggleLinks.length) {
        languageToggleLinks.forEach((link) => {
            link.setAttribute('href', languageToggleLink);
        });
    }

    if (buttonsAndIconsLinks.length) {
        buttonsAndIconsLinks.forEach((link) => {
            link.setAttribute('href', redirectUrl);
            link.addEventListener('click', function (event) {
                event.preventDefault();
            });
        });
    }
};

const getLanguageToggleLink = (
    language: string,
    href: string,
    fullPathname: string,
    pathname: string
) => {
    let languageToggleLink: string;

    if (language === 'en-th') {
        if (href.includes('enperf')) {
            languageToggleLink = `${href
                .replace('enperf', 'wwwperf')
                .replace('#', '')}`;
        } else if (pathname.includes('/en')) {
            languageToggleLink = `${fullPathname
                .replace('/en', '')
                .replace('#', '')}`;
        }
    }

    if (language === 'th-th') {
        if (href.includes('wwwperf')) {
            languageToggleLink = `${href
                .replace('wwwperf', 'enperf')
                .replace('#', '')}`;
        } else if (!pathname.includes('/en')) {
            languageToggleLink = `${fullPathname.replace(
                '/myaccount',
                '/en/myaccount'
            )}`;
        }
    }

    if (language === 'en-th' && href.includes('en-th')) {
        languageToggleLink = `${fullPathname.replace('en-th', 'th-th')}`;
    }
    if (language === 'th-th' && href.includes('th-th')) {
        languageToggleLink = `${fullPathname.replace('th-th', 'en-th')}`;
    }

    return languageToggleLink;
};

const getRedirectUrl = (
    pathname: string,
    fullPathname: string,
    search: string
) => {
    let redirectUrl: string;

    const indexWhereFmaStringBeginsWithQuery: number =
        fullPathname.indexOf('?');
    const indexWhereFmaStringBeginsWithAmp: number = fullPathname.indexOf('&');
    const prefixBeforeFmaString: string = fullPathname.slice(
        0,
        indexWhereFmaStringBeginsWithQuery
    );
    const prefixForAmpCase: string = fullPathname.slice(
        0,
        indexWhereFmaStringBeginsWithAmp
    );

    const isGlobalAccountRoute: boolean = possibleRoutes.includes(fullPathname);
    const endsWithSlash: boolean = fullPathname.endsWith('/');

    const hasFmaString: boolean = /[&?]code=/.test(fullPathname);
    const beginsWithCode: boolean = search.match(/[?&]code/)?.index == 0;
    const beginsWithOurParam: boolean =
        /[?]addvehicle=true/.test(search) ||
        /[?]connectedservices=true/.test(search);
    if (hasFmaString) {
        if (beginsWithCode) {
            redirectUrl = pathname + '#';
        } else if (beginsWithOurParam) {
            redirectUrl = prefixForAmpCase + '#';
        } else {
            redirectUrl = prefixBeforeFmaString + '#';
        }
    } else if (isGlobalAccountRoute && !endsWithSlash) {
        redirectUrl = `${fullPathname}#`;
    } else if (endsWithSlash) {
        redirectUrl = fullPathname.replace(/.$/, '#');
    } else {
        redirectUrl = `#`;
    }

    return redirectUrl;
};
