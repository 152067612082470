import { PrimaryButton } from '@/components/common';
import DropdownTemplate from '@/components/common/dropdown-template/dropdown-template';
import { useExperienceContent } from '@/hooks/use-server-content';
import { ExperienceFragmentModel } from '@/services/content-service/content-service.interface';
import OmsService, {
    OrderLineDetails,
} from '@/services/oms-service/oms-service';
import OrderDetailsItem from '@/views/order-details-view/components/order-details-item/order-details-item';
import { useOrderStatusContent } from '@/views/order-details-view/hooks/use-nvc-order-status-content';
import { useOrderDetailsContent } from '@/views/order-details-view/hooks/use-order-details-content';
import {
    NVC_ORDER_STATUS_MAP,
    OrderStatusOption,
    transformDeliveryMethod,
} from '@/views/order-details-view/nvc-status-map';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationType } from '@contexts/notificationContext';
import { Notification } from '@sections/account-portal/components/notification-message/notification';
import CreateReturnModal from '../create-return-modal/create-return-modal';
import { findPathByAlias } from '@/routes/routesList';
import HttpService from '@/services/http-service/http-service';
import { ActivityIndicator } from '@/components/common/activity-indicator/activity-indicator';

export interface ReasonDropdownContent extends ExperienceFragmentModel {
    dropdownOptions: ReasonOptions[];
    ':type': string;
}

export interface ReasonOptions {
    returnReasonLabel: string;
    returnReasonValue: string;
}

const CreateReturnComponent = () => {
    const [reasonValue, setReasonValue] = useState('');
    const [showError, setShowError] = useState<boolean>(false);
    const [isItemReturnLoading, setIsItemReturnLoading] =
        useState<boolean>(false);
    const [experienceFragmentReasonDropdownContent] =
        useExperienceContent<ReasonDropdownContent>(
            'common',
            'return-reason',
            'orderreturnreasondro'
        );
    const [orderDetailsItems, setOrderDetailsItems] =
        useState<OrderLineDetails[]>(null);
    const [selectedItems, setSelectedItems] = useState<OrderLineDetails[]>([]);

    const dropdownTemplateContent = [];
    experienceFragmentReasonDropdownContent?.dropdownOptions.map((item) =>
        dropdownTemplateContent.push(item.returnReasonValue)
    );
    const location = useLocation();
    const navigate = useNavigate();
    const orderDetailsData = location.state?.orderDetails;
    const deliveryMethod: string =
        orderDetailsData?.orderLine[0]?.deliveryMethod;
    const orderHeaderKey = orderDetailsData?.orderHeaderKey;
    const orderDetailsContent = useOrderDetailsContent();
    const orderStatusContent = useOrderStatusContent();
    const [modalState, setModalState] = useState<boolean>(false);
    const omsService = new OmsService(HttpService);

    const continueButtonIsDisabled = selectedItems.length === 0 || !reasonValue;

    const handleItemSelection = (key: string) => {
        setSelectedItems((prevSelectedItems) => ({
            ...prevSelectedItems,
            [key]: !prevSelectedItems[key],
        }));
    };

    const sortItems = (): OrderLineDetails[] => {
        let finalItems: OrderLineDetails[] = [];
        const deliveryMethodTransformed =
            transformDeliveryMethod(deliveryMethod);

        NVC_ORDER_STATUS_MAP[deliveryMethodTransformed].forEach(
            (statusOption: OrderStatusOption) => {
                const filteredList = orderDetailsData.orderLine.filter((item) =>
                    statusOption.options.includes(item.status?.toLowerCase())
                );

                if (filteredList.length) {
                    finalItems = [...finalItems, ...filteredList];
                }
            }
        );

        return finalItems;
    };

    const sortReturnableItems = (items: OrderLineDetails[]) => {
        return items.sort((a, b) => {
            if (
                a.isStorefrontReturnable === 'Y' &&
                b.isStorefrontReturnable !== 'Y'
            ) {
                return -1;
            } else if (
                a.isStorefrontReturnable !== 'Y' &&
                b.isStorefrontReturnable === 'Y'
            ) {
                return 1;
            }
            return 0;
        });
    };

    const sortedReturnableItems = orderDetailsItems
        ? sortReturnableItems([...orderDetailsItems])
        : [];

    const multipleQtyOrderDetails = sortedReturnableItems.flatMap(
        (item) =>
            Array.from(
                {
                    length:
                        item.isStorefrontReturnable === 'Y'
                            ? Number(item.returnableQty)
                            : 1,
                },
                (_, index) => ({
                    ...item,
                    localUniqueKey: `${item.orderLineKey}-${index + 1}`,
                })
            ) as any
    );

    const selectedOrderLines = multipleQtyOrderDetails.filter(
        (item) => selectedItems[item.localUniqueKey]
    );

    const orderLineArray: {
        orderLineKey: string;
        orderedQty: string;
        returnReason: string;
    }[] = [];

    selectedOrderLines.forEach((item) => {
        const existingOrderLine = orderLineArray.find(
            (orderLine) => orderLine.orderLineKey === item.orderLineKey
        );

        if (existingOrderLine) {
            existingOrderLine.orderedQty = (
                parseFloat(existingOrderLine.orderedQty) + 1
            ).toFixed(1);
        } else {
            orderLineArray.push({
                orderLineKey: item.orderLineKey,
                orderedQty: '1.0',
                returnReason: reasonValue,
            });
        }
    });

    const requestBody = {
        orderLine: orderLineArray,
    };

    const customerEmailId = orderDetailsData?.customerEmailId;

    const navigateToConfirmation = async () => {
        setSelectedItems([]);
        setModalState(false);
        setIsItemReturnLoading(true);
        await omsService
            .createReturn(requestBody, orderHeaderKey, customerEmailId)
            .then((createReturn) => {
                if (!createReturn) {
                    setShowError(true);
                    setIsItemReturnLoading(false);
                } else {
                    navigate(findPathByAlias('ReturnConfirmationView'), {
                        state: {
                            returnResponseContent: createReturn,
                            guestReturn: location.state?.guestReturn,
                            guestOrder: location.state?.guestOrder,
                        },
                    });
                }
            });
    };

    const handleClose = async () => {
        setSelectedItems([]);
        setModalState(false);
    };

    const handleError = (errorFlag: boolean) => {
        setShowError(errorFlag);
    };

    useEffect(() => {
        if (orderDetailsData && orderDetailsData.orderLine.length) {
            setOrderDetailsItems(sortItems());
        }
    }, [orderDetailsData, showError]);

    return (
        <>
            <CreateReturnModal
                isModalVisible={modalState}
                modalContentFragments={orderDetailsContent}
                selectedItems={selectedOrderLines}
                handleClose={handleClose}
                orderDetails={orderDetailsData}
                returnReason={reasonValue}
                onError={handleError}
                onConfirm={navigateToConfirmation}
            />
            <div
                className="create-return-component-container"
                data-test-id="create-return-component"
            >
                {showError && (
                    <div
                        className={`create-return-component-notification-container`}
                        data-testid={`create-return-component-notification-container`}
                    >
                        <Notification
                            status={NotificationType.Error}
                            mainCopy={
                                orderDetailsContent.createReturnErrorMessage
                            }
                            hideBorder={true}
                            hideAfterTimeout={false}
                            onHideNotification={() => {
                                setShowError(false);
                            }}
                        />
                    </div>
                )}

                <h1 className="create-return-component-title">
                    {orderDetailsContent?.returnspageHeader ||
                        'Select Items to Return'}
                </h1>
                <div
                    className="create-return-component-items"
                    data-testid="create-return-component-items"
                >
                    {orderStatusContent &&
                        multipleQtyOrderDetails?.map((item: any) => {
                            return (
                                <>
                                    <div className="create-return-component-checkbox-container">
                                        {item.isStorefrontReturnable ===
                                            'Y' && (
                                            <div
                                                className="create-return-component-user-input"
                                                key={item.localUniqueKey}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={item.localUniqueKey}
                                                    disabled={false}
                                                    className="create-return-component-user-checkbox"
                                                    data-testid={`create-return-component-user-checkbox-${item.localUniqueKey}`}
                                                    checked={
                                                        !!selectedItems[
                                                            item.localUniqueKey
                                                        ]
                                                    }
                                                    onChange={() =>
                                                        handleItemSelection(
                                                            item.localUniqueKey
                                                        )
                                                    }
                                                    aria-label={`Return item ${item.item.itemShortDesc}`}
                                                />
                                            </div>
                                        )}
                                        <OrderDetailsItem
                                            key={item.orderLineKey}
                                            itemData={item}
                                            orderDetailsContent={
                                                orderDetailsContent
                                            }
                                            orderStatusContent={
                                                orderStatusContent
                                            }
                                            returnDisabled={
                                                item.isStorefrontReturnable ===
                                                'N'
                                            }
                                            return={true}
                                        />
                                    </div>
                                </>
                            );
                        })}
                </div>
                <hr className="hr-line" aria-hidden={true} />
                <div className="create-return-component-reason-box">
                    <DropdownTemplate
                        DropdownTemplateContent={dropdownTemplateContent}
                        field={''}
                        selectedItem={reasonValue}
                        setSelectedItem={setReasonValue}
                        dropdownTemplateLabel={
                            orderDetailsContent.returnsDropdown
                        }
                        testId={'create-return-component-reason-dropdown'}
                    ></DropdownTemplate>
                    <PrimaryButton
                        testId={'create-return-component-continue-button'}
                        onClick={() => {
                            setModalState(true);
                        }}
                        disabled={continueButtonIsDisabled}
                    >
                        {orderDetailsContent?.continue || 'continue'}
                    </PrimaryButton>
                </div>
                <div className="create-return-component-faq-container">
                    <div className="create-return-component-faq-title">
                        {orderDetailsContent.faqTitle}
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: orderDetailsContent.faqItem
                                ? orderDetailsContent.faqItem
                                : '',
                        }}
                        className="create-return-component-faq-item"
                    ></div>
                </div>
            </div>
            {isItemReturnLoading && (
                <ActivityIndicator className={'full-height'} />
            )}
        </>
    );
};

export default CreateReturnComponent;
