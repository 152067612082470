import { useState, useEffect } from 'react';
import { AddVehicleContent } from './use-add-vehicle';

export const useVinValidation = (
    addVehicleContent: AddVehicleContent,
    isEURegion: boolean
) => {
    const [vinErrorState, setVinErrorState] = useState<boolean>(false);
    const [vinErrorMessage, setVinErrorMessage] = useState<string>('');

    const euVinWfoError = addVehicleContent.vinValidationWFO;
    const vinAlphanumericCombinationError =
        addVehicleContent.vinAlphanumericCombinationErrorMessage;
    const specialCharError =
        addVehicleContent.specialCharacterRegistrationErrorMessage;
    const euVinError = addVehicleContent.vinErrorMessage;
    const euRegistrationError =
        addVehicleContent.registrationNumberErrorMessage;
    const requiredFieldMessage = addVehicleContent.vinValidationEnter;
    const invalidVinError = addVehicleContent.incorrectVin;

    const setVinError = (message: string) => {
        setVinErrorState(true);
        setVinErrorMessage(message);
    };

    const resetVinErrorState = () => {
        setVinErrorState(false);
    };

    const isValidWFOVIN = (value: string) => {
        if (value && value.slice(0, 3).toLowerCase() === 'wfo') {
            return true;
        }
        return false;
    };

    const isAlphaNumericValue = (value: string) => {
        const regex = new RegExp(`^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 -]+$`);
        return regex.test(value);
    };

    const hasSpecialCharacters = (value: string): boolean => {
        // eslint-disable-next-line no-useless-escape
        const regex = /[*|\":<>[\]{}`\\()_=+'.?~$,!#^/|%;-@&$]/;
        return regex.test(value);
    };

    const validateVINWithWFO = (value: string): boolean => {
        if (isValidWFOVIN(value)) {
            setVinError(euVinWfoError);
            return false;
        } else {
            setVinErrorState(false);
            return true;
        }
    };

    const hasSpecialCharacterError = (value: string): boolean => {
        const isContainSpecialChar = hasSpecialCharacters(value);
        const hasErrorForEU =
            isEURegion && isContainSpecialChar && value.length <= 10;
        const hasErrorForNonEU = !isEURegion && isContainSpecialChar;
        if (hasErrorForEU || hasErrorForNonEU) {
            setVinErrorState(true);
            setVinErrorMessage('');
            if (hasErrorForEU) {
                setVinError(specialCharError);
            } else {
                setVinError(vinAlphanumericCombinationError);
            }
            return true;
        } else {
            setVinErrorState(false);
            return false;
        }
    };

    const validateVINOrReg = (value) => {
        if (value) {
            setVinErrorMessage('');
        }
        const inputLength = value.length;
        if (!hasSpecialCharacterError(value)) {
            // Do a WFO validation on user enter input - onChange
            if (validateVINWithWFO(value)) {
                if (isEURegion && inputLength === 10) {
                    setVinErrorState(false);
                } else if (
                    inputLength > 10 &&
                    inputLength <= 17 &&
                    !/^[0-9a-zA-Z\s-]+$/.test(value)
                ) {
                    setVinError(vinAlphanumericCombinationError);
                    return false;
                }
            }
        }
        return true;
    };

    const isValidatedAgainstRegistration = (value: string) => {
        const isAlphaNumeric = isAlphaNumericValue(value);
        if (!value) {
            setVinError(requiredFieldMessage);
            return false;
        }
        if (!validateVINWithWFO(value)) return false;
        if (hasSpecialCharacters(value)) return false;
        else if (value.length < 10 && !isAlphaNumeric) {
            setVinError(invalidVinError);
            return false;
        } else if (!isAlphaNumeric && value.length === 10) {
            setVinError(euRegistrationError);
            return false;
        } else {
            setVinErrorState(false);
            return true;
        }
    };

    const isValidatedAgainstVIN = (value: string) => {
        const isAlphaNumeric = isAlphaNumericValue(value);
        if (hasSpecialCharacters(value)) return false;
        else if (!isAlphaNumeric) {
            setVinError(euVinError);
            return false;
        } else if (value.length < 17) {
            setVinError(invalidVinError);
            return false;
        } else {
            setVinErrorState(false);
            return true;
        }
    };

    const isValidForEu = (value: string) => {
        if (value.length <= 10) {
            return isValidatedAgainstRegistration(value);
        } else {
            return isValidatedAgainstVIN(value);
        }
    };

    const isValidForNonEu = (value: string) => {
        if (!value) {
            setVinError(requiredFieldMessage);
            return false;
        } else if (value.length < 17 || value.length > 17) {
            setVinError(vinAlphanumericCombinationError);
            return false;
        } else if (value.length === 17 && isValidatedAgainstVIN(value)) {
            return true;
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (!vinErrorState) {
            setVinErrorMessage('');
        }
    }, [vinErrorState]);

    return {
        validateVINOrReg,
        isValidForEu,
        isValidForNonEu,
        resetVinErrorState,
        vinErrorState,
        vinErrorMessage,
    };
};
