import { ServerSideService } from '../server-side-service/server-side-service';

export interface SeoConfig {
    applicationName: string;
    pageTitle: string;
    pageDescription?: string;
    pageKeywords?: string;
}

export class SeoService {
    public set(seoConfig: SeoConfig, serverContext: any) {
        const titleParts: string[] = [];
        seoConfig.pageTitle && titleParts.push(seoConfig.pageTitle);
        seoConfig.applicationName && titleParts.push(seoConfig.applicationName);
        const newTitle = titleParts.join(' | ');

        if (new ServerSideService().isClientSide()) {
            document
                .getElementById('metaKeywords')
                ?.setAttribute('content', seoConfig.pageKeywords);
            document
                .getElementById('metaDescription')
                ?.setAttribute('content', seoConfig.pageDescription);
            document.title = newTitle;
        } else {
            serverContext.documentTitle = newTitle;
        }
    }
}

const seoService = new SeoService();
export default seoService;
