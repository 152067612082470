import React from 'react';
import { HelpCardsProps } from '@views/page-not-found-view/hooks/use-help-cards-content';
import { useAnalytics } from '@hooks/use-analytics';
import { HeroTile } from './hero-tile/hero-tile';
import './hero-tiles.scss';

interface Props {
    helpCardsContent: HelpCardsProps;
}

export const HeroTiles = (props: Props) => {
    const [fireAnalytics] = useAnalytics();
    const contentIsAvailable =
        props.helpCardsContent &&
        typeof props.helpCardsContent === 'object' &&
        Object.keys(props.helpCardsContent).length > 0;

    return contentIsAvailable && !props.helpCardsContent.hide ? (
        <div className="hero-tiles-container">
            <div className="tile-grid">
                <div id="SH1">
                    <HeroTile
                        title={props.helpCardsContent.findVehicleHelpCardTitle}
                        ariaLabel={
                            props.helpCardsContent?.findVehicleHelpCardAriaLabel
                        }
                        link={props.helpCardsContent.myFordVehicleHelpCardURL}
                        imageUrl={
                            process.env.REACT_APP_AEM_BASE_URL +
                            props.helpCardsContent.myFordVehicleHelpCardIcon
                        }
                        linkTarget={
                            props.helpCardsContent.myFordVehicleHelpCardTarget
                        }
                        onClick={() =>
                            fireAnalytics([
                                'findVehicleOnclickEvent',
                                'findVehicleReferralExit',
                            ])
                        }
                    />
                </div>
                <div id="SH2">
                    <HeroTile
                        title={props.helpCardsContent.contactUsHelpCardTitle}
                        ariaLabel={
                            props.helpCardsContent?.contactUsHelpCardAriaLabel
                        }
                        link={props.helpCardsContent.contactUsHelpCardURL}
                        imageUrl={
                            process.env.REACT_APP_AEM_BASE_URL +
                            props.helpCardsContent.contactUsHelpCardIcon
                        }
                        linkTarget={
                            props.helpCardsContent.contactUsHelpCardTarget
                        }
                        onClick={() =>
                            fireAnalytics([
                                'contactUsOnclickEvent',
                                'contactUsReferralExit',
                            ])
                        }
                    />
                </div>
                <div id="SH3">
                    <HeroTile
                        title={
                            props.helpCardsContent.myFordVehicleHelpCardTitle
                        }
                        ariaLabel={
                            props.helpCardsContent
                                ?.myFordVehicleHelpCardAriaLabel
                        }
                        link={props.helpCardsContent.findVehicleHelpCardURL}
                        imageUrl={
                            process.env.REACT_APP_AEM_BASE_URL +
                            props.helpCardsContent.findVehicleHelpCardIcon
                        }
                        linkTarget={
                            props.helpCardsContent.findVehicleHelpCardTarget
                        }
                    />
                </div>
            </div>
        </div>
    ) : null;
};
