import HttpService from '../http-service/http-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import {
    ShortcodeProviders,
    ShortcodeService,
} from '../shortcode-service/shortcode-service';
import { DataLayerService } from '../data-layer-service/data-layer-service';
import { LogService } from '../log-service/log-service';

export interface Event {
    eventName: string;
    directCallName: string;
    eventProperties: EventProperty[];
}

export interface EventProperty {
    propertyDescriptor: string;
    value: string;
}

export interface ReferralExit {
    url: string;
    pageName: string;
}

async function asyncForEach<T>(array: T[], callback: Function) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export class AnalyticsService {
    private static persistentProperties: EventProperty[] = [];

    public constructor(
        private targetViewName?: string,
        private shortcodeProviders?: ShortcodeProviders
    ) {}

    public async getAnalyticsConfig(): Promise<any | null> {
        const { currentCountryCode, brand } = new AppConfigurationService();
        try {
            const response = await HttpService.get(
                process.env.REACT_APP_AEM_BASE_URL +
                    `/content/experience-fragments/global-account/${brand}/${currentCountryCode}/config/analytics/analytics-config/master.model.json`,
                true
            );
            return response?.data[':items']['root'][':items'][
                'analyticsconfig'
            ];
        } catch (e) {
            console.error(
                `An error occurred while trying to get analytics config. Status Text: ${e.statusText}`
            );
            return null;
        }
    }

    public async fireEvents(
        eventNames: string | string[],
        clearDigitaldata = true
    ) {
        try {
            if (
                typeof (window as any)._satellite === 'undefined' ||
                typeof (window as any)._satellite.track === 'undefined'
            ) {
                setTimeout(() => {
                    this.fireEvents(eventNames, clearDigitaldata);
                }, 250);
            } else {
                let events: string[] = [];
                if (!Array.isArray(eventNames)) {
                    events.push(eventNames);
                } else {
                    events = eventNames;
                }
                if (clearDigitaldata) {
                    (window as any).digitaldata = {};
                }
                DataLayerService.populatePersistentProperties();
                const eventNamesMap = new Map();
                const eventPromises = events.map(async (event) => {
                    const eventDataObj = await this.getEventProperties(event);
                    if (eventDataObj && eventDataObj.eventProperties !== null) {
                        eventNamesMap.set(
                            eventDataObj.eventName,
                            eventDataObj.directCallName
                        );
                        const eventProperties = eventDataObj.eventProperties;
                        const processedEventProperties: EventProperty[] =
                            await this.handleShortcodes(eventProperties);
                        if (this.targetViewName) {
                            processedEventProperties.push({
                                propertyDescriptor: 'target.viewName',
                                value: this.targetViewName,
                            });
                        }
                        await DataLayerService.setProperties(
                            processedEventProperties,
                            (window as any).digitaldata
                        );
                        await this.fireTargetUpdateIfNeeded(
                            processedEventProperties
                        );
                    }
                });
                await Promise.all(eventPromises);
                events.forEach((event) => {
                    let eventName = event;
                    if (eventNamesMap.get(event)) {
                        eventName = eventNamesMap.get(event);
                    }
                    (window as any)._satellite.track(eventName);
                    LogService.log('Analytics', `Event Fired: "${event}"`);
                });
            }
        } catch (e) {
            console.error(`Error firing analytics event: ${eventNames}`);
            console.error(`Reason: ${e.message}`);
        }
    }

    private async getEventProperties(eventName: string): Promise<Event | null> {
        try {
            const config = await this.getAnalyticsConfig();
            const eventConfig = config['events'].filter(
                (e: any) => e['eventName'] === eventName
            )[0];
            if (typeof eventConfig !== 'undefined') {
                return eventConfig as Event;
            } else {
                return null;
            }
        } catch (e) {
            throw new Error(
                `An error occurred while trying to get analytics config for event "${eventName}". Status Text: ${e.statusText}`
            );
        }
    }

    public async handleShortcodes(
        eventProperties: EventProperty[]
    ): Promise<EventProperty[]> {
        const processedEventProperties: EventProperty[] = [];
        await asyncForEach(
            eventProperties,
            async (eventProperty: EventProperty) => {
                const processedEventProperty: EventProperty = {
                    propertyDescriptor:
                        eventProperty.propertyDescriptor.replace('*', ''),
                    value: eventProperty.value,
                };
                try {
                    processedEventProperty.value = await new ShortcodeService(
                        this.shortcodeProviders
                    ).processShortcodes(processedEventProperty.value);
                    processedEventProperties.push(processedEventProperty);
                } catch (e) {
                    if (
                        eventProperty.propertyDescriptor[
                            eventProperty.propertyDescriptor.length - 1
                        ] === '*'
                    ) {
                        LogService.log(
                            'Analytics',
                            `Property "${processedEventProperty.propertyDescriptor}" was marked as conditional, and could not be populated. Reason: ${e.message}`
                        );
                    } else {
                        LogService.log(
                            'Analytics',
                            `Property "${processedEventProperty.propertyDescriptor}" was NOT marked as conditional, and could not be populated. Reason: ${e.message}`
                        );
                    }
                }
            }
        );
        return processedEventProperties;
    }

    public async setProperties(
        properties: EventProperty[],
        on: any,
        recursive = false,
        persistent = false
    ) {
        try {
            if (persistent) {
                AnalyticsService.addPersistentProperties(properties);
            }
            await asyncForEach(
                properties,
                async (eventProperty: EventProperty) => {
                    let property = eventProperty.propertyDescriptor;
                    const currentProperty =
                        property.substring(0, property.indexOf('.')) ||
                        property;
                    property =
                        currentProperty !== property
                            ? property.substring(property.indexOf('.') + 1)
                            : '';

                    if (property.length > 0) {
                        if (on[currentProperty] === undefined)
                            on[currentProperty] = {};
                        await this.setProperties(
                            [
                                {
                                    propertyDescriptor: property,
                                    value: eventProperty.value,
                                },
                            ],
                            on[currentProperty],
                            true
                        );
                    } else {
                        on[currentProperty] = eventProperty.value;
                    }
                }
            );
            if (!recursive) {
                this.log(
                    `Data Layer Populated${
                        persistent ? ' (Persistent Properties)' : ''
                    }`,
                    properties
                );
            }
        } catch (e) {
            throw new Error(
                `Failed while trying to populate data layer. Error: ${e.message}`
            );
        }
    }

    private async fireTargetUpdateIfNeeded(eventProperties: EventProperty[]) {
        const targetRelProps: string[] = [
            'target.selectedVehicle',
            'target.deliveryStatus',
        ];
        const fireTargetUpdateConditionally = false;
        if (
            !fireTargetUpdateConditionally ||
            eventProperties
                .map((ep) => ep.propertyDescriptor)
                .filter((pd) => targetRelProps.includes(pd)).length > 0
        ) {
            (window as any)._satellite.track('target-update');
        }
    }
    private log(first: any, second?: any) {
        if (AnalyticsService.isAnalyticsLoggingOn()) {
            console.log('[GA Analytics]', first);
            if (second) {
                console.log('[GA Analytics]', second);
            }
        }
    }

    private static isAnalyticsLoggingOn() {
        return true;
    }

    private static addPersistentProperties(eventProperties: EventProperty[]) {
        eventProperties.forEach((eventProperty) => {
            const existingIndex =
                AnalyticsService.persistentProperties.findIndex(
                    (property) =>
                        property.propertyDescriptor ===
                        eventProperty.propertyDescriptor
                );
            if (existingIndex > -1) {
                AnalyticsService.persistentProperties[existingIndex] =
                    eventProperty;
            } else {
                AnalyticsService.persistentProperties.push(eventProperty);
            }
        });
    }

    public async findMatchingReferralExit(
        url: string
    ): Promise<ReferralExit | undefined> {
        const config = await this.getAnalyticsConfig();
        url = encodeURI(url);
        if (config) {
            const referralExits = config['referralExits'] as ReferralExit[];
            return referralExits?.find(
                (referralExit) => encodeURI(referralExit.url) === url
            );
        }
    }

    public static fireReferralExitEventBasedOnUrl(
        url: string,
        fireEvents: Function
    ) {
        new AnalyticsService()
            .findMatchingReferralExit(url)
            .then((referralExit) => {
                AnalyticsService.fireReferralExitsEvent(
                    fireEvents,
                    referralExit
                );
            });
    }

    public static fireReferralExitsEvent(
        fireEvents: Function,
        referralExit?: ReferralExit
    ) {
        if (referralExit && referralExit.pageName) {
            fireEvents(
                'referral-exit-event',
                undefined,
                { referredUrlPageName: referralExit.pageName },
                false
            );
        }
    }
}
