import React, { useEffect, useState } from 'react';
import TertiaryButton from '@common/tertiary-button/tertiary-button';
import { OrderHistoryContent } from '../../hooks/use-order-history-content';
import {
    OrderHistoryOrder,
    OrderLine,
} from '@services/oms-service/oms-service';
import { findPathByAlias } from '@routes/routesList';
import { OrderDetailsContent } from '@views/order-details-view/hooks/use-order-details-content';
import {
    NVC_GREEN_STATUSES,
    NVC_ORDER_STATUS_COLORS,
    NVC_ORDER_STATUS_MAP,
    NVC_RED_STATUSES,
    transformDeliveryMethod,
} from '@views/order-details-view/nvc-status-map';
import { OrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';
import { INSTALL_LINES_ID, LinkTargetValues } from '@constants';

interface Props {
    order: OrderHistoryOrder;
    index: number;
    orderHistoryContent: OrderHistoryContent;
    orderDetailsContent: OrderDetailsContent;
    orderStatusContent: OrderStatusContent;
    appConfig: any;
    className: string;
    isActive: boolean;
    totalOrders: string;
    alwaysIncludeBottomBorder?: boolean;
}

interface CombinedOrder {
    status: string;
    bgColor: string;
    items: OrderLine[];
}

const OrderHistoryItem = (props: Props) => {
    const {
        order,
        index,
        orderHistoryContent,
        orderDetailsContent,
        orderStatusContent,
        appConfig,
        className,
        isActive,
        totalOrders,
        alwaysIncludeBottomBorder,
    } = props;

    const productPageUrlTemplate = orderDetailsContent?.productPageUrl;

    const accessoriesUrl = orderDetailsContent?.accessoriesUrl;
    const [combinedOrders, setCombinedOrders] = useState<CombinedOrder[]>([]);

    const convertTimeStampToFormattedDate = (timestamp: string): string => {
        const date = new Date(timestamp);
        const languageRegionCode = appConfig
            .getLanguageRegionCode()
            .toLowerCase();
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
        };
        return date.toLocaleDateString(languageRegionCode, options);
    };

    const combineItemsBasedOnStatus = () => {
        const newOrderItems = order.OrderLines.OrderLine.filter(
            (orderLineItem) => orderLineItem.Item.ItemID !== INSTALL_LINES_ID
        ).map((orderLineItem) => {
            const displayStatus = determineDisplayStatus(
                orderLineItem.Status,
                orderLineItem.deliveryMethod
            );
            return {
                ...orderLineItem,
                status: displayStatus,
                bgColor: chooseStatusBackgroundColor(
                    orderLineItem.Status.toLowerCase()
                ),
            };
        });

        const uniqueStatus = [
            ...new Set(newOrderItems.map((item) => item.status)),
        ];

        const combinedOrders = uniqueStatus.map((status) => ({
            status,
            bgColor: newOrderItems.find((item) => item.status === status)
                .bgColor,
            items: newOrderItems.filter((item) => item.status === status),
        }));

        setCombinedOrders(combinedOrders);
    };

    const chooseStatusBackgroundColor = (status: string): string => {
        if (NVC_GREEN_STATUSES.includes(status)) {
            return NVC_ORDER_STATUS_COLORS.green;
        } else if (NVC_RED_STATUSES.includes(status)) {
            return NVC_ORDER_STATUS_COLORS.red;
        }
        return NVC_ORDER_STATUS_COLORS.gray;
    };

    const determineDisplayStatus = (
        rawStatus: string,
        deliveryMethod: string
    ): string => {
        const deliveryMethodTransformed: string =
            transformDeliveryMethod(deliveryMethod);

        const statusOption = NVC_ORDER_STATUS_MAP[
            deliveryMethodTransformed
        ]?.find((option) => option.options.includes(rawStatus.toLowerCase()));

        return statusOption
            ? orderStatusContent[statusOption.name] || rawStatus
            : rawStatus;
    };

    const createProductPageUrl = (
        itemId: string,
        itemShortDesc: string
    ): string => {
        if (itemId && itemShortDesc) {
            const formattedProductName: string = itemShortDesc
                .toLowerCase()
                .split(' ')
                .join('-')
                .replace(/[^a-z0-9-]/g, '')
                .replace(/-{2,}/g, '-');

            return productPageUrlTemplate
                ?.replace(/:productName/g, formattedProductName)
                .replace(/:partNumber/g, itemId);
        } else return accessoriesUrl;
    };

    const createImageUrl = (
        itemSubType: string,
        partNumber: string
    ): string => {
        const imageUrlTemplate =
            itemSubType === 'FPP'
                ? props.orderDetailsContent.performancePartsImageUrl
                : props.orderDetailsContent.accessoriesImageUrl;
        return imageUrlTemplate?.replace('[partNumber]', partNumber);
    };

    useEffect(() => {
        combineItemsBasedOnStatus();
    }, [order, orderDetailsContent, orderStatusContent]);

    return (
        <>
            <div className={`${className}-item-container`}>
                <div
                    className={`${className}-item`}
                    data-testid={`${className}-item-${
                        isActive ? 'active' : 'past'
                    }-${index}`}
                >
                    <p
                        className={`${className}-order-date-label`}
                        data-testid={`${className}-item-${
                            isActive ? 'active' : 'past'
                        }-${index}-order-date-label`}
                    >
                        {orderHistoryContent.orderDateLabelText}
                    </p>
                    <p
                        className={`${className}-order-date`}
                        data-testid={`${className}-item-${
                            isActive ? 'active' : 'past'
                        }-${index}-order-date`}
                    >
                        {convertTimeStampToFormattedDate(order.OrderDate)}
                    </p>
                    <p
                        className={`${className}-order-info`}
                        data-testid={`${className}-item-${
                            isActive ? 'active' : 'past'
                        }-${index}-order-number`}
                    >
                        {'#'}
                        {order.OrderNo}
                    </p>
                    <p
                        className={`${className}-order-info`}
                        data-testid={`${className}-item-${
                            isActive ? 'active' : 'past'
                        }-${index}-order-total`}
                    >
                        {'$'}
                        {order.OriginalTotalAmount}{' '}
                        {orderHistoryContent.orderTotalLabelText}
                    </p>
                    <p
                        className={`${className}-order-info`}
                        data-testid={`${className}-item-${
                            isActive ? 'active' : 'past'
                        }-${index}-total-records`}
                    >
                        {orderHistoryContent.orderNumberOfItemsLabelText}{' '}
                        {order.OrderLines?.TotalNumberOfRecords}
                    </p>

                    <TertiaryButton
                        labelText={
                            orderHistoryContent.viewDetailsButtonLabelText
                        }
                        ariaLabel={
                            orderHistoryContent.viewDetailsButtonAriaLabel
                        }
                        dataTestId={`${className}-item-${
                            isActive ? 'active' : 'past'
                        }-${index}-view-details-button`}
                        internal={true}
                        chevronDirection={'right'}
                        link={findPathByAlias(
                            'OrderDetailsViewWithoutOrderNumber',
                            order.OrderNo
                        )}
                    ></TertiaryButton>
                </div>
                <div className={`${className}-order-items-images-container`}>
                    {combinedOrders.map((orderLine, orderLineIndex) => (
                        <div
                            key={orderLine.status}
                            data-testid={`${className}-item-${index}-orderline-${orderLineIndex}`}
                        >
                            <div
                                className={`${className}-item-status`}
                                style={{
                                    backgroundColor: orderLine.bgColor,
                                }}
                                data-testid={`${className}-item-${index}-orderline-${orderLineIndex}-status`}
                            >
                                {orderLine.status}
                            </div>
                            {orderLine.items.map((item) => (
                                <>
                                    <a
                                        className={`${className}-item-link`}
                                        data-testid={`${className}-item-${index}-orderline-${orderLineIndex}-link`}
                                        href={createProductPageUrl(
                                            item.Item.ItemID,
                                            item.Item.ItemShortDesc
                                        )}
                                        target={LinkTargetValues.BLANK}
                                    >
                                        <img
                                            className={`${className}-item-image`}
                                            data-testid={`${className}-item-${index}-orderline-${orderLineIndex}-img`}
                                            src={createImageUrl(
                                                item?.Item.itemSubType,
                                                item?.Item.imageID
                                            )}
                                            alt="item image"
                                        />
                                    </a>
                                </>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            {alwaysIncludeBottomBorder ||
            index !== parseInt(totalOrders) - 1 ? (
                <hr className="hr-line" aria-hidden={true} />
            ) : null}
        </>
    );
};

export default OrderHistoryItem;
