import React, { ReactNode, useState } from 'react';
import './accordion.scss';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { useAnalytics } from '@/hooks/use-analytics';

const plusSignIconBlue = './icons/plus-sign-blue.svg';
const plusSignIconOrange = './icons/plus-sign-orange.svg';
const minusSignIconBlue = './icons/minus-sign-blue.svg';
const minusSignIconOrange = './icons/minus-sign-orange.svg';

interface Props {
    items: { title: string; content: ReactNode }[];
    analyticFiring: string;
}

const Accordion = (props: Props) => {
    const [currentOpenAccordion, setCurrentOpenAccordion] = useState(-1);
    const [fireAnalytics] = useAnalytics();

    const appConfig = new AppConfigurationService();
    const isLincoln = appConfig.brand === 'lincoln';
    const plusSignIcon = isLincoln ? plusSignIconOrange : plusSignIconBlue;
    const minusSignIcon = isLincoln ? minusSignIconOrange : minusSignIconBlue;

    return (
        <div data-testid="accordion-container">
            {props.items.map((item, index) => (
                <>
                    <button
                        key={index}
                        className="accordion-item"
                        data-testid={`accordion-item-${index}`}
                        onClick={() => {
                            setCurrentOpenAccordion(
                                currentOpenAccordion === index ? -1 : index
                            );
                            const category = item.title;
                            currentOpenAccordion !== index &&
                                props.analyticFiring &&
                                fireAnalytics(props.analyticFiring, '', {
                                    category,
                                });
                        }}
                    >
                        <div
                            className={`accordion-item-label ${
                                currentOpenAccordion === index ? 'open' : ''
                            }`}
                            data-testid={`accordion-item-label-${index}`}
                        >
                            {item.title}
                        </div>

                        <img
                            className="accordion-item-icon"
                            data-testid={`accordion-item-icon-${index}`}
                            src={
                                currentOpenAccordion === index
                                    ? minusSignIcon
                                    : plusSignIcon
                            }
                            alt={
                                currentOpenAccordion === index
                                    ? 'minus sign'
                                    : 'plus sign'
                            }
                        ></img>
                    </button>
                    <div
                        className={`accordion-item-content ${
                            currentOpenAccordion === index ? 'open' : ''
                        }`}
                        data-testid={`accordion-item-content-${index}`}
                        {...(currentOpenAccordion === index
                            ? {}
                            : { inert: 'true' })}
                    >
                        {item.content}
                    </div>
                </>
            ))}
        </div>
    );
};

export default Accordion;
