import React, { Dispatch, SetStateAction } from 'react';
import { InputField } from '@common/form-fields/form-fields';
import Tooltip from '@common/tooltip/tooltip';
import { AddVehicleContent } from '@sections/add-vehicle/hooks/use-add-vehicle';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { useAnalytics } from '@hooks/use-analytics';

interface AddVehicleContentProps {
    addVehicleContent: AddVehicleContent;
    vinRef: React.Ref<HTMLInputElement>;
    nickRef: React.Ref<HTMLInputElement>;
    tooltipRef: any;
    formValues: { vin: string; nickName: string };
    handleVinChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleNicknameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    errorState: boolean;
    errorMessage: string;
    showTooltip: boolean;
    toggleTooltip: Dispatch<SetStateAction<boolean>>;
}

const AddVehicleModalContent = (props: AddVehicleContentProps) => {
    const [fireAnalytics] = useAnalytics();
    const appConfig = new AppConfigurationService();
    const isLincoln: boolean = appConfig.brand === 'lincoln';
    const isFord: boolean = appConfig.brand === 'ford';

    const vinTooltipMouseHandler = () => {
        if (props.showTooltip !== true) {
            fireAnalytics('vinTooltipAddVehicleOnclickEvent');
            props.toggleTooltip(true);
        } else {
            props.toggleTooltip(false);
        }
    };

    const vinTooltipKeyboardHandler = (
        e: React.KeyboardEvent<HTMLButtonElement>
    ) => {
        if (e.key === 'Tab' && props.showTooltip) {
            props.toggleTooltip(false);
        } else if (e.key === 'Tab' && props.showTooltip !== true) {
            fireAnalytics('vinTooltipAddVehicleOnclickEvent');
            props.toggleTooltip(true);
        }
    };

    return (
        <>
            {props.addVehicleContent && (
                <div className="text-center">
                    <h2
                        className={`fmc-type--heading6 ${
                            isLincoln
                                ? 'fds-color__text--primary fmc-mb-3 fmc-pb-1'
                                : ''
                        }`}
                    >
                        {props.addVehicleContent.addVehicleLabel}
                    </h2>
                    <div
                        className={`fmc-type--body1 ${
                            isLincoln
                                ? 'fds-color__text--gray3 fmc-mb-6 fmc-pb-1'
                                : ''
                        }`}
                    >
                        <span>
                            {props.addVehicleContent.addVehicleDescription}
                        </span>
                    </div>
                    <div className="user-input-container">
                        {isFord && (
                            <>
                                <InputField
                                    ref={props.vinRef}
                                    name="add-vehicle-vin"
                                    id="add-vehicle-vin"
                                    data-testid="add-vehicle-vin"
                                    label={props.addVehicleContent.vin}
                                    showLabel={false}
                                    value={props.formValues.vin}
                                    key={props.addVehicleContent.vin}
                                    handleChange={props.handleVinChange}
                                    dataTestId="add-vehicle-input"
                                    error={{
                                        status: props.errorState,
                                        message: '',
                                    }}
                                />
                            </>
                        )}

                        {isLincoln && (
                            <>
                                <InputField
                                    ref={props.vinRef}
                                    showLabel={true}
                                    name="add-vehicle-vin"
                                    id="add-vehicle-vin"
                                    label={props.addVehicleContent.vin}
                                    aria-label={props.addVehicleContent.vin}
                                    value={props.formValues.vin}
                                    key={props.addVehicleContent.vin}
                                    handleChange={props.handleVinChange}
                                    dataTestId="add-vehicle-input"
                                    placeholder={props.addVehicleContent.vin}
                                />
                            </>
                        )}

                        {props.errorState && (
                            <div className="form-field-error">
                                <span
                                    className="error-text"
                                    data-testid="vin-error-message"
                                    id="vin-error-message"
                                >
                                    {props.errorMessage}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="tooltip-container" ref={props.tooltipRef}>
                        <Tooltip
                            content={
                                <div
                                    className="fmc-type--body1 vin-tooltip-content"
                                    tabIndex={0}
                                    role="tooltip"
                                    aria-live="polite"
                                    dangerouslySetInnerHTML={{
                                        __html: props.addVehicleContent
                                            .vinToolTipInfo,
                                    }}
                                />
                            }
                            direction={`${isLincoln ? 'bottom' : 'right'}`}
                            showTooltip={props.showTooltip}
                            toggleTooltip={props.toggleTooltip}
                            tooltipStyles={{
                                maxWidth: isLincoln ? '342px' : '360px',
                                height: isFord ? '320px' : '',
                                width: '100%',
                                padding: isLincoln ? '20px' : '16px',
                                textAlign: 'left',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                                marginTop: isLincoln ? 12 : '',
                                bottom: isLincoln ? 'auto' : '',
                            }}
                            isLincoln={isLincoln}
                            isFord={isFord}
                        >
                            <button
                                className="vin-help-text"
                                aria-label="Where do I find my vin?"
                                tabIndex={0}
                                onClick={vinTooltipMouseHandler}
                                onKeyUp={(e) => vinTooltipKeyboardHandler(e)}
                                onKeyDown={(e) => vinTooltipKeyboardHandler(e)}
                            >
                                {props.addVehicleContent.whereDoIFindMyVin}
                            </button>
                        </Tooltip>
                    </div>
                    {isLincoln && (
                        <div className="user-input-container">
                            <InputField
                                ref={props.nickRef}
                                showLabel={false}
                                name="add-vehicle-nickname"
                                id="add-vehicle-nickname"
                                data-test-id="add-vehicle-nickname"
                                aria-label={props.addVehicleContent.nickname}
                                value={props.formValues.nickName}
                                label={props.addVehicleContent.nickname}
                                key={props.addVehicleContent.nickname}
                                handleChange={props.handleNicknameChange}
                                placeholder={props.addVehicleContent.nickname}
                            />

                            <br />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default AddVehicleModalContent;
