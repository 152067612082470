import React from 'react';
import { AddVehicleContent } from '../../hooks/use-add-vehicle';

interface MaxGarageLimitProps {
    addVehicleContent: AddVehicleContent;
    isLincoln: boolean;
}

const AddVehicleMaxGarageLimitContent = (props: MaxGarageLimitProps) => {
    return (
        <>
            {props.addVehicleContent && (
                <div
                    className="text-center"
                    data-testid="max-vehicle-limit-error-modal"
                >
                    <h2
                        className={`fmc-type--heading6 ${
                            props.isLincoln
                                ? 'fds-color__text--primary fmc-mb-3 fmc-pb-1'
                                : ''
                        }`}
                    >
                        {props.addVehicleContent.addVehicleMaxLimitErrorMessage}
                    </h2>
                </div>
            )}
        </>
    );
};

export default AddVehicleMaxGarageLimitContent;
