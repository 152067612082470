import React from 'react';
import './chevron.scss';

type ChevronDirection = 'up' | 'down' | 'left' | 'right';

interface Props {
    color?: string;
    direction: ChevronDirection;
    selected?: boolean;
    className?: string;
}

const Chevron = (props: Props) => (
    <div className="chevron-icon-container">
        <div
            className={`chevron-${props.direction} ${
                props.selected ? 'chevron-selected' : ''
            } ${props.className}`}
        >
            <svg
                width="11"
                height="21"
                viewBox="0 0 11 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke={props.color || '#00095B'}
            >
                <rect transform="rotate(90)" />
                <path d="M0.999878 11L10.0922 20.0919" strokeLinecap="square" />
                <path d="M10.0918 1.70715L1 11" strokeLinecap="square" />
            </svg>
        </div>
    </div>
);

export default Chevron;
