import React, { Dispatch, SetStateAction } from 'react';
import StarIconFilled from '../star-icon-filled/star-icon-filled';
import { useAnalytics } from '@hooks/use-analytics';
import { ModalContextProps, useModalContext } from '@contexts/modalContext';
import { PreferredDealerItem } from '../../preferred-dealer-view';
import StarIconOutlined from '../star-icon-outlined/star-icon-outlined';
import { PreferredDealerContent } from '../../hooks/use-preferred-dealer-content';
import SecondaryButton from '@common/secondary-button/secondary-button';
import { EUDealer } from '@models/preferred-dealer';

import './dealer-info-tile.scss';

interface DealerInfoTileProps {
    index: number;
    dealer: EUDealer;
    brand: string;
    preferredDealerContent: PreferredDealerContent;
    isPreferredDealer: boolean;
    selectedModel: string;
    instantPreferredDealer: string;
    setInstantPreferredDealer: Dispatch<SetStateAction<string>>;
    updatePreferredDealer: (item: PreferredDealerItem) => void;
    vin: string;
    scrollToTopOfDealerResultsList: () => void;
}

export default function DealerInfoTile(props: DealerInfoTileProps) {
    const [fireAnalytics] = useAnalytics();
    const { setContext, resetContext } = useModalContext();
    const { index, dealer }: DealerInfoTileProps = props;

    const replacePreferredDealerModalProps: ModalContextProps = {
        modalType: {
            name: 'replace-preferred-dealer-modal',
            'aria-label': 'Replace Preferred Dealer Modal',
            primaryButtonLabel:
                props.preferredDealerContent
                    .replaceMyDealerModalConfirmButtonLabelText,
            primaryButtonAriaLabel:
                props.preferredDealerContent
                    .replaceMyDealerModalConfirmButtonAriaLabel,
            secondaryButtonLabel:
                props.preferredDealerContent
                    .replaceMyDealerModalCancelButtonLabelText,
            secondaryButtonAriaLabel:
                props.preferredDealerContent
                    .replaceMyDealerModalCancelButtonAriaLabel,
            closeButtonAriaLabel:
                props.preferredDealerContent
                    .replaceMyDealerModalCloseButtonAriaLabel,
            onPrimaryButtonClick: () => {
                props.setInstantPreferredDealer(
                    props.dealer.DealerID.substring(2)
                );
                props.updatePreferredDealer({
                    vin: props.vin,
                    model: props.selectedModel,
                    preferredDealerCode: props.dealer.DealerID.substring(2),
                });
                fireAnalytics('replaceMyDealerPreferredDealerOnclickEvent');
                resetContext();
                props.scrollToTopOfDealerResultsList();
            },
            onSecondaryButtonClick: () => resetContext(),
            onAfterClose: () => resetContext(),
            children: (
                <>
                    <h2>
                        {
                            props.preferredDealerContent
                                .replaceMyDealerModalHeaderText
                        }
                    </h2>
                    <p>
                        {
                            props.preferredDealerContent
                                .replaceMyDealerModalBodyText
                        }
                    </p>
                </>
            ),
        },
    };

    const formatDistance = (distance: number) => {
        let formattedDistance: number | string;

        if (distance) {
            if (distance >= 1) {
                formattedDistance = distance.toFixed(2);
            } else {
                formattedDistance = Math.round(distance * 10) / 10;
            }
            return formattedDistance;
        }
    };

    const formatDealerWebsiteUrl = (url: string): string => {
        let formattedUrl: string = url;

        if (formattedUrl.includes('http://')) {
            formattedUrl = formattedUrl.replace('http://', '');
        }

        return formattedUrl.includes('https://')
            ? formattedUrl
            : `https://${formattedUrl}`;
    };

    const getDistanceInMiles = (distance: number) => {
        return formatDistance(distance * 0.00062137119);
    };
    const getDistanceInKm = (distance: number) => {
        return formatDistance(distance * 0.001);
    };

    const distanceUnit = 'miles';
    const distance =
        distanceUnit === 'miles'
            ? getDistanceInMiles(Number(dealer.distance))
            : getDistanceInKm(Number(dealer.distance));

    return (
        <div
            className={`dealer-info-box-container`}
            data-testid="dealer-info-box-container"
        >
            <div className="dealer-info-box">
                <div className="dealer-info">
                    <div className="dealer-name">
                        {index + 1}.&nbsp;&nbsp;{dealer.DealerName}
                    </div>
                    <div className="dealer-address">{`${dealer.AddressLine1}, ${dealer.Locality}, ${dealer.PostCode}`}</div>
                    <div className="dealer-distance-label">
                        {dealer?.distance > 0 && (
                            <>
                                <span className="distance-label-text">
                                    {props.preferredDealerContent.distanceText +
                                        ':'}
                                </span>
                                <span className="distance-value">
                                    {distance}{' '}
                                    {props.preferredDealerContent.milesAwayText}
                                </span>
                            </>
                        )}
                    </div>
                </div>
                <div className="dealer-actions">
                    <button
                        data-testid="my-dealer-button"
                        className={`individual-dealer__my-dealer-button ${
                            props.isPreferredDealer ? 'is-my-dealer' : ''
                        }`}
                        onClick={() => {
                            if (!props.instantPreferredDealer) {
                                props.setInstantPreferredDealer(
                                    props.dealer.DealerID.substring(2)
                                );
                                props.updatePreferredDealer({
                                    vin: props.vin,
                                    model: props.selectedModel,
                                    preferredDealerCode:
                                        props.dealer.DealerID.substring(2),
                                });
                                fireAnalytics(
                                    'makeMyDealerPreferredDealerOnclickEvent'
                                );
                                props.scrollToTopOfDealerResultsList();
                            } else if (!props.isPreferredDealer) {
                                setContext(replacePreferredDealerModalProps);
                            }
                        }}
                    >
                        <span>
                            {props.isPreferredDealer ? (
                                <StarIconFilled color="#066FEF" />
                            ) : (
                                <StarIconOutlined color="#066FEF" />
                            )}
                        </span>
                        <span>
                            {props.isPreferredDealer
                                ? props.preferredDealerContent
                                      .myDealerButtonLabelText
                                : props.preferredDealerContent
                                      .makeMyDealerButtonLabelText}
                        </span>
                    </button>
                    {dealer.PrimaryURL && (
                        <SecondaryButton
                            aria-label={
                                props.preferredDealerContent
                                    .dealerWebsiteButtonAriaLabel
                            }
                            dataTestId="individual-dealer-dealer-website-button"
                            className="dealer-website-button"
                            linkTarget="_blank"
                            link={formatDealerWebsiteUrl(dealer.PrimaryURL)}
                            onClick={() =>
                                fireAnalytics(
                                    'dealerWebsitePreferredDealerReferralExit'
                                )
                            }
                        >
                            <span>
                                {
                                    props.preferredDealerContent
                                        .dealerWebsiteButtonLabelText
                                }
                            </span>
                        </SecondaryButton>
                    )}
                </div>
            </div>
        </div>
    );
}
