import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AccountSettingsLabels } from '@views/account-settings-view/hooks/use-account-settings-content';
import './personal-information.scss';
import PersonalInformationForm from './personal-information-form';
import PersonalInformationDetails from './personal-information-details';
import { LinkButton } from '@common/modal/link-button/link-button';
import { useAnalytics } from '@hooks/use-analytics';
import { AccountSettingsFormsLabels } from './hooks/use-personal-information-content/use-personal-information-content';
import {
    DynamicDropdownContent,
    DynamicDropdownOptions,
} from '@common/dynamic-dropdown/dynamic-dropdown';
import { useDynamicDropdownContent } from './hooks/use-dynamic-dropdown-content/use-dynamic-dropdown-content';
import { NotificationType } from '@contexts/notificationContext';

export interface ProfileError {
    status: boolean;
    message: any;
}

export interface AccountEditProps {
    appConfig: any;
    handleCancel: (mode: string) => void;
    updateSuccess: (user: any, additionalAddress: any) => void;
    setMode?: (mode: string) => void;
    user: any;
    accountSettingsLabels: AccountSettingsLabels;
    accountSettingsFormsLabels: AccountSettingsFormsLabels;
    additionalAddress: any;
    experienceFragmentContent?: any;
    experienceFragmentTitles?: any;
    experienceFragmentNationalID?: any;
    countryCode?: string;
    countryName?: string;
    isEdit?: boolean;
    isUpdateAndCancel?: boolean;
    profileServiceError?: any;
    setProfileError?: (profileError: ProfileError) => void;
    setAdditionalAddress?: any;
    setUserData?: any;
    handleAdditionalAddressResponse?: any;
    setIsLoading?: any;
    handleProfileServiceError?: any;
    dynamicDropdownContent?: DynamicDropdownContent;
    selectedPrimaryCountry?: DynamicDropdownOptions;
    selectedMobileCountry?: DynamicDropdownOptions;
    setSelectedPrimaryCountry?: Dispatch<
        SetStateAction<DynamicDropdownOptions>
    >;
    setSelectedMobileCountry?: Dispatch<SetStateAction<DynamicDropdownOptions>>;
    userIdentity: {
        identificationCountry: string;
        identificationSubType: string;
        identificationType: string;
        identificationValue: string;
    };
    setNotificationStatus: Dispatch<SetStateAction<NotificationType>>;
}

export interface UserPersonalData {
    firstName: string;
    lastName: string;
    middleName: string;
    address1Line1: string;
    address1Line2: string;
    address1City: string;
    address1State: string;
    address1Zip: string;
    address2Line1: string;
    address2Line2: string;
    address2City: string;
    address2State: string;
    address2Zip: string;
    mobilePhoneNumber: string;
    confirmMobilePhoneNumber: string;
    phoneNumber: string;
    nationalIdType?: string;
    nationalIdNumber?: string;
    email: string;
    confirmEmail: string;
    title: string;
    companyName: string;
    country?: string;
    userIdentity?: {
        identificationCountry: string;
        identificationSubType: string;
        identificationType: string;
        identificationValue: string;
    };
}

export interface Errors {
    [key: string]: any;
}

export const PersonalInformation = (props: AccountEditProps) => {
    const {
        appConfig,
        user,
        accountSettingsLabels,
        additionalAddress,
        countryCode,
        countryName,
        accountSettingsFormsLabels,
        isEdit,
        setMode,
        userIdentity,
        setNotificationStatus,
    } = props;
    const [fireAnalytics] = useAnalytics();
    const dynamicDropdownContent = useDynamicDropdownContent(countryCode);
    const [instantProfileUpdate, setInstantUpdate] = useState(user);
    const [selectedMobileCountry, setSelectedMobileCountry] =
        useState<DynamicDropdownOptions>(null);
    const [selectedPrimaryCountry, setSelectedPrimaryCountry] =
        useState<DynamicDropdownOptions>(null);

    useEffect(() => {
        if (dynamicDropdownContent) {
            const mobilePhoneNumber = user.mobilePhoneNumber;
            const primaryPhoneNumber = user.phoneNumber;
            const listOfCountries = dynamicDropdownContent.dropdownOptions;

            if (mobilePhoneNumber) {
                const preselectMobilePhone = listOfCountries.filter((x) =>
                    mobilePhoneNumber.includes(x.phoneNumberCountryCode)
                )[0];

                setSelectedMobileCountry(preselectMobilePhone);
            }

            if (primaryPhoneNumber) {
                const preselectPrimaryPhone = listOfCountries.filter((x) =>
                    primaryPhoneNumber.includes(x.phoneNumberCountryCode)
                )[0];

                setSelectedPrimaryCountry(preselectPrimaryPhone);
            }
        }
    }, [dynamicDropdownContent]);

    return (
        <>
            <div
                className="account-view-section"
                data-testid="personal-information-container"
            >
                <h2 className="account-view-section__title fmc-mt-4-xs fmc-pt-1-xs">
                    {accountSettingsLabels.personalInformation}
                </h2>

                <div className="edit-button-container">
                    {!isEdit && (
                        <LinkButton
                            ariaControls={'personal-information-form'}
                            ariaExpanded={isEdit}
                            label={accountSettingsLabels.btnEdit}
                            ariaLabel={
                                accountSettingsLabels?.editPersonalInfoAriaLabel
                            }
                            dataTestId="user-info-edit-btn"
                            class={`edit-link ${isEdit ? 'hide' : ''}`}
                            onClick={() => {
                                fireAnalytics('personalInfoEditOnclickEvent');
                                setMode('edit');
                            }}
                            id="edit-link"
                            ariaHidden={isEdit}
                            tabIndex={isEdit && -1}
                            isEditButton
                        />
                    )}
                </div>
            </div>
            {!isEdit || appConfig.brand === 'ford' ? (
                <hr className="hr-line mB0" aria-hidden={true} />
            ) : null}
            {isEdit ? (
                <PersonalInformationForm
                    {...props}
                    appConfig={appConfig}
                    user={instantProfileUpdate}
                    setUserData={setInstantUpdate}
                    userIdentity={userIdentity}
                    selectedMobileCountry={selectedMobileCountry}
                    selectedPrimaryCountry={selectedPrimaryCountry}
                    setSelectedMobileCountry={setSelectedMobileCountry}
                    setSelectedPrimaryCountry={setSelectedPrimaryCountry}
                    dynamicDropdownContent={dynamicDropdownContent}
                    accountSettingsFormsLabels={accountSettingsFormsLabels}
                    setNotificationStatus={setNotificationStatus}
                />
            ) : (
                <PersonalInformationDetails
                    contentFragments={accountSettingsLabels}
                    countryCode={countryCode}
                    countryName={countryName}
                    additionalAddress={additionalAddress}
                    user={instantProfileUpdate}
                    userIdentity={userIdentity}
                />
            )}
        </>
    );
};
