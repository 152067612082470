import { ExperienceFragmentModel } from '@services/content-service/content-service.interface';
import { useEffect, useState } from 'react';
import { useExperienceContent } from '@hooks/use-server-content';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

export interface DynamicMastheadContent extends ExperienceFragmentModel {
    options: DynamicMastheadGreetingStyle[];
}

export interface DynamicMastheadGreetingStyle {
    key: string;
    greetingText: string;
    backgroundImgWithVehiclesDesktop: string;
    backgroundImgWithoutVehiclesDesktop: string;
    backgroundImgWithVehiclesMobile: string;
    backgroundImgWithoutVehiclesMobile: string;
    title: string;
    description: string;
    ctaText: string;
    ctaHref: string;
    ctaAriaLabel: string;
    orientation: string;
}

export interface DynamicMastheadProperties {
    hasDynamicMasthead: boolean;
    orientation: string;
}

export const useDynamicMastheadContent = (
    countryCode: string
): DynamicMastheadContent | null => {
    const [content, setContent] = useState<DynamicMastheadContent | null>(null);
    const [fragmentArgs, setFragmentArgs] = useState({
        category: '',
        name: '',
        componentName: '',
    });

    const appConfig = new AppConfigurationService();
    const brand = appConfig.brand;

    useEffect(() => {
        if (brand === 'ford') {
            setFragmentArgs({
                category: 'common',
                name: 'masthead',
                componentName: 'masthead',
            });
        }
    }, []);

    const [experienceFragmentDynamicMasthead] =
        useExperienceContent<DynamicMastheadContent>(
            fragmentArgs.category,
            fragmentArgs.name,
            fragmentArgs.componentName
        );

    useEffect(() => {
        if (experienceFragmentDynamicMasthead) {
            setContent(experienceFragmentDynamicMasthead);
        }
    }, [experienceFragmentDynamicMasthead]);

    return content;
};
