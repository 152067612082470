import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';

export class Emplifi {
    userMessage?: string;
    async createConversation() {
        const baseUrl = 'https://www.authagent.ford.com/api/secure/emplifi/';
        const locale =
            new AppConfigurationService().currentLanguageRegionCode.replace(
                '-',
                '_'
            );
        const url = `${baseUrl}${locale}/conversation`;
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            contentType: 'application/json',
        };

        return fetch(url, request).then((response) => response.json());
    }

    async setConversationLanguage(conversationId: string) {
        const baseUrl = 'https://www.authagent.ford.com/api/secure/emplifi/';
        const locale =
            new AppConfigurationService().currentLanguageRegionCode.replace(
                '-',
                '_'
            );
        const url = `${baseUrl}${locale}/conversation/${conversationId}/context`;
        const request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return fetch(url, request).then((response) => response.json());
    }

    async getBotResponse(userMessage: string, conversationId: string) {
        const baseUrl = 'https://www.authagent.ford.com/api/secure/emplifi/';
        const url = `${baseUrl}conversation/${conversationId}/sendConversation`;
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            contentType: 'application/json',
            body: JSON.stringify({
                contentType: 'text',
                content: userMessage,
            }),
        };

        return fetch(url, request).then((response) => response.json());
    }

    async getConversation(conversationId: string | null) {
        const baseUrl = 'https://www.authagent.ford.com/api/secure/emplifi/';
        const url = `${baseUrl}conversation/${conversationId}/getConversation`;
        const request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return fetch(url, request).then((response) => response.json());
    }
}
