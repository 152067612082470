import React, { useState } from 'react';
import ServiceHandler from '@services/service-handler';
import {
    SupportedLanguage,
    SupportedLanguageOverride,
} from '@services/app-configuration-service/app-configuration-service';
import Dropdown from '@common/dropdown/dropdown';
import './lincoln-footer-language-toggle.scss';
interface Props {
    className?: string;
    tabIndex: number;
}
export const LincolnLanguageToggle = (props: Props) => {
    const appConfigurationService = ServiceHandler.AppConfigurationService;
    const { currentLanguage, currentDomain, currentRoot } =
        appConfigurationService;

    const [languages] = useState<SupportedLanguage[]>(
        appConfigurationService
            .getSupportedLanguages()
            .filter((l) => l.domain !== currentDomain)
    );

    const onLanguageChange = (value: any) => {
        if (
            appConfigurationService.getAppConfiguration().environment !==
            'fallback'
        ) {
            languages.forEach((language: SupportedLanguage) => {
                if (value === language.name) {
                    const currentPathWithoutBase =
                        window.location.pathname.indexOf(currentRoot) === 0
                            ? window.location.pathname.substr(
                                  currentRoot.length
                              )
                            : '';
                    const url = `${window.location.protocol}//${
                        language.domain
                    }${window.location.port && ':' + window.location.port}${
                        language.root
                    }${currentPathWithoutBase}`;
                    return url;
                }
            });
        } else {
            appConfigurationService
                .getLanguageOverrides()
                .forEach((language: SupportedLanguageOverride) => {
                    if (value === language.languageName) {
                        (window as any).languageOverride(
                            language.languageName,
                            language.languageRegionCode,
                            language.countryCode,
                            language.threeLetterCountryCode
                        );
                        window.location.reload();
                    }
                });
        }
    };
    const languageList = () => {
        const option: any[] = [];
        if (
            appConfigurationService.getAppConfiguration().environment !==
            'fallback'
        ) {
            return languages.map(
                (language: SupportedLanguage, index) =>
                    (option[index] = language.name)
            );
        } else {
            return appConfigurationService
                .getLanguageOverrides()
                .map(
                    (language: SupportedLanguageOverride, index) =>
                        (option[index] = language.languageName)
                );
        }
    };

    return (
        <div className="lincoln-language-toggle-container">
            <div className="lincoln-language-toggle-dropdown">
                <Dropdown
                    tabIndex={props.tabIndex}
                    onChange={onLanguageChange}
                    value={currentLanguage}
                    label={currentLanguage}
                    options={languageList()}
                    className={`lincoln-language-toggle${
                        props.className || ''
                    }`}
                />
            </div>
        </div>
    );
};
