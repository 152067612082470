import React, { ReactElement } from 'react';

import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

import './tertiary-button.scss';
import SecondaryButton from '@common/secondary-button/secondary-button';
import TertiaryButtonChevron, {
    ChevronDirection,
} from '@common/tertiary-button/tertiary-button-chevron/tertiary-button-chevron';
import { LinkTargetValues } from '@constants';
import plusSignIconBlue from '@assets/plus-sign.svg';

interface Props {
    labelText: string;
    link?: string;
    linkTarget?: LinkTargetValues;
    onClick?: () => void;
    ariaLabel?: string;
    internal: boolean;
    dataTestId?: string;
    chevronDirection?: ChevronDirection;
    plusSign?: boolean;
}
const TertiaryButton = (props: Props): ReactElement => {
    const appConfig = new AppConfigurationService();
    const isLincoln: boolean = appConfig.brand === 'lincoln';

    return (
        <>
            {props.labelText && (
                <div className="tertiary-button" data-testid="tertiary-button">
                    <SecondaryButton
                        className={`${
                            isLincoln
                                ? 'fmc-text-button fmc-text-button--chevron-left'
                                : ''
                        }`}
                        internal={props.internal}
                        onClick={props.onClick}
                        link={props.link}
                        linkTarget={props.linkTarget}
                        buttonType={isLincoln ? 'chevron-left' : 'dark-left'}
                        aria-label={props.ariaLabel}
                        dataTestId={props.dataTestId}
                    >
                        {props.chevronDirection === 'left' && (
                            <TertiaryButtonChevron
                                direction={props.chevronDirection}
                            />
                        )}
                        <span className="tertiary-button__label-text">
                            {props.labelText}
                        </span>
                        {props.chevronDirection === 'right' && (
                            <TertiaryButtonChevron
                                direction={props.chevronDirection}
                            />
                        )}
                        {props.plusSign && (
                            <img
                                alt=""
                                className="plus-icon"
                                data-testid={`plus-icon`}
                                src={plusSignIconBlue}
                            ></img>
                        )}
                    </SecondaryButton>
                </div>
            )}
        </>
    );
};

export default TertiaryButton;
