import React from 'react';

export interface HeartIconProps {
    height: number;
    width: number;
    color: string;
}

const HeartIcon = (props: HeartIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox={`0 0 ${props.width} ${props.height}`}
            fill="none"
            data-testid="heart-icon"
        >
            <path
                d="M30.4558 20.9531L18.7419 33.3331C17.9693 32.5308 6.13404 20.4644 5.24065 18.1209C4.83442 17.0552 4.63936 15.9114 4.66775 14.7614C4.69613 13.6113 4.94736 12.48 5.40559 11.4387C5.86383 10.3974 6.51915 9.46856 7.3303 8.71077C8.14145 7.95298 9.09084 7.38262 10.1187 7.03561C11.1466 6.68859 12.2306 6.57245 13.3026 6.69448C14.3746 6.8165 15.4113 7.17407 16.3474 7.74465C17.2836 8.31523 18.0988 9.08647 18.7419 10.0098C19.3878 9.09318 20.204 8.32867 21.1395 7.76415C22.075 7.19962 23.1096 6.84722 24.1786 6.72901C25.2476 6.61079 26.3279 6.7293 27.352 7.07713C28.3761 7.42495 29.3219 7.9946 30.1302 8.75041C30.9385 9.50623 31.5919 10.4319 32.0495 11.4696C32.5071 12.5073 32.7591 13.6346 32.7897 14.781C32.8202 15.9274 32.6287 17.0681 32.2271 18.1319C31.8255 19.1957 31.2225 20.1595 30.4558 20.9631"
                stroke={props.color}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default HeartIcon;
