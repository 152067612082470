import React, { ReactElement } from 'react';
import { ExperienceFragmentModel } from '@services/content-service/content-service.interface';
import { InputField, SelectBox } from '@common/form-fields/form-fields';
import { Errors } from '../../personal-information';

export interface Options {
    titleName: string;
    titleValue: string;
}

export interface TitleFieldsDropdown extends ExperienceFragmentModel {
    titleFieldLabel: string;
    options: Options[];
}

export interface TitleProps {
    useExperienceContent: any;
    title: string;
    handleChange?: () => void;
    inputValidations?: any;
    error?: Errors;
    countryCode: string;
    setFocusToField?: (type: string, value: boolean) => void;
}

const Title = (props: TitleProps) => {
    const {
        title,
        handleChange,
        useExperienceContent,
        countryCode,
        setFocusToField,
        inputValidations,
        error,
    } = props;
    const [experienceFragmentTitles] = useExperienceContent(
        'common',
        'title-fields',
        'titlefields'
    );

    const transformSelectOptions = (options) => {
        const titleOptions = [];
        if (options === null || options === undefined) {
            return [];
        }
        for (let i = 0; i < options.length; i++) {
            const option = {
                value: options[i].titleValue,
                displayName: options[i].titleName,
            };
            titleOptions.push(option);
        }
        return titleOptions;
    };

    const getSelectBox = (
        name: string,
        value: string,
        options: any,
        label: string,
        testId?: string
    ): ReactElement => {
        return (
            <SelectBox
                name={name}
                id={name}
                onChange={handleChange}
                value={value}
                className="user-input-select"
                label={label || ''}
                options={options}
                testId={testId}
            />
        );
    };

    return (
        <>
            {countryCode === 'th' ? (
                <InputField
                    id="title-text-input"
                    dataTestId="title-text-input"
                    label={experienceFragmentTitles?.titleFieldLabel}
                    showLabel={true}
                    handleChange={handleChange}
                    name="title"
                    value={title}
                    validationRules={inputValidations.title.validationRules}
                    validationPattern={inputValidations.title.validationPattern}
                    error={error.title}
                    className="user-input-text"
                    onFocus={() => setFocusToField('title', true)}
                    onBlur={() =>
                        title.length == 0 && setFocusToField('title', false)
                    }
                    maxLength={20}
                    placeholder={experienceFragmentTitles?.titleFieldLabel}
                />
            ) : (
                <>
                    {experienceFragmentTitles &&
                        experienceFragmentTitles.options && (
                            <div
                                className={`user-input-select-container`}
                                data-testid="title-section"
                            >
                                {getSelectBox(
                                    'title',
                                    title,
                                    transformSelectOptions(
                                        experienceFragmentTitles.options
                                    ),
                                    experienceFragmentTitles?.titleFieldLabel,
                                    'title'
                                )}
                            </div>
                        )}
                </>
            )}
        </>
    );
};

export default Title;
