import React from 'react';
import './primary-button.scss';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

interface Props {
    theme?: string;
    ariaLabel?: string;
    role?: string;
    testId?: string;
    disabled?: boolean;
    id?: string;
    hideChevron?: boolean;
}

const PrimaryButton = (props: Props & React.HTMLProps<HTMLButtonElement>) => {
    const isLincoln = new AppConfigurationService().brand === 'lincoln';
    const disabledState: { disabled?: boolean } = {};
    let disabledClass = '';

    if (props.disabled) {
        disabledClass = 'disabled';
        disabledState.disabled = true;
    }

    return (
        <button
            className={`fmc-button fmc-button--no-shadow ${
                props.className || ''
            } ${disabledClass}`}
            onClick={props.onClick}
            style={props.style}
            data-dark={props.theme === 'dark' ? true : null}
            aria-label={props.ariaLabel}
            role={props.role}
            data-testid={props.testId}
            id={props.id}
            {...disabledState}
        >
            {props.children}{' '}
            {isLincoln && !props.hideChevron ? (
                <span className="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right"></span>
            ) : null}
        </button>
    );
};

export default PrimaryButton;
