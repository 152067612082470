import { useEffect, useState } from 'react';
import { useExperienceContent } from '@hooks/use-server-content';
import { ExperienceFragmentModel } from '@services/content-service/content-service.interface';
import { IMG_COUNTRIES, EU_COUNTRIES } from '@constants';

export interface Options {
    titleName: string;
    titleValue: string;
}

export interface TitleFieldsDropdown extends ExperienceFragmentModel {
    titleFieldLabel: string;
    options: Options[];
}

export const useTitleContent = (countryCode): TitleFieldsDropdown | null => {
    const [content, setContent] = useState<TitleFieldsDropdown | null>(null);
    const [fragmentArgs, setFragmentArgs] = useState({
        category: '',
        name: '',
        componentName: '',
    });
    const isEu = EU_COUNTRIES.includes(countryCode);
    const isImg = IMG_COUNTRIES.includes(countryCode);
    useEffect(() => {
        if ((isEu || isImg) && countryCode !== 'th') {
            setFragmentArgs({
                category: 'common',
                name: 'title-fields',
                componentName: 'titlefields',
            });
        }
    }, [isEu, isImg]);
    const [experienceFragmentTitles] =
        useExperienceContent<TitleFieldsDropdown>(
            fragmentArgs.category,
            fragmentArgs.name,
            fragmentArgs.componentName
        );
    useEffect(() => {
        if (experienceFragmentTitles) {
            setContent(experienceFragmentTitles);
        }
    }, [experienceFragmentTitles]);
    return content;
};
