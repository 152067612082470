import React from 'react';
import { StepMarkerProps } from '@views/vehicle-order-tracking-status-view/components/tracker/tracker';

const StepMarkerLabel = (props: StepMarkerProps) => {
    return (
        <li
            className="vehicle-order-tracking__tracker--status-list-item"
            data-testid={`step-marker-label-${props.stepNumber}-container`}
        >
            <span
                className={`${props.isCurrentStep ? 'current-step' : ''} ${
                    props.isPreviouslyCompletedStep
                        ? 'previously-completed'
                        : ''
                }`}
                data-testid={`step-marker-label-${props.stepNumber}-text`}
            >
                {props.stepLabel}
            </span>
        </li>
    );
};

export default StepMarkerLabel;
