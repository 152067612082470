import { useEffect, useState } from 'react';
import { ExperienceFragmentModel } from '@services/content-service/content-service.interface';
import { useExperienceContent } from '@hooks/use-server-content';
import { IMG_COUNTRIES, NA_COUNTRIES, SA_COUNTRIES } from '@constants';

export interface DropDownFieldContent extends ExperienceFragmentModel {
    dropdownFieldLabel: string;
    options: DropDownOptions[];
}

export interface DropDownOptions {
    name: string;
    value: string;
}

export const useTerritoryContent = (
    countryCode
): DropDownFieldContent | null => {
    const [content, setContent] = useState<DropDownFieldContent | null>(null);
    const [fragmentArgs, setFragmentArgs] = useState({
        category: '',
        name: '',
        componentName: '',
    });
    const isSA = SA_COUNTRIES.includes(countryCode);
    const isNa = NA_COUNTRIES.includes(countryCode);
    const isImg = IMG_COUNTRIES.includes(countryCode);

    useEffect(() => {
        if (isSA || isNa || isImg || countryCode === 'mx') {
            setFragmentArgs({
                category: 'common',
                name: 'territory',
                componentName: 'dropdown',
            });
        }
    }, [isSA, isNa, isImg]);
    const [experienceFragmentTerritories] =
        useExperienceContent<DropDownFieldContent>(
            fragmentArgs.category,
            fragmentArgs.name,
            fragmentArgs.componentName
        );
    useEffect(() => {
        if (experienceFragmentTerritories) {
            setContent(experienceFragmentTerritories);
        }
    }, [experienceFragmentTerritories]);
    return content;
};
