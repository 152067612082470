import { ERROR_TYPES } from './emplifi-wrapper';

export function chatbotReducer(
    state: {
        chatHistory: any[];
        chatVisible: boolean;
        conversationStarted: boolean;
        errorReason: any;
        lastMessage: string;
        snackbarState: boolean;
        snackbarType: typeof ERROR_TYPES;
        errorLabel: any;
        typingVisible: boolean;
    },
    action: { type: string; payload?: any }
) {
    switch (action.type) {
        case 'updateChatHistory':
            return {
                ...state,
                chatHistory: [...state.chatHistory, action.payload],
            };
        case 'filterChatHistory':
            return {
                ...state,
                chatHistory: state.chatHistory.filter(action.payload),
            };
        case 'replaceChatHistory':
            return { ...state, chatHistory: action.payload };
        case 'updateLastMessage':
            return { ...state, lastMessage: action.payload };
        case 'toggleChatVisibility':
            return { ...state, chatVisible: !state.chatVisible };
        case 'toggleConversationStarted':
            return {
                ...state,
                conversationStarted: !state.conversationStarted,
            };
        case 'setConversationStarted':
            return { ...state, conversationStarted: true };
        case 'updateTypingVisible':
            return { ...state, typingVisible: action.payload };
        case 'updateErrorReason':
            return { ...state, errorReason: action.payload };
        case 'updateErrorLabel':
            return { ...state, errorLabel: action.payload };
        case 'updateSnackbarState':
            return { ...state, snackbarState: action.payload };
        case 'updateSnackbarType':
            return { ...state, snackbarType: action.payload };
        case 'updateQuickRepliesLast':
            return { ...state, quickRepliesLast: action.payload };
        default:
            throw new Error();
    }
}

export const chatbotInitState = {
    chatHistory: [],
    lastMessage: '',
    chatVisible: false,
    conversationStarted: false,
    typingVisible: false,
    errorReason: '',
    errorLabel: '',
    snackbarState: false,
    snackbarType: '',
};
