import React from 'react';
import { InputField } from '@common/form-fields/form-fields';

const FirstName = (props) => {
    const {
        label,
        focus,
        setFocusToField,
        userData,
        handleChange,
        error,
        inputValidations,
    } = props;

    return (
        <>
            {label && (
                <InputField
                    id="firstName"
                    dataTestId="firstName"
                    label={label}
                    showLabel={focus.firstname}
                    handleChange={handleChange}
                    name="firstName"
                    value={userData}
                    className={`${
                        error.firstName.status ? 'error-spacing' : ''
                    }`}
                    validationRules={inputValidations.firstName.validationRules}
                    validationPattern={
                        inputValidations.firstName.validationPattern
                    }
                    validationCharLimit={
                        inputValidations.firstName.validationCharLimit
                    }
                    error={error.firstName}
                    onFocus={() => setFocusToField('firstname', true)}
                    onBlur={() =>
                        userData.length == 0 &&
                        setFocusToField('firstname', false)
                    }
                    placeholder={label}
                />
            )}
        </>
    );
};

export default FirstName;
