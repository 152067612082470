import { useState, useContext, useEffect } from 'react';

import { getNameFromContent } from '@services/content-service/content-service-util';
import ServerSideService from '@services/server-side-service/server-side-service';
import ServerContext from '@contexts/serverContext';
import {
    ContentResponse,
    ExperienceFragmentModel,
} from '@services/content-service/content-service.interface';
import contentService from '@services/content-service/content-service';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

type ReturnType = [ContentResponse | null, (title: string) => string | boolean];

const appConfig = new AppConfigurationService();

const setRegionLanguageDefaults = (serverContext: any) => {
    if (!serverContext.currentRegionCode)
        serverContext.currentRegionCode =
            appConfig.getAppConfiguration().countryCode;

    if (!serverContext.currentLanguageRegionCode)
        serverContext.currentLanguageRegionCode =
            appConfig.getAppConfiguration().languageRegionCode;
    if (!serverContext.brand)
        serverContext.brand = appConfig.getAppConfiguration().brand;
};

const buildServerContext = (
    serverContext: any,
    category: string,
    name: string,
    brand?: string,
    componentName?: string
) => {
    const [currentRegionCode, currentLanguageRegionCode] = [
        serverContext.currentRegionCode,
        serverContext.currentLanguageRegionCode,
    ];
    if (brand) {
        if (!serverContext.content[brand])
            serverContext.content[brand] = {
                [currentRegionCode]: {
                    [currentLanguageRegionCode]: { [category]: { [name]: {} } },
                },
            };
        else if (!serverContext.content[brand][currentRegionCode])
            serverContext.content[brand][currentRegionCode] = {
                [currentLanguageRegionCode]: { [category]: { [name]: {} } },
            };
        else if (
            !serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ]
        )
            serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ] = { [category]: { [name]: {} } };
        else if (
            !serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category]
        )
            serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category] = { [name]: {} };
        else if (
            !serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category][name]
        )
            serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category][name] = {};
        else if (
            componentName &&
            !serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category][name][componentName]
        )
            serverContext.content[brand][currentRegionCode][
                currentLanguageRegionCode
            ][category][name][componentName] = {};
    } else {
        if (!serverContext.content[currentRegionCode])
            serverContext.content[currentRegionCode] = {
                [currentLanguageRegionCode]: { [category]: { [name]: {} } },
            };
        else if (
            !serverContext.content[currentRegionCode][currentLanguageRegionCode]
        )
            serverContext.content[currentRegionCode][
                currentLanguageRegionCode
            ] = { [category]: { [name]: {} } };
        else if (
            !serverContext.content[currentRegionCode][
                currentLanguageRegionCode
            ][category]
        )
            serverContext.content[currentRegionCode][currentLanguageRegionCode][
                category
            ] = { [name]: {} };
        else if (
            !serverContext.content[currentRegionCode][
                currentLanguageRegionCode
            ][category][name]
        )
            serverContext.content[currentRegionCode][currentLanguageRegionCode][
                category
            ][name] = {};
        else if (
            componentName &&
            !serverContext.content[currentRegionCode][
                currentLanguageRegionCode
            ][category][name][componentName]
        )
            serverContext.content[currentRegionCode][currentLanguageRegionCode][
                category
            ][name][componentName] = {};
    }
};

export function useContent(
    category: string,
    name: string,
    brandOverride?: string,
    ymmServlet?: boolean
): ReturnType {
    const serverContext = useContext(ServerContext);
    const appConfig = new AppConfigurationService();

    if (ServerSideService.isClientSide()) {
        serverContext.currentRegionCode = appConfig.get2LetterCountryCode();
        serverContext.currentLanguageRegionCode =
            appConfig.getLanguageRegionCode();
    }
    setRegionLanguageDefaults(serverContext);
    const [brand, currentRegionCode, currentLanguageRegionCode] = [
        brandOverride ? brandOverride : serverContext.brand,
        serverContext.currentRegionCode,
        serverContext.currentLanguageRegionCode,
    ];
    const [content, setContent] = useState<ContentResponse | null>(null);
    const [contentRequested, setContentRequested] = useState<boolean>(false);
    const loadContent = async () => {
        if (!content) {
            buildServerContext(serverContext, category, name, brand);
            if (
                brand &&
                serverContext.content[brand][currentRegionCode][
                    currentLanguageRegionCode
                ][category][name] &&
                Object.keys(
                    serverContext.content[brand][currentRegionCode][
                        currentLanguageRegionCode
                    ][category][name]
                ).length !== 0
            ) {
                setContent(
                    serverContext.content[brand][currentRegionCode][
                        currentLanguageRegionCode
                    ][category][name]
                );
            } else if (
                !brand &&
                serverContext.content[currentRegionCode][
                    currentLanguageRegionCode
                ][category][name] &&
                Object.keys(
                    serverContext.content[currentRegionCode][
                        currentLanguageRegionCode
                    ][category][name]
                ).length !== 0
            ) {
                setContent(
                    serverContext.content[currentRegionCode][
                        currentLanguageRegionCode
                    ][category][name]
                );
            } else {
                if (!contentRequested) {
                    const promise = contentService.getContent(
                        category,
                        name,
                        brand,
                        currentRegionCode,
                        currentLanguageRegionCode,
                        ymmServlet
                    );
                    if (ServerSideService.isServerSide()) {
                        serverContext.promises.push(promise);
                    }
                    promise
                        .then((response: ContentResponse) => {
                            if (ServerSideService.isServerSide()) {
                                if (brand)
                                    serverContext.content[brand][
                                        currentRegionCode
                                    ][currentLanguageRegionCode][category][
                                        name
                                    ] = response;
                                else
                                    serverContext.content[currentRegionCode][
                                        currentLanguageRegionCode
                                    ][category][name] = response;
                            }
                            setContent(response);
                        })
                        .catch(
                            (err) =>
                                ServerSideService.isServerSide() &&
                                console.log(
                                    `error resolving promise for $category:${category} name:${name}. ${err}`
                                )
                        );
                    setContentRequested(true);
                }
            }
        }
    };

    loadContent();
    function getValueByName(name: string): string | boolean {
        if (content) return getNameFromContent(content, name);
        else return '';
    }

    useEffect(() => {
        setContent(null);
        setContentRequested(false);
    }, [JSON.stringify({ category, name, brandOverride, ymmServlet })]);

    if (content && content.name === 'resource-not-found') {
        return [null, getValueByName];
    }

    return [content, getValueByName];
}

export function useExperienceContent<T extends ExperienceFragmentModel>(
    category: string,
    name: string,
    componentName: string,
    brandOverride?: string,
    ymmServlet?: boolean,
    parentFolderOverride?: string
) {
    const serverContext = useContext(ServerContext);
    setRegionLanguageDefaults(serverContext);
    const [brand, currentRegionCode, currentLanguageRegionCode, parentFolder] =
        [
            brandOverride !== '' ? serverContext.brand : '',
            serverContext.currentRegionCode,
            serverContext.currentLanguageRegionCode,
            parentFolderOverride ? parentFolderOverride : 'global-account',
        ];

    const [content, setContent] = useState<T | null>(null);
    const [contentRequested, setContentRequested] = useState<boolean>(false);

    const loadContent = async () => {
        if (!content) {
            buildServerContext(
                serverContext,
                category,
                name,
                brand,
                componentName
            );
            if (
                brand &&
                serverContext.content[brand][currentRegionCode][
                    currentLanguageRegionCode
                ][category][name][componentName] &&
                Object.keys(
                    serverContext.content[brand][currentRegionCode][
                        currentLanguageRegionCode
                    ][category][name][componentName]
                ).length !== 0
            ) {
                setContent(
                    serverContext.content[brand][currentRegionCode][
                        currentLanguageRegionCode
                    ][category][name][componentName]
                );
            } else if (
                !brand &&
                serverContext.content[currentRegionCode][
                    currentLanguageRegionCode
                ][category][name][componentName] &&
                Object.keys(
                    serverContext.content[currentRegionCode][
                        currentLanguageRegionCode
                    ][category][name][componentName]
                ).length !== 0
            ) {
                setContent(
                    serverContext.content[currentRegionCode][
                        currentLanguageRegionCode
                    ][category][name][componentName]
                );
            } else {
                if (!contentRequested && category && name && componentName) {
                    const promise = contentService.getExperience<T>(
                        category,
                        name,
                        componentName,
                        brand,
                        currentRegionCode,
                        currentLanguageRegionCode,
                        ymmServlet,
                        parentFolder
                    );
                    if (ServerSideService.isServerSide()) {
                        serverContext.promises.push(promise);
                    }
                    promise
                        .then((response) => {
                            if (ServerSideService.isServerSide()) {
                                if (brand) {
                                    serverContext.content[brand][
                                        currentRegionCode
                                    ][currentLanguageRegionCode][category][
                                        name
                                    ][componentName] = response;
                                } else {
                                    serverContext.content[currentRegionCode][
                                        currentLanguageRegionCode
                                    ][category][name][componentName] = response;
                                }
                            }
                            setContent(response);
                        })
                        .catch(
                            (err) =>
                                ServerSideService.isServerSide() &&
                                console.log(
                                    `error resolving promise for ${
                                        brand ? `brand:${brand}` : ''
                                    } category:${category} name:${name} componentName:${componentName}. ${err}`
                                )
                        );
                    setContentRequested(true);
                }
            }
        }
    };

    loadContent();

    useEffect(() => {
        setContent(null);
        setContentRequested(false);
    }, [
        JSON.stringify({
            category,
            name,
            componentName,
            brandOverride,
            ymmServlet,
        }),
    ]);

    return [content];
}
