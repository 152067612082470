import React, { useEffect, useState } from 'react';
import {
    CommunicationCenterContent,
    PermissionOption,
    PreferenceOption,
} from '@models/communication-center';
import { PrivacyPreferences } from '@models/profile-with-vehicles';

const checkmarkIcon = './icons/black-checkmark.svg';

export interface PermissionDisplayProps {
    communicationCenterContent: CommunicationCenterContent;
    profileData: any;
    permissionOption: PermissionOption;
}

const PermissionDisplay = (props: PermissionDisplayProps) => {
    const { communicationCenterContent, permissionOption, profileData } = props;
    const [showPreferences, setShowPreferences] = useState<boolean>(false);
    const [matchingOptions, setMatchingOptions] = useState<PreferenceOption[]>(
        []
    );

    const emailPermission: PrivacyPreferences =
        profileData.privacyPreferences.find(
            (preference: PrivacyPreferences) =>
                preference.preferenceName === 'EmailPermission'
        );
    const isOptedIn: boolean = ['1', 1].includes(
        emailPermission?.preferenceCode
    );

    const getMatchingOptions = (): void => {
        const { preferenceOptions } = permissionOption;
        const optionsToDisplay: PreferenceOption[] = [];

        preferenceOptions.forEach((option: PreferenceOption) => {
            profileData.privacyPreferences.forEach(
                (preference: PrivacyPreferences) => {
                    if (
                        preference.preferenceName === option.apiVariable &&
                        preference.preferenceCode === '1'
                    ) {
                        optionsToDisplay.push(option);
                    }
                }
            );
        });

        optionsToDisplay.length && setMatchingOptions(optionsToDisplay);
    };

    const generateYesOrNo = (): string => {
        return isOptedIn
            ? communicationCenterContent.yesText
            : communicationCenterContent.noText;
    };

    useEffect(() => {
        getMatchingOptions();
    }, []);

    useEffect(() => {
        if (matchingOptions.length > 0) {
            setShowPreferences(true);
        }
    }, [matchingOptions]);

    return (
        <div
            className="permission-display__container"
            data-testid="permission-display-container"
        >
            <p className="permission-category">
                {permissionOption.toggleLabel}
            </p>

            <div className="permission-display__selections">
                <h5 className="is-opted-in" data-testid="is-opted-in">
                    {generateYesOrNo()}
                </h5>

                {isOptedIn && showPreferences && (
                    <div
                        className="permission-display__preferences"
                        data-testid="permission-display-preferences"
                    >
                        {matchingOptions.map((preference) => (
                            <div
                                key={preference.optionLabel}
                                className="selected-preference"
                                data-testid="selected-preference"
                            >
                                <img src={checkmarkIcon} alt="" />
                                <p>{preference.optionLabel}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PermissionDisplay;
