import React from 'react';

export interface GradientBackgroundImageCardProps {
    width?: number | string;
    height?: number | string;
    radius?: number;
}

const GradientBackgroundImageCard = (
    props: GradientBackgroundImageCardProps
) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox={`0 0 ${props.width} ${props.height}`}
            fill="none"
        >
            <rect
                width={props.width}
                height={props.height}
                rx={props.radius}
                data-testid="border-radius-test"
                fill="#00142E"
            />
            <rect
                width={props.width}
                height={props.height}
                rx={props.radius}
                fill="url(#paint0_linear_1643_55036)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1643_55036"
                    x1="418.405"
                    y1="10"
                    x2="118.239"
                    y2="496.785"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#00142E" />
                    <stop
                        offset="0.878323"
                        stop-color="#0562D2"
                        stop-opacity="0"
                    />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default GradientBackgroundImageCard;
