import React, { useEffect, useState } from 'react';
import AuthenticationService from '@services/authentication-service/authentication-service';
import serverSideService from '@services/server-side-service/server-side-service';
import ProfileService from '@services/profile-service/profile-service';
import { SignIn } from '@models/experiencefragments/header';
import './login.scss';

const userLoggedOut = './icons/user-profile-loggedout-outline.svg';
const userLoggedIn = './icons/user-profile-loggedin-outline.svg';

interface Props {
    content: SignIn;
    isNavMenuOpen?: boolean;
}

export const Login = (props: Props) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string | null>(null);
    const authService = new AuthenticationService();
    const profileService = new ProfileService();

    const determineUserIcon = () => {
        let icon = userLoggedOut;
        if (serverSideService.isClientSide() && isAuthenticated)
            icon = userLoggedIn;
        return icon;
    };

    const [userIcon, setUserIcon] = useState<string>(determineUserIcon);

    useEffect(() => {
        authService.onIsAuthenticated().then((isAuthenticated: boolean) => {
            setIsAuthenticated(isAuthenticated);
            if (isAuthenticated) {
                profileService.request().then((profileService) => {
                    if (profileService) {
                        setFirstName(
                            profileService.profile.firstName.toUpperCase()
                        );
                    }
                });
                setUserIcon(determineUserIcon());
            }
        });
    }, [firstName]);

    const toggleDropdownAndOverlay = () => {
        document
            .getElementsByClassName('login-dropdown')[0]
            .classList.toggle('active');
        document
            .getElementsByClassName('login-button')[0]
            .classList.toggle('active');
        document
            .getElementsByClassName('main-overlay')[0]
            .classList.toggle('open');

        if (!props.isNavMenuOpen && !document.body.classList.contains('open')) {
            document.body.classList.add('open');
        } else if (
            !props.isNavMenuOpen &&
            document.body.classList.contains('open')
        ) {
            document.body.classList.remove('open');
        }
    };

    if (serverSideService.isClientSide()) {
        window.onresize = () => {
            setUserIcon(determineUserIcon());
        };
        document.body.onclick = (e: MouseEvent) => {
            const eventSource = e.target as HTMLElement;
            const loginDropdown =
                document.getElementsByClassName('login-dropdown')[0];
            if (
                !eventSource.closest('.login-container') &&
                loginDropdown &&
                loginDropdown.classList.contains('active')
            )
                toggleDropdownAndOverlay();
        };
    }

    const loginText = () => {
        return isAuthenticated ? '' : props.content.signInTitle;
    };

    const toggleDisplayOrLogin = () => {
        if (isAuthenticated) {
            toggleDropdownAndOverlay();
        } else authService.login();
    };

    return (
        <>
            {props.content.signInTitle && !props.isNavMenuOpen && (
                <div
                    className={`login-container ${
                        !isAuthenticated ? 'unauthenticated' : ''
                    }`}
                    data-testid="login-container"
                >
                    <div>
                        <button
                            className="login-button"
                            onClick={toggleDisplayOrLogin}
                        >
                            <img src={userIcon} alt="user-profile" />
                            <i className="icon linc1638-lw-icons-38px-c16-expanded-06 hide-me" />
                            <span className="login-text">{loginText()}</span>
                        </button>
                    </div>
                    {isAuthenticated && (
                        <div
                            data-testid="login-dropdown"
                            className="login-dropdown"
                        >
                            <ul className="login-dropdown-list">
                                <li className="login-dropdown-list-item">
                                    <button
                                        className="fmc-text-button"
                                        onClick={authService.logout}
                                        data-testid="sign-out-button"
                                    >
                                        {props.content.signOutTitle}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
