import React from 'react';
import { StarIconProps } from '@views/preferred-dealer-view/components/star-icon-filled/star-icon-filled';

const StarIconOutlined = (props: StarIconProps) => {
    return (
        <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.5007 0.5L8.10083 7.41284L0.5 8.52139L5.89848 13.7012L4.62474 21.5L11.5007 17.9114L18.3766 21.5L17.1029 13.7012L22.5 8.52139L14.8992 7.41284L11.5007 0.5ZM7.37595 13.2199L3.45495 9.4853L9.01421 8.67439L11.5007 3.61843L13.9872 8.67439L19.5464 9.4853L15.6254 13.2199L16.5485 18.9769L11.5007 16.3536L6.45288 18.9769L7.37595 13.2199Z"
                fill={props.color}
            />
        </svg>
    );
};

export default StarIconOutlined;
