import { useEffect, useState } from 'react';

import { VehicleAttributes } from '@models/vehicle-attributes';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import VehicleImageService from '../services/vehicle-image-service/vehicle-image-service';

export const useVehicleImage = (
    vehicleAttributes: VehicleAttributes | OrderedVehiclesItem,
    imageUrl: string,
    page = 'undefined',
    countryCode: string
): string | null => {
    const [vehicleImage, setVehicleImage] = useState<string | null>(null);

    useEffect(() => {
        if (imageUrl) {
            setVehicleImage(imageUrl);
        } else {
            new VehicleImageService()
                .getVehicleImage(vehicleAttributes, page, countryCode)
                .then((vehicleImageUrl) => {
                    setVehicleImage(vehicleImageUrl);
                });
        }
    }, []);
    return vehicleImage;
};
