import React from 'react';
import parseHTML from 'html-react-parser';

interface Props {
    card: any;
    isDealerLocator: boolean;
}

const ChatbotCard = (props: Props) => {
    const { buttons, title, subtitle } = props.card;
    const renderPhoneLink = (button: {
        text:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined;
        payload: string | undefined;
    }) =>
        button.text ? (
            <a className="chatbot__cards-cta" href={button.payload}>
                {button.text}
            </a>
        ) : null;

    const renderWebLink = (button: {
        payload: string | undefined;
        text:
            | string
            | number
            | boolean
            | React.ReactFragment
            | React.ReactPortal
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | null
            | undefined;
    }) => (
        <a
            className="chatbot__cards-cta"
            target="_blank"
            rel="noreferrer noopener"
            href={button.payload}
        >
            {button.text}
        </a>
    );

    return (
        <li className="chatbot__cards-item">
            <div className="chatbot__cards-title">{parseHTML(title)}</div>
            <div className="chatbot__cards-subtitle">
                <div>{parseHTML(subtitle)}</div>
            </div>
            {props.isDealerLocator
                ? renderPhoneLink(buttons[0])
                : renderWebLink(buttons[0])}
        </li>
    );
};

export default ChatbotCard;
