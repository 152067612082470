export class ScrollUtil {
    public scrollPageToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    public scrollAndFocusElement(elemntId: string) {
        const element = document.getElementById(elemntId);
        if (element) {
            element.focus();
        }
    }
}

export default ScrollUtil;
