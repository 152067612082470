import React, { useState, useRef, useEffect, useCallback } from 'react';
import errorWarning from '@assets/error-warning.png';
import { FdsChevron } from '../chevron/fds-chevron';
import './dropdown.scss';

interface Props {
    value: string | number;
    label: string;
    options: string[];
    className?: string;
    onChange: (value: string, index: number) => any;
    onClick?: () => void;
    disabled?: boolean;
    noLabel?: boolean;
    errorMessage?: string;
    tabIndex?: number;
}

const DEFAULT_CURRENT_INDEX = 0;

const Dropdown = (props: Props) => {
    const buttonElement = useRef<HTMLButtonElement>(null);
    const panelElement = useRef<HTMLUListElement>(null);
    const itemElements: HTMLLIElement[] = [];

    const [keySelectionIndex, setKeySelectionIndex] = useState<number>(
        DEFAULT_CURRENT_INDEX
    );

    const [accessibilityActive, setAccessibilityActive] =
        useState<boolean>(false);

    const [currentIndex, setCurrentIndex] = useState<number>(
        DEFAULT_CURRENT_INDEX
    );
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const options = Array.from(props.options);
    if (props.options.length < 1 || !props.noLabel)
        options.unshift(props.label);

    const addItemRef = (element: HTMLLIElement) => {
        itemElements.push(element);
    };

    const alignPanelToItem = (index: number) => {
        const panel = panelElement.current;
        const selectedItem = itemElements.find((element) =>
            element.classList.contains('dropdown-item-' + index)
        );

        if (!!panel && !!selectedItem) {
            const scrollBottom = panel.clientHeight + panel.scrollTop;
            const elementBottom =
                selectedItem.offsetTop + selectedItem.offsetHeight;

            if (elementBottom > scrollBottom) {
                panel.scrollTop = elementBottom - panel.clientHeight;
            } else if (selectedItem.offsetTop < panel.scrollTop) {
                panel.scrollTop = selectedItem.offsetTop;
            }
        }
    };

    const setSelectedItem = (index: number) => {
        alignPanelToItem(index);

        setCurrentIndex(index);
        props.onChange(options[index], index);
    };

    const handleDropdownClick = (e: any) => {
        e.stopPropagation();
        if (!isOpen) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
            setSelectedItem(currentIndex);
        }
    };

    const closeDropdown = (focusButton: boolean) => {
        setIsOpen(false);
        if (focusButton && buttonElement.current) {
            buttonElement.current.focus();
            setSelectedItem(currentIndex);
        }
    };

    const closeDropdownOnBlur = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleKeyDownList = (
        event: React.KeyboardEvent<HTMLUListElement>
    ) => {
        event.preventDefault();
        event.stopPropagation();
        setAccessibilityActive(true);
        switch (event.key) {
            case 'ArrowUp':
                if (keySelectionIndex !== undefined && keySelectionIndex > 0) {
                    alignPanelToItem(keySelectionIndex - 1);
                    setKeySelectionIndex(keySelectionIndex - 1);
                }
                break;
            case 'ArrowDown':
                if (
                    keySelectionIndex !== undefined &&
                    keySelectionIndex < options.length - 1
                ) {
                    alignPanelToItem(keySelectionIndex + 1);
                    setKeySelectionIndex(keySelectionIndex + 1);
                }
                break;
            case 'Enter':
                setSelectedItem(keySelectionIndex);
                setIsOpen(false);
                setAccessibilityActive(false);
                break;
            case 'Home':
                setSelectedItem(0);
                break;
            case 'End':
                setSelectedItem(options.length - 1);
                break;
            case 'Escape':
                closeDropdown(true);
                break;
        }
    };

    const handleKeyDownButton = (
        event: React.KeyboardEvent<HTMLButtonElement>
    ) => {
        if (!isOpen && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
            event.preventDefault();
            event.stopPropagation();
            handleDropdownClick(event);
        }
    };

    const isSelectedItem = (index: number) => {
        return (
            (keySelectionIndex === index && accessibilityActive) ||
            (currentIndex === index && !accessibilityActive)
        );
    };
    const sanitizedOptionId = (optionValue: string, optionIndex: number) => {
        return optionIndex + '_' + optionValue.replace(' ', '-');
    };

    useEffect(() => {
        if (isOpen && !!panelElement.current) {
            panelElement.current.focus();
        }
    }, [isOpen]);

    useEffect(() => {
        let newIndex = DEFAULT_CURRENT_INDEX;
        const currentIndexOfValue = options.indexOf(String(props.value));
        if (currentIndexOfValue > 0) newIndex = currentIndexOfValue;

        setCurrentIndex(newIndex);
    }, [props.value, options]);

    useEffect(() => {
        window.addEventListener('click', closeDropdownOnBlur);
        return () => {
            window.removeEventListener('click', closeDropdownOnBlur);
        };
    }, [closeDropdownOnBlur]);

    return (
        <div
            className={`dropdown ${props.className} ${
                isOpen ? 'dropdown-open' : ''
            } `}
            onClick={props.onClick}
        >
            {props.errorMessage && (
                <div className="error-message">
                    {props.errorMessage}
                    <img src={errorWarning} alt="" className="error-icon" />
                </div>
            )}
            <button
                className="dropdown-button"
                onClick={handleDropdownClick}
                disabled={props.disabled}
                aria-haspopup="true"
                ref={buttonElement}
                onKeyDown={handleKeyDownButton}
            >
                <div className="dropdown-current-item">
                    <span>
                        {currentIndex !== undefined
                            ? options[currentIndex]
                            : props.label}
                    </span>
                    <FdsChevron
                        direction={isOpen ? 'up' : 'down'}
                        type="unfilled"
                    />
                </div>
            </button>
            {isOpen && (
                <div className="dropdown-container">
                    <ul
                        className="dropdown-items-panel"
                        tabIndex={0}
                        role="listbox"
                        aria-activedescendant={sanitizedOptionId(
                            options[currentIndex],
                            currentIndex
                        )}
                        ref={panelElement}
                        onKeyDown={handleKeyDownList}
                    >
                        <div className={'dropdown-list'}>
                            {options.map((option, index) => (
                                <li
                                    id={sanitizedOptionId(option, index)}
                                    key={option.toLowerCase()}
                                    className={`dropdown-item dropdown-item-${index} ${
                                        isSelectedItem(index)
                                            ? 'selected-item'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        setSelectedItem(index);
                                        closeDropdown(false);
                                    }}
                                    aria-selected={isSelectedItem(index)}
                                    role="option"
                                    ref={addItemRef}
                                    value={index}
                                >
                                    {option}
                                </li>
                            ))}
                        </div>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
