import React from 'react';
import { Config } from '@utils/router-util/router-util';
import {
    AccountDashboardView,
    AccountSettingsView,
    DeleteAccountView,
    MarketingOptionsView,
    PageNotFoundView,
    PreferredDealerView,
    WalletView,
} from '@/views';
import { ConnectedServicesView } from '@views/connected-services-view/connected-services-view';
import EuPostRegistrationRedirect from '@sections/eu-post-registration-redirect/eu-post-registration-redirect';
import SignInCredentialsView from '@views/sign-in-credentials-view/sign-in-credentials-view';

export const configs: Config[] = [
    {
        brand: 'ford',
        locale: 'de-de',
        routes: [
            {
                path: '/',
                alias: 'GlobalAccountHomePage',
                component: <AccountDashboardView />,
            },
            {
                path: '/ford/de-de/',
                alias: 'GlobalAccountHomePageDe',
                component: <AccountDashboardView />,
            },
            {
                path: '/ford/de-de/account-dashboard',
                alias: 'GlobalAccountHomePageDe',
                component: <AccountDashboardView />,
            },
            {
                path: '/account-dashboard',
                alias: 'AccountDashboardView',
                component: <AccountDashboardView />,
            },
            {
                path: '/kontoeinstellungen',
                alias: 'AccountSettingsView',
                component: <AccountSettingsView />,
            },
            {
                path: '/wallet',
                alias: 'WalletView',
                component: <WalletView />,
            },
            {
                path: '/connected-services',
                alias: 'ConnectedServicesView',
                component: <ConnectedServicesView />,
            },
            {
                path: '/marketing-optionen',
                alias: 'MarketingOptionsView',
                component: <MarketingOptionsView />,
            },
            {
                path: '/eu-post-registration',
                alias: 'EuPostRegistrationRedirect',
                component: <EuPostRegistrationRedirect />,
            },
            {
                path: '/signin-credentials',
                alias: 'SignInCredentialsView',
                component: <SignInCredentialsView />,
            },
            {
                path: '/delete-account',
                alias: 'DeleteAccountView',
                component: <DeleteAccountView />,
            },
            {
                path: '/preferred-dealer',
                alias: 'PreferredDealerView',
                component: <PreferredDealerView />,
            },
            {
                path: '*',
                alias: 'PageNotFoundView',
                component: <PageNotFoundView />,
            },
        ],
    },
    {
        brand: 'ford',
        locale: 'no-no',
        routes: [
            {
                path: '/',
                alias: 'GlobalAccountHomePage',
                component: <AccountDashboardView />,
            },
            {
                path: '/ford/no-no/',
                alias: 'GlobalAccountHomePageNo',
                component: <AccountDashboardView />,
            },
            {
                path: '/ford/no-no/account-dashboard',
                alias: 'GlobalAccountHomePageNo',
                component: <AccountDashboardView />,
            },
            {
                path: '/account-dashboard',
                alias: 'AccountDashboardView',
                component: <AccountDashboardView />,
            },
            {
                path: '/account-settings',
                alias: 'AccountSettingsView',
                component: <AccountSettingsView />,
            },
            {
                path: '/wallet',
                alias: 'WalletView',
                component: <WalletView />,
            },
            {
                path: '/tilkoblede-tjenester',
                alias: 'ConnectedServicesView',
                component: <ConnectedServicesView />,
            },
            {
                path: '/marketing-options',
                alias: 'MarketingOptionsView',
                component: <MarketingOptionsView />,
            },
            {
                path: '/eu-post-registration',
                alias: 'EuPostRegistrationRedirect',
                component: <EuPostRegistrationRedirect />,
            },
            {
                path: '/signin-credentials',
                alias: 'SignInCredentialsView',
                component: <SignInCredentialsView />,
            },
            {
                path: '/delete-account',
                alias: 'DeleteAccountView',
                component: <DeleteAccountView />,
            },
            {
                path: '/preferred-dealer',
                alias: 'PreferredDealerView',
                component: <PreferredDealerView />,
            },
            {
                path: '*',
                alias: 'PageNotFoundView',
                component: <PageNotFoundView />,
            },
        ],
    },
];
