import SmartTileIcon from '../smart-tile-icon/smart-tile-icon';
import React from 'react';
import SmartTile, { SmartTileProps } from '../smart-tile/smart-tile';
import './smart-tile-fpr.scss';

interface Props {
    appConfig: any;
    iconPath: string;
    staticSubCopy: string;
    dynamicCTATitle?: string;
    dynamicTitle?: string;
    analyticEventName?: string;
    tierText?: string;
    useLoyaltyPointsDataForTile: any;
    isMobile: boolean;
    dynamicSubCopy: string;
}

export const formatDate = (date: string, country: string): string => {
    const splitDate = date.split('-');
    if (country === 'us')
        return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
    return date;
};

const SmartTileFPR = (props: Props & SmartTileProps) => {
    const {
        appConfig,
        dynamicTitle,
        dynamicCTATitle,
        analyticEventName,
        isMobile,
        dynamicSubCopy,
        expirationDateText,
        iconPath,
    } = props;
    const isFord: boolean = appConfig.brand === 'ford';
    const isLincoln: boolean = appConfig.brand === 'lincoln';
    const countryCode: string = appConfig.get2LetterCountryCode();
    const loyaltyDataForTile = props.useLoyaltyPointsDataForTile();
    const dynamicTitleHeading = dynamicTitle ? dynamicTitle : '';

    const daysUntilExpiration = (): number => {
        const dateNow: number = new Date().getTime();
        const dateOfExpiration: number = new Date(
            loyaltyDataForTile?.ford?.pointsExpirationDate
        ).getTime();
        const millisecondsInADay: number = 1000 * 60 * 60 * 24;

        return (dateOfExpiration - dateNow) / millisecondsInADay;
    };
    const showExpireText: boolean =
        daysUntilExpiration() > 0 && daysUntilExpiration() <= 90;
    let pointsExpirationSignal = '';
    if (daysUntilExpiration() <= 90) {
        pointsExpirationSignal =
            daysUntilExpiration() > 30
                ? 'smart-tile-fpr__points-expiration--caution'
                : 'smart-tile-fpr__points-expiration--danger';
    }

    if (
        (isFord && loyaltyDataForTile?.ford?.points > 0) ||
        (isLincoln && loyaltyDataForTile?.lincoln?.points > 0)
    ) {
        return (
            <SmartTile
                {...props}
                className="smart-tile-fpr"
                header={dynamicTitle}
                ctaTitle={dynamicCTATitle}
                ctaHref={props.dynamicCTAPath}
                analyticEventName={analyticEventName}
                isMobile={isMobile}
            >
                <img
                    className="icon"
                    alt=""
                    src={process.env.REACT_APP_AEM_BASE_URL + iconPath}
                />

                <div className="smart-tile__content--container">
                    <div className="smart-tile__text--container">
                        {' '}
                        <h6 className="fmc-type--heading6">
                            {dynamicTitleHeading}
                        </h6>
                        <h6 className="fmc-type--subtitle1">
                            {dynamicTitleHeading}
                        </h6>
                        <div className="smart-tile-fpr__points-and-expiration">
                            <div className="points">
                                <span className="fmc-type--body1">
                                    {loyaltyDataForTile.ford.points}
                                </span>

                                <span
                                    style={{ marginLeft: '5px' }}
                                    className="fmc-type--body1"
                                >
                                    {dynamicSubCopy}
                                </span>
                            </div>

                            {showExpireText && (
                                <div
                                    className={`smart-tile-fpr__points-expiration ${pointsExpirationSignal}`}
                                >
                                    <span>{expirationDateText} </span>
                                    <span>
                                        {formatDate(
                                            loyaltyDataForTile?.ford
                                                ?.pointsExpirationDate,
                                            countryCode
                                        )}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <img src={'./icons/smart-tile-caret--right.svg'} alt="" />
                </div>
            </SmartTile>
        );
    } else {
        return <SmartTileIcon {...props} />;
    }
};

export default SmartTileFPR;
