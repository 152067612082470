import GradientBackground from '@common/gradient-background/gradient-background';
import PersonIcon from '@common/person-icon/person-icon';
import React from 'react';

const PersonIconLogo = ({ isMobile }) => {
    return (
        <div
            className="person-icon__container"
            data-testid="person-icon-container"
        >
            <GradientBackground
                isMobile={isMobile}
                primaryColor="#00142E"
                secondaryColor="#0562D2"
            />
            <PersonIcon color="white" testId="person-icon" />
        </div>
    );
};

export default PersonIconLogo;
