import { AccountSettingsFormsLabels } from './hooks/use-personal-information-content/use-personal-information-content';
import { DropDownFieldContent } from './personal-information-form';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { PHONE_NUMBER_COUNTRIES } from '@constants';
import { DynamicDropdownOptions } from '@common/dynamic-dropdown/dynamic-dropdown';

export const errors = {
    firstName: {
        status: false,
        message: '',
    },
    lastName: {
        status: false,
        message: '',
    },
    middleName: {
        status: false,
        message: '',
    },
    address1Line1: {
        status: false,
        message: '',
    },
    address1Line2: {
        status: false,
        message: '',
    },
    address1City: {
        status: false,
        message: '',
    },
    address1State: {
        status: false,
        message: '',
    },
    address1Zip: {
        status: false,
        message: '',
    },
    address1InteriorNumber: {
        status: false,
        message: '',
    },
    address1SuburbField: {
        status: false,
        message: '',
    },
    address2Line1: {
        status: false,
        message: '',
    },
    address2Line2: {
        status: false,
        message: '',
    },
    address2City: {
        status: false,
        message: '',
    },
    address2State: {
        status: false,
        message: '',
    },
    address2Zip: {
        status: false,
        message: '',
    },
    address2InteriorNumber: {
        status: false,
        message: '',
    },
    address2SuburbField: {
        status: false,
        message: '',
    },
    mobilePhoneNumber: {
        status: false,
        message: '',
    },
    confirmMobilePhoneNumber: {
        status: false,
        message: '',
    },
    phoneNumber: {
        status: false,
        message: '',
    },
    email: {
        status: false,
        message: '',
    },
    confirmEmail: {
        status: false,
        message: '',
    },
    companyName: {
        status: false,
        message: '',
    },
    title: {
        status: false,
        message: '',
    },
    nationalIdNumber: {
        status: false,
        message: '',
    },
    nationalIdType: {
        status: false,
        message: '',
    },
    secondLastName: {
        status: false,
        message: '',
    },
};

export const inputValidations = (
    labelContent: AccountSettingsFormsLabels,
    provinceContent?: DropDownFieldContent,
    addressHasValue?: boolean,
    selectedPrimaryCountry?: DynamicDropdownOptions,
    selectedMobileCountry?: DynamicDropdownOptions
) => {
    const appConfig = new AppConfigurationService();
    const titleConfig = labelContent?.titlePatternRules
        ? {
              validationRules: ['validateMatchesPattern'],
              validationPattern: labelContent?.titlePatternRules,
              label: labelContent?.titleField,
          }
        : {
              validationRules: [],
              validationPattern: '',
              label: labelContent?.titleField,
          };

    const secondLastName = labelContent?.secondLastNameField
        ? {
              validationRules: ['validateNonEmpty', 'validateMatchesPattern'],
              validationPattern: labelContent?.namePatternRules,
              label: labelContent?.secondLastNameField,
          }
        : {
              validationRules: [],
              validationPattern: '',
              label: labelContent?.secondLastNameField,
          };

    const address1ValidationRules = !addressHasValue
        ? ['validateMatchesPattern']
        : ['validateNonEmpty', 'validateMatchesPattern'];

    const address2PatternRule = ['validateMatchesPattern'];

    const address2ValidationPattern =
        appConfig?.currentCountryCode === 'mx'
            ? labelContent?.numberPatternRules
            : labelContent?.addressPatternRules;

    return {
        firstName: {
            validationRules: [
                'validateNonEmpty',
                'validateMatchesPattern',
                'validateMatchesCharLimit',
            ],
            validationPattern: labelContent?.namePatternRules,
            validationCharLimit: labelContent?.firstNameCharacterLength,
            label: labelContent?.firstNameField,
        },
        lastName: {
            validationRules: ['validateNonEmpty', 'validateMatchesPattern'],
            validationPattern: labelContent?.namePatternRules,
            label: labelContent?.lastNameField,
        },
        middleName: {
            validationRules: ['validateMatchesPattern'],
            validationPattern: labelContent?.namePatternRules,
            label: labelContent?.middleNameField,
        },
        nationalIdNumber: {
            validationRules: [],
            validationPattern: '',
            label: labelContent?.nationalIdNumber,
        },
        nationalIdType: {
            validationRules: [],
            validationPattern: '',
            label: labelContent?.nationalIdType,
        },
        address1Line1: {
            validationRules: address1ValidationRules,
            validationPattern: labelContent?.addressPatternRules,
            label: labelContent?.addressField,
        },
        address1Line2: {
            validationRules: address2PatternRule,
            validationPattern: address2ValidationPattern,
            label: labelContent?.addressTwoField,
        },
        address1City: {
            validationRules: !addressHasValue
                ? ['validateMatchesPattern']
                : ['validateNonEmpty', 'validateMatchesPattern'],
            validationPattern: labelContent?.cityPatternRules
                ? labelContent?.cityPatternRules
                : labelContent?.addressPatternRules,
            label: labelContent?.cityField,
        },
        address1State: {
            validationRules: !addressHasValue ? [] : ['validateNonEmpty'],
            validationPattern: '',
            label: provinceContent?.dropdownFieldLabel,
        },
        address1Zip: {
            validationRules: !addressHasValue
                ? ['validateMatchesPattern']
                : ['validateNonEmpty', 'validateMatchesPattern'],
            validationPattern: labelContent?.postalCodePatternRules,
            label: labelContent?.postalCodeField,
        },
        address1InteriorNumber: {
            validationRules: ['validateMatchesPattern'],
            validationPattern: labelContent?.numberPatternRules,
            label: labelContent?.interiorNumberField,
        },
        address1SuburbField: {
            validationRules: ['validateNonEmpty', 'validateMatchesPattern'],
            validationPattern: labelContent?.addressPatternRules,
            label: labelContent?.suburbField,
        },
        address2Line1: {
            validationRules: ['validateMatchesPattern'],
            validationPattern: labelContent?.addressPatternRules,
            label: labelContent?.addressField,
        },
        address2Line2: {
            validationRules: address2PatternRule,
            validationPattern: address2ValidationPattern,
            label: labelContent?.addressTwoField,
        },
        address2City: {
            validationRules: ['validateMatchesPattern'],
            validationPattern: labelContent?.addressPatternRules,
            label: labelContent?.cityField,
        },
        address2State: {
            validationRules: [],
            validationPattern: '',
            label: provinceContent?.dropdownFieldLabel,
        },
        address2Zip: {
            validationRules: ['validateMatchesPattern'],
            validationPattern: labelContent?.postalCodePatternRules,
            label: labelContent?.postalCodeField,
        },
        address2InteriorNumber: {
            validationRules: ['validateMatchesPattern'],
            validationPattern: labelContent?.numberPatternRules,
            label: labelContent?.interiorNumberField,
        },
        address2SuburbField: {
            validationRules: ['validateNonEmpty', 'validateMatchesPattern'],
            validationPattern: labelContent?.addressPatternRules,
            label: labelContent?.suburbField,
        },
        mobilePhoneNumber: {
            validationRules: selectedMobileCountry
                ? ['validateNonEmpty', 'validateMatchesPattern']
                : PHONE_NUMBER_COUNTRIES.includes(appConfig.currentCountryCode)
                ? []
                : ['validateMatchesPattern'],
            validationPattern:
                selectedMobileCountry?.countryRegex ||
                labelContent?.phoneNumberPatternRules,
            label: labelContent?.mobilePhoneField,
        },
        phoneNumber: {
            validationRules: selectedPrimaryCountry
                ? ['validateNonEmpty', 'validateMatchesPattern']
                : PHONE_NUMBER_COUNTRIES.includes(appConfig.currentCountryCode)
                ? []
                : ['validateMatchesPattern'],
            validationPattern:
                selectedPrimaryCountry?.countryRegex ||
                labelContent?.phoneNumberPatternRules,
            label: labelContent?.primaryPhoneField,
        },
        confirmMobilePhoneNumber: {
            validationRules: [],
            validationPattern: labelContent?.phoneNumberPatternRules,
            label: labelContent?.mobilePhoneField,
        },
        email: {
            validationRules: PHONE_NUMBER_COUNTRIES.includes(
                appConfig.currentCountryCode
            )
                ? ['validateNonEmpty', 'validateMatchesPattern']
                : [],
            validationPattern: labelContent?.emailPatternRules,
            label: labelContent?.emailField,
        },
        companyName: {
            validationRules: [],
            validationPattern: '',
            label: labelContent?.companyField,
        },
        title: titleConfig,
        secondLastName,
    };
};
