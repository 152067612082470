export class ServerSideService {
    public isServerSide() {
        return typeof window === 'undefined';
    }

    public isClientSide() {
        return !this.isServerSide();
    }
}

export default new ServerSideService();
