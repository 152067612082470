import React from 'react';
import { HelpRouterFragment } from './help-router.interface';
import { LIVE_CHAT_DIV_IDS } from '../../../constants';
import { HelpRouterContentFragment } from '@sections/help-router/hooks/use-help-router';
interface Props {
    chatVisible: boolean;
    language: string;
    helpRouterServiceHistoryContent: HelpRouterContentFragment;
    helpRouterContent: HelpRouterFragment;
}

const HelpRouterInnerLinks = (props: Props) => {
    const getLiveChatDivId = () => {
        return LIVE_CHAT_DIV_IDS[props.language] || '';
    };

    return (
        <>
            <div className={'help-router-sticky-cta-inner'}>
                <div className={'help-router-inner-links-wrapper'}>
                    <a
                        className={'help-router-inner-link'}
                        href={
                            props.helpRouterContent?.mainMenu.menuItem.item1
                                .item1CtaUrlPath
                        }
                        aria-label={
                            props.helpRouterContent?.mainMenu.menuItem.item1
                                .item1AriaLabel
                        }
                    >
                        {props.helpRouterContent?.mainMenu.menuItem.item1
                            .item1IconPath && (
                            <img
                                src={`${process.env.REACT_APP_AEM_BASE_URL}${props.helpRouterContent?.mainMenu.menuItem.item1.item1IconPath}`}
                                alt=""
                            ></img>
                        )}
                        {
                            props.helpRouterContent?.mainMenu.menuItem.item1
                                .item1CtaTitle
                        }
                    </a>
                </div>
                <div className={'help-router-inner-links-wrapper'}>
                    <a
                        className={'help-router-inner-link'}
                        href={
                            props.helpRouterServiceHistoryContent
                                .serviceHistoryLink
                        }
                        aria-label={
                            props.helpRouterServiceHistoryContent
                                .serviceHistoryAriaLabel
                        }
                    >
                        {props.helpRouterServiceHistoryContent
                            .serviceHistoryIcon && (
                            <img
                                src={`${process.env.REACT_APP_AEM_BASE_URL}${props.helpRouterServiceHistoryContent.serviceHistoryIcon}`}
                                alt=""
                            ></img>
                        )}
                        {
                            props.helpRouterServiceHistoryContent
                                .serviceHistoryText
                        }
                    </a>
                </div>
                <div className={'help-router-inner-links-wrapper'}>
                    <a
                        className={'help-router-inner-link'}
                        href={
                            props.helpRouterContent?.mainMenu.menuItem.item3
                                .item3CtaUrlPath
                        }
                        aria-label={
                            props.helpRouterContent?.mainMenu.menuItem.item1
                                .item1AriaLabel
                        }
                    >
                        {props.helpRouterContent?.mainMenu.menuItem.item3
                            .item3IconPath && (
                            <img
                                src={`${process.env.REACT_APP_AEM_BASE_URL}${props.helpRouterContent?.mainMenu.menuItem.item3.item3IconPath}`}
                                alt=""
                            ></img>
                        )}
                        {
                            props.helpRouterContent?.mainMenu.menuItem.item3
                                .item3CtaTitle
                        }
                    </a>
                </div>
                <div className={'help-router-inner-links-wrapper'}>
                    <a
                        className={'help-router-inner-link'}
                        href={
                            props.helpRouterContent?.mainMenu.menuItem.item4
                                .item4CtaUrlPath
                        }
                        aria-label={
                            props.helpRouterContent?.mainMenu.menuItem.item4
                                .item4AriaLabel
                        }
                    >
                        {props.helpRouterContent?.mainMenu.menuItem.item4
                            .item4IconPath && (
                            <img
                                src={`${process.env.REACT_APP_AEM_BASE_URL}${props.helpRouterContent?.mainMenu.menuItem.item4.item4IconPath}`}
                                alt=""
                            ></img>
                        )}
                        {
                            props.helpRouterContent?.mainMenu.menuItem.item4
                                .item4CtaTitle
                        }
                    </a>
                </div>
                <div
                    id={getLiveChatDivId()}
                    className={`livechat ${
                        props?.chatVisible ? 'visually-hidden' : ''
                    }`}
                ></div>
            </div>
        </>
    );
};
export default HelpRouterInnerLinks;
