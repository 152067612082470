import Axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios';
import { CacheService } from '../cache-service/cache-service';
import AuthenticationService from '../authentication-service/authentication-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import { SESSION_KEY_API_CACHE } from '@constants';
import { getBase64ConsumerKey } from '@utils/consumer-key-converter';
export default class HttpService {
    private static cacheService: CacheService = new CacheService();

    private static appConfigService: AppConfigurationService =
        new AppConfigurationService();

    private static axiosCustomInstance(
        cachingEnabled: boolean,
        ttlMillis?: number
    ): AxiosInstance {
        const axiosInstance = Axios.create();
        axiosInstance.interceptors.request.use((config) => config);

        if (cachingEnabled) {
            axiosInstance.interceptors.response.use((data: AxiosResponse) => {
                let key = data.config.url;
                if (data.config.params) {
                    key += this.buildQueryParams(data.config.params);
                }
                if (key) {
                    HttpService.cacheService.putInCache(
                        SESSION_KEY_API_CACHE,
                        {
                            key,
                            data: data.data,
                        },
                        ttlMillis
                    );
                }
                return data;
            });
        }
        return axiosInstance;
    }

    static axios(): AxiosInstance {
        return this.axiosCustomInstance(false);
    }

    static get<T = any, R = AxiosResponse<T>>(
        apiUrl: string,
        cacheable: boolean,
        config?: AxiosRequestConfig,
        ttlMillis?: number
    ): Promise<R> {
        if (cacheable) {
            const cachedResponse = HttpService.cacheService.getFromCache(
                SESSION_KEY_API_CACHE,
                config && config.params
                    ? apiUrl + this.buildQueryParams(config.params)
                    : apiUrl,
                ttlMillis
            );
            return cachedResponse
                ? Promise.resolve({ data: cachedResponse } as any)
                : this.axiosCustomInstance(cacheable, ttlMillis).get(
                      apiUrl,
                      config
                  );
        } else
            return this.axiosCustomInstance(cacheable, ttlMillis).get(
                apiUrl,
                config
            );
    }

    static post<T = any, R = AxiosResponse<T>>(
        apiUrl: string,
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.axiosCustomInstance(false).post(apiUrl, data, config);
    }

    static put<T = any, R = AxiosResponse<T>>(
        apiUrl: string,
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.axiosCustomInstance(false).put(apiUrl, data, config);
    }

    static delete<T = any, R = AxiosResponse<T>>(
        apiUrl: string,
        data?: any
    ): Promise<R> {
        return this.axiosCustomInstance(false).delete(apiUrl, data);
    }

    static buildQueryParams(params: any) {
        const qs: string[] = [];
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                qs.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        params[key]
                    )}`
                );
            }
        }
        return `?${qs.join('&')}`;
    }

    static getConsumerKeyRequestHeaders() {
        return {
            'Consumer-Key': getBase64ConsumerKey(),
        };
    }

    static getConsumerKeyAndAuthTokenRequestHeaders() {
        const authService = new AuthenticationService();

        return {
            'Auth-Token': authService.getCatBundle()?.access_token,
            'Consumer-Key': getBase64ConsumerKey(),
        };
    }
}
