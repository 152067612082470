import { useContent } from '@hooks/use-server-content';

export interface DisclosuresLinkProps {
    hide: boolean;
    defaultStateOpen: boolean;
    ctaLinkText: string;
    ctaLinkAriaLabel: string;
    content: string;
    disclosuresPageTitle: string;
}
export const useDisclosuresLinkContent = (): DisclosuresLinkProps | null => {
    const [content, getValueByName] = useContent('common', 'disclosures');
    let contentFragment: any = {};
    content?.elements.forEach((element) => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide || !contentFragment.ctaLinkText) {
            contentFragment = null;
        }
    }
    return contentFragment as DisclosuresLinkProps;
};
