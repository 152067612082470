import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';
import './tooltip.scss';
import { PreferenceOption } from '@models/communication-center';
import { KEYBOARD_KEYS } from '@constants';

const cancelCloseIcon = './icons/cancel-close.svg';
interface TooltipProps {
    content: NonNullable<React.ReactNode>;
    children: NonNullable<React.ReactNode>;
    direction?: 'top' | 'bottom' | 'left' | 'right'; // Tooltip will be positioned on top by default
    delay?: number; // By default tooltip will be display immediately.
    tooltipStyles?: any; // custom tooltip styles
    onDisplayTooltip?: any;
    showTooltip: boolean;
    toggleTooltip?: Dispatch<SetStateAction<boolean>>;
    toggleCommunicationTooltip?: (value: boolean) => void;
    isLincoln: boolean;
    isFord: boolean;
    tooltipPreferenceOption?: PreferenceOption;
    onIconClick?: () => void;
    isMobile?: boolean;
    tooltipAriaLabel?: string;
    isFromCommunication?: boolean;
}
const Tooltip = (props: TooltipProps) => {
    const {
        isFromCommunication,
        isMobile,
        tooltipPreferenceOption,
        toggleCommunicationTooltip,
    }: TooltipProps = props;
    const tooltipContentRef = useRef(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const tooltipId: string = tooltipPreferenceOption
        ? 'communication-center-tooltip'
        : 'add-vehicle-tooltip';
    const tooltipAriaLabel: string = tooltipPreferenceOption
        ? props.tooltipAriaLabel
        : 'Where do I find my VIN?';
    const tooltipCustomStyles =
        props.isLincoln && props.tooltipStyles
            ? { ...props.tooltipStyles }
            : {};
    const [closeTooltip, setCloseTooltip] = useState(true);

    const onClick = () => {
        if (closeTooltip) {
            setCloseTooltip(false);
        } else {
            setCloseTooltip(true);
        }

        if (props.showTooltip !== false) {
            if (isFromCommunication) {
                toggleCommunicationTooltip(false);
            } else {
                props.toggleTooltip(false);
            }

            setCloseTooltip(true);
        } else {
            if (isFromCommunication) {
                toggleCommunicationTooltip(true);
            } else {
                props.toggleTooltip(true);
            }
        }
    };

    const handleIconClick = (): void => {
        if (isFromCommunication) {
            props.onIconClick();
        }
    };

    const onTabBackwards = (e): void => {
        if (e.key === KEYBOARD_KEYS.TAB && e.shiftKey) {
            toggleCommunicationTooltip(false);
        }
    };

    const closeOnEscapeKey = (e): void => {
        if (e.key === KEYBOARD_KEYS.ESC) {
            if (isFromCommunication) {
                toggleCommunicationTooltip(false);
            } else {
                props.toggleTooltip(false);
            }
        }
    };

    const handleClickOutsideTooltip = (e) => {
        const isOutsideTooltip =
            tooltipContentRef.current &&
            !tooltipContentRef.current.contains(e.target);
        const isMobileAndTooltipOpen =
            isMobile && isFromCommunication && props.showTooltip;

        if (isMobileAndTooltipOpen) {
            return;
        }

        if (isOutsideTooltip) {
            if (isFromCommunication) {
                toggleCommunicationTooltip(false);
            } else {
                props.toggleTooltip(false);
            }
        }
    };

    useEffect(() => {
        if (props.showTooltip) {
            document.addEventListener(
                'click',
                handleClickOutsideTooltip,
                false
            );
            document.addEventListener('keydown', closeOnEscapeKey, false);
        }

        return () => {
            document.removeEventListener(
                'click',
                handleClickOutsideTooltip,
                false
            );
            document.removeEventListener('keydown', closeOnEscapeKey, false);
        };
    }, [props.showTooltip]);

    useEffect(() => {
        if (
            props.showTooltip &&
            isFromCommunication &&
            isMobile &&
            closeButtonRef?.current
        ) {
            setTimeout(() => {
                closeButtonRef?.current.focus();
            }, 250);
        }
    }, [props.showTooltip, isMobile]);

    return (
        <div className="tooltip-wrapper" ref={tooltipContentRef}>
            <div onClick={handleIconClick} onKeyDown={onTabBackwards}>
                {props.children}
            </div>

            {props.showTooltip && (
                <div
                    id={tooltipId}
                    data-testid={tooltipId}
                    role="dialog"
                    aria-label={tooltipAriaLabel}
                    className={`tooltip-tip ${props.direction || 'top'}`}
                    style={{ ...tooltipCustomStyles }}
                >
                    {props.isFord || isFromCommunication ? (
                        <button
                            ref={closeButtonRef}
                            data-testid="test-close"
                            onClick={onClick}
                            aria-label="Close Dialog"
                            className={`fmc-dialog__close ${
                                props.isLincoln
                                    ? 'fds-icon fds-font--ford-icons__clear'
                                    : ''
                            }`}
                            onKeyDown={(e) => {
                                if (e.key === KEYBOARD_KEYS.TAB) {
                                    toggleCommunicationTooltip(false);
                                }
                            }}
                        >
                            {props.isFord && (
                                <img
                                    src={cancelCloseIcon}
                                    alt="close-icon"
                                ></img>
                            )}
                        </button>
                    ) : null}
                    {props.content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
