import React, { useEffect, useState } from 'react';
import SecondaryButton from '@common/secondary-button/secondary-button';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { MemberId } from '../member-id/member-id';
import DeleteAccountModal from '../delete-account-modal/delete-account-modal';
import { DeleteAccountContent } from './hooks/use-delete-account-content';
import './delete-account.scss';
import { useAnalytics } from '@/hooks/use-analytics';

export interface DeleteAccountProps {
    deleteAccountContent: DeleteAccountContent;
    memberId: string;
    memberIdLabel: string;
    accountSettingsPath: any;
}

const DeleteAccountComponent = (props: DeleteAccountProps) => {
    useAnalytics('ownerAccountDeleteMyAccount');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showApiResponsePage, setApiResponsePage] = useState(true);
    const scrollUtil = new ScrollUtil();
    const handleDeleteAccount = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        scrollUtil.scrollPageToTop();
    }, []);

    return (
        <>
            <DeleteAccountModal
                modalContent={props.deleteAccountContent}
                isModalVisible={isModalVisible}
                handleClose={handleClose}
                setApiResponsePage={setApiResponsePage}
            />
            {showApiResponsePage && (
                <div
                    className="delete-account-component-container"
                    data-testid="delete-account-component-container"
                >
                    <h1
                        className="delete-account-header fmc-type--heading5 fds-color__text--primary fds-align--center"
                        data-testid="delete-account-header"
                    >
                        {props.deleteAccountContent.deleteAccountHeader}
                    </h1>

                    <MemberId
                        memberIdFieldLabel={props.memberIdLabel}
                        memberIdNumber={props.memberId}
                        className="delete-account-member-id "
                    />

                    <div
                        className="delete-account-body-text fmc-type--body1 fds-color__text--gray3"
                        data-testid="delete-account-body-text"
                        dangerouslySetInnerHTML={{
                            __html: props.deleteAccountContent
                                .deleteAccountBodyText,
                        }}
                    ></div>

                    <div className="delete-account-button-container">
                        <button
                            className="delete-account-button fmc-button"
                            data-testid="delete-account-button"
                            onClick={handleDeleteAccount}
                        >
                            {
                                props.deleteAccountContent
                                    .deleteAccountButtonLabel
                            }
                        </button>

                        <SecondaryButton
                            className={'cancel-button fmc-text-button'}
                            internal
                            link={props.accountSettingsPath}
                            dataTestId="delete-account-cancel-button"
                        >
                            {props.deleteAccountContent?.cancelButtonLabel}
                        </SecondaryButton>
                    </div>

                    {props.deleteAccountContent.legalCopy && (
                        <div
                            className="delete-account__legal-copy"
                            dangerouslySetInnerHTML={{
                                __html: props.deleteAccountContent.legalCopy,
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default DeleteAccountComponent;
