import CryptoJS from 'crypto-js';

export default class EncryptionService {
    key: CryptoJS.lib.WordArray;
    iv: CryptoJS.lib.WordArray;

    constructor(key: string) {
        this.key = CryptoJS.enc.Utf8.parse(key);
        this.iv = CryptoJS.enc.Utf8.parse(key?.substring(0, 16));
    }

    public encrypt(textToEncrypt: string) {
        return CryptoJS.AES.encrypt(textToEncrypt, this.key, {
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }).toString();
    }

    public decrypt(textToEncrypt: string) {
        return CryptoJS.AES.decrypt(textToEncrypt, this.key, {
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }).toString(CryptoJS.enc.Utf8);
    }
}
