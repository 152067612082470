import React, {
    useCallback,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';
import './help-router.scss';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { HelpRouterFragment } from './help-router.interface';
import { Chevron } from '../../common';
import EmplifiWrapper, {
    KEYS,
    MESSAGE_ORIGIN,
    FEEDBACK_TIMEOUT,
} from './emplifi-wrapper';
import EuHelpRouterPopularLinks from './help-router-popular-links';
import HelpRouterInnerLinks from './help-router-inner-links';
import { MESSAGE_TYPES } from './emplifi-wrapper';

const helpIcon = './icons/help-icon.svg';
const menu = './icons/menu.svg';
export const PERSISTENCE_MESSAGE_TYPES = {
    NEW_MESSAGE: 'new-message',
    FEEDBACK_RECEIVED: 'feedback-received',
};
import {
    FILTER_CHAT_HISTORY,
    REPLACE_CHAT_HISTORY,
    TOGGLE_CHAT_VISIBILITY,
    TOGGLE_CONVERSATION_STARTED,
    UPDATE_CHAT_HISTORY,
    UPDATE_ERROR_REASON,
    UPDATE_LAST_MESSAGE,
    UPDATE_QUICK_REPLIES_LAST,
    UPDATE_SNACKBAR_STATE,
    UPDATE_TYPING_VISIBLE,
} from './actionTypes';
import {
    chatbotReducer as reducer,
    chatbotInitState as initState,
} from './reducers';
import ChatbotWindow from './ChatBotResponse';
import {
    parseChatbotError,
    resetChatbotError,
    setErrorTimers,
} from './errorUtils';
import ChatbotSnackbar from './ChatbotSnackbar';
import ChatbotInput from './chatbot-input/chatbot-input';
import getUuid from '../../utils/uuid-util/uuid';
import { KEYBOARD_KEYS } from '../../../constants';
import { HelpRouterContentFragment } from '@sections/help-router/hooks/use-help-router';
interface Props {
    className?: string;
    onAnalogClick?: () => void;
    onVAClick?: () => void;
    message?: any;
    language: string;
    helpRouterServiceHistoryContent: HelpRouterContentFragment;
}
const HelpRouter = (props: Props) => {
    const [state, dispatch] = useReducer(reducer, initState);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const backToVARef = useRef<HTMLButtonElement>(null);
    const [goToAnalog, setGoToAnalog] = useState<boolean>(false);
    const [helpRouterContent] = useExperienceContent<HelpRouterFragment>(
        'common',
        'help-router',
        'helprouter',
        null,
        false,
        'global-owner'
    );

    const chatHistoryRef = useRef(state.chatHistory);
    useEffect(() => {
        chatHistoryRef.current = state.chatHistory;
    }, [state.chatHistory]);
    const updateChatHistory = useCallback(
        (
            origin: any,
            type: any,
            content: any,
            fullContent = null,
            isVertical = null
        ) => {
            const payload = {
                origin,
                type,
                content,
                fullContent,
                isVertical,
                id: getUuid(),
            };

            const persistenceData = {
                type: PERSISTENCE_MESSAGE_TYPES.NEW_MESSAGE,
                payload,
            };

            localStorage.setItem(
                KEYS.LAST_MESSAGE,
                JSON.stringify(persistenceData)
            );

            dispatch({
                type: UPDATE_CHAT_HISTORY,
                payload,
            });
        },
        []
    );
    const handleUserMessage = useCallback(
        (message: any) => {
            updateChatHistory(MESSAGE_ORIGIN.USER, MESSAGE_TYPES.TEXT, message);
            dispatch({
                type: FILTER_CHAT_HISTORY,
                payload: (msg: { type: number }) =>
                    msg.type !== MESSAGE_TYPES.QUICK_REPLIES,
            });
            if (message !== '') {
                dispatch({ type: UPDATE_LAST_MESSAGE, payload: message });
                dispatch({ type: UPDATE_QUICK_REPLIES_LAST, payload: false });
            }
        },
        [updateChatHistory]
    );
    const handleBotResponse = useCallback(
        (response: any) => {
            response?.forEach(
                (message: {
                    type: number;
                    isVertical: null | undefined;
                    content: any;
                    fullContent: null | undefined;
                }) => {
                    if (
                        message.type === MESSAGE_TYPES.QUICK_REPLIES &&
                        !message.isVertical
                    ) {
                        dispatch({
                            type: UPDATE_QUICK_REPLIES_LAST,
                            payload: true,
                        });
                    }

                    updateChatHistory(
                        MESSAGE_ORIGIN.BOT,
                        message.type,
                        message.content,
                        message.fullContent,
                        message.isVertical
                    );
                }
            );
        },
        [updateChatHistory]
    );
    const shouldBePersisted = (
        msg: { type: number },
        i: number,
        allMessages: string | any[]
    ) =>
        msg.type !== MESSAGE_TYPES.QUICK_REPLIES ||
        i === allMessages.length - 1;

    const getUpdatedConversation = useCallback(() => {
        setTimeout(() => {
            chatbotApi.current.getAllMessages().then((chatMessages) => {
                const filteredMessages = chatMessages.filter(shouldBePersisted);

                if (filteredMessages.length > chatHistoryRef.current.length) {
                    const chatMessagesWithId = chatMessages.map((msg) => ({
                        ...msg,
                        id: getUuid(),
                    }));

                    dispatch({
                        type: REPLACE_CHAT_HISTORY,
                        payload: chatMessagesWithId,
                    });

                    dispatch({
                        type: FILTER_CHAT_HISTORY,
                        payload: shouldBePersisted,
                    });

                    const persistenceData = {
                        type: PERSISTENCE_MESSAGE_TYPES.FEEDBACK_RECEIVED,
                    };

                    localStorage.setItem(
                        KEYS.LAST_MESSAGE,
                        JSON.stringify(persistenceData)
                    );
                }
            });
        }, FEEDBACK_TIMEOUT);
    }, []);
    const chatbotApi = useRef(new EmplifiWrapper());
    const delayedResponseMessage =
        helpRouterContent?.virtualAssistant.delayedResponseMessage;
    const connectionErrorMessage =
        helpRouterContent?.virtualAssistant.connectionErrorMessage;
    const generalErrorMessage =
        helpRouterContent?.virtualAssistant.generalErrorMessage;
    const onInitialSubmit = useCallback(
        (message: any) => {
            dispatch({ type: UPDATE_TYPING_VISIBLE, payload: true });
            const { stillLoadingTimer, unableToConnectTimer } = setErrorTimers(
                dispatch,
                delayedResponseMessage,
                connectionErrorMessage
            );
            dispatch({ type: UPDATE_LAST_MESSAGE, payload: message });
            chatbotApi.current
                .createConversation()
                .then(() => {
                    dispatch({
                        type: TOGGLE_CONVERSATION_STARTED,
                    });
                    dispatch({ type: TOGGLE_CHAT_VISIBILITY });
                    handleUserMessage(message);
                })
                .then(() => {
                    chatbotApi.current
                        .getBotResponse(message)
                        .then(handleBotResponse)
                        .then(() => {
                            resetChatbotError(dispatch);
                            dispatch({
                                type: UPDATE_ERROR_REASON,
                                payload: '',
                            });
                            getUpdatedConversation();
                        })
                        .finally(() => {
                            clearTimeout(stillLoadingTimer);
                            clearTimeout(unableToConnectTimer);
                            dispatch({
                                type: UPDATE_TYPING_VISIBLE,
                                payload: false,
                            });
                        });
                })
                .catch((error) => {
                    parseChatbotError(
                        error,
                        dispatch,
                        connectionErrorMessage,
                        generalErrorMessage
                    );
                });
        },
        [
            handleBotResponse,
            handleUserMessage,
            delayedResponseMessage,
            connectionErrorMessage,
            generalErrorMessage,
            getUpdatedConversation,
        ]
    );

    const onSubmit = useCallback(
        (message: any) => {
            dispatch({ type: UPDATE_TYPING_VISIBLE, payload: true });
            handleUserMessage(message);
            const { stillLoadingTimer, unableToConnectTimer } = setErrorTimers(
                dispatch,
                delayedResponseMessage,
                connectionErrorMessage
            );

            chatbotApi.current
                .getBotResponse(message)
                .then(handleBotResponse)
                .then(() => {
                    resetChatbotError(dispatch);
                    dispatch({ type: UPDATE_ERROR_REASON, payload: '' });

                    getUpdatedConversation();
                })
                .catch((error) =>
                    parseChatbotError(
                        error,
                        dispatch,
                        connectionErrorMessage,
                        generalErrorMessage
                    )
                )
                .finally(() => {
                    clearTimeout(stillLoadingTimer);
                    clearTimeout(unableToConnectTimer);
                    dispatch({ type: UPDATE_TYPING_VISIBLE, payload: false });
                });
        },
        [handleBotResponse, handleUserMessage, getUpdatedConversation]
    );
    const handleAnalogClick = (e: any) => {
        e.stopPropagation();
        const closedBackground =
            document.getElementsByClassName('background')[0];
        const closedRouter = document.getElementsByClassName(
            'help-router-container'
        )[0];

        if (!isOpen) {
            props.onAnalogClick && props.onAnalogClick();

            closedRouter.classList.add('open');
            closedRouter.classList.remove('closed');
            closedBackground.classList.add('open');
            closedBackground.classList.remove('closed');
            setIsOpen(true);
            document.body.classList.add('no-scroll');
        } else {
            closedRouter.classList.add('closed');
            closedBackground.classList.add('closed');
            document.body.classList.remove('no-scroll');
            setTimeout(() => {
                closedRouter.classList.remove('open');
                closedBackground.classList.remove('open');
                document.body.classList.remove('no-scroll');
                setIsOpen(false);
            }, 800);
        }
    };

    const backToAnalog = (e: any) => {
        e.stopPropagation();
        setGoToAnalog(true);
        dispatch({ type: TOGGLE_CHAT_VISIBILITY });
    };
    const onRetry = () => {
        if (state.conversationStarted) {
            onSubmit(state.lastMessage);
        } else {
            onInitialSubmit(state.lastMessage);
        }
    };
    const onCloseSnackbar = () => {
        dispatch({ type: UPDATE_SNACKBAR_STATE, payload: false });
    };
    const onEscape = (event: KeyboardEvent) => {
        if (event.key === KEYBOARD_KEYS.ESC) {
            handleAnalogClick(event);
        }
    };
    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', onEscape, false);
        }
        return () => {
            if (isOpen) {
                document.removeEventListener('keydown', onEscape, false);
            }
        };
    }, [isOpen]);

    return helpRouterContent && !helpRouterContent.hide ? (
        <div className={'help-router'}>
            <button
                className={'sticky-cta-open-label'}
                onClick={handleAnalogClick}
                aria-label={helpRouterContent.helpAnalog.ariaLabel}
            >
                <img src={helpIcon} alt="" className="help-icon"></img>
                <p>{helpRouterContent.helpAnalog.helpText}</p>
            </button>
            <div aria-hidden={!isOpen} className={'help-router-container'}>
                <div className={'help-router-title'}>
                    <p>{helpRouterContent?.mainMenu.howToHelp}</p>
                    <button
                        className={'help-router-cta-close'}
                        onClick={handleAnalogClick}
                    ></button>
                </div>
                <div className={'help-router-container-inner'}>
                    {!goToAnalog && (
                        <>
                            <p className={'chatbot-title'}>
                                {
                                    helpRouterContent.mainMenu
                                        .virtualAssistantHeader
                                }
                            </p>
                            <ChatbotInput
                                onSubmit={onInitialSubmit}
                                dispatch={dispatch}
                                placeholder={
                                    helpRouterContent.mainMenu.placeholderText
                                }
                                conversationStarted={state.conversationStarted}
                            />
                        </>
                    )}
                    {goToAnalog && (
                        <>
                            <p className={'chatbot-title'}>
                                {
                                    helpRouterContent.mainMenu
                                        .backToVirtualAssistantHeader
                                }
                            </p>
                            <button
                                ref={backToVARef}
                                className="return-va"
                                onClick={() => {
                                    dispatch({
                                        type: TOGGLE_CHAT_VISIBILITY,
                                    });
                                }}
                                aria-label={
                                    helpRouterContent.mainMenu
                                        .backToVirtualAssistantCtaAriaLabel
                                }
                            >
                                {
                                    helpRouterContent.mainMenu
                                        .backToVirtualAssistantCta
                                }
                                <Chevron
                                    direction={'right'}
                                    color={'#102B4E'}
                                />
                            </button>
                        </>
                    )}
                    {state.chatVisible && (
                        <div className={'chatbot-container'}>
                            <ChatbotWindow
                                chatHistory={state.chatHistory}
                                onSubmit={onSubmit}
                                isTypingVisible={state.typingVisible}
                                chatVisible={state.chatVisible}
                                chatbotSentAccessibilityMessage={
                                    helpRouterContent.virtualAssistant
                                        .chatbotSentAccessibilityMessage
                                }
                                showLessLabel={
                                    helpRouterContent.virtualAssistant
                                        .showLessLabel
                                }
                                showMoreLabel={
                                    helpRouterContent.virtualAssistant
                                        .showMoreLabel
                                }
                                userSentAccessibilityMessage={
                                    helpRouterContent.virtualAssistant
                                        .userSentAccessibilityMessage
                                }
                                retryAction={onRetry}
                                retryCtaMessage={
                                    helpRouterContent.virtualAssistant
                                        .retryCTAMessage
                                }
                                retryMessageAriaLabel={
                                    helpRouterContent.virtualAssistant
                                        .retryMessageAriaLabel
                                }
                                sendFailureMessage={
                                    helpRouterContent.virtualAssistant
                                        .sendFailureMessage
                                }
                                errorReason={state.errorReason}
                                showLessAriaLabel={
                                    helpRouterContent.virtualAssistant
                                        .showLessAriaLabel
                                }
                                showMoreAriaLabel={
                                    helpRouterContent.virtualAssistant
                                        .showMoreAriaLabel
                                }
                            />
                            <div className={'chatbot-menu'}>
                                <button
                                    className={'chatbot-menu-button'}
                                    onClick={backToAnalog}
                                >
                                    <img
                                        src={menu}
                                        alt=""
                                        className="menu-icon"
                                    ></img>
                                    {
                                        helpRouterContent.virtualAssistant
                                            .backToMainMenuHamburgerCTAText
                                    }
                                </button>
                                <ChatbotInput
                                    className={'chatbot-input__conversation'}
                                    conversationStarted={
                                        state.conversationStarted
                                    }
                                    onSubmit={onSubmit}
                                    dispatch={dispatch}
                                    placeholder={
                                        helpRouterContent.virtualAssistant
                                            .inputFieldPlaceholder
                                    }
                                />
                            </div>
                        </div>
                    )}
                    {!state.chatVisible && (
                        <EuHelpRouterPopularLinks
                            helpRouterContent={helpRouterContent}
                        />
                    )}
                </div>
                <HelpRouterInnerLinks
                    chatVisible={state.chatVisible}
                    language={props.language}
                    helpRouterServiceHistoryContent={
                        props.helpRouterServiceHistoryContent
                    }
                    helpRouterContent={helpRouterContent}
                />
                {!state.conversationStarted && (
                    <ChatbotSnackbar
                        snackbarType={state.snackbarType}
                        closeAction={onCloseSnackbar}
                        messageLabel={
                            helpRouterContent.virtualAssistant
                                .generalErrorMessage
                        }
                        onSnackbarButtonClick={onRetry}
                        actionLabel={
                            helpRouterContent.virtualAssistant.retryCTAMessage
                        }
                        isVisible={state.snackbarState}
                        retryMessageAriaLabel={
                            helpRouterContent.virtualAssistant
                                .retryMessageAriaLabel
                        }
                    />
                )}
            </div>

            <div className={'background'} onClick={handleAnalogClick} />
        </div>
    ) : (
        <></>
    );
};
export default HelpRouter;
