import { GlobalAccountRoute } from '@routes/routesList';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { CHARGER_ADAPTER_STATUS_QUERY_PARAM } from '@constants';

export interface Config {
    brand: string;
    locale: string | string[];
    alias?: string;
    routes: Array<GlobalAccountRoute>;
}

export const findRouterConfig = (
    configuration: Array<Config>,
    currentBrand: string,
    currentLocale: string
): Config | undefined => {
    return configuration.find((config) => {
        if (typeof config.locale !== 'string') {
            return (
                config.brand === currentBrand &&
                config.locale.includes(currentLocale)
            );
        } else {
            return (
                config.brand === currentBrand && config.locale === currentLocale
            );
        }
    });
};

const mergeRoutesWith =
    () =>
    (
        defaultRegistry: Array<GlobalAccountRoute>,
        configuration: Array<Config>,
        currentBrand?: string,
        currentLocale?: string
    ): GlobalAccountRoute[] => {
        const overriddenComponentRegister = findRouterConfig(
            configuration,
            currentBrand,
            currentLocale
        );

        if (overriddenComponentRegister) {
            return overriddenComponentRegister.routes;
        }

        return defaultRegistry;
    };

export const mergeRoutes = mergeRoutesWith();

export const findRoute =
    (
        find: Function,
        defaultRegistry: Array<GlobalAccountRoute>,
        configuration: Array<Config>,
        currentBrand: string
    ) =>
    (alias: string) => {
        const currentAppConfig =
            new AppConfigurationService().getAppConfiguration();

        return find(
            alias,
            mergeRoutes(
                defaultRegistry,
                configuration,
                currentBrand,
                currentAppConfig.languageRegionCode
            )
        );
    };

export const hasBrandAndLocale = (
    appConfig: AppConfigurationService
): boolean => {
    return appConfig.currentRoot.includes(appConfig.currentBrandAndLocale);
};

export const findRouteByAlias = (
    alias: string,
    mergedRoutes: Array<GlobalAccountRoute>
) => mergedRoutes.find((route) => route.alias === alias)?.path;

export const buildCampaignIdQueryParameter = (): string => {
    const campaignId = localStorage.getItem('SSP_CAMPAIGN_ID');
    return campaignId ? `&campaignId=${campaignId}` : '';
};

export const buildConnectedServicesExtraQueryParams = (queryParams): string => {
    queryParams.delete('connectedservices');
    queryParams.delete('vin');
    if (localStorage.getItem('SSP_CAMPAIGN_ID') != null) {
        queryParams.delete('campaignId');
    }
    return queryParams.toString().length > 0
        ? '&' + queryParams.toString()
        : '';
};

export const buildChargerAdapterStatusQueryParameter = (
    chargerAdapterStatus: boolean
): string => {
    return chargerAdapterStatus ? CHARGER_ADAPTER_STATUS_QUERY_PARAM : '';
};

export const getRoot = (
    brand: string,
    languageRegionCode: string,
    configRoot: string,
    routerBasename?: string
) => {
    return configRoot
        ? configRoot
        : routerBasename
        ? `${routerBasename}/`
        : `/${brand}/${languageRegionCode}/`;
};
