import React from 'react';
import {
    CommunicationCenterContent,
    PermissionOption,
} from '@models/communication-center';

export interface PermissionDisplayProps {
    communicationCenterContent: CommunicationCenterContent;
    permissionOption: PermissionOption;
    emailPermissionOptedIn: boolean;
}

const EmailPermissionDisplay = (props: PermissionDisplayProps) => {
    const {
        communicationCenterContent,
        permissionOption,
        emailPermissionOptedIn,
    } = props;

    const generateYesOrNo = (): string => {
        return emailPermissionOptedIn
            ? communicationCenterContent.yesText
            : communicationCenterContent.noText;
    };

    return (
        <div
            className="permission-display__container"
            data-testid="permission-display-container"
        >
            <p className="permission-category">
                {permissionOption.toggleLabel}
            </p>

            <div className="permission-display__selections">
                <div className="is-opted-in" data-testid="is-opted-in">
                    {generateYesOrNo()}
                </div>
            </div>
        </div>
    );
};

export default EmailPermissionDisplay;
