import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useExperienceContent } from '@hooks/use-server-content';
import { Link } from '@common/index';
import { FdsChevron } from '@common/chevron/fds-chevron';
import {
    HeaderFragment,
    MainNavLeft,
} from '@models/experiencefragments/header';
import './eu-sidebar-menu.scss';

interface SidebarMenuProps {
    isOpen: boolean;
    className: string;
    toggleMenu: React.MouseEventHandler<HTMLElement>;
}

const EuSidebarMenu = (props: SidebarMenuProps) => {
    const [experienceHeaderContent] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header'
    );

    const [activeSubnav] = useState('');

    const [activeIndex, setActiveIndex] = useState('');
    const animationProps = useSpring({
        left: props.isOpen ? '0%' : '-100%',
    });

    function chooseClassForNavMenuButton(navToCheckAgainst: string) {
        if (activeSubnav === '') {
            return '';
        }

        return activeSubnav === navToCheckAgainst
            ? 'eu-selected-sidebar-menu-item'
            : 'eu-unselected-sidebar-menu-item';
    }

    const noCategories = (nav: MainNavLeft): boolean => {
        return !nav.categories && nav.noFlyout;
    };

    const hasCategories = (nav: MainNavLeft): boolean => {
        return nav.categories && !nav.noFlyout;
    };

    const renderCategories = (nav: MainNavLeft): any => {
        return (
            <ul className="eu-main-nav-title">
                {nav?.categories &&
                    nav.categories.map((subnav, index) => {
                        return (
                            <li key={index}>
                                <span>{subnav.categoryTitle}</span>

                                <ul>
                                    {subnav?.categoryItems &&
                                        subnav.categoryItems?.map(
                                            (sideNav, inx) => {
                                                return (
                                                    <li key={inx}>
                                                        <Link
                                                            target={
                                                                sideNav.targetBlank
                                                                    ? '_blank'
                                                                    : ''
                                                            }
                                                            href={sideNav.url}
                                                            aria-label={
                                                                sideNav.ariaLabel
                                                            }
                                                            className={
                                                                'category-items-links'
                                                            }
                                                        >
                                                            {sideNav.title}
                                                        </Link>
                                                    </li>
                                                );
                                            }
                                        )}
                                </ul>
                            </li>
                        );
                    })}
            </ul>
        );
    };

    const renderNavWithNoCategories = (
        nav: MainNavLeft,
        index: number
    ): any => {
        return (
            <ul key={index}>
                <li className={chooseClassForNavMenuButton(nav.title)}>
                    <Link
                        href={nav.url}
                        target={nav.targetBlank ? '_blank' : '_self'}
                        className="eu-sidebar-menu-item"
                        aria-label={nav.ariaLabel}
                    >
                        <span className="eu-sidebar-menu-item-text">
                            {nav.title}
                        </span>
                    </Link>
                </li>
            </ul>
        );
    };

    const renderNavWithCategories = (nav: MainNavLeft, index: number): any => {
        return (
            <ul aria-label={nav.title} key={index}>
                <li className={chooseClassForNavMenuButton(nav.title)}>
                    <button
                        onClick={() => {
                            setActiveIndex(
                                `${
                                    activeIndex === index.toString()
                                        ? ''
                                        : index
                                }`
                            );
                        }}
                        className="eu-sidebar-menu-item"
                        aria-label={nav.ariaLabel}
                    >
                        <span className={`eu-sidebar-menu-item-text`}>
                            {nav.title}
                        </span>
                        <FdsChevron
                            type="unfilled"
                            direction={
                                activeIndex === index.toString() ? 'up' : 'down'
                            }
                        />
                    </button>
                    <div
                        id={`subNav${index}`}
                        className={`${
                            activeIndex === index.toString()
                                ? 'show-nav'
                                : 'hide-me'
                        }`}
                    >
                        {renderCategories(nav)}
                    </div>
                </li>
            </ul>
        );
    };

    useEffect(() => {
        props.isOpen
            ? document.body.classList.add('open-menu')
            : document.body.classList.remove('open-menu');
    }, [props.isOpen]);

    return (
        experienceHeaderContent && (
            <animated.div
                id="sidebar-menu"
                className={`eu-sidebar-container ${props.className} ${
                    activeSubnav ? 'grey-border' : ''
                }`}
                data-testid="sidebar-menu"
                style={animationProps}
            >
                <div
                    className={`eu-sidebar-menu-container  ${
                        activeSubnav ? 'eu-sidebar-minimized' : ''
                    }`}
                >
                    <nav tabIndex={props.isOpen ? 0 : -1}>
                        {experienceHeaderContent['main-nav-left']?.map(
                            (navsub, index) => {
                                if (noCategories(navsub)) {
                                    return renderNavWithNoCategories(
                                        navsub,
                                        index
                                    );
                                } else if (hasCategories(navsub)) {
                                    return renderNavWithCategories(
                                        navsub,
                                        index
                                    );
                                }
                            }
                        )}

                        <ul>
                            {experienceHeaderContent['main-nav-right']
                                .utilityLinks &&
                                experienceHeaderContent[
                                    'main-nav-right'
                                ]?.utilityLinks?.map((navright, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className="eu-sidebar-menu-item1"
                                        >
                                            <a aria-label={navright.title}>
                                                <Link
                                                    href={navright.url}
                                                    rel="noopener noreferrer"
                                                    aria-label={
                                                        navright.ariaLabel
                                                    }
                                                >
                                                    {navright.title}
                                                </Link>
                                            </a>
                                        </li>
                                    );
                                })}
                        </ul>
                    </nav>
                </div>
            </animated.div>
        )
    );
};
export default EuSidebarMenu;
