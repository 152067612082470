import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import ErrorPage from '@sections/profile-error/error-page';
import ChangeEmailComponent from '@sections/change-email/change-email';
import { useChangeEmailContent } from '@sections/change-email/hooks/use-change-email-content';
import seoService from '@services/seo-service/seo-service';
import ServerContext from '@contexts/serverContext';
import { useAppInformationContent } from '@sections/breadcrumbs/hook/use-breadcrumb-content';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

import { useCommunicationPreferencesContent } from '../communication-preferences-view/hooks/use-communication-preference-content';
import './change-email-view.scss';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';

const className = 'change-email-view';

const ChangeEmailView = () => {
    const appConfig = new AppConfigurationService();
    const { setNotificationContext } = useNotificationContext();
    const countryCode = appConfig.get3LetterCountryCode().toUpperCase();
    const location = useLocation();
    const queryStrings = new URLSearchParams(location.search);
    const cksId = queryStrings.get('cksid');
    const cks = queryStrings.get('cks');
    const consumerId = cksId !== null ? cksId : cks;
    const communicationPreferenceLabels = useCommunicationPreferencesContent();
    const changeEmailContent = useChangeEmailContent();
    const [hasLabels, setHasLabels] = useState(false);
    const serverContext = useContext(ServerContext);
    const appInformationContent = useAppInformationContent();

    seoService.set(
        {
            pageTitle: appInformationContent?.changeEmailPageTitle,
            applicationName: appInformationContent?.applicationName,
            pageDescription: appInformationContent?.changeEmailPageDescription,
        },
        serverContext
    );

    useEffect(() => {
        if (communicationPreferenceLabels) {
            setHasLabels(true);
        }
    }, [communicationPreferenceLabels]);

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
    }, []);

    return (
        <Fragment>
            {!hasLabels ? (
                <ActivityIndicator className={'full-height'} />
            ) : (
                <>
                    {consumerId ? (
                        <div
                            className={`${className}-container`}
                            data-test-id="change-email-view"
                        >
                            <ChangeEmailComponent
                                consumerId={consumerId}
                                countryCode={countryCode}
                                changeEmailContent={changeEmailContent}
                                communicationPreferenceLabels={
                                    communicationPreferenceLabels
                                }
                            />
                        </div>
                    ) : (
                        <ErrorPage
                            title={
                                communicationPreferenceLabels?.apiErrorMessageForECPTitle
                            }
                            description={
                                communicationPreferenceLabels?.apiErrorMessageForECPDescription
                            }
                        />
                    )}
                </>
            )}
        </Fragment>
    );
};

export default ChangeEmailView;
