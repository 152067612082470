import { VehicleYmm, VehicleYmmGroup } from '../../models/vehicle-ymm';
import { AxiosResponse } from 'axios';
import { HttpsProxyAgent } from 'https-proxy-agent';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';

export const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

type Brand = 'F' | 'M' | 'L';

export interface GetVehicleIndividualResponse {
    id: number;
    year: number;
    model: string;
    marketName: string;
    vehicleLine: string;
    bodyCodes: string;
    brand: Brand;
}

export interface GetVehiclesResponse {
    getAdoptedVehiclesForChannelResult: {
        supportedVehicle: GetVehicleIndividualResponse[];
    };
}
export default class VehicleListBaseService {
    protected groupByYear(vehicles: VehicleYmm[]): VehicleYmmGroup[] {
        const final: VehicleYmmGroup[] = [];

        vehicles.forEach((vehicle) => {
            let foundVehicle = final.find(
                (element) => element.year === vehicle.year
            );
            if (!foundVehicle) {
                foundVehicle = {
                    year: vehicle.year,
                    vehicles: [],
                };
                final.push(foundVehicle);
            }
            foundVehicle.vehicles.push(vehicle);
        });

        return final;
    }

    protected transformBrandToMake(brand: Brand): string {
        switch (brand) {
            case 'F':
                return 'Ford';
            case 'L':
                return 'Lincoln';
            case 'M':
                return 'Mercury';
        }
    }

    protected convertResponseToVehicleYmmList = (
        response: AxiosResponse<GetVehiclesResponse>
    ): VehicleYmm[] => {
        const { data } = response;
        return data.getAdoptedVehiclesForChannelResult.supportedVehicle.map(
            (individual) => {
                const marketName =
                    individual.marketName === 'Ford GT'
                        ? 'GT'
                        : individual.marketName;
                return {
                    year: individual.year,
                    model: individual.model,
                    marketName,
                    make: this.transformBrandToMake(individual.brand),
                };
            }
        );
    };

    protected filterToBrandVehicles(
        vehicles: VehicleYmm[],
        brandsToMatch?: string[]
    ): VehicleYmm[] {
        const { brand } = new AppConfigurationService();
        return vehicles.filter((vehicle) =>
            brandsToMatch
                ? brandsToMatch?.includes(vehicle.make.toLowerCase())
                : vehicle.make.toLowerCase() === brand.toLowerCase()
        );
    }

    protected filterToBefore2021(vehicleGroups: VehicleYmmGroup[]) {
        return vehicleGroups.filter((vehicleGroup) => vehicleGroup.year < 2021);
    }
}
