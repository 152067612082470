import React, { Dispatch, SetStateAction } from 'react';
import Link from '@common/link/link';
import { InputField } from '@common/form-fields/form-fields';
import { CountrySelectorOption } from '@models/connected-vehicle';
import { useAnalytics } from '@hooks/use-analytics';

export interface CountrySelectorContentProps {
    filteredCountries: CountrySelectorOption[];
    searchValue: string;
    setSearchValue: Dispatch<SetStateAction<string>>;
}

const CountrySelectorContent = (props: CountrySelectorContentProps) => {
    const { filteredCountries, searchValue, setSearchValue } = props;
    const [fireAnalytics] = useAnalytics();

    return (
        <div
            className="country-selector-content"
            data-testid="country-selector-content"
        >
            <h1
                className="country-selector-content__title"
                data-testid="country-selector-title"
            >
                Ford Account
            </h1>
            <hr />
            <h2
                className="country-selector-content__subtitle"
                data-testid="country-selector-subtitle"
            >
                Select your country to access your Ford Account.
            </h2>
            <div className="country-selector-content__input-container">
                <InputField
                    id="countrySelectorSearchBar"
                    dataTestId="country-selector-search-bar"
                    value={searchValue}
                    name="countrySelectorSearchBar"
                    label="Search country"
                    handleChange={(e) => setSearchValue(e.target.value)}
                    showLabel={false}
                    placeholder="Search country"
                />
            </div>
            <div
                className="country-selector-content__grid-container"
                data-testid="country-grid"
            >
                {filteredCountries.map((country) => {
                    return (
                        <div
                            className="individual-country"
                            data-testid={`individual-country-${country.languageRegionCode}`}
                            key={country.displayName}
                        >
                            <div className="flag-icon-background">
                                <Link
                                    href={country.url}
                                    aria-label={country.displayName}
                                    onClick={() =>
                                        fireAnalytics(
                                            'countrySelectionOnclickEvent',
                                            '',
                                            {
                                                languageRegionCode:
                                                    country.languageRegionCode,
                                            }
                                        )
                                    }
                                >
                                    <img
                                        alt=""
                                        src={
                                            process.env.REACT_APP_AEM_BASE_URL +
                                            '/content/dam/global-account/flag-icons/' +
                                            country.imageUrl
                                        }
                                    />
                                </Link>
                            </div>

                            <Link
                                href={country.url}
                                aria-label={country.displayName}
                                onClick={() =>
                                    fireAnalytics(
                                        'countrySelectionOnclickEvent',
                                        '',
                                        {
                                            languageRegionCode:
                                                country.languageRegionCode,
                                        }
                                    )
                                }
                            >
                                {country.displayName}
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CountrySelectorContent;
