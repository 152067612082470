import { useContent } from '@hooks/use-server-content';

export interface CongratsModalContent {
    hide: boolean;
    headerText: string;
    bodyText: string;
    primaryButtonLabel: string;
    primaryButtonAriaLabel: string;
    closeButtonAriaLabel: string;
}

export const useCongratsModalContent = (): CongratsModalContent | null => {
    const [content, getValueByName] = useContent('common', 'congrats-modal');
    let contentFragment: any;

    if (content) {
        contentFragment = {};
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
        if (contentFragment) {
            if (contentFragment.hide) {
                contentFragment = null;
            }
        }
    }
    return contentFragment as CongratsModalContent;
};
