import { ExperienceFragmentModel } from '@services/content-service/content-service.interface';

export interface SmartTilesFragment extends ExperienceFragmentModel {
    informationGridTitle?: string;
    informationGridSubCopy?: string;
    heroRowTilesTitle?: string;
    heroRowTilesSubCopy?: string;
    smartTiles?: SmartTileContent[];
}

export interface SmartTileContent {
    type?: string;
    vehicleType: string;
    staticTitle: string;
    dynamicTitle: string;
    iconPath: string;
    iconAriaLabel: string;
    analyticEventName?: string;
    staticSubCopy: string;
    dynamicSubCopy: string;
    staticCTATitle: string;
    dynamicCTATitle: string;
    staticCTAPath: string;
    dynamicCTAPath: string;
    ctaAriaLabel: string;
    ctaTargetBlank: boolean;
    showOnlyInAuth?: boolean;
    recallModalCtaSecondaryAriaLabel?: string;
    recallModalCtaSecondaryDynamicPath?: string;
    recallModalCtaSecondaryText?: string;
    recallModalTitle?: string;
    noRecallModalTitle?: string;
    recallModalCtaPrimaryAriaLabel?: string;
    recallModalCtaPrimaryDynamicPath?: string;
    recallModalCtaPrimaryText?: string;
    preDeliveryRank: string;
    postDeliveryRank: string;
    toolTipMsg?: string;
    expirationDateText?: string;
}

const filterAuthOnlyBasedTiles = (tile: SmartTileContent, isAuth: boolean) => {
    if (isAuth) return true;
    else return !tile.showOnlyInAuth;
};

const sortBasedOnVehicleTypeRanking = (
    ownerState: number,
    tileB: SmartTileContent,
    tileA: SmartTileContent
) => {
    const tileARank =
        (ownerState == 1 ? tileA.postDeliveryRank : tileA.preDeliveryRank) ||
        '0';
    const tileBRank =
        (ownerState == 1 ? tileB.postDeliveryRank : tileB.preDeliveryRank) ||
        '0';
    return Number.parseInt(tileARank) - Number.parseInt(tileBRank);
};

export const transformSmartTileContent = (
    experienceContent: object | any,
    ownerState: number,
    isAuth: boolean,
    startIndex: number,
    endIndex?: number
) => {
    let smartTilesArray: Array<any>;
    if (experienceContent && experienceContent.smartTiles) {
        smartTilesArray = experienceContent.smartTiles
            .filter((tile) => {
                return filterAuthOnlyBasedTiles(tile, isAuth);
            })
            .sort((tileA, tileB) => {
                return sortBasedOnVehicleTypeRanking(ownerState, tileB, tileA);
            })
            .map((tile) => tile);
        if (!endIndex) {
            endIndex = smartTilesArray.length;
        }
        smartTilesArray = smartTilesArray.slice(startIndex, endIndex);
    } else {
        smartTilesArray = [];
    }
    return smartTilesArray;
};
