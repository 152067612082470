import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { formatPhoneNumberByCountry } from '@utils/values-by-country/value-by-country';
import {
    DynamicDropdown,
    DynamicDropdownContent,
    DynamicDropdownOptions,
} from '@common/dynamic-dropdown/dynamic-dropdown';
import { InputField } from '@common/form-fields/form-fields';
import { Props } from './phone-number';

export interface PhoneNumberEuProps extends Props {
    dynamicDropdownContent: DynamicDropdownContent;
    selectedMobileCountry: DynamicDropdownOptions;
    setSelectedMobileCountry: Dispatch<SetStateAction<DynamicDropdownOptions>>;
    selectedPrimaryCountry: DynamicDropdownOptions;
    setSelectedPrimaryCountry: Dispatch<SetStateAction<DynamicDropdownOptions>>;
}

const PhoneNumberEu = (props: PhoneNumberEuProps): ReactElement => {
    const {
        userData,
        focus,
        setFocusToField,
        accountSettingsFormsLabels,
        inputValidations,
        error,
        handleChange,
        selectedMobileCountry,
        selectedPrimaryCountry,
        setSelectedPrimaryCountry,
        setSelectedMobileCountry,
        countryCode,
        dynamicDropdownContent,
    } = props;

    return (
        <div
            data-testid="eu-phone-number-container"
            className="input-section-container phone-number"
        >
            {/* Mobile Phone Number */}
            <div className="phone-number-field-group">
                {dynamicDropdownContent &&
                    accountSettingsFormsLabels.mobilePhoneField && (
                        <DynamicDropdown
                            dynamicDropdownContent={dynamicDropdownContent}
                            setFocusToField={setFocusToField}
                            focus={focus}
                            error={error}
                            field="mobilePhoneNumber"
                            accountSettingsFormsLabels={
                                accountSettingsFormsLabels
                            }
                            selectedCountry={selectedMobileCountry}
                            setSelectedCountry={setSelectedMobileCountry}
                        />
                    )}
                {accountSettingsFormsLabels.mobilePhoneField && (
                    <InputField
                        id="mobilePhoneNumber"
                        label={accountSettingsFormsLabels.mobilePhoneField}
                        showLabel={focus.mobile}
                        handleChange={handleChange}
                        name="mobilePhoneNumber"
                        value={formatPhoneNumberByCountry(
                            countryCode,
                            userData.mobilePhoneNumber,
                            selectedMobileCountry?.phoneNumberCountryCode
                        )}
                        className="two-fields"
                        dataTestId="mobilePhoneNumber"
                        validationRules={
                            inputValidations.mobilePhoneNumber.validationRules
                        }
                        validationPattern={
                            inputValidations.mobilePhoneNumber.validationPattern
                        }
                        error={error.mobilePhoneNumber}
                        onFocus={() => setFocusToField('mobile', true)}
                        onBlur={() =>
                            userData.mobilePhoneNumber.length === 0 &&
                            setFocusToField('mobile', false)
                        }
                        placeholder={
                            accountSettingsFormsLabels.mobilePhoneField
                        }
                    />
                )}
                {/* Confirm Mobile Phone Number - only visible when there is an authored value */}
                {accountSettingsFormsLabels.confirmMobilePhoneField ? (
                    <InputField
                        id="confirmMobilePhoneNumber"
                        label={
                            accountSettingsFormsLabels.confirmMobilePhoneField
                        }
                        showLabel={focus.confirmMobile}
                        handleChange={handleChange}
                        name="confirmMobilePhoneNumber"
                        value={formatPhoneNumberByCountry(
                            countryCode,
                            userData.confirmMobilePhoneNumber
                        )}
                        className="two-fields"
                        dataTestId="confirmMobilePhoneNumber"
                        error={error.confirmMobilePhoneNumber}
                        onFocus={() => setFocusToField('confirmMobile', true)}
                        onBlur={() =>
                            userData.confirmMobilePhoneNumber.length === 0 &&
                            setFocusToField('confirmMobile', false)
                        }
                        placeholder={
                            accountSettingsFormsLabels.confirmMobilePhoneField
                        }
                    />
                ) : null}
            </div>

            <div className={`phone-number-field-group`}>
                {dynamicDropdownContent &&
                    accountSettingsFormsLabels.primaryPhoneField && (
                        <DynamicDropdown
                            dynamicDropdownContent={dynamicDropdownContent}
                            setFocusToField={setFocusToField}
                            focus={focus}
                            error={error}
                            field="phoneNumber"
                            accountSettingsFormsLabels={
                                accountSettingsFormsLabels
                            }
                            selectedCountry={selectedPrimaryCountry}
                            setSelectedCountry={setSelectedPrimaryCountry}
                        />
                    )}
                {/* Primary Phone Number - hidden in regions without an authored value */}
                {accountSettingsFormsLabels.primaryPhoneField ? (
                    <InputField
                        id="phoneNumber"
                        dataTestId="phoneNumber"
                        label={accountSettingsFormsLabels.primaryPhoneField}
                        showLabel={focus.phone}
                        handleChange={handleChange}
                        name="phoneNumber"
                        value={formatPhoneNumberByCountry(
                            countryCode,
                            userData.phoneNumber,
                            selectedPrimaryCountry?.phoneNumberCountryCode
                        )}
                        className="two-fields"
                        validationRules={
                            inputValidations.phoneNumber.validationRules
                        }
                        validationPattern={
                            inputValidations.phoneNumber.validationPattern
                        }
                        error={error.phoneNumber}
                        onFocus={() => setFocusToField('phone', true)}
                        onBlur={() =>
                            userData.phoneNumber.length === 0 &&
                            setFocusToField('phone', false)
                        }
                        placeholder={
                            accountSettingsFormsLabels.primaryPhoneField
                        }
                    />
                ) : null}
            </div>
        </div>
    );
};

export default PhoneNumberEu;
