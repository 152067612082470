import { useEffect, useState } from 'react';
import { ProfileWithVehiclesResponse } from '@models/profile-with-vehicles';
import ProfileService from '@services/profile-service/profile-service';
import AuthenticationService from '@services/authentication-service/authentication-service';

export const useProfileData = (): ProfileWithVehiclesResponse | null => {
    const [profileData, setProfileData] =
        useState<ProfileWithVehiclesResponse | null>(null);

    const getProfileData = () => {
        new AuthenticationService()
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    new ProfileService()
                        .request()
                        .then((profile) => {
                            if (profile) {
                                profile.getProfileData = getProfileData;
                                setProfileData(profile);
                            } else {
                                setProfileData(null);
                            }
                        })
                        .catch((err) => {
                            setProfileData(err);
                        });
                } else {
                    setProfileData(null);
                    new AuthenticationService().login();
                }
            });
    };

    useEffect(() => {
        getProfileData();
    }, []);
    return profileData;
};

export const useProfileDataLite = (
    cacheable = false
): ProfileWithVehiclesResponse | null => {
    const [profileDataLite, setProfileDataLite] =
        useState<ProfileWithVehiclesResponse | null>(null);

    const getProfileDataLite = () => {
        new AuthenticationService()
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    new ProfileService()
                        .requestLite(cacheable)
                        .then((profile) => {
                            if (profile) {
                                profile.getProfileData = getProfileDataLite;
                                setProfileDataLite(profile);
                            } else {
                                setProfileDataLite(null);
                            }
                        })
                        .catch((err) => {
                            setProfileDataLite(err);
                        });
                } else {
                    setProfileDataLite(null);
                    new AuthenticationService().login();
                }
            });
    };

    useEffect(() => {
        getProfileDataLite();
    }, []);
    return profileDataLite;
};

export const useProfileDataForWebview =
    (): ProfileWithVehiclesResponse | null => {
        const [profileData, setProfileData] =
            useState<ProfileWithVehiclesResponse | null>(null);

        const getProfileData = () => {
            new ProfileService()
                .request()
                .then((profile) => {
                    if (profile) {
                        profile.getProfileData = getProfileData;
                        setProfileData(profile);
                    } else {
                        setProfileData(null);
                    }
                })
                .catch((err) => {
                    setProfileData(err);
                });
        };

        useEffect(() => {
            getProfileData();
        }, []);
        return profileData;
    };
