export const UPDATE_CHAT_HISTORY = 'updateChatHistory';
export const FILTER_CHAT_HISTORY = 'filterChatHistory';
export const REPLACE_CHAT_HISTORY = 'replaceChatHistory';
export const UPDATE_INPUT = 'updateInput';
export const UPDATE_LAST_MESSAGE = 'updateLastMessage';
export const TOGGLE_CHAT_VISIBILITY = 'toggleChatVisibility';
export const TOGGLE_CONVERSATION_STARTED = 'toggleConversationStarted';
export const SET_CONVERSATION_STARTED = 'setConversationStarted';
export const UPDATE_TYPING_VISIBLE = 'updateTypingVisible';
export const UPDATE_ERROR_REASON = 'updateErrorReason';
export const UPDATE_ERROR_LABEL = 'updateErrorLabel';
export const UPDATE_SNACKBAR_STATE = 'updateSnackbarState';
export const UPDATE_SNACKBAR_TYPE = 'updateSnackbarType';
export const UPDATE_QUICK_REPLIES_LAST = 'updateQuickRepliesLast';
