export interface CvotFieldError {
    status: boolean;
    message: string;
}
export interface CvotErrors {
    [key: string]: CvotFieldError;
}

export const cvotErrors: CvotErrors = {
    customerOrderNumber: {
        status: false,
        message: '',
    },
    vin: {
        status: false,
        message: '',
    },
};
