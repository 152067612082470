import React, { useState } from 'react';
import parseHTML, {
    attributesToProps,
    domToReact,
    HTMLReactParserOptions,
} from 'html-react-parser';

interface Props {
    content: any;
    fullContent: string;
    showMoreLabel: string;
    showLessLabel: string;
    showMoreAriaLabel: string;
    showLessAriaLabel: string;
    chatbotSentAccessibilityMessage: string;
    submitMessage: (message: any) => void;
}
const ChatbotCollapsibleMessage = (props: Props) => {
    const [collapsed, setCollapsed] = useState(true);
    const {
        content,
        fullContent,
        showMoreAriaLabel,
        showLessAriaLabel,
        chatbotSentAccessibilityMessage,
        showMoreLabel,
        showLessLabel,
        submitMessage,
    } = props;

    const options: HTMLReactParserOptions = {
        replace: ({
            name,
            children,
            attribs,
        }: any): JSX.Element | object | void | undefined | null | false => {
            if (['responsetemplate', 'agent-utterance'].includes(name)) {
                return <>{domToReact(children, options)}</>;
            }

            if (attribs && attribs.style) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { style, ...props } = attributesToProps(attribs);
                return React.createElement(
                    name,
                    props,
                    domToReact(children, options)
                );
            }

            if (attribs && attribs['data-intent']) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { style, href, ...props } = attributesToProps(attribs);

                const clickHandler = (e: any) => {
                    submitMessage(e.target.textContent);
                };

                return React.createElement(
                    'button',
                    { ...props, onClick: clickHandler },
                    domToReact(children, options)
                );
            }
        },
    };

    return (
        <div className="chatbot-bot">
            <span className="visually-hidden">
                {chatbotSentAccessibilityMessage}
            </span>
            <div className="chatbot-bot-message">
                {collapsed
                    ? parseHTML(content, options)
                    : parseHTML(fullContent, options)}
                <button
                    className="chatbot__button chatbot__button--show-more"
                    aria-label={
                        collapsed ? showMoreAriaLabel : showLessAriaLabel
                    }
                    onClick={() => setCollapsed(!collapsed)}
                >
                    {collapsed ? showMoreLabel : showLessLabel}
                </button>
            </div>
        </div>
    );
};

export default ChatbotCollapsibleMessage;
