import React, { useEffect } from 'react';
import './create-return-view.scss';
import { Breadcrumbs } from '@/components/sections/breadcrumbs/breadcrumbs';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';
import { SearchBar } from '@/components/sections';
import CreateReturnComponent from './components/create-return-component';
import ScrollUtil from '@/components/utils/scroll-to-top-util/scroll-to-top-util';
import { ActivityIndicator } from '@/components/common/activity-indicator/activity-indicator';
import { useLocation } from 'react-router-dom';
import { findPathByAlias } from '@/routes/routesList';
import { useOrderDetailsContent } from '../order-details-view/hooks/use-order-details-content';
import { useAnalytics } from '@/hooks/use-analytics';

const CreateReturnView = () => {
    useAnalytics('startReturn');
    const appConfig = new AppConfigurationService();
    const location = useLocation();
    const orderDetailsContent = useOrderDetailsContent();

    useEffect(() => {
        new ScrollUtil().scrollPageToTop();
    }, []);

    return (
        <>
            {orderDetailsContent ? (
                <div
                    className="create-return-container"
                    data-test-id="create-return-view"
                >
                    {location.state?.guestReturn && (
                        <Breadcrumbs
                            currentPage={
                                orderDetailsContent.createReturnBreadcrumbTitle
                            }
                            previousPage={
                                orderDetailsContent.guestReturnBreadcrumbTitle
                            }
                            previousLink={findPathByAlias('GuestReturnView')}
                            hideBaseBreadcrumb={true}
                            crumbSeparator={
                                appConfig.brand === 'ford' ? '/' : '>'
                            }
                        ></Breadcrumbs>
                    )}
                    {location.state?.guestOrder && (
                        <Breadcrumbs
                            currentPage={
                                orderDetailsContent.createReturnBreadcrumbTitle
                            }
                            previousPage={
                                orderDetailsContent.guestDetailsBreadcrumbTitle
                            }
                            previousLink={findPathByAlias('GuestOrderView')}
                            hideBaseBreadcrumb={true}
                            crumbSeparator={
                                appConfig.brand === 'ford' ? '/' : '>'
                            }
                        ></Breadcrumbs>
                    )}
                    {!location.state?.guestOrder &&
                        !location.state?.guestReturn && (
                            <Breadcrumbs
                                currentPage={
                                    orderDetailsContent.createReturnBreadcrumbTitle
                                }
                                previousPage={
                                    orderDetailsContent.createReturnBreadcrumbPreviousTitle
                                }
                                secondPreviousPage={
                                    orderDetailsContent.createReturnBreadcrumbSecondPreviousTitle
                                }
                                previousLink={
                                    location.state?.orderDetails
                                        ? findPathByAlias(
                                              'OrderDetailsViewWithoutOrderNumber',
                                              location.state?.orderDetails
                                                  .orderNo
                                          )
                                        : findPathByAlias('OrderHistoryView')
                                }
                                secondPreviousLink={findPathByAlias(
                                    'OrderHistoryView'
                                )}
                                crumbSeparator={
                                    appConfig.brand === 'ford' ? '/' : '>'
                                }
                            ></Breadcrumbs>
                        )}
                    <CreateReturnComponent />
                    <hr className="hr-line" aria-hidden={true} />
                    <SearchBar />
                </div>
            ) : (
                <ActivityIndicator className={'full-height'} />
            )}
        </>
    );
};

export default CreateReturnView;
