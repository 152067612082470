import HttpService from '@services/http-service/http-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import AuthenticationService from '@services/authentication-service/authentication-service';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

export interface MFAOptions {
    type: string;
    value: string;
}

export interface MFAResponse {
    userRequiresMFA: boolean;
    mfaOptions: MFAOptions[];
    error?: any;
}

interface MFAUserLevelRequest {
    userRequiresMFA: string;
}

export default class MFAService {
    private httpService: any;
    public url: string;

    constructor(
        httpService: HttpService,
        appConfigurationService?: AppConfigurationService
    ) {
        this.httpService = httpService;
        const dslUrlFromConfig =
            appConfigurationService?.getAppConfiguration()?.dslUrl;
        this.url = dslUrlFromConfig
            ? dslUrlFromConfig + DSL_API_PATH.MFA_OPTIONS
            : DSL_BASE_URL + DSL_API_PATH.MFA_OPTIONS;
    }

    private redirectToFma() {
        const authenticationService: AuthenticationService =
            new AuthenticationService();
        console.error('User Timeout');
        authenticationService.login();
    }

    public async getMFAOptions(): Promise<MFAResponse> {
        try {
            const response = await this.httpService.get(this.url, false, {
                headers:
                    this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
            });

            return response.data as MFAResponse;
        } catch (e) {
            if (e.errorCode === '401') {
                this.redirectToFma();
            }
        }
    }

    public async deleteMFAOption(optionType: string): Promise<any> {
        const url: string = this.url + `?optionType=${optionType}`;

        try {
            return await this.httpService.delete(url, {
                headers:
                    this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
            });
        } catch (e) {
            if (e.errorCode === '401') {
                this.redirectToFma();
            }
        }
    }

    public async updateUserLevelMfaFlag(
        userLevelMfaFlag: boolean
    ): Promise<any> {
        const url: string = this.url + '/user-level';

        const body: MFAUserLevelRequest = {
            userRequiresMFA: userLevelMfaFlag ? 'true' : 'false',
        };

        try {
            return await this.httpService.put(url, body, {
                headers:
                    this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
            });
        } catch (e) {
            if (e.errorCode === '401') {
                this.redirectToFma();
            }
        }
    }
}
