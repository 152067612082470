import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../email-channel-preferences-view/email-channel-preferences-view.scss';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import UserEmailChannelPreferenceService from '@services/email-channel-preference-service/email-channel-preference-service';
import seoService from '@services/seo-service/seo-service';
import ServerContext from '@contexts/serverContext';
import { useAppInformationContent } from '@sections/breadcrumbs/hook/use-breadcrumb-content';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import HttpService from '@services/http-service/http-service';
import { NotificationType } from '@contexts/notificationContext';
import { SearchBar } from '@/components/sections';
import { Notification } from '@sections/account-portal/components/notification-message/notification';
import {
    CommunicationCenterContent,
    PermissionOption,
} from '@/models/communication-center';
import { useExperienceContent } from '@/hooks/use-server-content';
import EmailPermission from './email-permission/email-permission';

const EmailChannelPreferenceView = () => {
    const appConfig = new AppConfigurationService();
    const { brand } = new AppConfigurationService();
    const isLincoln = brand === 'lincoln';
    const countryCode = appConfig.get3LetterCountryCode().toUpperCase();
    const serverContext = useContext(ServerContext);
    const appInformationContent = useAppInformationContent();
    const location = useLocation();
    const queryStrings = new URLSearchParams(location.search);
    const cksid = queryStrings.get('cksid');
    const cks = queryStrings.get('cks');
    const consumerId = cksid !== null ? cksid : cks;
    const service = new UserEmailChannelPreferenceService(HttpService);
    const [userPreferences, setUserPreferences] = useState<any>();

    const [communicationCenterContent] =
        useExperienceContent<CommunicationCenterContent>(
            'common',
            'categories',
            'categories'
        );
    const [currentPreferences, setCurrentPreferences] = useState<any>();
    const hasPermissionOptions: boolean =
        communicationCenterContent?.permissionOptions?.length > 0;
    const [notificationStatus, setNotificationStatus] =
        useState<NotificationType>(null);
    const [loading, setIsLoading] = useState(true);

    seoService.set(
        {
            pageTitle: appInformationContent?.communicationPreferencesPageTitle,
            applicationName: appInformationContent?.applicationName,
            pageDescription:
                appInformationContent?.communicationPreferencesPageDescription,
        },
        serverContext
    );

    const getEmailPreferences = (data) =>
        data?.marketingConsumer?.privacyPreference?.filter(
            (preference) => preference?.permPrefType === 'EmailPermission'
        );

    useEffect(() => {
        async function getECP() {
            try {
                const response = (await service.getEmailChannelPreferences(
                    consumerId,
                    countryCode
                )) as any;
                if (response && Object.keys(response).length > 0) {
                    const getCurrentPreferences = getEmailPreferences(response);
                    setUserPreferences(response);
                    if (getCurrentPreferences[0]?.indicator === 'Y') {
                        setCurrentPreferences(true);
                    } else {
                        setCurrentPreferences(false);
                    }
                }
            } catch (err) {
                setUserPreferences(err);
            }
        }
        getECP();
        setIsLoading(false);
    }, [currentPreferences]);

    return (
        <>
            <div
                className="email-channel-preferences-view__container limit-1440"
                data-testid="email-channel-preferences-view"
            >
                {!loading ? (
                    <div className="email-channel-preferences-view__content">
                        {notificationStatus && (
                            <div
                                className="email-channel-preferences-view__notification-container"
                                data-testid="email-channel-preferences-view__notification-container"
                            >
                                <Notification
                                    status={notificationStatus}
                                    mainCopy={
                                        notificationStatus ===
                                        NotificationType.Success
                                            ? communicationCenterContent.notificationBannerSuccess
                                            : communicationCenterContent.notificationBannerError
                                    }
                                    hideBorder={true}
                                    hideAfterTimeout={true}
                                    onHideNotification={() => {
                                        setNotificationStatus(null);
                                    }}
                                />
                            </div>
                        )}
                        <h1
                            className="email-channel-preferences-view__header"
                            data-testid="email-channel-preferences-view-header-text"
                        >
                            {communicationCenterContent?.headerText}
                        </h1>
                        <p
                            className="email-channel-preferences-view__description"
                            dangerouslySetInnerHTML={{
                                __html: communicationCenterContent?.descriptionUnauth,
                            }}
                            data-testid="email-channel-preferences-view-description-unauth-text"
                        />

                        {hasPermissionOptions &&
                            communicationCenterContent.permissionOptions.map(
                                (permissionOption: PermissionOption) => {
                                    return (
                                        <div key={permissionOption.apiVariable}>
                                            <EmailPermission
                                                communicationCenterContent={
                                                    communicationCenterContent
                                                }
                                                permissionOption={
                                                    permissionOption
                                                }
                                                emailPermissionOptedIn={
                                                    currentPreferences
                                                }
                                                setNotificationStatus={
                                                    setNotificationStatus
                                                }
                                                isLincoln={isLincoln}
                                            />
                                        </div>
                                    );
                                }
                            )}
                    </div>
                ) : (
                    <ActivityIndicator className="full-height" />
                )}

                {!isLincoln && (
                    <>
                        <hr className="hr-line" />
                        <SearchBar />
                    </>
                )}
            </div>
            {isLincoln && <SearchBar />}
        </>
    );
};
export default EmailChannelPreferenceView;
