interface Message {
    nonEmpty: string | null;
    pattern: string | null;
    dontMatch?: string | null;
    charLimit?: string | null;
}
const Validator = {
    validateNonEmpty: (
        fieldValue: string | number | boolean,
        label: string,
        pattern: string,
        message: Message
    ) => {
        const errStatus = !(fieldValue === '' || fieldValue == null);
        return {
            status: !errStatus,
            message: !errStatus ? `${message.nonEmpty} ${label}` : '',
        };
    },

    validateMatchesPattern: (
        fieldValue: string,
        label: string,
        pattern: string,
        message: Message,
        showLabelWithErrorMessage: boolean
    ) => {
        if (fieldValue) {
            const errStatus = new RegExp(pattern).test(fieldValue);
            const errMessage = showLabelWithErrorMessage
                ? `${message.pattern} ${label}`
                : message.pattern;
            return {
                status: !errStatus,
                message: !errStatus ? errMessage : '',
            };
        } else {
            return { status: false, message: '' };
        }
    },

    validateMatchesCharLimit: (
        fieldValue: string,
        label: string,
        pattern: string,
        message: Message,
        showLabelWithErrorMessage: boolean,
        limit: any
    ) => {
        const defaultCharLimitMessage = 'Maximum characters exceeded';
        const errStatus = limit >= fieldValue.length;
        let errorMessage = message.charLimit
            ? message.charLimit
            : defaultCharLimitMessage;
        errorMessage = `${label} ${errorMessage} ${limit}`;
        if (fieldValue && limit && !isNaN(Number(limit))) {
            return {
                status: !errStatus,
                message: !errStatus ? errorMessage : '',
            };
        } else {
            return { status: false, message: '' };
        }
    },

    checkValidations(
        rules: any[],
        fieldValue: string | number | boolean,
        label: string,
        pattern: string,
        message: Message,
        showLabelWithErrorMessage = true,
        charLimit?: number
    ) {
        let validateInput = {};
        const validationRules = [...rules];
        validationRules.length > 0 &&
            validationRules.forEach((rule, index, rulesArr) => {
                const validate = this[rule.toString()](
                    fieldValue,
                    label,
                    pattern,
                    message,
                    showLabelWithErrorMessage,
                    charLimit
                );
                if (validate.status) {
                    rulesArr.length = index;
                }
                validateInput = validate;
            });
        return validateInput;
    },
};

export const removeATags = (html: string): string => {
    return html.replace(/<a[^>]*>(.*?)<\/a>/gs, '$1');
};

export default Validator;
