import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '@services/http-service/http-service';
import { RecallsServiceResponse } from '@models/vehicle-health';

export default class RecallsService {
    private httpService: any;
    private appConfig = new AppConfigurationService();
    private dslUrl = this.appConfig.getAppConfiguration().dslUrl
        ? this.appConfig.getAppConfiguration().dslUrl
        : DSL_BASE_URL;
    private url: string = this.dslUrl + DSL_API_PATH.RECALLS;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getRecallsAlerts(
        vin: string
    ): Promise<RecallsServiceResponse> {
        const appConfig = new AppConfigurationService();

        const config = {
            headers: {
                vin,
            },
        };

        this.url = `${this.dslUrl + DSL_API_PATH.RECALLS}?country=${
            appConfig.current3LetterCountryCode
        }&langscript=${appConfig.currentLangScript}&language=${
            appConfig.currentLanguageCode
        }&region=${appConfig.currentLanguageRegionCode}`;

        let response;

        try {
            response = await this.httpService.get(this.url, true, config);
        } catch (e) {
            return e.message;
        }
        return response?.data;
    }
}
