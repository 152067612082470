import {
    AppConfiguration,
    appConfigurationDev,
    appConfigurationLocal,
    appConfigurationPerf,
    appConfigurationProd,
    appConfigurationQa,
    AppEnvironmentConfiguration,
    fallbackAppConfiguration,
} from '../../app-configuration/app-configuration';
import ServerSideService from '../server-side-service/server-side-service';
import { CacheService, STORE_TYPE } from '../cache-service/cache-service';
import { BRAND, EU_COUNTRIES, NON_GA_COUNTRIES } from '@constants';

export class AppConfigurationService {
    private win: Window | undefined;
    public appConfigurations: AppConfiguration[];
    private currentAppConfiguration: AppConfiguration;
    public currentLanguageCode: string;
    public currentCountryName: string;
    public currentCountryCode: string;
    public current3LetterCountryCode: string;
    public current3LetterCountryCapitalizedCode: string;
    public currentLangScript: string;
    public currentRegionCode: string;
    public currentLanguageRegionCode: string;
    public currentLanguageRegionCapitalizedCode: string;
    public currentThreeLetterLangCode: string;
    public currentLanguage: string;
    public currentDomain: string;
    public currentRoot: string;
    public brand: string;
    public languageSetId: number;
    public enableDebugLogs?: boolean;
    public subscriptionEnv: string;
    public currentBrandAndLocale?: string;

    public constructor() {
        let languageOverride;
        if (ServerSideService.isClientSide()) {
            this.win = window;

            (this.win as any).languageOverride = (
                languageName: string,
                languageRegionCode: string,
                threeLetterLangCode: string,
                langScript: string,
                countryCode: string,
                threeLetterCountryCode: string
            ) => {
                new CacheService(STORE_TYPE.LOCAL).putInCache('overrides', {
                    key: 'languageOverride',
                    data: {
                        languageName: languageName,
                        languageRegionCode: languageRegionCode,
                        threeLetterLangCode: threeLetterLangCode,
                        langScript: langScript,
                        countryCode: countryCode,
                        threeLetterCountryCode: threeLetterCountryCode,
                    },
                });
            };

            languageOverride = new CacheService(STORE_TYPE.LOCAL).getFromCache(
                'overrides',
                'languageOverride'
            );
        }

        this.appConfigurations = [];
        this.appConfigurations = this.appConfigurations
            .concat(
                this.flattenEnvironmentConfiguration(
                    appConfigurationProd,
                    'prod',
                    ''
                )
            )
            .concat(
                this.flattenEnvironmentConfiguration(
                    appConfigurationPerf,
                    'perf',
                    'STG'
                )
            )
            .concat(
                this.flattenEnvironmentConfiguration(
                    appConfigurationQa,
                    'qa',
                    'STG'
                )
            )
            .concat(
                this.flattenEnvironmentConfiguration(
                    appConfigurationDev,
                    'dev',
                    'STG'
                )
            )
            .concat(
                this.flattenEnvironmentConfiguration(
                    appConfigurationLocal,
                    'local',
                    'STG'
                )
            );

        this.currentAppConfiguration = this.getAppConfiguration();

        this.currentDomain = this.currentAppConfiguration.domain;
        this.currentRoot = this.currentAppConfiguration.root;
        this.currentCountryCode = languageOverride
            ? languageOverride.countryCode
            : this.currentAppConfiguration.countryCode;
        this.current3LetterCountryCode = languageOverride
            ? languageOverride.threeLetterCountryCode
            : this.currentAppConfiguration.threeLetterCountryCode;
        this.current3LetterCountryCapitalizedCode = languageOverride
            ? languageOverride.threeLetterCountryCode.toUpperCase()
            : this.currentAppConfiguration.threeLetterCountryCode.toUpperCase();
        this.currentLanguage = languageOverride
            ? languageOverride.languageName
            : this.currentAppConfiguration.languageName;
        this.currentLangScript = languageOverride
            ? languageOverride.langScript
            : this.currentAppConfiguration.langScript;
        this.currentLanguageRegionCode = languageOverride
            ? languageOverride.languageRegionCode
            : this.currentAppConfiguration.languageRegionCode;
        this.currentThreeLetterLangCode = languageOverride
            ? languageOverride.threeLetterLangCode
            : this.currentAppConfiguration.threeLetterLangCode;
        this.currentCountryName = this.currentAppConfiguration.countryName;
        this.currentLanguageCode = this.currentLanguageRegionCode.split('-')[0];
        this.currentRegionCode = this.currentLanguageRegionCode.split('-')[1];
        this.currentLanguageRegionCapitalizedCode =
            this.currentLanguageCode +
            '-' +
            this.currentRegionCode.toUpperCase();
        this.brand = this.currentAppConfiguration.brand;
        this.languageSetId = this.currentAppConfiguration.languageSetId;
        this.enableDebugLogs = this.currentAppConfiguration.enableDebugLogs;
        this.subscriptionEnv = this.currentAppConfiguration.subscriptionEnv;
        this.currentBrandAndLocale = `${this.brand}/${this.currentLanguageRegionCode}`;
    }

    private flattenEnvironmentConfiguration = (
        appEnvironmentConfiguration: AppEnvironmentConfiguration,
        environment: string,
        subscriptionEnv: string
    ): AppConfiguration[] => {
        return appEnvironmentConfiguration.countryConfigurations.flatMap((cc) =>
            cc.regionConfigurations.flatMap((rc) => {
                const appConfiguration: AppConfiguration = {
                    environment: environment,
                    countryName: cc.name,
                    countryCode: cc.countryCode,
                    threeLetterCountryCode: cc.threeLetterCountryCode,
                    domain: rc.domain,
                    root: rc.root,
                    languageName: rc.language,
                    languageRegionCode: rc.languageRegionCode,
                    threeLetterLangCode: rc.threeLetterLangCode,
                    langScript: rc.langScript,
                    clientId: rc.clientId,
                    appId: cc.appId,
                    fmaUrl: cc.fmaUrl,
                    fmaRegion: cc.fmaRegion,
                    analyticsEmbedScriptUrl: cc.analyticsEmbedScriptUrl,
                    fmaFsUrl: cc.fmaFsUrl,
                    dslUrl: cc.dslUrl,
                    walletUrl: cc.walletUrl,
                    subscriptionEnv: subscriptionEnv,
                    subscriptionUrl: cc.subscriptionUrl,
                    blockSearchIndexing: rc.blockSearchIndexing,
                    includeDomainsInLanguageSelector:
                        cc.includeDomainsInLanguageSelector,
                    brand: rc.brand,
                    languageSetId: rc.languageSetId,
                    mouseflowId: rc.mouseflowId,
                    medalliaCommentCardId: rc.medalliaCommentCardId,
                    enableDebugLogs: rc.enableDebugLogs,
                };
                return appConfiguration;
            })
        );
    };

    public getBrandRegionLanguage() {
        return [
            this.getAppConfiguration().brand,
            this.getAppConfiguration().languageRegionCode,
            this.getAppConfiguration().languageRegionCode,
            this.getAppConfiguration().threeLetterLangCode,
        ];
    }

    public getAppConfiguration(
        hostname?: string,
        url?: string,
        brand?: string,
        locale?: string
    ): AppConfiguration {
        let appConfiguration = this.appConfigurations.filter(
            (ac) => ac.domain === (hostname || this.getHostname())
        );

        const windowBrandPathName = this.win?.location?.pathname?.split('/')[1]
            ? this.win?.location?.pathname?.split('/')[1]
            : '';

        const windowLanguagePathName =
            windowBrandPathName !== BRAND.ford.LONG_NAME &&
            windowBrandPathName !== BRAND.lincoln.LONG_NAME &&
            windowBrandPathName !== 'en'
                ? ''
                : this.win?.location?.pathname?.split('/')[2];

        const combinedBrandAndLocale =
            windowBrandPathName === '' && windowLanguagePathName === ''
                ? '/'
                : `/${windowBrandPathName}/${windowLanguagePathName}`;

        let pathName =
            brand && locale ? `/${brand}/${locale}/` : combinedBrandAndLocale;

        if (!url && appConfiguration.length > 1) {
            if (!pathName.endsWith('/')) pathName = `${pathName}/`;
            const result = appConfiguration.filter(
                (ac) => ac.root === pathName
            );
            appConfiguration = result.length ? result : appConfiguration;
        }
        if (url && appConfiguration.length > 1) {
            if (!url.endsWith('/')) url = `${url}/`;
            const result = appConfiguration.filter((ac) => ac.root === url);
            appConfiguration = result.length ? result : appConfiguration;
        }

        const appConfig = appConfiguration[0]
            ? { ...appConfiguration[0] }
            : fallbackAppConfiguration;

        return this.updateAppConfigFromQueryParams(
            appConfig,
            this.win?.location?.search
        );
    }

    public updateAppConfigFromQueryParams(appConfig, queryString) {
        const queryParams = new URLSearchParams(queryString);
        const localeParam = queryParams.get('locale')?.toLowerCase();
        const matchedCountryObject = NON_GA_COUNTRIES[localeParam];

        if (matchedCountryObject) {
            appConfig.languageRegionCode = localeParam;
            appConfig.countryCode = matchedCountryObject.twoLetterCountryCode;
            appConfig.threeLetterCountryCode =
                matchedCountryObject.threeLetterCountryCode;
            appConfig.languageName = matchedCountryObject.languageName;
        }

        return appConfig;
    }

    public getLanguageRegionCode = (): string => {
        return this.currentAppConfiguration.languageRegionCode;
    };

    public getThreeLetterLangCode = (): string => {
        return this.currentAppConfiguration.threeLetterLangCode || '';
    };

    public get3LetterCountryCode = (): string => {
        return this.currentAppConfiguration.threeLetterCountryCode;
    };

    public get2LetterCountryCode = (): string => {
        return this.currentAppConfiguration.countryCode;
    };

    public getRegionCode() {
        return this.currentAppConfiguration.languageRegionCode.split('-')[1];
    }

    public getGeoCountryCode(geoCountryCode: any) {
        return this.currentAppConfiguration.countryCode === 'mothersite'
            ? 'gb'
            : geoCountryCode;
    }

    private getHostname(): string | undefined {
        return this.win?.location.hostname;
    }

    public getPathname(): string | undefined {
        let path = this.win?.location.pathname;
        path = path.replace(/[^-A-Za-z0-9+&@#/%?=~_|!:,.;()]/gi, '');
        return path;
    }

    public getBrand(): string {
        return this.currentAppConfiguration.brand;
    }

    public getSupportedLanguages(): SupportedLanguage[] {
        const additionalDomainsToInclude: string[] | undefined =
            this.currentAppConfiguration.includeDomainsInLanguageSelector;
        let additionalAppConfigurationsToMap: AppConfiguration[] = [];
        if (additionalDomainsToInclude) {
            additionalAppConfigurationsToMap = additionalDomainsToInclude.map(
                (domain: string) => {
                    return this.getAppConfiguration(domain);
                }
            );
        }
        return this.appConfigurations
            .filter(
                (ac) =>
                    ac.countryName === this.currentCountryName &&
                    ac.environment ===
                        this.currentAppConfiguration.environment &&
                    ac.brand === this.brand &&
                    ac.languageSetId === this.languageSetId
            )
            .concat(additionalAppConfigurationsToMap)
            .map((ac) => {
                return {
                    domain: ac.domain,
                    root: ac.root,
                    name: ac.languageName,
                    langScript: ac.langScript,
                    languageRegionCode: ac.languageRegionCode,
                    threeLetterLangCode: ac.threeLetterLangCode || '',
                };
            });
    }

    public getLanguageOverrides(): SupportedLanguageOverride[] {
        const allLanguages = this.appConfigurations
            .filter(
                (configuration) =>
                    configuration.countryCode === this.currentCountryCode &&
                    configuration.brand === this.brand &&
                    configuration.languageSetId === this.languageSetId
            )
            .map((configuration) => {
                return {
                    languageName: `${configuration.languageName} (${configuration.languageRegionCode})`,
                    langScript: configuration.langScript,
                    languageRegionCode: configuration.languageRegionCode,
                    threeLetterLangCode: configuration.threeLetterLangCode,
                    countryCode: configuration.countryCode,
                    threeLetterCountryCode:
                        configuration.threeLetterCountryCode,
                };
            });
        const distinctAllLanguages: any = [];
        allLanguages.forEach((language) => {
            if (
                distinctAllLanguages.filter(
                    (filterLanguage: any) =>
                        filterLanguage.languageName === language.languageName &&
                        filterLanguage.languageRegionCode ===
                            language.languageRegionCode &&
                        filterLanguage.threeLetterLangCode ===
                            language.threeLetterLangCode &&
                        filterLanguage.countryCode === language.countryCode &&
                        filterLanguage.threeLetterCountryCode ===
                            language.threeLetterCountryCode
                ).length === 0
            ) {
                distinctAllLanguages.push(language);
            }
        });
        return distinctAllLanguages;
    }

    public isRegionNA(): boolean {
        return this.currentAppConfiguration.fmaRegion === 'na';
    }

    public isRegionEU(): boolean {
        return this.currentAppConfiguration.fmaRegion === 'eu';
    }

    public isEUCountry(): boolean {
        return (
            this.currentAppConfiguration.fmaRegion === 'eu' ||
            EU_COUNTRIES.includes(this.get2LetterCountryCode())
        );
    }
}

export interface SupportedLanguage {
    domain: string;
    root: string;
    name: string;
    langScript: string;
    languageRegionCode: string;
    threeLetterLangCode: string;
}

export interface SupportedLanguageOverride {
    languageName: string;
    langScript: string;
    languageRegionCode: string;
    threeLetterLangCode: string;
    countryCode: string;
    threeLetterCountryCode: string;
}

export default AppConfigurationService;
