import { DSLAPIService } from '../service-orchestration/service-dsl';
import { DSL_API_PATH } from '@constants';

import {
    DealerArray,
    DealerDetails,
    DealerProfileInfo,
    DslDealerDetails,
    OsbDealerDetailsRequest,
    OsbDealerDetailsResponse,
} from '@models/eu-map-models/eu-map-dealer-info';
import { VehicleDataPreferredDealer } from '@models/preferred-dealer';

export interface OSBAPIResponse<T> {
    responseError: ErrorResponse;
    value: T;
}
export interface ErrorResponse {
    errorCode?: string;
    errorMessage: string;
    statusCode?: number;
}
const extractDealerProfile = (dealer: DslDealerDetails): DealerProfileInfo => {
    const earliestAvailableTimeslot =
        dealer.dealerAppointments && dealer.dealerAppointments.length > 0
            ? dealer.dealerAppointments[0].nextAppointmentDate
            : '';
    return {
        dealerCode: dealer.dealerCode,
        dealerGroup: dealer.dealerGroup ? dealer.dealerGroup : '',
        dealerName: dealer.name,
        street: dealer.street,
        town: dealer.town,
        postalCode: dealer.postalCode,
        openingHours: dealer.openingHours,
        customerRating: 0,
        customerRespondentsService: 0,
        distance: 0,
        delivery: dealer.delivery,
        primaryPhone: dealer.phone,
        location: {
            lat: 0,
            lng: 0,
        },
        dealerType: '',
        specialServices: [],
        additionalInfo: {
            earliestAvailableTimeslot: earliestAvailableTimeslot,
            dealerFilterServiceAvailability:
                dealer.filterServiceAvailability ?? [],
            nextAppointmentDateAm:
                dealer.dealerAppointments?.[0]?.nextAppointmentDateAm,
            nextAppointmentDatePm:
                dealer.dealerAppointments?.[0]?.nextAppointmentDatePm,
            nextAppointmentDateWeekend:
                dealer.dealerAppointments?.[0]?.nextAppointmentDateWeekend,
        },
    };
};
export const transformDealerDetailsResponse = (
    dealerDetailsResponse: OsbDealerDetailsResponse
): DealerArray => {
    const dealers: DealerDetails[] = [];
    const transformDealer = (dealer: DslDealerDetails): DealerDetails => {
        const dealerProfile = extractDealerProfile(dealer);
        return {
            dealerName: dealer.name,
            dealerCode: dealer.dealerCode,
            dealerType: '',
            dealerProfile: dealerProfile,
        };
    };

    for (const dealer of dealerDetailsResponse.dealers) {
        dealers.push(transformDealer(dealer));
    }
    return {
        dealers: dealers,
        filterDeliveryServices: dealerDetailsResponse.filterDeliveryServices
            ? JSON.parse(dealerDetailsResponse.filterDeliveryServices)
            : [],
        filterDidServices: dealerDetailsResponse.filterDidServices
            ? JSON.parse(dealerDetailsResponse.filterDidServices)
            : [],
        filterRegularServices: dealerDetailsResponse.filterRegularServices
            ? JSON.parse(dealerDetailsResponse.filterRegularServices)
            : [],
    };
};

export const transformErrorResponse = (errorResponse: any) => {
    const transformedErrorResponse = {} as ErrorResponse;
    if (errorResponse?.response?.data?.error?.dataErrors?.length > 0) {
        transformedErrorResponse.errorCode =
            errorResponse?.response.data.error.dataErrors[0]?.name?.trim() ||
            errorResponse?.response?.data?.error?.errorCode;
        transformedErrorResponse.errorMessage =
            errorResponse?.response.data.error.dataErrors[0]?.message;
        transformedErrorResponse.statusCode =
            errorResponse?.response.data.status;
        return transformedErrorResponse;
    } else {
        return errorResponse;
    }
};
export class OsbDealerDetailsServiceClient {
    public getDealerDetails(args: { vehicleData: VehicleDataPreferredDealer }) {
        const osbDealerDetailsRequest: OsbDealerDetailsRequest = {
            vehicle: args.vehicleData,
        };
        return new Promise<OSBAPIResponse<DealerArray>>((resolve, reject) => {
            const transformedResponse: OSBAPIResponse<DealerArray> = {
                value: {} as DealerArray,
                responseError: {} as ErrorResponse,
            };
            new DSLAPIService()
                .callServiceAPI<
                    OsbDealerDetailsRequest,
                    OsbDealerDetailsResponse
                >(
                    osbDealerDetailsRequest,
                    DSL_API_PATH.OSB_DEALER_DETAILS,
                    true
                )
                .then((response) => {
                    try {
                        transformedResponse.value =
                            transformDealerDetailsResponse(response);
                        resolve(transformedResponse);
                    } catch (err) {
                        transformedResponse.responseError.errorMessage =
                            'DEALER_SERVICES_CLIENT_TRANSFORMATION_ERROR: ' +
                            (err as Error)?.message;
                        reject(transformedResponse.responseError);
                    }
                })
                .catch((_error) => {
                    transformedResponse.responseError =
                        transformErrorResponse(_error);
                    reject(transformedResponse.responseError);
                    console.error(
                        'Rejected the promise - ',
                        transformedResponse.responseError
                    );
                });
        });
    }
}
