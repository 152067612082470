import React, { ReactElement, useEffect, useState } from 'react';

import './return-button.scss';
import { findPathByAlias } from '@routes/routesList';
import { ReturnButtonProps } from './hook/use-return-button-content';
import { useLocation } from 'react-router-dom';
import TertiaryButton from '@common/tertiary-button/tertiary-button';

interface Props {
    returnButtonContent: ReturnButtonProps;
    fromSignInAndSecurityErrorPage?: boolean;
    fromPreferredDealerPage?: boolean;
    onClick?: () => void;
    ariaLabel?: string;
    dataTestId?: string;
}
const ReturnButton = (props: Props): ReactElement => {
    const dashboardView = findPathByAlias('AccountDashboardView');
    const signInAndSecurityView = findPathByAlias('SignInCredentialsView');
    const dashboardPath =
        typeof dashboardView === 'object' ? dashboardView[1] : dashboardView;
    const [showReturnButton, setShowReturnButton] = useState<boolean>(false);
    const fromWebview =
        new URLSearchParams(useLocation().search).get('webview') || undefined;

    useEffect(() => {
        if (!fromWebview) {
            setShowReturnButton(true);
        }
    }, [fromWebview]);

    return (
        props.returnButtonContent &&
        showReturnButton && (
            <div className="return-button" data-testid="return-button">
                <TertiaryButton
                    labelText={props.returnButtonContent.returnText}
                    onClick={props.onClick}
                    link={
                        props.fromSignInAndSecurityErrorPage
                            ? signInAndSecurityView
                            : dashboardPath
                    }
                    ariaLabel={props.ariaLabel}
                    internal={true}
                    chevronDirection={'left'}
                    dataTestId={props.dataTestId}
                />
            </div>
        )
    );
};

export default ReturnButton;
