export const SUPPORT_HUB_REGEX = {
    SPACES: /\s+/g,
    HYPHENS: /-+/g,
    VALID_CHARS: /[^a-z0-9. -]/g,
    ALL_MARKUP_TAGS: /(<([^>]{0,20})>)/g,
    FIND_FIRST_160_CHARS: /^(.{160}[^\s]*).*/,
    FIND_ALL_TABS_NEW_LINE: /\n|\r|\t/g,
    NON_BREAKING_SPACE: /&nbsp;/g,
    PERIOD: /\./g,
    HREF_HASH: /href="#/g,
    COPYRIGHTS_TRADE_REGISTERED_MARKS:
        /(™|®|©|&trade;|&reg;|&copy;|&#8482;|&#174;|&#169;)/g,
    INVALID_CHARS: /[*+()'":?]/g,
    AMPERSAND: /&amp;/g,
};

export const BRAND_ATTRIBUTES = {
    ford: {
        sitemap: ['AF - '],
        cache: ['AF - ', 'AS - ', 'AB - ', 'AM - '],
        touchpoint: '1007054935',
        searchUrl: {
            https: 'https://api.pd01e.gcp.ford.com/dbi-kb/api/knowledge/v1/search',
        },
        bbt: ['AF - '],
        brandName: 'Ford ',
    },
    lincoln: {
        sitemap: ['AL - '],
        cache: ['AL - ', 'AS - ', 'AB - ', 'AM - '],
        touchpoint: '1007054941',
        searchUrl: {
            https: 'https://api.pd01e.gcp.ford.com/dbi-kb/api/knowledge/v1/search',
        },
        bbt: ['AL - '],
        brandName: 'Lincoln ',
    },
};

export const TABLET_BREAKPOINT = 768;

export const GTM_EVENTS = {
    PREDICTIVE_SEARCH: 'predictive-search',
    SELECT_VEHICLE: 'select-vehicle',
    TOPIC_PAGE_404: 'topic-page-404',
};
