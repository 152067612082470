import React, { useEffect, useState } from 'react';
import Slide from '@sections/account-portal/components/vehicle-carousel/slides/slide';
import { CarouselSlideContent } from '@sections/account-portal/components/vehicle-carousel/slides/hooks/use-carousel-slide-content';
import { VehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import { useAnalytics } from '@hooks/use-analytics';
import { CX740_CARD, ORDER_STATUS_CODES } from '@constants';
import EncryptionService from '@services/encryption-service/encryption-service';
import { ORDER_TYPE } from '@sections/account-portal/components/vehicle-carousel/vehicle-order-card/vehicle-order-card';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import './order-slide.scss';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';

interface OrderSlideProps {
    data: OrderedVehiclesItem;
    identification: number;
    carouselContentFragment: VehicleCarouselContent;
    slideContent: CarouselSlideContent;
}
const OrderSlide = ({
    data,
    carouselContentFragment,
    slideContent,
}: OrderSlideProps) => {
    const encryptionService = new EncryptionService(
        process.env.REACT_APP_CVOT_ENCRYPTION_KEY
    );
    const appConfig: AppConfigurationService = new AppConfigurationService();
    const [fireAnalytics] = useAnalytics();
    const orderCardAnalyticEventType: string =
        data?.id?.length > 4 ? 'ecom' : 'cvot';
    const [orderStatusAlertText, setOrderStatusAlertText] =
        useState<string>('');

    let hasCvotOrderNumber = data?.id?.length <= 4;
    //TODO remove the line below when new CVOT is ready for launch
    hasCvotOrderNumber = false;
    const reservationCardCTAHref =
        data?.model == CX740_CARD
            ? `${carouselContentFragment.eComVehicleCardCtaHrefECOMAlternate}?reservationId=${data?.id}`
            : `${carouselContentFragment.eComVehicleCardCtaHrefECOM}?reservationId=${data?.id}`;
    const orderCardEncryptedVin: string = encryptionService.encrypt(data?.vin);
    const orderCardEncryptedDealerOrderNumber: string =
        encryptionService.encrypt(data?.id);
    const ctaHrefForOrderCard: string =
        data?.id?.length > 4
            ? reservationCardCTAHref
            : `${carouselContentFragment.eComVehicleCardCtaHrefCVOT}?vin=${orderCardEncryptedVin}&don=${orderCardEncryptedDealerOrderNumber}`;

    const isEu: boolean = appConfig.getAppConfiguration().fmaRegion === 'eu';
    const marketStatusCodes = ORDER_STATUS_CODES[isEu ? 'eu' : 'us'];
    const [isReservation, setIsReservation] = useState(false);

    const chooseOrderStatusClass = (orderStatus: string) => {
        if (!orderStatus) return;

        const orderStatusClasses = {
            ordered: {
                matched:
                    data.orderType?.toUpperCase() === ORDER_TYPE.ORDER &&
                    (!data.vehicleStatusCode || !data.vin) &&
                    !data.readyToOrder,
                alertText: carouselContentFragment.eComVehicleCardStatusOrdered,
            },
            reservation: {
                matched:
                    data.orderType?.toUpperCase() === ORDER_TYPE.RESERVATION &&
                    !data.readyToOrder,
                alertText:
                    carouselContentFragment.eComVehicleCardStatusReservation,
            },
            placeOrder: {
                matched:
                    data.orderType?.toUpperCase() === ORDER_TYPE.RESERVATION &&
                    data.readyToOrder,
                alertText:
                    carouselContentFragment.eComVehicleCardStatusPlaceOrder,
            },
            inProduction: {
                matched: marketStatusCodes?.inProduction?.includes(orderStatus),
                alertText:
                    carouselContentFragment.eComVehicleCardStatusInProduction,
            },
            shipped: {
                matched: marketStatusCodes?.shipped?.includes(orderStatus),
                alertText: carouselContentFragment.eComVehicleCardStatusShipped,
            },
            purchaseCompleted: {
                matched:
                    marketStatusCodes?.purchaseCompleted?.includes(orderStatus),
                alertText:
                    carouselContentFragment.eComVehicleCardCompletedPurchase,
            },
            delivered: {
                matched: marketStatusCodes?.delivered?.includes(orderStatus),
                alertText: carouselContentFragment.eComVehicleCardDelivered,
            },
        };

        for (const key in orderStatusClasses) {
            const currentStatusClass: { matched: boolean; alertText: string } =
                orderStatusClasses[key];

            if (currentStatusClass.matched) {
                if (['reservation', 'ordered', 'placeOrder'].includes(key))
                    setIsReservation(true);

                setOrderStatusAlertText(currentStatusClass.alertText);
            }
        }
    };

    useEffect(() => {
        chooseOrderStatusClass(data?.vehicleStatusCode || data?.status);
    }, [data?.vehicleStatusCode, data?.status]);

    return (
        <Slide
            data={data}
            isOrderCard
            orderStatusAlertText={orderStatusAlertText}
            slideContent={slideContent}
            carouselContentFragment={carouselContentFragment}
            onCTAClick={() =>
                fireAnalytics('orderCardCTA', '', {
                    orderType: orderCardAnalyticEventType,
                })
            }
            secondaryButtonLink={ctaHrefForOrderCard}
            isReservation={isReservation}
            hasCvotOrderNumber={hasCvotOrderNumber}
        />
    );
};

export default OrderSlide;
