import React, { useState } from 'react';
import { Chevron } from '../../../common';
import './chatbot-input.scss';
import { UPDATE_SNACKBAR_STATE, UPDATE_SNACKBAR_TYPE } from '../actionTypes';
import { ERROR_TYPES } from '../emplifi-wrapper';
interface Props {
    onSubmit: (message: string) => void;
    className?: string;
    placeholder: string;
    dispatch: any;
    conversationStarted: boolean;
}
const ChatbotInput = (props: Props) => {
    const handleChange = (e: any) => {
        setMessage(e.target.value);
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (!message || !message.trim()) {
            if (props.conversationStarted) {
                props.dispatch({ type: UPDATE_SNACKBAR_STATE, payload: false });
            } else {
                props.dispatch({ type: UPDATE_SNACKBAR_STATE, payload: true });
                props.dispatch({
                    type: UPDATE_SNACKBAR_TYPE,
                    payload: ERROR_TYPES.SELF_CLOSING,
                });
                return;
            }
        }
        props.onSubmit(message);
        setMessage('');
    };
    const [message, setMessage] = useState('');
    return (
        <form className={'chatbot-input-container'} onSubmit={handleSubmit}>
            <input
                className={`chatbot-input ${props?.className}`}
                required={true}
                placeholder={props.placeholder}
                tabIndex={0}
                value={message}
                onChange={handleChange}
            />
            <button className={'chatbot-button'} type="submit">
                <Chevron direction="right" color="#102B4E" />
            </button>
        </form>
    );
};

export default ChatbotInput;
