import React from 'react';
import { VehicleOrderTrackingContent } from '@views/vehicle-order-tracking-view/hooks/use-vehicle-order-tracking-content';
import StepMarker from '@views/vehicle-order-tracking-status-view/components/step-marker/step-marker';
import StepMarkerLabel from '@views/vehicle-order-tracking-status-view/components/step-marker-label/step-marker-label';
import { CustomerOrderTrackingData } from '@services/customer-order-tracking-service/customer-order-tracking-service';

interface TrackerProps {
    vehicleOrderTrackingContent: VehicleOrderTrackingContent;
    currentStep: number;
    formatDateForLocale: (date: string) => string;
    orderTrackingData: CustomerOrderTrackingData;
}
export interface StepMarkerProps {
    stepNumber: number;
    stepLabel: string;
    isCurrentStep: boolean;
    isPreviouslyCompletedStep: boolean;
}

const Tracker = (props: TrackerProps) => {
    const {
        vehicleOrderTrackingContent,
        formatDateForLocale,
        orderTrackingData,
    } = props;
    const stepLabels: string[] = [
        vehicleOrderTrackingContent.orderTrackerStatus1Text,
        vehicleOrderTrackingContent.orderTrackerStatus2Text,
        vehicleOrderTrackingContent.orderTrackerStatus3Text,
        vehicleOrderTrackingContent.orderTrackerStatus4Text,
        vehicleOrderTrackingContent.orderTrackerStatus5Text,
        vehicleOrderTrackingContent.orderTrackerStatus6Text,
    ];
    const dates: string[] = [
        orderTrackingData.orderInfo.receiptDate,
        orderTrackingData.orderInfo.currentBuildWeek,
        orderTrackingData.orderInfo.producedDate,
        orderTrackingData.orderInfo.releaseDate,
        orderTrackingData.orderInfo.shippedDate,
        orderTrackingData.orderInfo.finalDeliveredDate,
    ];

    return (
        <div
            className="vehicle-order-tracking__tracker"
            data-testid="vehicle-order-status-tracker"
        >
            <ul className="vehicle-order-tracking__tracker--status-list">
                {stepLabels.map((step: string, index: number) => {
                    return (
                        <StepMarkerLabel
                            key={step}
                            stepNumber={index + 1}
                            stepLabel={step}
                            isCurrentStep={index + 1 === props.currentStep}
                            isPreviouslyCompletedStep={
                                index + 1 < props.currentStep
                            }
                        />
                    );
                })}
            </ul>
            <ul className="vehicle-order-tracking__tracker--dates">
                {dates.map((date: string, index: number) => {
                    return (
                        <li className="date" key={`${date}-${index}`}>
                            {formatDateForLocale(date)}
                        </li>
                    );
                })}
            </ul>
            <div className="vehicle-order-tracking__tracker--graph-container">
                <ul className="vehicle-order-tracking__tracker--graph">
                    {stepLabels.map((step: string, index: number) => {
                        return (
                            <StepMarker
                                key={step}
                                stepNumber={index + 1}
                                stepLabel={step}
                                isCurrentStep={index + 1 === props.currentStep}
                                isPreviouslyCompletedStep={
                                    index + 1 < props.currentStep ||
                                    props.currentStep === 6
                                }
                            />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Tracker;
