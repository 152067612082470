import React from 'react';
import { Checkbox, Option } from '@common/form-fields/form-fields';
import { PrimaryButton } from '@common/index';
import { CommunicationPreferenceLabels } from '@views/communication-preferences-view/hooks/use-communication-preference-content';
import CommunicationPreferencesModal from '../communication-preference-modal/communication-preferences-modal';

interface Props {
    modalState: boolean;
    communicationPreferenceContent: CommunicationPreferenceLabels;
    modalType: any;
    handleClose: any;
    handleConfirmationModalConfirmation: any;
    className: string;
    values?: Option[];
    handleSave: any;
    handleChange: any;
}

const EmailCommunicationPreferencesSection = (props: Props) => {
    return (
        <>
            <CommunicationPreferencesModal
                isModalVisible={props.modalState}
                modalContentFragments={props.communicationPreferenceContent}
                modalType={props.modalType}
                handleClose={props.handleClose}
                handleConfirmationModalConfirmation={
                    props.handleConfirmationModalConfirmation
                }
            />
            <div className={`${props.className}-top-section-container`}>
                <h1
                    className={`${props.className}-section-name fmc-type--heading3 fds-color__text--primary fmc-mb-3 fmc-pb-1`}
                >
                    {props.communicationPreferenceContent?.pageHeader}
                </h1>
                <p
                    className={`${props.className}-section-content fmc-type--body1 fds-color__text--gray3`}
                >
                    {props.communicationPreferenceContent?.pageDescription}
                </p>
            </div>
            <div className={`${props.className}-user-input-section-wrapper`}>
                <div className={`${props.className}-user-input-section`}>
                    <div className={`${props.className}-user-input`}>
                        <Checkbox
                            handleChange={props.handleChange}
                            options={props.values}
                            labelClassName={`${props.className}-user-input-label fmc-type--heading6 fds-color__text--primary`}
                        />
                    </div>
                    <p
                        className={`${props.className}-user-input-help-text fmc-type--body1 fds-color__text--gray3`}
                    >
                        {
                            props.communicationPreferenceContent
                                ?.emailOptionDescription
                        }
                    </p>
                    <div className={`${props.className}-btn-wrapper`}>
                        <PrimaryButton
                            onClick={(e) => {
                                e.preventDefault();
                                props.handleSave();
                            }}
                            className={`${props.className}-save-btn`}
                        >
                            {props.communicationPreferenceContent?.btnSave}
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailCommunicationPreferencesSection;
