import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '../http-service/http-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import AuthenticationService from '../authentication-service/authentication-service';

interface Response {
    status: number;
    httpStatus: string;
}

interface SuccessResponse {
    uomTemperature: string;
    uomWeight: string;
    uomDistance: number;
}

export default class UnitsOfMeasurementService {
    private httpService: any;
    private authenticationService: any;
    private appConfig = new AppConfigurationService();
    private dslUrl = this.appConfig.getAppConfiguration().dslUrl
        ? this.appConfig.getAppConfiguration().dslUrl
        : DSL_BASE_URL;
    private url: string = this.dslUrl + DSL_API_PATH.UNITS_OF_MEASUREMENT;
    public config: any;

    constructor(
        httpService: HttpService,
        authenticationService: AuthenticationService
    ) {
        this.httpService = httpService;
        this.authenticationService = authenticationService;

        this.config = {
            headers:
                this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
        };
    }

    public async getMeasurements(): Promise<SuccessResponse> {
        let response;

        try {
            response = await this.httpService.get(this.url, true, this.config);
        } catch (e) {
            throw new Error(e);
        }

        return response.data;
    }

    public async updateMeasurements(
        distance: number,
        tirePressure: string,
        temperature: string,
        weight: string
    ): Promise<Response> {
        const requestData = {
            uomDistance: distance,
            uomPressure: tirePressure,
            uomTemperature: temperature,
            uomWeight: weight,
        };

        let updateResponse;

        try {
            updateResponse = await this.httpService.put(
                this.url,
                requestData,
                this.config
            );
        } catch (e) {
            if (e?.response?.status === 401) {
                this.authenticationService.login();
            } else {
                return e?.message;
            }
        }

        return updateResponse;
    }
}
