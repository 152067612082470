import React from 'react';
import { InputField } from '@common/form-fields/form-fields';

const MiddleName = (props) => {
    const {
        focus,
        userData,
        handleChange,
        setFocusToField,
        label,
        inputValidations,
        error,
    } = props;

    return (
        <>
            {label && (
                <InputField
                    id="middleName"
                    dataTestId="middleName"
                    value={userData}
                    name="middleName"
                    label={label}
                    showLabel={focus.middlename}
                    handleChange={handleChange}
                    className=""
                    onFocus={() => setFocusToField('middlename', true)}
                    onBlur={() => setFocusToField('middlename', false)}
                    validationRules={
                        inputValidations.middleName.validationRules
                    }
                    validationPattern={
                        inputValidations.middleName.validationPattern
                    }
                    error={error.middleName}
                    placeholder={label}
                />
            )}
        </>
    );
};

export default MiddleName;
