import { EU_COUNTRIES, IMG_COUNTRIES } from '@constants';

export interface Select {
    value: string;
    displayName?: string;
    ariaLabel?: string;
}

interface Fields {
    state: boolean;
    country: boolean;
    [key: string]: boolean;
}
interface FieldsConfig {
    [key: string]: Fields;
}

const fieldsConfig: FieldsConfig = {
    ca: {
        state: true,
        country: false,
        companyName: false,
        middleName: true,
        title: false,
        address1Line2: true,
        additionalAddress: false,
    },
    us: {
        state: true,
        country: false,
        companyName: false,
        middleName: true,
        title: false,
        address1Line2: true,
        additionalAddress: true,
    },
    se: {
        state: false,
        country: true,
        companyName: true,
        middleName: false,
        title: true,
        address1Line2: false,
        additionalAddress: false,
    },
    eu: {
        state: false,
        country: true,
        companyName: true,
        middleName: false,
        title: true,
        address1Line2: true,
    },
    ar: {
        state: true,
        country: false,
        companyName: false,
        middleName: false,
        title: false,
        address1Line2: true,
        nationalId: true,
        additionalAddress: false,
    },
    br: {
        state: true,
        country: false,
        companyName: false,
        middleName: false,
        title: false,
        address1Line2: true,
        nationalId: true,
        additionalAddress: false,
    },
    au: {
        state: true,
        country: false,
        companyName: false,
        middleName: false,
        title: true,
        address1Line2: true,
        nationalId: false,
        additionalAddress: false,
    },
    vn: {
        state: true,
        country: false,
        companyName: false,
        middleName: false,
        title: true,
        address1Line2: true,
        nationalId: false,
        additionalAddress: false,
    },
    nz: {
        state: true,
        country: false,
        companyName: false,
        middleName: false,
        title: true,
        address1Line2: true,
        nationalId: false,
        additionalAddress: false,
    },
    th: {
        state: true,
        country: false,
        companyName: false,
        middleName: false,
        title: true,
        address1Line2: true,
        nationalId: false,
        additionalAddress: false,
    },
    za: {
        state: true,
        country: false,
        companyName: false,
        middleName: false,
        title: true,
        address1Line2: true,
        nationalId: false,
        additionalAddress: false,
    },
    ph: {
        state: true,
        country: false,
        companyName: false,
        middleName: false,
        title: true,
        address1Line2: true,
        nationalId: false,
        additionalAddress: false,
    },
    mx: {
        state: true,
        country: false,
        companyName: false,
        middleName: false,
        title: false,
        address1Line2: true,
        nationalId: false,
        additionalAddress: false,
    },
};
fieldsConfig.can = fieldsConfig.ca;
fieldsConfig.usa = fieldsConfig.us;
fieldsConfig.swe = fieldsConfig.se;
fieldsConfig.eur = fieldsConfig.eu;
fieldsConfig.arg = fieldsConfig.ar;
fieldsConfig.bra = fieldsConfig.br;
fieldsConfig.aus = fieldsConfig.au;
fieldsConfig.vnm = fieldsConfig.vn;
fieldsConfig.nzl = fieldsConfig.nz;
fieldsConfig.tha = fieldsConfig.th;
fieldsConfig.zaf = fieldsConfig.za;
fieldsConfig.phl = fieldsConfig.ph;
fieldsConfig.mex = fieldsConfig.mx;

export const getFieldsConfig = (country: string) => {
    if (country) {
        const output = Object.keys(fieldsConfig).filter(
            (val) => val.toLowerCase() === country.toLowerCase()
        );
        return output.length > 0 ? fieldsConfig[output[0]] : {};
    } else return [];
};

export const EU_COUNTRIES_NAME = [
    { displayName: 'United Kingdom', value: 'gbr' },
    { displayName: 'Ireland', value: 'irl' },
    { displayName: 'Switzerland', value: 'che' },
    { displayName: 'Austria', value: 'aut' },
    { displayName: 'Germany', value: 'deu' },
    { displayName: 'France', value: 'fra' },
    { displayName: 'Belgium', value: 'bel' },
    { displayName: 'Luxembourg', value: 'lux' },
    { displayName: 'Italy', value: 'ita' },
    { displayName: 'Spain', value: 'esp' },
    { displayName: 'Portugal', value: 'prt' },
    { displayName: 'Norway', value: 'nor' },
    { displayName: 'Nederland', value: 'nld' },
    { displayName: 'Finland', value: 'fin' },
    { displayName: 'Denmark', value: 'dnk' },
    { displayName: 'Czech Republic', value: 'cze' },
    { displayName: 'Greece', value: 'grc' },
    { displayName: 'Hungary', value: 'hun' },
    { displayName: 'Poland', value: 'pol' },
    { displayName: 'Romania', value: 'rou' },
    { displayName: 'Russia', value: 'rus' },
    { displayName: 'Turkey', value: 'tur' },
];

export const EU_COUNTRIES_WITH_THREE_DIGIT_COUNTRY_CODES = [
    '+420',
    '+351',
    '+352',
    '+353',
    '+358',
];

export const EU_COUNTRIES_USING_POST_REGISTRATION_REDIRECT: Set<string> =
    new Set([
        'gb',
        'ch',
        'de',
        'fr',
        'it',
        'es',
        'no',
        'nl',
        'ie',
        'at',
        'be',
        'lu',
        'pt',
        'cz',
        'gr',
        'hu',
        'pl',
        'ro',
        'ru',
        'tr',
    ]);

// display phone number with leading zero for IMG countries
export const formatPhoneNumberByCountry = (
    countryCode: string,
    value: string,
    prefix?: string
) => {
    if (
        value &&
        IMG_COUNTRIES.includes(countryCode) &&
        value.charAt(0) != '0'
    ) {
        return `0${value}`;
    } else if (
        EU_COUNTRIES.includes(countryCode) &&
        prefix &&
        value?.charAt(0) != '+'
    ) {
        return `${prefix}${value}`;
    } else if (
        value &&
        EU_COUNTRIES.includes(countryCode) &&
        value.charAt(0) == '+' &&
        value.includes(prefix)
    ) {
        const splitValue = value.split(prefix, 2);
        return `${prefix}${splitValue[1] || ''}`;
    } else if (
        value &&
        EU_COUNTRIES.includes(countryCode) &&
        value.charAt(0) == '+' &&
        !value.includes(prefix)
    ) {
        const firstFourCharacters = value.slice(0, 4);
        const formatWithFour =
            EU_COUNTRIES_WITH_THREE_DIGIT_COUNTRY_CODES.includes(
                firstFourCharacters
            );
        const postCountryCodeString = formatWithFour
            ? value.slice(4)
            : value.slice(3);
        return `${prefix || ''}${postCountryCodeString}`;
    }

    return value;
};
