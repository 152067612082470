import React from 'react';
import Email from './email/email';
import ConfirmEmail from './confirm-email/confirm-email';
import { inputValidations as validationData } from '../personal-information-fields-info';

const EmailSection = (props) => {
    const {
        userData,
        handleChange,
        error,
        focus,
        setFocusToField,
        personalInfoLabels,
    } = props;

    const inputValidations = validationData(personalInfoLabels);
    return (
        <div
            className="input-section-container email-container"
            data-testid="email-container"
        >
            <Email
                label={personalInfoLabels?.emailField}
                focus={focus}
                setFocusToField={setFocusToField}
                userData={userData.email}
                handleChange={handleChange}
                error={error}
                inputValidations={inputValidations}
            />
            <ConfirmEmail
                label={personalInfoLabels?.confirmEmailField}
                focus={focus}
                setFocusToField={setFocusToField}
                userData={userData.confirmEmail}
                handleChange={handleChange}
                error={error}
            />
        </div>
    );
};

export default EmailSection;
