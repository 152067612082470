import React, { useEffect, useState } from 'react';
import { useExperienceContent } from '@hooks/use-server-content';
import { Breadcrumbs } from '@sections/breadcrumbs/breadcrumbs';
import AuthenticationService from '@services/authentication-service/authentication-service';
import './communication-center-view.scss';
import Permission from '@views/communication-center-view/components/permission/permission';
import ProfileService from '@services/profile-service/profile-service';
import {
    CommunicationCenterContent,
    PermissionOption,
} from '@models/communication-center';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { SearchBar } from '@/components/sections';
import {
    ProfileWithVehiclesResponse,
    UserProfileDetails,
} from '@models/profile-with-vehicles';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { Notification } from '@sections/account-portal/components/notification-message/notification';
import { NotificationType } from '@contexts/notificationContext';

const CommunicationCenterView = () => {
    const { brand } = new AppConfigurationService();
    const isLincoln = brand === 'lincoln';
    const authenticationService = new AuthenticationService();
    const profileService = new ProfileService();
    const scrollUtil = new ScrollUtil();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [profileData, setProfileData] = useState<UserProfileDetails>(null);
    const [notificationStatus, setNotificationStatus] =
        useState<NotificationType>(null);
    const [communicationCenterContent] =
        useExperienceContent<CommunicationCenterContent>(
            'common',
            'categories',
            'categories'
        );
    const hasPermissionOptions: boolean =
        communicationCenterContent?.permissionOptions?.length > 0;

    const getProfileData = async (): Promise<ProfileWithVehiclesResponse> => {
        if (isAuthenticated) {
            try {
                const response = await profileService.request();
                if (response) {
                    setProfileData(response.profile);
                    return response;
                }
            } catch (e: any) {
                if (e?.response?.status === 401) {
                    profileService.redirectToFMA();
                }
            } finally {
                scrollUtil.scrollPageToTop();
            }
        } else {
            profileService.redirectToFMA();
        }
    };

    useEffect(() => {
        authenticationService.onIsAuthenticated().then((isAuth) => {
            if (isAuth) {
                setIsAuthenticated(true);
            } else {
                profileService.redirectToFMA();
            }
        });
        scrollUtil.scrollPageToTop();
    }, []);

    useEffect(() => {
        getProfileData();
    }, [isAuthenticated]);

    return (
        <>
            <div className="communication-center-view__wrapper">
                <div
                    className="communication-center-view__container limit-1440"
                    data-testid="communication-center-view"
                >
                    <Breadcrumbs
                        currentPage={
                            communicationCenterContent?.breadcrumbCurrentPage
                        }
                        crumbSeparator={isLincoln ? '>' : '/'}
                    />

                    {profileData ? (
                        <div className="communication-center-view__content">
                            {notificationStatus && (
                                <div
                                    className="communication-center-view__notification-container"
                                    data-testid="communication-center-view__notification-container"
                                >
                                    <Notification
                                        status={notificationStatus}
                                        mainCopy={
                                            notificationStatus ===
                                            NotificationType.Success
                                                ? communicationCenterContent.notificationBannerSuccess
                                                : communicationCenterContent.notificationBannerError
                                        }
                                        hideBorder={true}
                                        hideAfterTimeout={true}
                                        onHideNotification={() => {
                                            setNotificationStatus(null);
                                        }}
                                    />
                                </div>
                            )}
                            <h1
                                className="communication-center-view__header"
                                data-testid="communication-center-view-header-text"
                            >
                                {communicationCenterContent?.headerText}
                            </h1>
                            <p
                                className="communication-center-view__description"
                                dangerouslySetInnerHTML={{
                                    __html: isAuthenticated
                                        ? communicationCenterContent?.descriptionAuth
                                        : communicationCenterContent?.descriptionUnauth,
                                }}
                                data-testid="communication-center-view-description-auth-text"
                            />

                            {hasPermissionOptions &&
                                communicationCenterContent.permissionOptions.map(
                                    (permissionOption: PermissionOption) => {
                                        return (
                                            <div
                                                key={
                                                    permissionOption.apiVariable
                                                }
                                            >
                                                <Permission
                                                    communicationCenterContent={
                                                        communicationCenterContent
                                                    }
                                                    profileData={profileData}
                                                    getProfileData={
                                                        getProfileData
                                                    }
                                                    permissionOption={
                                                        permissionOption
                                                    }
                                                    scrollUtil={scrollUtil}
                                                    setNotificationStatus={
                                                        setNotificationStatus
                                                    }
                                                    isLincoln={isLincoln}
                                                />
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    ) : (
                        <ActivityIndicator className="full-height" />
                    )}

                    {!isLincoln && (
                        <>
                            <hr className="hr-line" />
                            <SearchBar />
                        </>
                    )}
                </div>
            </div>
            {isLincoln && <SearchBar />}
        </>
    );
};
export default CommunicationCenterView;
