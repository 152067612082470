import React from 'react';

interface Props {
    id: React.Key | null | undefined;
    replies: any;
    isVertical: boolean;
    onSubmit: (message: any) => void;
}
const ChatbotQuickReplies = (props: Props) => {
    const renderItem = (
        reply:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined,
        index: any
    ) => (
        <li
            className={`chatbot__quick-replies--item-${
                props.isVertical ? 'vertical' : 'horizontal'
            }`}
            key={props.id + index}
        >
            <button
                className={`chatbot-replies-button-${
                    props.isVertical ? 'vertical' : 'horizontal'
                }`}
                onClick={() => props.onSubmit(reply)}
            >
                {reply}
            </button>
        </li>
    );

    return (
        <ul
            className={`${
                props.isVertical
                    ? 'chatbot__quick-replies--vertical'
                    : 'chatbot__quick-replies--horizontal'
            }`}
        >
            {props.replies.map(renderItem)}
        </ul>
    );
};

export default ChatbotQuickReplies;
