import React, { useEffect } from 'react';
import HelpRouter from '@sections/help-router/help-router';
import { useHelpRouterContentFragment } from '@sections/help-router/hooks/use-help-router';
import { scriptService } from '@services/script-service/script-service';

interface Props {
    language: string;
    country: string;
}

const HelpRouterContainer = (props: Props) => {
    const euHelpRouterCountryCode = [
        'gb',
        'de',
        'fr',
        'it',
        'nl',
        'at',
        'dk',
        'no',
    ];

    const helpRouterServiceHistoryContent = useHelpRouterContentFragment();
    useEffect(() => {
        if (
            euHelpRouterCountryCode.includes(props.country) &&
            helpRouterServiceHistoryContent.serviceHistoryText
        ) {
            scriptService.loadCafexScript();
        }
    }, []);

    return (
        helpRouterServiceHistoryContent.serviceHistoryText && (
            <HelpRouter
                language={props.language}
                helpRouterServiceHistoryContent={
                    helpRouterServiceHistoryContent
                }
            />
        )
    );
};

export default HelpRouterContainer;
