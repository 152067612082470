import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useState,
} from 'react';
import SideDrawer from '@common/side-drawer/side-drawer';

export interface SideDrawerProps {
    name: string;
    children?: ReactNode;
}

export interface SideDrawerContextProps {
    drawerType: SideDrawerProps;
    isVisible?: boolean;
    setDrawerContext?: (updates: SideDrawerContextProps) => void;
}

export const SideDrawerContext = createContext({
    drawerType: { name: '' },
    isVisible: false,
    setDrawerContext: () => null,
} as any);

const SideDrawerProvider = ({ children }) => {
    const [sideDrawerContext, setSideDrawerContext] =
        useState<SideDrawerContextProps | null>({
            drawerType: { name: '' },
        });
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const setDrawerContext = (
        sideDrawerContextUpdates: SideDrawerContextProps
    ) => {
        const sideDrawerPortal = document.getElementById('side-drawer-portal');
        sideDrawerPortal && sideDrawerPortal.classList.remove('closed');

        setSideDrawerContext((prevState) => {
            return {
                ...prevState,
                ...sideDrawerContextUpdates,
            };
        });

        if (sideDrawerContextUpdates.drawerType?.name?.length) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const resetContext = useCallback(() => {
        setSideDrawerContext({
            drawerType: { name: '' },
        });
    }, []);

    const getContext = useCallback(() => {
        return {
            sideDrawerContext,
            isVisible,
            setIsVisible,
            setDrawerContext,
            resetContext,
        };
    }, [sideDrawerContext, isVisible]);

    return (
        <SideDrawerContext.Provider value={getContext()}>
            {children}
            <SideDrawer />
        </SideDrawerContext.Provider>
    );
};

export const useSideDrawerContext = () => useContext(SideDrawerContext);

export default SideDrawerProvider;
