import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import * as serviceWorker from './serviceWorker';
import ServerSideService from '@services/server-side-service/server-side-service';
import './index.scss';
import App from './App';

ReactDOM.hydrate(
    <CookiesProvider>
        <BrowserRouter basename={(window as any).baseForRouting}>
            <App serverSideService={ServerSideService} />
        </BrowserRouter>
    </CookiesProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
