import React, { useContext, useEffect } from 'react';
import { ViewTemplate, HeroTiles } from '@sections/index';
import { PrimaryButton } from '@common/index';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { useErrorsContent } from './hooks/use-errors-content';
import { useHelpCardsContent } from './hooks/use-help-cards-content';
import './page-not-found-view.scss';
import { useAppInformationContent } from '@sections/breadcrumbs/hook/use-breadcrumb-content';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import ServerContext from '@contexts/serverContext';

interface PageNotFoundTopSectionProps {
    title: string;
    body: string;
    btnLabel: string;
    btnLink: string;
}

const PageNotFoundTopSection = (props: PageNotFoundTopSectionProps) => {
    const goToHome = () => {
        window.location.href = props.btnLink;
    };
    return (
        <div className="error-404-text-container">
            <h1 className="title">{props.title}</h1>
            <p className="paragraph">{props.body}</p>
            <PrimaryButton className="home-page-button" onClick={goToHome}>
                {props.btnLabel}
            </PrimaryButton>
        </div>
    );
};

export const PageNotFoundView = () => {
    const serverContext = useContext(ServerContext);
    serverContext.pageNotFound = true;

    const { setNotificationContext } = useNotificationContext();
    const helpCardsContent = useHelpCardsContent();
    const errorsContent = useErrorsContent();
    const appInformationContent = useAppInformationContent();
    const page = '404';

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
    }, []);

    return (
        <>
            {errorsContent && appInformationContent ? (
                <ViewTemplate
                    seoConfig={{
                        pageTitle: errorsContent?.errorTitle404,
                        applicationName: appInformationContent.applicationName,
                    }}
                    page={page}
                    hideInformationGridSection
                    hideSearchBar
                    topMasthead={
                        <PageNotFoundTopSection
                            title={errorsContent?.errorTitle404}
                            body={errorsContent.errorDescription404}
                            btnLabel={errorsContent.errorButtonLabel404}
                            btnLink={errorsContent.errorButtonLink404}
                        />
                    }
                    ctaBottomComponent={
                        <HeroTiles helpCardsContent={helpCardsContent} />
                    }
                    backgroundImageDesktop={
                        process.env.REACT_APP_AEM_BASE_URL +
                        errorsContent.errorBackgroundImageDesktop
                    }
                    backgroundImageMobile={
                        process.env.REACT_APP_AEM_BASE_URL +
                        errorsContent.errorBackgroundImageMobile
                    }
                />
            ) : (
                <ActivityIndicator className={'full-height'} />
            )}
        </>
    );
};
