import { AxiosResponse } from 'axios';
import HttpService from '../http-service/http-service';
import { ContentResponse } from './content-service.interface';
import { HttpsProxyAgent } from 'https-proxy-agent';

export class ContentService {
    public getContent(
        category: string,
        name: string,
        brand: string,
        region: string,
        language: string,
        ymmServlet?: boolean
    ): Promise<ContentResponse> {
        let url;
        const contentFragmentBaseUrl = `${process.env.REACT_APP_AEM_BASE_URL}/content/dam/global-account/${brand}`;
        if (ymmServlet) {
            url = `${contentFragmentBaseUrl}/${region}/${language}/content-fragments/vehicles.${name}.json`;
        } else {
            url = `${contentFragmentBaseUrl}/${region}/${language}/content-fragments/${category}/${name}.global-account-model.json`;
        }
        return this.get(url).then((response) => response.data);
    }

    public getExperience<T>(
        category: string,
        name: string,
        componentName: string,
        brand: string,
        region: string,
        language: string,
        ymmServlet?: boolean,
        parentFolder?: string
    ): Promise<T> {
        let url;
        const experienceFragmentBaseUrl = `${process.env.REACT_APP_AEM_BASE_URL}/content/experience-fragments/${parentFolder}`;
        if (ymmServlet) {
            url = `${experienceFragmentBaseUrl}/${brand}/${region}/${language}/vehicles.${name}.json`;
        } else if (brand) {
            url = `${experienceFragmentBaseUrl}/${brand}/${region}/${language}/${category}/${name}/master.model.json`;
        } else if (brand === '') {
            url = `${experienceFragmentBaseUrl}/${category}/${name}/master.model.json`;
        }

        return this.get(url).then((response) => {
            return response.data[':items'].root[':items'][componentName];
        });
    }

    private get(url: string): Promise<AxiosResponse<any>> {
        if (url.includes('globalaccountaem')) {
            return HttpService.get(url, true, {
                httpsAgent: new HttpsProxyAgent(
                    process.env.https_proxy || 'http://internet.ford.com:83'
                ),
            });
        } else return HttpService.get(url, true);
    }
}

const contentService = new ContentService();
export default contentService;
