import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { Buffer } from 'buffer';

const appConfiguration = new AppConfigurationService();
const brand = appConfiguration.getAppConfiguration().brand;
const fmaRegion = appConfiguration.getAppConfiguration().fmaRegion;

export const getBase64ConsumerKey = () => {
    return Buffer.from(`ga-${fmaRegion}-${brand}`).toString('base64');
};
