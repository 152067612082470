import React from 'react';
import { SA_COUNTRIES } from '@constants';
import { InputField } from '@common/form-fields/form-fields';
import { AccountSettingsFormsLabels } from '../hooks/use-personal-information-content/use-personal-information-content';
import { Errors } from '../personal-information';
import './national-id.scss';

export interface NationalIdProps {
    personalInfolabels: AccountSettingsFormsLabels;
    handleChange: () => void;
    inputValidations: any;
    error: Errors;
    focus: any;
    userData: any;
    sectionHeader: string;
    countryCode: string;
    userIdentity: any;
}

const NationalID = (props) => {
    const {
        sectionHeader,
        handleChange,
        setFocusToField,
        focus,
        personalInfoLabels,
        error,
        countryCode,
        userIdentity,
    } = props;

    const disabledProp = SA_COUNTRIES.includes(countryCode)
        ? { disabled: true }
        : {};
    return (
        <fieldset
            className="input-section-name"
            data-testid="national-id-section"
        >
            <h3
                className="fmc-type--heading6 fmc-mb-5"
                data-testid="national-id-heading"
            >
                {sectionHeader}
            </h3>
            <div className="national-id-section">
                <div className="user-input-select-container three-fields">
                    <InputField
                        id="nationalIdType"
                        dataTestId="national-id-type"
                        value={userIdentity?.identificationSubType}
                        name="nationalIdType"
                        label={personalInfoLabels?.nationalIdType}
                        handleChange={handleChange}
                        showLabel={focus.nationalIdType}
                        className="three-fields"
                        onFocus={() => setFocusToField('nationalIdType', true)}
                        onBlur={() => setFocusToField('nationalIdType', false)}
                        error={error.nationalIdType}
                        {...disabledProp}
                        placeholder={personalInfoLabels?.nationalIdType}
                    />
                </div>

                <InputField
                    id="nationalIdNumber"
                    dataTestId="national-id-number"
                    value={userIdentity?.identificationValue}
                    name="nationalIdNumber"
                    label={personalInfoLabels?.nationalIdNumber}
                    handleChange={handleChange}
                    showLabel={focus.nationalIdNumber}
                    className="three-fields"
                    onFocus={() => setFocusToField('nationalIdNumber', true)}
                    onBlur={() => setFocusToField('nationalIdNumber', false)}
                    error={error.nationalIdNumber}
                    {...disabledProp}
                    placeholder={personalInfoLabels?.nationalIdNumber}
                />
            </div>
        </fieldset>
    );
};

export default NationalID;
