import React from 'react';
import { InputField } from '@common/form-fields/form-fields';

const Email = (props) => {
    const {
        label,
        focus,
        setFocusToField,
        userData,
        handleChange,
        error,
        inputValidations,
    } = props;

    return (
        <>
            {label && (
                <InputField
                    id="email"
                    dataTestId="email"
                    label={label}
                    name="email"
                    showLabel={focus.email}
                    handleChange={handleChange}
                    value={userData}
                    className="two-fields"
                    validationRules={inputValidations.email.validationRules}
                    validationPattern={inputValidations.email.validationPattern}
                    error={error.email}
                    onFocus={() => setFocusToField('email', true)}
                    onBlur={() =>
                        userData.length == 0 && setFocusToField('email', false)
                    }
                    placeholder={label}
                />
            )}
        </>
    );
};

export default Email;
