import React, { Dispatch, SetStateAction, useRef } from 'react';

import { EU_COUNTRIES } from '@constants';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import addVehicleImageLincoln from '@assets/Plus.svg';
import { useAnalytics } from '@hooks/use-analytics';

import { AddVehicle } from '@/components/sections/add-vehicle/add-vehicle';
import { VehicleCarouselContent } from '../../vehicle-tabs/hooks/use-vehicle-carousel-content';
import { AddVehicleContent } from '@sections/add-vehicle/hooks/use-add-vehicle';

import '@sections/account-portal/components/card/card.scss';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import { VehicleAttributes } from '@models/vehicle-attributes';

export interface Props {
    addVehicleContent: AddVehicleContent;
    data: CardData;
    updateVehiclesData: (cachable: boolean) => void;
    cardContentFragments: VehicleCarouselContent;
    displayCTA: boolean;
    setIsLoading: any;
    currentVehiclesCount: number;
    setCurrentVehiclesCount: Dispatch<SetStateAction<number>>;
    onOrderStatusVin: string[];
    vehiclesOnOrder: OrderedVehiclesItem[];
    garageVehicles: VehicleAttributes[];
    setIsNewVehicleAdded: Dispatch<SetStateAction<boolean>>;
}

export interface CardData {
    hide?: boolean;
    title: string;
    description: string;
    imageSrc?: string;
    imageAlt?: string;
    ctaHref?: string;
    ctaTarget?: string;
    ctaLabel?: string;
    ctaAriaLabel?: string;
}

export const AddVehicleCard = (props: Props) => {
    const {
        cardContentFragments,
        updateVehiclesData,
        data,
        setIsLoading,
        onOrderStatusVin,
        vehiclesOnOrder,
        garageVehicles,
    } = props;
    const addVehicleRef = useRef(null);
    const appConfig = new AppConfigurationService();
    const countryCode = appConfig.get2LetterCountryCode();
    const isEu = EU_COUNTRIES.includes(countryCode);
    const [fireAnalytics] = useAnalytics();

    return (
        <div
            className="fmc-cards__card"
            id="add-vehicle-card"
            data-testid="add-vehicle-carousel-card"
        >
            <div
                className="fmc-cards__media-area fds-display--flex fds-layout-grid__inner--span-12 fds-flex__items--center fds-color__bg--gray1"
                data-testid="add-vehicle-fmc-card-image"
                onClick={() => {
                    fireAnalytics('getStartedOnclickEvent');
                    addVehicleRef?.current?.setFromOutside();
                }}
            >
                <img
                    className="fmc-cards__image"
                    src={
                        appConfig.brand === 'lincoln'
                            ? addVehicleImageLincoln
                            : process.env.REACT_APP_AEM_BASE_URL +
                              cardContentFragments?.addvehicleimage
                    }
                    alt="a placeholder"
                />
            </div>
            <div className="fmc-cards__content fds-layout-grid__inner--span-12">
                <h2 className="fmc-cards__title fmc-type--heading5 fds-color__text--primary fds-align--center fds-layout-grid__cell fmc-pt-5 fmc-mt-1">
                    {data.title}
                </h2>
                <p className="fmc-cards__body fds-layout-grid__cell fmc-type--body1 fds-color__text--gray3 fds-align--center fmc-px-3">
                    {data.description}
                </p>
                <div className="fmc-cards__footer fds-layout-grid__cell fds-flex--center">
                    <AddVehicle
                        addVehicleContent={props.addVehicleContent}
                        ref={addVehicleRef}
                        buttonText={cardContentFragments?.addvehiclecardcta}
                        addButtonPlusSign={false}
                        updateVehiclesData={updateVehiclesData}
                        isEURegion={isEu}
                        displayAddButton
                        getStartedAriaLabel={
                            cardContentFragments?.getStartedAriaLabel
                        }
                        setIsLoading={setIsLoading}
                        currentVehiclesCount={props.currentVehiclesCount}
                        setCurrentVehiclesCount={props.setCurrentVehiclesCount}
                        fromOwnerAddVehicle={false}
                        fromConnectedServices={false}
                        fromCard={true}
                        onOrderStatusVin={onOrderStatusVin}
                        vehiclesOnOrder={vehiclesOnOrder}
                        garageVehicles={garageVehicles}
                        setIsNewVehicleAdded={props.setIsNewVehicleAdded}
                    />
                </div>
            </div>
        </div>
    );
};
