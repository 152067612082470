import React, { ReactElement, useEffect } from 'react';
import { CommunicationPreferenceLabels } from '@views/communication-preferences-view/hooks/use-communication-preference-content';
import './communication-preferences-modal.scss';
import {
    ModalContextProps,
    useModalContext,
} from '../../../contexts/modalContext';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

type ModalType = 'success' | 'confirmation' | '';

const className = 'communication-preference';
interface ModalContentInterface {
    modalType: ModalType;
    isModalVisible: boolean;
    modalContentFragments: CommunicationPreferenceLabels;
    handleClose: () => void;
    handleConfirmationModalConfirmation: () => void;
}

const CommunicationPreferencesModal = (
    props: ModalContentInterface
): ReactElement => {
    let primaryBtnLabel: string,
        secondaryBtnLabel: string,
        content: any,
        ariaLabel: string,
        onPrimaryBtnClick: () => void;

    const contentLabels = props.modalContentFragments;
    const { setContext, resetContext } = useModalContext();
    const appConfig = new AppConfigurationService();
    const isLincoln = appConfig.brand === 'lincoln';

    const successModalContent = () => (
        <h2
            className={`'fmc-type--heading6 ${
                isLincoln ? 'fds-color__text--primary fds-align--center' : ''
            }`}
        >
            {contentLabels?.successModalHeader}
        </h2>
    );

    const unsubscribeModalContent = () => (
        <div>
            <h2
                className={`fmc-type--heading6 ${
                    isLincoln
                        ? 'fds-color__text--primary fds-align--center'
                        : ''
                }`}
            >
                {contentLabels?.confirmUnsubcribeModalHeader}
            </h2>
            <p
                className={`fmc-type--body2 ${
                    isLincoln ? 'fds-color__text--gray3 fds-align--center' : ''
                }`}
            >
                {contentLabels?.confirmUnsubscribeModalDescription}
            </p>
        </div>
    );
    // set modal button labels, content and button links
    if (props.modalType === 'success') {
        primaryBtnLabel = contentLabels?.successModalBtn;
        content = successModalContent();

        ariaLabel = contentLabels?.successModalAriaLabel;
        onPrimaryBtnClick = props.handleClose;
    } else if (props.modalType === 'confirmation') {
        primaryBtnLabel = contentLabels?.btnConfirm;
        secondaryBtnLabel = contentLabels?.btnCancel;
        ariaLabel = contentLabels?.unsubscribeModalAriaLabel;
        content = unsubscribeModalContent();
        onPrimaryBtnClick = props.handleConfirmationModalConfirmation;
    } else {
        content = null;
    }

    const communicationPreferenceModalProps: ModalContextProps = {
        modalType: {
            name: 'communication-preference-modal',
            'aria-label': ariaLabel || 'Communication preference modal',
            primaryButtonLabel: primaryBtnLabel,
            secondaryButtonLabel: secondaryBtnLabel,
            role: 'dialog',
            onPrimaryButtonClick: () => {
                onPrimaryBtnClick();
                resetContext();
            },
            onSecondaryButtonClick: () => {
                props.handleClose();
                resetContext();
            },
            onAfterClose: () => {
                props.handleClose();
                resetContext();
            },
            modalContentClass: `${className}-modal-content`,
            modalFooterClass: `${className}-modal-footer`,
            children: content,
        },
    };

    useEffect(() => {
        if (content && props.isModalVisible) {
            setContext(communicationPreferenceModalProps);
        }
    }, [props]);

    return <></>;
};

export default CommunicationPreferencesModal;
