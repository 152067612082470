import React from 'react';
import { findPathByAlias } from '@routes/routesList';
import SecondaryButton from '@common/secondary-button/secondary-button';
import './delete-account-button.scss';
import { useAnalytics } from '@hooks/use-analytics';

interface DeleteAccountLink {
    deleteMyAccountLabel: string;
}

export const DeleteAccountButton = (props: DeleteAccountLink) => {
    const deleteAccountPath = findPathByAlias('DeleteAccountView');
    const [fireAnalytics] = useAnalytics();

    return (
        props.deleteMyAccountLabel && (
            <div className="delete-account-section">
                <hr className="hr-line mB0" aria-hidden={true} />

                <SecondaryButton
                    className="fmc-text-button fmc-text-button--chevron-right"
                    onClick={() => fireAnalytics('deleteMyAccountButton')}
                    internal
                    link={deleteAccountPath}
                    dataTestId="delete-account-link"
                >
                    {props.deleteMyAccountLabel}
                </SecondaryButton>
            </div>
        )
    );
};
