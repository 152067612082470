import React from 'react';

const ServerContext = React.createContext<any>({
    content: {},
    data: {},
    documentTitle: '',
    currentRegionCode: '',
    currentLanguageRegionCode: '',
    brand: '',
    webviewData: null,
    pageNotFound: false,
    adToken: '',
});

export default ServerContext;
