import React from 'react';
import { InputField } from '@common/form-fields/form-fields';

const ConfirmEmail = (props) => {
    const { label, focus, setFocusToField, userData, handleChange, error } =
        props;

    return (
        <>
            {label && (
                <InputField
                    id="confirmEmail"
                    dataTestId="confirmEmail"
                    label={label}
                    name="confirmEmail"
                    showLabel={focus.confirmEmail}
                    handleChange={handleChange}
                    value={userData}
                    className="two-fields"
                    error={error.confirmEmail}
                    onFocus={() => setFocusToField('confirmEmail', true)}
                    onBlur={() =>
                        userData.length == 0 &&
                        setFocusToField('confirmEmail', false)
                    }
                    placeholder={label}
                />
            )}
        </>
    );
};

export default ConfirmEmail;
