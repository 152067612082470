import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
    CommunicationCenterContent,
    PermissionOption,
} from '@models/communication-center';
import './email-permission.scss';
import CommunicationPreferencesModal from '@/components/sections/communication-preference-modal/communication-preferences-modal';
import { useCommunicationPreferencesContent } from '@/views/communication-preferences-view/hooks/use-communication-preference-content';
import UserEmailChannelPreferenceService from '@/services/email-channel-preference-service/email-channel-preference-service';
import HttpService from '@/services/http-service/http-service';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import { useWindowSize } from '@hooks/use-window-size';
import EmailPermissionDisplay from './email-permission-display/email-permission-display';

export interface PermissionProps {
    communicationCenterContent: CommunicationCenterContent;
    permissionOption: PermissionOption;
    emailPermissionOptedIn?: any;
    setNotificationStatus?: Dispatch<SetStateAction<NotificationType>>;
    isLincoln?: boolean;
}

type ModalType = 'success' | 'confirmation' | '';

const EmailPermission = (props: PermissionProps) => {
    const {
        communicationCenterContent,
        permissionOption,
        emailPermissionOptedIn,
        setNotificationStatus,
        isLincoln,
    }: PermissionProps = props;
    const [emailOptedIn, setEmailOptedIn] = useState<boolean>(false);
    const communicationPreferenceLabels = useCommunicationPreferencesContent();
    const [modalState, setModalState] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalType, setModalType] = useState<ModalType>('');
    const service = new UserEmailChannelPreferenceService(HttpService);

    const queryStrings = new URLSearchParams(location.search);
    const cksid = queryStrings.get('cksid');
    const cks = queryStrings.get('cks');
    const consumerId = cksid !== null ? cksid : cks;
    const email = queryStrings.get('email');
    const appConfig = new AppConfigurationService();
    const countryCode = appConfig.get3LetterCountryCode().toUpperCase();
    const handleClose = () => {
        setModalState(false);
    };
    const { setNotificationContext } = useNotificationContext();

    const handleConfirmationModalConfirmation = async () => {
        setShowModal(false);
        const response = await service.updateEmailChannelPreference({
            consumerId,
            countryCode,
            email,
            emailPermission: false,
        });
        if (response) {
            setEmailOptedIn(false);
            setShowModal(false);
            setNotification(
                NotificationType.Success,
                communicationCenterContent.notificationBannerSuccess
            );
        } else {
            setNotification(
                NotificationType.Error,
                communicationCenterContent.notificationBannerError
            );
        }
    };

    const setNotification = (type: NotificationType, message: string) => {
        if (isLincoln) {
            setNotificationContext(type, false, message);
        } else {
            setNotificationStatus(type);
        }
    };

    useEffect(() => {
        setShowModal(false);
        setEmailOptedIn(emailPermissionOptedIn);
        if (emailPermissionOptedIn === true) {
            setShowModal(true);
            setModalType('confirmation');
            setModalState(true);
        }
    }, [emailPermissionOptedIn]);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const size = useWindowSize();

    useEffect(() => {
        if (size.width < 992 && !isMobile) {
            setIsMobile(true);
        } else if (size.width >= 992 && isMobile) {
            setIsMobile(false);
        }
    }, [size.width]);

    return (
        <div
            className="permission__container"
            data-testid="permission-container"
        >
            {showModal && (
                <CommunicationPreferencesModal
                    isModalVisible={modalState}
                    modalContentFragments={communicationPreferenceLabels}
                    modalType={modalType}
                    handleClose={handleClose}
                    handleConfirmationModalConfirmation={
                        handleConfirmationModalConfirmation
                    }
                />
            )}
            <div className="permission-header__container">
                <div className="email-preferences-header">
                    {communicationCenterContent?.emailPreferences}
                </div>
            </div>

            <hr className="email-preferences-divider" />

            <EmailPermissionDisplay
                permissionOption={permissionOption}
                communicationCenterContent={communicationCenterContent}
                emailPermissionOptedIn={emailOptedIn}
            />
        </div>
    );
};

export default EmailPermission;
