import React, {
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { SmartTileGrid, ViewTemplate } from '..';
import { PageType } from '../view-template/page-type';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import AuthenticationService from '@services/authentication-service/authentication-service';
import ProfileService from '@services/profile-service/profile-service';
import { MastheadContentProps } from './hooks/use-masthead-content';
import { useNavigate } from 'react-router-dom';
import { EU_COUNTRIES_NAME } from '@utils/values-by-country/value-by-country';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { useAnalytics } from '@hooks/use-analytics';
import { ConnectedServicesModalContentProps } from '@smart-tiles/smart-tile-connected-services/hook/use-connected-services-modal-content';
import { VehicleCarouselContent } from './components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { AddVehicleContent } from '../add-vehicle/hooks/use-add-vehicle';
import {
    DynamicMastheadGreetingStyle,
    DynamicMastheadProperties,
} from './components/vehicle-tabs/hooks/use-dynamic-masthead-content';
import { findPathByAlias } from '@routes/routesList';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import { VehicleAttributes } from '@models/vehicle-attributes';
import VoiBanner from '@sections/voi-banner/voi-banner';
import { useVoiBannerContent } from '@sections/voi-banner/hooks/use-voi-banner-content';
import HttpService from '@services/http-service/http-service';
import MnvService from '@services/mnv-service/mnv-service';
import { DynamicBanner } from '@sections/account-portal/components/dynamic-masthead/dynamic-banner';
import { EU_COUNTRIES } from '@constants';
import { PreferredDealerModalContentProps } from '@smart-tiles/smart-tile-preferred-dealer/hook/use-preferred-dealer-modal-content';
import './account-portal.scss';
import ImageCard from '@common/image-card/image-card';
import { useImageCardContent } from '@common/image-card/hook/use-image-card-content';

interface Props {
    page: PageType;
    mastheadContent: MastheadContentProps;
    vehicleCarouselContent: VehicleCarouselContent;
    addVehicleContent: AddVehicleContent;
    topMasthead?: ReactNode;
    hasVehicles: boolean;
    dynamicMastheadContent: DynamicMastheadGreetingStyle;
    dynamicMastheadProperties: DynamicMastheadProperties;
    updateAddVehicleStatusWithVin: (vin: string) => void;
    openAddVehicleModal: boolean;
    vehiclesCount?: number;
    isMobile?: boolean;
    setIsLoading?: Dispatch<SetStateAction<boolean>>;
    ordersCount: number;
    vehiclesOnOrder: OrderedVehiclesItem[];
    currentVehiclesCount: number;
    setCurrentVehiclesCount: Dispatch<SetStateAction<number>>;
    addVehicleFromSmartTile: boolean;
    garageVehicles: VehicleAttributes[];
    selectedDynamicMasthead: DynamicMastheadGreetingStyle;
    setDynamicMastheadProperties: Dispatch<
        SetStateAction<DynamicMastheadProperties>
    >;
    setSelectedDynamicMasthead: Dispatch<
        SetStateAction<DynamicMastheadGreetingStyle>
    >;
    handleAddVehicleModal: Dispatch<SetStateAction<boolean>>;
    setIsNewVehicleAdded: Dispatch<SetStateAction<boolean>>;
    fromConnectedServices: boolean;
    fromPreferredDealer: boolean;
    preferredDealerModalContent: PreferredDealerModalContentProps;
    connectedServicesModalContent: ConnectedServicesModalContentProps;
    showOrderHistoryImageCard: boolean;
}

const setTime = (hours: number, minutes: number, seconds: number) => {
    const currentTime: Date = new Date();
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(seconds);
    return currentTime;
};

const greetingBackgroundImageTime = () => {
    const currentTime: Date = new Date();
    const goodMorningTime = setTime(5, 0, 1);
    const goodAfterNoonTime = setTime(12, 0, 0);
    const goodEveningTime = setTime(17, 0, 0);
    if (currentTime >= goodMorningTime && currentTime <= goodAfterNoonTime) {
        return 'morning';
    } else if (
        currentTime > goodAfterNoonTime &&
        currentTime <= goodEveningTime
    ) {
        return 'afternoon';
    } else {
        return 'evening';
    }
};

export const AccountPortal = (props: Props) => {
    useAnalytics('ownerAccount');
    const [firstName, setFirstName] = useState<string | null>(null);
    const authenticationService = new AuthenticationService();
    const profileService = new ProfileService();
    const httpService = HttpService;
    const mnvService: MnvService = new MnvService(httpService);
    const imageCardContent = useImageCardContent();
    const voiBannerContent = useVoiBannerContent();
    const [, setIsVoiBannerVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const displayModal = () => {
        setIsModalVisible(true);
    };
    const appConfig = new AppConfigurationService();
    const countryCode = appConfig.currentCountryCode;
    const threeLetterCountryCode = appConfig.get3LetterCountryCode();
    const navigate = useNavigate();
    const isEu = EU_COUNTRIES.includes(countryCode);
    const hasParamToShowModal: boolean =
        props.fromConnectedServices || props.openAddVehicleModal;
    const displayModalIfFromParam = (): void =>
        hasParamToShowModal && displayModal();
    const shouldNavigateToEuPostRegistration = (
        showPreferenceModal: boolean
    ): boolean =>
        showPreferenceModal &&
        EU_COUNTRIES_NAME.map((option) => option.value).includes(
            threeLetterCountryCode
        );
    const goToEuPostRegistration = (): void => {
        navigate(findPathByAlias('EuPostRegistrationRedirect'), {
            state: {
                postRegistration: true,
            },
        });
    };
    const [numberOfImageCards, setNumberOfImageCards] = useState<number>(0);
    const [imageCardContainerClassName, setImageCardContainerClassName] =
        useState<string>('');

    useEffect(() => {
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    profileService.request().then((profileService) => {
                        if (profileService) {
                            setFirstName(
                                profileService.profile.firstName.toUpperCase()
                            );
                            // Display EU marketing options modal only id privacyPreferences is null (i.e first time user login)
                            const showPreferenceModal =
                                profileService.profile.privacyPreferences ===
                                null;

                            shouldNavigateToEuPostRegistration(
                                showPreferenceModal
                            ) && goToEuPostRegistration();
                        }
                        displayModalIfFromParam();
                    });
                }
            });
    }, [firstName]);

    const getMastheadImagePathDesktop = (): string => {
        const dynamicMastheadImageDesktop: string = props.hasVehicles
            ? props.dynamicMastheadContent?.backgroundImgWithVehiclesDesktop
            : props.dynamicMastheadContent?.backgroundImgWithoutVehiclesDesktop;
        const bgConst = greetingBackgroundImageTime();
        let backgroundImage;
        switch (bgConst) {
            case 'morning':
                backgroundImage =
                    dynamicMastheadImageDesktop ||
                    props.mastheadContent.morningImageDesktop;
                break;
            case 'afternoon':
                backgroundImage =
                    dynamicMastheadImageDesktop ||
                    props.mastheadContent.afternoonImageDesktop;
                break;
            case 'evening':
                backgroundImage =
                    dynamicMastheadImageDesktop ||
                    props.mastheadContent.eveningImageDesktop;
                break;
        }
        return (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            process.env.REACT_APP_AEM_BASE_URL! + backgroundImage
        );
    };

    const getMastheadImagePathMobile = (): string => {
        const dynamicMastheadImageMobile: string = props.hasVehicles
            ? props.dynamicMastheadContent?.backgroundImgWithVehiclesMobile
            : props.dynamicMastheadContent?.backgroundImgWithoutVehiclesMobile;
        const timeOfDay: string = greetingBackgroundImageTime();
        let backgroundImage;

        switch (timeOfDay) {
            case 'morning':
                backgroundImage =
                    dynamicMastheadImageMobile ||
                    props.mastheadContent.morningImageMobile;
                break;
            case 'afternoon':
                backgroundImage =
                    dynamicMastheadImageMobile ||
                    props.mastheadContent.afternoonImageMobile;
                break;
            case 'evening':
                backgroundImage =
                    dynamicMastheadImageMobile ||
                    props.mastheadContent.eveningImageMobile;
                break;
        }
        return (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            process.env.REACT_APP_AEM_BASE_URL! + backgroundImage
        );
    };

    useEffect(() => {
        new ScrollUtil().scrollPageToTop();
    }, []);

    useEffect(() => {
        if (imageCardContent) {
            const imageCardContainer = document.querySelector(
                '.image-card__section'
            );

            setNumberOfImageCards(imageCardContainer?.children?.length);
        }
    }, [imageCardContent]);

    useEffect(() => {
        if (numberOfImageCards === 3) {
            setImageCardContainerClassName('three-cards');
        } else if (numberOfImageCards === 2) {
            setImageCardContainerClassName('two-cards');
        } else if (numberOfImageCards === 1) {
            setImageCardContainerClassName('one-card');
        }
    }, [numberOfImageCards]);

    return (
        <>
            {props.mastheadContent ? (
                <>
                    <ViewTemplate
                        topMasthead={
                            <div
                                className={`vehicle-masthead-dashboard ${
                                    props.vehiclesCount +
                                        props.ordersCount +
                                        1 <=
                                        3 && window.innerWidth > 767
                                        ? 'no-indicators-desktop'
                                        : ''
                                } ${
                                    !props.hasVehicles &&
                                    props.ordersCount === 0
                                        ? 'no-vehicle-state'
                                        : ''
                                }`}
                            >
                                {props.topMasthead}
                            </div>
                        }
                        page={props.page}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        breadcrumb="Account Dashboard"
                        backgroundImageDesktop={getMastheadImagePathDesktop()}
                        backgroundImageMobile={getMastheadImagePathMobile()}
                        hasVehicles={props.hasVehicles}
                        hasOrders={props.ordersCount > 0}
                        hasCarousel={!props.vehicleCarouselContent.hide}
                        dynamicMastheadProperties={
                            props.dynamicMastheadProperties
                        }
                        dynamicMastheadContent={props.dynamicMastheadContent}
                        className="vehicle-portal"
                    >
                        {props.connectedServicesModalContent ? (
                            <div className={'limit-1440'}>
                                <SmartTileGrid
                                    appConfig={appConfig}
                                    page={props.page}
                                    vehiclesCount={props.vehiclesCount}
                                    hasVehicles={props.hasVehicles}
                                    ordersCount={props.ordersCount}
                                    vehiclesOnOrder={props.vehiclesOnOrder}
                                    hasOrders={props.ordersCount > 0}
                                    className={`information-grid`}
                                    updateAddVehicleStatusWithVin={
                                        props.updateAddVehicleStatusWithVin
                                    }
                                    isMobile={props.isMobile}
                                    toggleCarouselAnimation={setIsModalVisible}
                                    setIsLoading={props.setIsLoading}
                                    connectedServicesModalContent={
                                        props.connectedServicesModalContent
                                    }
                                    preferredDealerModalContent={
                                        props.preferredDealerModalContent
                                    }
                                    addVehicleContent={props.addVehicleContent}
                                    currentVehiclesCount={
                                        props.currentVehiclesCount
                                    }
                                    setCurrentVehiclesCount={
                                        props.setCurrentVehiclesCount
                                    }
                                    setIsNewVehicleAdded={
                                        props.setIsNewVehicleAdded
                                    }
                                    fromConnectedServicesParam={
                                        isModalVisible &&
                                        props.fromConnectedServices
                                    }
                                    fromPreferredDealerParam={
                                        props.fromPreferredDealer
                                    }
                                    garageVehicles={props.garageVehicles}
                                    openAddVehicleModal={
                                        props.openAddVehicleModal
                                    }
                                    handleAddVehicleModal={
                                        props.handleAddVehicleModal
                                    }
                                    hideCarousel={
                                        props.vehicleCarouselContent.hide
                                    }
                                />
                            </div>
                        ) : null}

                        {imageCardContent && props.hasVehicles ? (
                            <div
                                className={`image-card__section limit-1440 ${imageCardContainerClassName}`}
                            >
                                {imageCardContent?.accessoriesCardCtaLabelText &&
                                    imageCardContent?.accessoriesCardCtaHref && (
                                        <ImageCard
                                            imageCardType="accessories"
                                            imageCardTitle={
                                                imageCardContent?.accessoriesCardTitle
                                            }
                                            imageCardCtaLabelText={
                                                imageCardContent?.accessoriesCardCtaLabelText
                                            }
                                            imageCardCtaAriaLabel={
                                                imageCardContent?.accessoriesCardCtaAriaLabel
                                            }
                                            imageCardCtaHref={
                                                imageCardContent?.accessoriesCardCtaHref
                                            }
                                            imageCardBackgroundImage={
                                                imageCardContent?.accessoriesCardBackgroundImage
                                            }
                                            imageCardCount={numberOfImageCards}
                                        />
                                    )}

                                {imageCardContent?.savedItemsCardCtaLabelText &&
                                    imageCardContent?.savedItemsCardCtaHref && (
                                        <ImageCard
                                            imageCardType="saved-items"
                                            imageCardTitle={
                                                imageCardContent?.savedItemsCardTitle
                                            }
                                            imageCardCtaLabelText={
                                                imageCardContent?.savedItemsCardCtaLabelText
                                            }
                                            imageCardCtaAriaLabel={
                                                imageCardContent?.savedItemsCardCtaAriaLabel
                                            }
                                            imageCardCtaHref={
                                                imageCardContent?.savedItemsCardCtaHref
                                            }
                                            imageCardCount={numberOfImageCards}
                                        />
                                    )}

                                {imageCardContent?.orderHistoryCardCtaLabelText &&
                                    imageCardContent?.orderHistoryCardCtaHref &&
                                    props.showOrderHistoryImageCard && (
                                        <ImageCard
                                            imageCardType="order-history"
                                            imageCardTitle={
                                                imageCardContent?.orderHistoryCardTitle
                                            }
                                            imageCardCtaLabelText={
                                                imageCardContent?.orderHistoryCardCtaLabelText
                                            }
                                            imageCardCtaAriaLabel={
                                                imageCardContent?.orderHistoryCardCtaAriaLabel
                                            }
                                            imageCardCtaHref={
                                                imageCardContent?.orderHistoryCardCtaHref
                                            }
                                            imageCardCount={numberOfImageCards}
                                        />
                                    )}
                            </div>
                        ) : null}

                        {imageCardContent && (
                            <hr className="image-card__section--divider limit-1440" />
                        )}

                        {isEu && (
                            <DynamicBanner
                                dynamicMastheadProperties={
                                    props.dynamicMastheadProperties
                                }
                                selectedDynamicMasthead={
                                    props.selectedDynamicMasthead
                                }
                                vehiclesData={props.garageVehicles}
                                setDynamicMastheadProperties={
                                    props.setDynamicMastheadProperties
                                }
                                setSelectedDynamicMasthead={
                                    props.setSelectedDynamicMasthead
                                }
                                backgroundImageDesktop={getMastheadImagePathDesktop()}
                                backgroundImageMobile={getMastheadImagePathMobile()}
                            />
                        )}

                        {voiBannerContent && (
                            <VoiBanner
                                voiBannerContent={voiBannerContent}
                                mnvService={mnvService}
                                setIsVoiBannerVisible={setIsVoiBannerVisible}
                            />
                        )}
                    </ViewTemplate>
                </>
            ) : (
                <ActivityIndicator />
            )}
        </>
    );
};
