import React from 'react';
import { MarketingOptionContent } from '@sections/marketing-options/marketing-options-section';

import './marketing-options-details.scss';

export interface MarketingOptionsDetailsProps {
    optionValues: any;
    marketingOptionsContent: MarketingOptionContent;
}

const MarketingOptionsDetails = (props: MarketingOptionsDetailsProps) => {
    const { optionValues, marketingOptionsContent } = props;

    return (
        <div
            className="marketing-options-details"
            data-testid="marketing-options-details"
        >
            {marketingOptionsContent.options.map((option, index) => {
                return (
                    <div
                        className="marketing-options-details__row"
                        key={`option-${index}`}
                    >
                        <h3>{option.optionName}</h3>
                        <p>
                            {optionValues[option.apiVariable] === '1'
                                ? marketingOptionsContent.yesOptionLabel
                                : marketingOptionsContent.noOptionLabel}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

export default MarketingOptionsDetails;
