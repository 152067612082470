import { useContent } from '@hooks/use-server-content';

export interface FordPassRewardsContent {
    title: string;
    tierText: string;
    defaultTierText: string;
    pointsText: string;
    goToRewardsCtaText: string;
    goToRewardsCtaHref: string;
    goToRewardsCtaAriaLabel: string;
    redeemCtaText: string;
    redeemCtaHref: string;
    redeemCtaAriaLabel: string;
    activateCtaText: string;
    activateCtaHref: string;
    activateCtaAriaLabel: string;
    expirationDateText: string;
}

export const useFordPassRewardsContent = (): FordPassRewardsContent | null => {
    const [content, getValueByName] = useContent('common', 'ford-pass-rewards');
    const contentFragment: any = {};

    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }

    return contentFragment as FordPassRewardsContent;
};
