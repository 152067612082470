import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import serverSideService from '../server-side-service/server-side-service';

type LogFrom = 'Analytics' | 'DataLayer' | 'Shortcodes' | 'Authentication';

export class LogService {
    private static logs: string[] = [];

    public static log(from: LogFrom, output: any, ...restOutput: any[]) {
        if (
            serverSideService.isClientSide() &&
            (window as any).goLogs === undefined
        ) {
            (window as any).goLogs = () => {
                console.log(LogService.logs);
            };
        }
        LogService.logs.push(`[GA ${from}] ${JSON.stringify(output)}`);
        if (new AppConfigurationService().enableDebugLogs === true) {
            console.debug(`[GA ${from}]`, output);
        }
        restOutput.forEach((toLog) => this.log(from, toLog));
    }
}
