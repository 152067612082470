import { useContent } from '@hooks/use-server-content';
import { GuestLoginContent } from '@views/guest-login-views/guest-login-content-interface';

export const useGuestOrderLoginContent = (): GuestLoginContent | null => {
    const [content, getValueByName] = useContent('common', 'nvc-guest-login');
    const contentFragment: any = {};

    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }

    if (!Object.keys(contentFragment).length) return null;

    return contentFragment as GuestLoginContent;
};
