import React, { Dispatch, SetStateAction } from 'react';
import { SecurityContent } from '@sections/sign-in-and-security/hook/use-security-content';
import { findPathByAlias } from '@routes/routesList';
import SecondaryButton from '@common/secondary-button/secondary-button';

export interface SignInAndSecurityErrorContentProps {
    securityContent: SecurityContent;
    setShowErrorPage: Dispatch<SetStateAction<boolean>>;
}

const SignInAndSecurityErrorContent = (
    props: SignInAndSecurityErrorContentProps
) => {
    return (
        <div
            className="sign-in-and-security-error-content"
            data-testid="sign-in-and-security-error-content"
        >
            <h1 className="sign-in-credentials__section-header">
                {props.securityContent.errorPageHeaderText}
            </h1>
            <img
                src={
                    process.env.REACT_APP_AEM_BASE_URL +
                    props.securityContent.errorPageAlertIcon
                }
                alt=""
            />
            <p className="fmc-type--body2">
                {props.securityContent.errorPageDescriptionText}
            </p>
            <SecondaryButton
                internal
                className="fmc-text-button"
                aria-Label={
                    props.securityContent.errorPageCancelButtonAriaLabel
                }
                id="sign-in-and-security-error-cancel-button"
                link={findPathByAlias('SignInCredentialsView')}
                onClick={() => props.setShowErrorPage(false)}
            >
                {props.securityContent.errorPageCancelButtonText}
            </SecondaryButton>
        </div>
    );
};

export default SignInAndSecurityErrorContent;
