import React from 'react';

const AddVehicleErrorContent = ({
    errorsContent,
    errorResponse,
    addVehicleContent,
    vinAlreadyExists,
    isLincoln,
}) => {
    const vinAlreadyExistsInAPI: boolean =
        errorResponse &&
        errorResponse.error?.statusCode === '919' &&
        errorResponse.error?.statusContext === 'SAP';

    return (
        <>
            {addVehicleContent && addVehicleContent.incorrectVin && (
                <>
                    {!vinAlreadyExists &&
                        ![409, 400].includes(errorResponse?.httpStatus) &&
                        errorResponse?.status !== 400 &&
                        errorResponse?.data?.requestStatus !== 'UNAVAILABLE' &&
                        errorResponse?.status !== 404 && (
                            <h3
                                className={`fmc-type--heading5 ${
                                    isLincoln
                                        ? 'fds-color__text--primary error-text'
                                        : ''
                                }`}
                                data-testid="noServiceErrorMessage"
                            >
                                {errorsContent?.errorDescription403}
                            </h3>
                        )}
                    {(errorResponse?.status === 404 ||
                        errorResponse?.data?.requestStatus === 'UNAVAILABLE' ||
                        errorResponse?.status === 400) && (
                        <h3
                            className={`fmc-type--heading5 ${
                                isLincoln
                                    ? 'fds-color__text--primary error-text'
                                    : ''
                            }`}
                            data-testid="vehicle-not-found-error-message"
                        >
                            {addVehicleContent.vehicleNotFoundErrorMessage}
                        </h3>
                    )}
                    {(vinAlreadyExists || vinAlreadyExistsInAPI) && (
                        <h3
                            className={`fmc-type--heading5 ${
                                isLincoln
                                    ? 'fds-color__text--primary error-text'
                                    : ''
                            }`}
                            data-testid="vinExistsErrorMessage"
                        >
                            {addVehicleContent.existingVinMessage}
                        </h3>
                    )}
                </>
            )}
        </>
    );
};

export default AddVehicleErrorContent;
