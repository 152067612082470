import { useContent } from '@hooks/use-server-content';

export interface ImageCardContent {
    accessoriesCardTitle: string;
    accessoriesCardCtaLabelText: string;
    accessoriesCardCtaAriaLabel: string;
    accessoriesCardCtaHref: string;
    accessoriesCardBackgroundImage: string;
    savedItemsCardTitle: string;
    savedItemsCardCtaLabelText: string;
    savedItemsCardCtaAriaLabel: string;
    savedItemsCardCtaHref: string;
    orderHistoryCardTitle: string;
    orderHistoryCardCtaLabelText: string;
    orderHistoryCardCtaAriaLabel: string;
    orderHistoryCardCtaHref: string;
}

export const useImageCardContent = (): ImageCardContent | null => {
    const [content, getValueByName] = useContent('common', 'image-card');
    const contentFragment: any = {};

    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }

    if (!Object.keys(contentFragment).length) return null;

    return contentFragment as ImageCardContent;
};
