import { useContent } from '@hooks/use-server-content';

export interface OrderStatusContent {
    created: string;
    placed: string;
    backordered: string;
    processing: string;
    preparingToShip: string;
    shipped: string;
    inTransitToDealer: string;
    readyForPickup: string;
    pickedUp: string;
    orderDelivered: string;
    returnCreated: string;
    returned: string;
    cancelled: string;
}

export const useOrderStatusContent = (): OrderStatusContent | null => {
    const [content, getValueByName] = useContent('common', 'nvc-order-status');
    const contentFragment: any = {};

    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }

    if (!Object.keys(contentFragment).length) return null;

    return contentFragment as OrderStatusContent;
};
