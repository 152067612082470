import httpAdapter from 'axios/lib/adapters/http';
import { HttpsProxyAgent } from 'https-proxy-agent';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '@services/http-service/http-service';

export interface CustomerBooking {
    additionalQuestions: string;
    bookingEndTime: string;
    bookingId: string;
    bookingStartTime: string;
    bookingStatus: string;
    contactNumber: string;
    email: string;
    modelName: string;
    topics: string[];
    vin: string;
}
export interface GCCTCurrentBookingsResponse {
    customerbookings: CustomerBooking[];
    customerfirstname?: string;
    customerid?: string;
    customerlastname?: string;
    message?: string | null;
}

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export default class GCCTService {
    private httpService: any;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getCurrentBookings(): Promise<GCCTCurrentBookingsResponse> {
        const url = `${DSL_BASE_URL + DSL_API_PATH.CURRENT_BOOKINGS}`;
        const config = {
            headers:
                this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
            adapter: httpAdapter,
            proxy: false,
            httpAgent: proxyAgent,
        };

        return this.httpService
            .get(url, false, config)
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
            .catch(() => {
                return {};
            });
    }
}
