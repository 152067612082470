import AppConfigurationService from '../app-configuration-service/app-configuration-service';

class CafexService {
    private UAT_SCRIPT = 'cafex/js/QACafeXJS.js';
    private PROD_SCRIPT = 'cafex/js/ProdCafeXJS.js';
    public getCafexScriptPath(): string {
        const appConfiguration =
            new AppConfigurationService().getAppConfiguration();
        const root = appConfiguration.root === '/' ? '' : appConfiguration.root;
        if (appConfiguration.environment === 'prod') {
            return appConfiguration.domain
                ? root + this.PROD_SCRIPT
                : `/${this.PROD_SCRIPT}`;
        } else {
            return appConfiguration.domain
                ? root + this.UAT_SCRIPT
                : `/${this.UAT_SCRIPT}`;
        }
    }
}

export const cafexService = new CafexService();
