import GradientBackground from '@common/gradient-background/gradient-background';
import React from 'react';

interface InitialsLogoProps {
    firstName: string;
    lastName: string;
    isMobile: boolean;
}

const InitialsLogo = (props: InitialsLogoProps) => {
    return (
        <div className="initials__container" data-testid="initials-container">
            <GradientBackground
                isMobile={props.isMobile}
                primaryColor="#00142E"
                secondaryColor="#0562D2"
            />
            <span data-testid="initials">
                {props.firstName.charAt(0).toUpperCase()}
                {props.lastName.charAt(0).toUpperCase()}
            </span>
        </div>
    );
};

export default InitialsLogo;
