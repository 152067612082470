import React from 'react';
import './parts-list.scss';
import { Part } from '@services/customer-order-tracking-service/customer-order-tracking-service';
import { ModalContextProps, useModalContext } from '@contexts/modalContext';
import { CVOT_IMAGE_URL_BASE } from '@constants';
import { VehicleOrderTrackingContent } from '@views/vehicle-order-tracking-view/hooks/use-vehicle-order-tracking-content';

interface Props {
    parts: Part[];
    vehicleOrderTrackingContent: VehicleOrderTrackingContent;
}

export const PartsList = (props: Props) => {
    const { setContext, resetContext } = useModalContext();

    const hasImage = (part: Part): boolean => {
        const partImagePath = getPartImageUrl(part);

        return Boolean(partImagePath);
    };
    const buildPartModalProps = (part: Part): ModalContextProps => {
        const partDescription = getPartDescription(part);
        const partImageUrl = getPartImageUrl(part);
        const partDetails = getPartDetails(part);

        return {
            modalType: {
                name: 'part-info-modal',
                className: 'part-info-modal',
                onAfterClose: () => resetContext(),
                closeButtonAriaLabel:
                    props.vehicleOrderTrackingContent
                        .partInfoModalCloseButtonAriaLabel,
                children: (
                    <div className="part-info-modal-container">
                        {partDescription && (
                            <h1
                                className="part-info-modal-title"
                                data-testid="part-info-modal-title"
                            >
                                {partDescription}
                            </h1>
                        )}
                        {partImageUrl && (
                            <img
                                src={partImageUrl}
                                alt={part.description}
                                className="part-info-modal-image"
                                data-testid="part-info-modal-image"
                            />
                        )}
                        {partDetails && (
                            <p
                                className="part-info-modal-details"
                                data-testid="part-info-modal-details"
                            >
                                {partDetails}
                            </p>
                        )}
                    </div>
                ),
            },
        };
    };

    const getPartDescription = (part: Part): string => {
        return part.attribute?.find((attr) =>
            attr.name?.toLowerCase().includes('partdescription')
        )?.value;
    };

    const getPartImageUrl = (part: Part): string => {
        const partImagePath = part.attribute?.find((attr) =>
            attr.name?.toLowerCase().includes('image')
        )?.value;

        return partImagePath ? CVOT_IMAGE_URL_BASE + partImagePath : null;
    };

    const getPartDetails = (part: Part): string => {
        return part.attribute?.find((attr) =>
            attr.name?.toLowerCase().includes('partdetails')
        )?.value;
    };

    return (
        <ul className="parts-list" data-testid="parts-list">
            {props.parts?.map((part, index) => (
                <li>
                    <button
                        className={`part-link ${
                            hasImage(part) ? 'enabled' : 'disabled'
                        }`}
                        data-testid={`part-link-${index}`}
                        onClick={() => {
                            if (hasImage(part)) {
                                setContext(buildPartModalProps(part));
                            }
                        }}
                    >
                        {part?.description}
                    </button>
                </li>
            ))}
        </ul>
    );
};
