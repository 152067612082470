import React, { HTMLProps, useCallback, useContext, useState } from 'react';
import { Modal } from '@common/modal/modal';

export interface ModalProps extends HTMLProps<HTMLDivElement> {
    name?: string;
    primaryButtonLabel?: string;
    secondaryButtonLabel?: string;
    secondaryButtonAriaLabel?: string;
    primaryButtonAriaLabel?: string;
    closeButtonAriaLabel?: string;
    onPrimaryButtonClick?: (event: React.MouseEvent) => void;
    onSecondaryButtonClick?: (event: React.MouseEvent) => void;
    preventClose?: boolean;
    onBeforeClose?: () => void;
    onAfterClose?: () => void;
    role?: string;
    ariaLabel?: string;
    isVisible?: boolean;
    modalContentClass?: string;
    modalFooterClass?: string;
    children?: any;
}

export interface ModalContextProps {
    modalType: ModalProps;
    isVisible?: boolean;
    setContext?: (updates: ModalContextProps) => void;
}

export const ModalContext = React.createContext({
    modalType: { name: '' },
    isVisible: false,
    setContext: () => null,
} as any);

const ModalProvider = ({ children }) => {
    const [modalContext, setModalContext] = useState<ModalContextProps | null>({
        modalType: { name: '' },
    });
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const setContext = (modalContextUpdates: ModalContextProps) => {
        setModalContext((prevState) => {
            return {
                ...prevState,
                ...modalContextUpdates,
            };
        });

        if (modalContextUpdates.modalType?.name?.length) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const resetContext = useCallback(() => {
        setModalContext({
            modalType: { name: '' },
        });
    }, []);

    const getContext = useCallback(() => {
        return {
            modalContext,
            isVisible,
            setIsVisible,
            setContext,
            resetContext,
        };
    }, [modalContext, isVisible]);

    return (
        <ModalContext.Provider value={getContext()}>
            {children}
            <Modal />
        </ModalContext.Provider>
    );
};

export const useModalContext = () => useContext(ModalContext);

export default ModalProvider;
