import React from 'react';
import Title from './title/title';
import FirstName from './first-name/first-name';
import MiddleName from './middle-name/middle-name';
import LastName from './last-name/last-name';
import { inputValidations as validationData } from '../personal-information-fields-info';
import './name-section.scss';
import { useExperienceContent } from '@hooks/use-server-content';
import { IMG_COUNTRIES, EU_COUNTRIES } from '@constants';

const Name = (props) => {
    const {
        contentFragments,
        userData,
        handleChange,
        error,
        focus,
        setFocusToField,
        personalInfoLabels,
        countryCode,
    } = props;
    const inputValidations = validationData(personalInfoLabels);
    const isEu = EU_COUNTRIES.includes(countryCode);
    const isImg = IMG_COUNTRIES.includes(countryCode);
    return (
        <fieldset className="input-section-name" data-testid="name-section">
            <h3
                className="fmc-type--heading6 fmc-mb-5"
                data-testid="name-heading"
            >
                {contentFragments?.name}
            </h3>
            <div className={`input-section-container`}>
                {isEu || isImg ? (
                    <Title
                        title={userData.title}
                        handleChange={handleChange}
                        useExperienceContent={useExperienceContent}
                        countryCode={countryCode}
                        setFocusToField={setFocusToField}
                        error={error}
                        inputValidations={inputValidations}
                    />
                ) : (
                    ''
                )}

                <FirstName
                    label={personalInfoLabels?.firstNameField}
                    userData={userData.firstName}
                    handleChange={handleChange}
                    focus={focus}
                    setFocusToField={setFocusToField}
                    error={error}
                    inputValidations={inputValidations}
                />

                <MiddleName
                    label={personalInfoLabels?.middleNameField}
                    userData={userData.middleName}
                    handleChange={handleChange}
                    focus={focus}
                    setFocusToField={setFocusToField}
                    error={error}
                    inputValidations={inputValidations}
                />

                <LastName
                    label={personalInfoLabels?.lastNameField}
                    userData={userData.lastName}
                    handleChange={handleChange}
                    focus={focus}
                    setFocusToField={setFocusToField}
                    error={error}
                    inputValidations={inputValidations}
                />
                {/* second last name applicable for mexico alone */}
                <LastName
                    label={personalInfoLabels?.secondLastNameField}
                    userData={userData.secondLastName}
                    handleChange={handleChange}
                    focus={focus}
                    setFocusToField={setFocusToField}
                    error={error}
                    inputValidations={inputValidations}
                    isSecondLastName
                />
            </div>
        </fieldset>
    );
};

export default Name;
