import AuthenticationService from '../authentication-service/authentication-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '../http-service/http-service';
import httpAdapter from 'axios/lib/adapters/http';
import { HttpsProxyAgent } from 'https-proxy-agent';
import Axios from 'axios';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export default class DeleteAccountService {
    public redirectToFMA = () => {
        const authenticationService = new AuthenticationService();
        console.error(`User Timeout`);
        authenticationService.login();
    };

    public async delete(): Promise<any> {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.DELETE_ACCOUNT;

        return HttpService.delete<any>(`${url}`, {
            headers: HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
            adapter: httpAdapter,
            proxy: false,
            httpAgent: proxyAgent,
        })
            .then((response) => {
                return response;
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    this.redirectToFMA();
                }
                return e.response;
            });
    }
}
