import AppConfigurationService from './app-configuration-service/app-configuration-service';
import LoyaltyPointsService from './loyalty-points-service/loyalty-points-service';
import HttpService from './http-service/http-service';
import { OsbDealerDetailsServiceClient } from '@services/eu-map-service-dependencies/dealer-details-dsl-service/dealer-details-dsl-service-client';

const ServiceHandler = {
    AppConfigurationService: new AppConfigurationService(),
    LoyaltyPointsService: new LoyaltyPointsService(HttpService),
    OsbDslDealerDetailsService: new OsbDealerDetailsServiceClient(),
};

export default ServiceHandler;
