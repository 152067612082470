import { useContent } from '@hooks/use-server-content';

export interface DeleteAccountContent {
    returnButtonText: string;
    deleteAccountHeader: string;
    deleteAccountBodyText: string;
    deleteAccountButtonLabel: string;
    cancelButtonLabel: string;
    modalHeader: string;
    modalBodyText: string;
    modalPrimaryBtnLabel: string;
    modalCancelBtnLabel: string;
    legalCopy: string;
    deleteAccountSuccessTitle: string;
    deleteAccountErrorTitle: string;
    deleteAccountDuplicateTitle: string;
    deleteAccountSuccessDescription: string;
    deleteAccountErrorDescription: string;
    deleteAccountDuplicateDescription: string;
    deleteAccountSuccessIcon: string;
    deleteAccountErrorIcon: string;
    deleteAccountDuplicateIcon: string;
}

export const useDeleteAccountContent = (): DeleteAccountContent | null => {
    const [content, getValueByName] = useContent('common', 'delete-account');

    const contentFragment: any = {};

    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    return contentFragment as DeleteAccountContent;
};
