import { ServerSideService } from '@services/server-side-service/server-side-service';

class RandomNumberUtil {
    public static getRandomNumber = () => {
        let crypto: Crypto;
        const serverSideService = new ServerSideService();

        if (serverSideService.isClientSide()) {
            crypto = window.crypto;
        } else {
            crypto = global.crypto;
        }

        const randomBuffer = new Uint32Array(1);

        crypto.getRandomValues(randomBuffer);

        return randomBuffer[0] / (0xffffffff + 1);
    };
}
export default RandomNumberUtil;
