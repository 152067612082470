import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '@services/http-service/http-service';

export interface WindowStickerResponse {
    windowStickerUrl: string;
}

export default class WindowStickerService {
    private httpService: any;
    private appConfig = new AppConfigurationService();
    private dslUrl = this.appConfig.getAppConfiguration().dslUrl
        ? this.appConfig.getAppConfiguration().dslUrl
        : DSL_BASE_URL;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getWindowSticker(
        vin: string,
        paCode: string,
        isCanada?: boolean
    ): Promise<WindowStickerResponse> {
        const url = this.dslUrl + DSL_API_PATH.WINDOW_STICKER;

        const response = await this.httpService.get(url, false, {
            headers: {
                vin: vin,
                'dealer-pa-code': paCode,
            },
            params: { isCanada: isCanada },
        });

        if (response.status === 200) {
            return response.data;
        }

        return response.status;
    }
}
