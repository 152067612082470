import HttpService from '@services/http-service/http-service';
import httpAdapter from 'axios/lib/adapters/http';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import { HttpsProxyAgent } from 'https-proxy-agent';
import { AxiosResponse } from 'axios';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export interface BingDealerResponse {
    disambiguation: boolean;
    dealerResultList: BingDealerInfo[];
}

export interface BingDealerInfo {
    dealerId: string;
    unEncryptedDlrpa: string;
    unEncryptedDealerId: string;
    streetAddress: string;
    brandcode: string;
    dealerName: string;
    city: string;
    country: string;
    cupid: string;
    detailpage: string;
    directionpage: string;
    distkm: string;
    distmiles: string;
    dlrpa: string;
    phone: string;
    state: string;
    url: string;
    zip: string;
    latitude: string;
    longitude: string;
    errorMessage: string;
    smarttprovider: string;
    smarttsubset: string;
    qlCode: string;
    ownerAdvAwdsMember: boolean;
    creditMember: boolean;
}

export default class BingService {
    private httpService: any;
    private url = DSL_BASE_URL + DSL_API_PATH.BING_DEALERS;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getDealerInfoById(
        dealerId: string
    ): Promise<BingDealerInfo[]> {
        const client = 'myford';
        const ovtype = 2;
        const count = 1;
        const isNum = /^\d+$/.test(dealerId);

        const params = {
            client,
            f_cupid: dealerId,
            ovtype,
            count,
            f_dlrpa: '',
        };

        if (isNum) {
            params.f_cupid = '';
            params.f_dlrpa = Buffer.from(dealerId).toString('base64'); // encodeBase64;
        }

        if (params.f_dlrpa === '') delete params.f_dlrpa;

        return this.httpService
            .get(this.url, false, {
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
                params: params,
            })
            .then((response: AxiosResponse<BingDealerResponse>) => {
                if (response?.data?.dealerResultList) {
                    return response.data.dealerResultList;
                }
                return null;
            });
    }
}
