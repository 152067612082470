import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '../http-service/http-service';
import { HttpsProxyAgent } from 'https-proxy-agent';
import httpAdapter from 'axios/lib/adapters/http';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';

interface ConsumerIdResponse {
    consumer: {
        id: string;
        guidCountryCode: string;
    };
}

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export default class ConsumerIdService {
    public async getConsumerId(): Promise<ConsumerIdResponse | void> {
        const appConfig = new AppConfigurationService();

        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;

        const url = dslUrl + DSL_API_PATH.CONSUMER_ID;

        return HttpService.get<any>(
            `${url}?countryCode=${appConfig.current3LetterCountryCapitalizedCode}`,
            true,
            {
                headers: HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
            }
        )
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                return e.message;
            });
    }
}
