import React, {
    KeyboardEvent,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { KEYBOARD_KEYS } from '@constants';
import CarouselIndicator, {
    Direction,
} from '@sections/account-portal/components/vehicle-carousel/carousel-indicator/carousel-indicator';
import MobileCarouselIndicators from '@views/vehicle-order-tracking-status-view/components/vehicle-image-carousel/mobile-carousel-indicators/mobile-carousel-indicators';
import { useWindowSize, WindowSize } from '@hooks/use-window-size';
import {
    SwipePoint,
    useSwipe,
} from '@sections/account-portal/components/vehicle-carousel/hooks/use-swipe';

import './vehicle-image-carousel.scss';

interface VehicleImageCarouselProps {
    images: string[];
}

const VehicleImageCarousel = (
    props: VehicleImageCarouselProps
): JSX.Element => {
    const imageRef = useRef<HTMLLIElement>(null);
    const size: WindowSize = useWindowSize();
    const [cardsPositionX, setCardsPositionX] = useState<number>(0);
    const [swipeOffsetX, setSwipeOffsetX] = useState<number>(0);
    const [swipeStartX, setSwipeStartX] = useState<number | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(size.width < 992);
    const [workingIndex, setWorkingIndex] = useState<number>(0);

    const handleSwipeStart = useCallback(
        (swipePoint: SwipePoint): void => {
            setSwipeStartX(swipePoint.x);
        },
        [setSwipeStartX]
    );

    const handleSwipeMove = useCallback(
        (swipePoint: SwipePoint) => {
            if (swipeStartX) {
                setSwipeOffsetX(swipePoint.x - swipeStartX);
            }
        },
        [swipeStartX, setSwipeOffsetX]
    );

    const handleSwipeEnd = useCallback(() => {
        let closestCardIndex = 0;
        let closestCardX = 0;
        for (
            let cardIndex = 0;
            cardIndex <= props.images.length - 1;
            cardIndex++
        ) {
            const cardPosX =
                cardIndex *
                (imageRef.current?.getBoundingClientRect().width || 0);
            if (
                Math.abs(cardsPositionX + cardPosX + swipeOffsetX) <
                Math.abs(cardsPositionX + closestCardX + swipeOffsetX)
            ) {
                closestCardIndex = cardIndex;
                closestCardX = cardPosX;
            }
        }
        setWorkingIndex(closestCardIndex);
        setSwipeStartX(null);
        setSwipeOffsetX(0);
    }, [
        imageRef,
        swipeOffsetX,
        setSwipeStartX,
        setWorkingIndex,
        cardsPositionX,
    ]);

    const carouselTransition = {
        left: cardsPositionX + swipeOffsetX,
        transition: 'left 400ms ease',
        willChange: 'left',
    };

    const carouselContainerRef = useSwipe(
        isMobile,
        handleSwipeStart,
        handleSwipeMove,
        handleSwipeEnd
    );

    const moveToPreviousImage = (): void => {
        if (workingIndex > 0) {
            setWorkingIndex(workingIndex - 1);
        }
    };

    const moveToNextImage = (): void => {
        if (workingIndex < props.images.length - 1) {
            setWorkingIndex(workingIndex + 1);
        }
    };

    const updateCardPositions = useCallback(() => {
        setCardsPositionX(
            -workingIndex * imageRef.current?.getBoundingClientRect().width
        );
    }, [imageRef, workingIndex, setCardsPositionX]);

    const tabNavigation = (e: KeyboardEvent): void => {
        if (e.shiftKey && e.key === KEYBOARD_KEYS.TAB) {
            moveToPreviousImage();
        } else if (e.key === KEYBOARD_KEYS.TAB) {
            moveToNextImage();
        }
    };

    useLayoutEffect(() => {
        updateCardPositions();
    }, [workingIndex, isMobile, updateCardPositions]);

    useEffect(() => {
        if (size.width < 992 && !isMobile) {
            setIsMobile(true);
        } else if (size.width >= 992 && isMobile) {
            setIsMobile(false);
        }
    }, [size.width]);

    return (
        <div className="fmc-carousel-container">
            <div
                ref={carouselContainerRef}
                className="carousel js-fmc-carousel-container"
                data-testid="carousel"
            >
                <ul
                    className="fmc-carousel fmc-cards carousel__image-list"
                    style={
                        imageRef ? carouselTransition : { left: cardsPositionX }
                    }
                >
                    {props.images.map((image, index) => {
                        return (
                            <li
                                id={`slide__${index}`}
                                className="carousel__slide"
                                key={`carousel__image-list--item-${index}`}
                                tabIndex={!isMobile ? -1 : 0}
                                ref={imageRef}
                                onKeyDown={tabNavigation}
                            >
                                <img
                                    src={image}
                                    alt={`Vehicle Image ${index + 1}`}
                                    className="vehicle-image"
                                />
                            </li>
                        );
                    })}
                </ul>
                {isMobile ? (
                    <MobileCarouselIndicators
                        workingIndex={workingIndex}
                        items={props.images}
                    />
                ) : (
                    <>
                        <CarouselIndicator
                            backgroundColor="#010203"
                            caretColor="white"
                            direction={Direction.LEFT}
                            onClick={moveToPreviousImage}
                            disabled={workingIndex === 0}
                            fromCvot
                        />
                        <CarouselIndicator
                            backgroundColor="#010203"
                            caretColor="white"
                            direction={Direction.RIGHT}
                            onClick={moveToNextImage}
                            disabled={workingIndex === 6}
                            fromCvot
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default VehicleImageCarousel;
