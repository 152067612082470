import { AppEnvironmentConfiguration } from '../app-configuration';

export const appConfigurationQa: AppEnvironmentConfiguration = {
    countryConfigurations: [
        {
            name: 'Canada',
            countryCode: 'ca',
            threeLetterCountryCode: 'can',
            appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/11f5ac9aea6e/launch-9c6de279ddb8-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwqa.globalaccount.ford.ca',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'frqa.globalaccount.ford.ca',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwqa.globalaccount.lincolncanada.com',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 3,
                },
                {
                    domain: 'frqa.globalaccount.lincolncanada.com',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 3,
                },
            ],
        },
        {
            name: 'USA',
            countryCode: 'us',
            threeLetterCountryCode: 'usa',
            appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-658deca8deb3-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwqa.globalaccount.ford.com',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'esqa.globalaccount.ford.com',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwqa.lincoln.globalaccount.ford.com',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 3,
                },
            ],
        },
        {
            name: 'Mothersite',
            countryCode: 'mothersite',
            threeLetterCountryCode: 'gbr',
            appId: '8f50cefa-b884-4a92-8be5-b5eef63806a5',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwqa2.brandeulb.ford.com',
                    root: '/content/guxeu/mothersite/en_gb/home/owners/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'United Kingdom',
            countryCode: 'gb',
            threeLetterCountryCode: 'gbr',
            appId: '8f50cefa-b884-4a92-8be5-b5eef63806a5',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://qa.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwqa.ford.co.uk',
                    root: '/owners/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwuat.en.globalaccount.ford.co.uk',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwqa-couk.brandeulb.ford.com',
                    root: '/owners/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 3,
                },
                {
                    domain: 'wwwqa.brandeulb.ford.com',
                    root: '/content/guxeu-demo/uk/en_gb/home/owners/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'demo',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 4,
                },
                {
                    domain: 'wwwint-couk.brandeulb.ford.com',
                    root: '/owners/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 5,
                },
                {
                    domain: 'wwwint.brandeulb.ford.com',
                    root: '/content/guxeu-demo/uk/en_gb/home/owners/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'demo',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 6,
                },
            ],
        },
        {
            name: 'Switzerland',
            countryCode: 'ch',
            threeLetterCountryCode: 'che',
            appId: '8f50cefa-b884-4a92-8be5-b5eef63806a5',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwuat.de.globalaccount.ford.ch',
                    root: '/',
                    language: 'German',
                    languageRegionCode: 'de-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwuat.fr.globalaccount.ford.ch',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwuat.it.globalaccount.ford.ch',
                    root: '/',
                    language: 'Italian',
                    languageRegionCode: 'it-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Germany',
            countryCode: 'de',
            threeLetterCountryCode: 'deu',
            appId: '8f50cefa-b884-4a92-8be5-b5eef63806a5',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwuat.de.globalaccount.ford.de',
                    root: '/',
                    language: 'German',
                    languageRegionCode: 'de-de',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa-de.brandeulb.ford.com',
                    root: '/owners/',
                    language: 'German',
                    languageRegionCode: 'de-de',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwint-de.brandeulb.ford.com',
                    root: '/owners/',
                    language: 'German',
                    languageRegionCode: 'de-de',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 3,
                },
            ],
        },
        {
            name: 'France',
            countryCode: 'fr',
            threeLetterCountryCode: 'fra',
            appId: '8f50cefa-b884-4a92-8be5-b5eef63806a5',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://qa.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwuat.fr.globalaccount.ford.fr',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-fr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwint-fr.brandeulb.ford.com',
                    root: '/owners/',
                    language: 'Français',
                    languageRegionCode: 'fr-fr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwqa-fr.brandeulb.ford.com',
                    root: '/owners/',
                    language: 'Français',
                    languageRegionCode: 'fr-fr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 3,
                },
            ],
        },
        {
            name: 'Italy',
            countryCode: 'it',
            threeLetterCountryCode: 'ita',
            appId: '8f50cefa-b884-4a92-8be5-b5eef63806a5',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwuat.it.globalaccount.ford.it',
                    root: '/',
                    language: 'Italian',
                    languageRegionCode: 'it-it',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Spain',
            countryCode: 'es',
            threeLetterCountryCode: 'esp',
            appId: '8f50cefa-b884-4a92-8be5-b5eef63806a5',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwuat.es.globalaccount.ford.es',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-es',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Norway',
            countryCode: 'no',
            threeLetterCountryCode: 'nor',
            appId: '8f50cefa-b884-4a92-8be5-b5eef63806a5',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwuat.no.globalaccount.ford.no',
                    root: '/',
                    language: 'Norwegian',
                    languageRegionCode: 'no-no',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Nederland',
            countryCode: 'nl',
            threeLetterCountryCode: 'nld',
            appId: '8f50cefa-b884-4a92-8be5-b5eef63806a5',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptions.fordlb.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwuat.nl.globalaccount.ford.nl',
                    root: '/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-nl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Finland',
            countryCode: 'fi',
            threeLetterCountryCode: 'fin',
            appId: '8f50cefa-b884-4a92-8be5-b5eef63806a5',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwuat.fi.globalaccount.ford.fi',
                    root: '/',
                    language: 'Finnish',
                    languageRegionCode: 'fi-fi',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Brazil',
            countryCode: 'br',
            threeLetterCountryCode: 'bra',
            appId: '',
            fmaUrl: '',
            fmaRegion: 'na',
            fmaFsUrl: '',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'wwwuat.pt.globalaccount.ford.com.br',
                    root: '/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-br',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
    ],
};
