import HttpService from '@services/http-service/http-service';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';

export interface MnvServiceData {
    dealerUrl: string;
    dealerName: string;
    recommendedOfferUrl: string;
    recommendedVehicleToken: RecommendedVehicleToken;
}

interface RecommendedVehicleToken {
    year: string;
    make: string;
    model: string;
    trimName: string;
    trimID: string;
    imageToken: string;
    vehicleType: string;
    color: string;
    displayName: string;
    engineType: string;
    vin: VinObject;
}

interface VinObject {
    value: string;
    vinManufacturerCode: string;
}

export default class MnvService {
    private httpService: any;
    private appConfig = new AppConfigurationService();
    private dslUrl = this.appConfig.getAppConfiguration().dslUrl
        ? this.appConfig.getAppConfiguration().dslUrl
        : DSL_BASE_URL;
    private readonly config: any;

    constructor(httpService: HttpService) {
        this.httpService = httpService;

        this.config = {
            headers:
                this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
        };
    }

    public async getMnvOffer(
        year: string,
        make: string,
        model: string,
        trim: string,
        zipCode: string
    ): Promise<MnvServiceData> {
        let response;

        const url = `${
            this.dslUrl + DSL_API_PATH.MNV_OFFER
        }?year=${year}&make=${make}&model=${model}&trim=${trim}&zipCode=${zipCode}`;

        try {
            response = await this.httpService.get(url, true, this.config);
        } catch (e) {
            return null;
        }
        return response?.data;
    }
}
