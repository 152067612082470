import React, { useEffect, useMemo, useState } from 'react';
import './active-vehicle-order-card.scss';
import { VehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import { SecondaryButton } from '@common/index';
import { VehicleAttributes } from '@models/vehicle-attributes';
import { ORDER_TYPE } from '@sections/account-portal/components/vehicle-carousel/vehicle-order-card/vehicle-order-card';
import { CX740_CARD, ORDER_STATUS_CODES } from '@constants';
import { findPathByAlias } from '@routes/routesList';
import { useAnalytics } from '@hooks/use-analytics';
import { useVehicleImage } from '@sections/account-portal/hooks/use-vehicle-image';

export interface ActiveVehicleOrderCardProps {
    vehicleCarouselContent: VehicleCarouselContent;
    isCurrentCard: boolean;
    order: OrderedVehiclesItem;
    appConfig: any;
}

const ActiveVehicleOrderCard = (props: ActiveVehicleOrderCardProps) => {
    const { vehicleCarouselContent, order, appConfig } = props;
    const [fireAnalytics] = useAnalytics();
    const imageUrl: string = order?.imageUrl || null;
    const vinForVehicleImageLookup: VehicleAttributes = {
        vin: order?.vin,
        year: Number(order.year),
        make: order.make,
        model: order.model,
    };
    const vehicleImage = useVehicleImage(
        vinForVehicleImageLookup,
        imageUrl,
        'undefined',
        appConfig.current3LetterCountryCapitalizedCode
    );
    const finalImage = useMemo<string>(() => vehicleImage, [vehicleImage]);
    const [orderStatusAlertText, setOrderStatusAlertText] =
        useState<string>('');
    const [statusBackgroundColor, setStatusBackgroundColor] =
        useState<string>(null);

    const isEu: boolean = appConfig.getAppConfiguration().fmaRegion === 'eu';
    const marketStatusCodes = ORDER_STATUS_CODES[isEu ? 'eu' : 'us'];
    const gray = '#e6e8ea';
    const green = '#e5f3e6';
    const hasCvotOrderNumber = order?.id?.length <= 4;

    const eComCardCtaHref: string =
        order?.model == CX740_CARD
            ? `${vehicleCarouselContent.eComVehicleCardCtaHrefECOMAlternate}?reservationId=${order?.id}`
            : `${vehicleCarouselContent.eComVehicleCardCtaHrefECOM}?reservationId=${order?.id}`;

    const chooseOrderStatusClass = (orderStatus: string) => {
        if (!orderStatus) return;

        const orderStatusClasses = {
            ordered: {
                matched:
                    order.orderType?.toUpperCase() === ORDER_TYPE.ORDER &&
                    (!order.vehicleStatusCode || !order.vin) &&
                    !order.readyToOrder,
                alertText: vehicleCarouselContent.eComVehicleCardStatusOrdered,
            },
            reservation: {
                matched:
                    order.orderType?.toUpperCase() === ORDER_TYPE.RESERVATION &&
                    !order.readyToOrder,
                alertText:
                    vehicleCarouselContent.eComVehicleCardStatusReservation,
            },
            placeOrder: {
                matched:
                    order.orderType?.toUpperCase() === ORDER_TYPE.RESERVATION &&
                    order.readyToOrder,
                alertText:
                    vehicleCarouselContent.eComVehicleCardStatusPlaceOrder,
            },
            inProduction: {
                matched: marketStatusCodes?.inProduction?.includes(orderStatus),
                alertText:
                    vehicleCarouselContent.eComVehicleCardStatusInProduction,
            },
            shipped: {
                matched: marketStatusCodes?.shipped?.includes(orderStatus),
                alertText: vehicleCarouselContent.eComVehicleCardStatusShipped,
            },
            purchaseCompleted: {
                matched:
                    marketStatusCodes?.purchaseCompleted?.includes(orderStatus),
                alertText:
                    vehicleCarouselContent.eComVehicleCardCompletedPurchase,
            },
            delivered: {
                matched: marketStatusCodes?.delivered?.includes(orderStatus),
                alertText: vehicleCarouselContent.eComVehicleCardDelivered,
            },
        };

        for (const key in orderStatusClasses) {
            const currentStatusClass: { matched: boolean; alertText: string } =
                orderStatusClasses[key];

            if (currentStatusClass.matched) {
                if (
                    ['inProduction', 'shipped', 'purchaseCompleted'].includes(
                        key
                    )
                )
                    setStatusBackgroundColor(green);

                if (['reservation', 'ordered', 'placeOrder'].includes(key))
                    setStatusBackgroundColor(gray);

                setOrderStatusAlertText(currentStatusClass.alertText);
            }
        }
    };

    useEffect(() => {
        chooseOrderStatusClass(order?.vehicleStatusCode || order?.status);
    }, [order?.vehicleStatusCode, order?.status]);

    return (
        <div
            className="active-vehicle-order-card__container"
            data-testid="active-vehicle-order-card"
        >
            <div
                className={`active-vehicle-order-card__media-area`}
                data-testid="active-vehicle-order-card-image"
            >
                {finalImage && (
                    <img src={finalImage} alt="" crossOrigin="anonymous" />
                )}
            </div>

            <div
                className="active-vehicle-order-card__content-area"
                data-testid="active-vehicle-order-card-content-area"
            >
                <hr className="active-vehicle-order-card__divider" />

                {order.status && (
                    <p
                        className="active-vehicle-order-card__status"
                        data-testid="active-vehicle-order-card-status"
                        style={{ backgroundColor: statusBackgroundColor }}
                    >
                        {orderStatusAlertText}
                    </p>
                )}

                <h3
                    className="active-vehicle-order-card__ymm"
                    data-testid="active-vehicle-order-card-ymm"
                >
                    {`${order.year} ${order.model}`}
                </h3>

                <p
                    className="active-vehicle-order-card__order-number"
                    data-testid="active-orders-card-order-number"
                >
                    {`${vehicleCarouselContent.orderLabelText}: ${order.id}`}
                </p>

                {order.vin && (
                    <p
                        className="active-vehicle-order-card__vin"
                        data-testid="active-orders-card-vin"
                    >
                        {`${vehicleCarouselContent.vinText}: ${order.vin}`}
                    </p>
                )}

                {vehicleCarouselContent.orderDetailsCtaText && (
                    <>
                        {hasCvotOrderNumber ? (
                            <SecondaryButton
                                internal
                                className="active-vehicle-order-card__order-details-cta"
                                aria-label={
                                    vehicleCarouselContent.orderDetailsAriaLabel
                                }
                                link={findPathByAlias(
                                    'VehicleOrderTrackingStatusView'
                                )}
                                state={{
                                    customerOrderNumber: order.id,
                                    vin: order.vin,
                                }}
                                onClick={() =>
                                    fireAnalytics(
                                        'vehicleOrderStatusOnclickEvent'
                                    )
                                }
                                dataTestId="active-orders-card-order-details-cta"
                            >
                                {vehicleCarouselContent.orderDetailsCtaText}
                            </SecondaryButton>
                        ) : (
                            <SecondaryButton
                                className="active-vehicle-order-card__order-details-cta"
                                aria-label={
                                    vehicleCarouselContent.orderDetailsAriaLabel
                                }
                                link={eComCardCtaHref}
                                onClick={() => {
                                    fireAnalytics('fvOrderDetailsOnclickEvent');
                                }}
                                dataTestId="active-orders-card-order-details-cta"
                            >
                                {vehicleCarouselContent.orderDetailsCtaText}
                            </SecondaryButton>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ActiveVehicleOrderCard;
