import React, { useEffect, useState } from 'react';

import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { ActivityIndicator } from '@/components/common/activity-indicator/activity-indicator';
import { SearchBar } from '@/components/sections';
import ErrorPage from '@/components/sections/profile-error/error-page';
import OrderHistoryComponent from './components/order-history-component/order-history-component';
import './order-history-view.scss';
import AuthenticationService from '@/services/authentication-service/authentication-service';
import { CacheService } from '@/services/cache-service/cache-service';
import ProfileService from '@/services/profile-service/profile-service';
import { Breadcrumbs } from '@/components/sections/breadcrumbs/breadcrumbs';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';
import { useOrderHistoryContent } from '@views/order-history-view/hooks/use-order-history-content';
import OmsService, {
    OmsOrderHistoryResponse,
} from '@services/oms-service/oms-service';
import { ProfileWithVehiclesResponse } from '@models/profile-with-vehicles';
import HttpService from '@services/http-service/http-service';
import {
    NotificationType,
    useNotificationContext,
} from '@/contexts/notificationContext';
import { Notification } from '@/components/sections/account-portal/components/notification-message/notification';
import { useAnalytics } from '@/hooks/use-analytics';
import { useOrderDetailsContent } from '../order-details-view/hooks/use-order-details-content';
import { useOrderStatusContent } from '../order-details-view/hooks/use-nvc-order-status-content';

const className = 'order-history';

const OrderHistoryView = () => {
    useAnalytics('orderHistory');
    const [showErrorPage, handleShowErrorPage] = useState(false);
    const { setNotificationContext } = useNotificationContext();
    const authenticationService = new AuthenticationService();
    const cacheService = new CacheService();
    const profileService = new ProfileService();
    const omsService = new OmsService(HttpService);
    const appConfig = new AppConfigurationService();
    const orderHistoryContent = useOrderHistoryContent();
    const [profileData, setProfileData] =
        useState<ProfileWithVehiclesResponse>(null);
    const [orderHistoryData, setOrderHistoryData] =
        useState<OmsOrderHistoryResponse>(null);
    const [notificationStatus, setNotificationStatus] =
        useState<NotificationType>(null);
    const [ordersFinishedLoading, setOrdersFinishedLoading] = useState(false);
    const [pageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [isShowMore, setShowMore] = useState(false);
    const orderDetailsContent = useOrderDetailsContent();
    const orderStatusContent = useOrderStatusContent();

    const checkAuthenticationStatus = () => {
        cacheService.evictProfileServiceCache();
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    profileService
                        .requestLite()
                        .then((profile) => {
                            if (profile) {
                                setProfileData(profile);
                            }
                        })
                        .catch(() => {
                            cacheService.evictProfileServiceCache();
                            handleShowErrorPage(true);
                        });
                } else {
                    cacheService.evictProfileServiceCache();
                    authenticationService.login();
                }
            });
    };

    useEffect(() => {
        if (profileData?.profile?.email) {
            orderHistoryService(pageNumber);
        }
    }, [profileData]);

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
        checkAuthenticationStatus();
        new ScrollUtil().scrollPageToTop();
    }, []);

    useEffect(() => {
        if (!orderHistoryData && ordersFinishedLoading == true) {
            setNotificationStatus(NotificationType.Error);
        }
    }, [ordersFinishedLoading]);

    const orderHistoryService = (pageNumber) => {
        omsService
            .getOrderHistory(
                profileData.profile.email,
                pageNumber.toString(),
                pageSize.toString()
            )
            .then((orderHistory) => {
                if (orderHistoryData) {
                    orderHistory?.orders.forEach((order) => {
                        orderHistoryData.orders.push(order);
                    });
                    orderHistoryData.isLastPage = orderHistory.isLastPage;
                    orderHistoryData.pageNumber = orderHistory.pageNumber;
                    setOrderHistoryData(orderHistoryData);
                } else {
                    setOrderHistoryData(orderHistory);
                }
                setShowMore(orderHistory?.isLastPage == 'N');
            })
            .catch(() => {
                handleShowErrorPage(true);
            })
            .finally(() => {
                setOrdersFinishedLoading(true);
            });
    };

    const onChangeShowMore = () => {
        const newPageNumber = pageNumber + 1;
        setPageNumber(newPageNumber);
        setOrdersFinishedLoading(false);
        orderHistoryService(newPageNumber);
    };

    const renderContent = () => {
        if (showErrorPage) {
            return <ErrorPage />;
        } else if (orderHistoryContent && profileData) {
            return (
                <div
                    className={`${className}-container`}
                    data-test-id={`${className}-view`}
                >
                    <Breadcrumbs
                        currentPage={orderHistoryContent.breadcrumbTitle}
                        crumbSeparator={appConfig.brand === 'ford' ? '/' : '>'}
                    ></Breadcrumbs>
                    {notificationStatus && (
                        <div
                            className={`order-history-view-notification-container`}
                            data-testid={`order-history-view-notification-container`}
                        >
                            <Notification
                                status={notificationStatus}
                                mainCopy={
                                    orderHistoryContent.errorNotificationMessage
                                }
                                hideBorder={true}
                                hideAfterTimeout={false}
                                onHideNotification={() => {
                                    setNotificationStatus(null);
                                }}
                            />
                        </div>
                    )}
                    <OrderHistoryComponent
                        orderHistoryContent={orderHistoryContent}
                        orderDetailsContent={orderDetailsContent}
                        orderStatusContent={orderStatusContent}
                        orderHistoryData={orderHistoryData}
                        ordersFinishedLoading={ordersFinishedLoading}
                        isShowMore={isShowMore}
                        onShowMore={onChangeShowMore}
                    />
                    <hr className="hr-line" aria-hidden={true} />
                    <SearchBar />
                </div>
            );
        } else {
            return <ActivityIndicator className={'full-height'} />;
        }
    };

    return <>{renderContent()}</>;
};

export default OrderHistoryView;
