import { ContentResponse } from './content-service.interface';

export const getNameFromContent = (
    content: ContentResponse,
    name: string | undefined
) => {
    if (!name) {
        return '';
    }
    const contentName = content.elements.find(
        (ele) => ele.name!.toLowerCase() === name!.toLowerCase()
    );
    if (contentName != null) return contentName.value;
    else return '';
};
