import React from 'react';

import './fds-chevron.scss';

type Direction = 'up' | 'down' | 'left' | 'right';
type ChevronType = 'filled' | 'unfilled';

interface Props {
    direction: Direction;
    type: ChevronType;
    className?: string;
}

export const FdsChevron = (props: Props) => {
    return (
        <div className="fds-chevron-wrap" aria-hidden>
            <div
                className={`${props.className || ''} fds-chevron ${
                    props.direction
                }`}
                data-testid="fds-chevron"
            >
                {props.type === 'filled' && <span className="fds-dot"></span>}
                <span className={`fds-arrow ${props.type}`}></span>
            </div>
        </div>
    );
};
