import React, { useEffect, useState } from 'react';
import Slide from '@sections/account-portal/components/vehicle-carousel/slides/slide';
import SecondaryButton from '@common/secondary-button/secondary-button';
import {
    setLocalStorageWithSelectedVIN,
    VEHICLE_CARD_STORAGE_KEY,
} from '@constants';
import { VehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import { SwipePoint } from '@sections/account-portal/components/vehicle-carousel/hooks/use-swipe';
import { CustomerBooking } from '@services/gcct-service/gcct-service';
import ProfileService from '@services/profile-service/profile-service';
import { useAnalytics } from '@hooks/use-analytics';
import { useModalContext } from '@contexts/modalContext';
import HttpService from '@services/http-service/http-service';
import { CarouselSlideContent } from '@sections/account-portal/components/vehicle-carousel/slides/hooks/use-carousel-slide-content';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import DeletedVehicleService from '@services/deleted-vehicle-service/deleted-vehicle-service';
import { findPathByAlias } from '@/routes/routesList';

export interface VehicleSlideProps {
    data: any;
    updateVehiclesData?: (cachable: boolean) => void;
    identification?: number;
    carouselContentFragment?: VehicleCarouselContent;
    onRemove?: () => void;
    onTouchStart?: (swipePoint: SwipePoint) => void;
    onTouchEnd?: () => void;
    onTabNavigation?: (index: number) => void;
    booking: CustomerBooking;
    slideContent: CarouselSlideContent;
    deliveredVehicles: OrderedVehiclesItem[];
}
const VehicleSlide = ({
    data,
    updateVehiclesData,
    identification,
    carouselContentFragment,
    slideContent,
    onRemove,
    booking,
    deliveredVehicles,
}: VehicleSlideProps) => {
    const [fireAnalytics] = useAnalytics();
    const { resetContext } = useModalContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showCta, setShowCta] = useState<boolean>(false);
    const profileService = new ProfileService();
    const httpService = HttpService;
    const deletedVehicleService = new DeletedVehicleService(httpService);

    const onCardRemove = async () => {
        const vinStorage = sessionStorage.getItem('isEditId');
        setIsLoading(true);
        fireAnalytics(['removeVehicleOnclickEvent']);
        if (vinStorage) {
            if (
                deliveredVehicles?.some((vehicle) => vehicle.vin === vinStorage)
            ) {
                await deletedVehicleService.addDeletedVehicleToDB(
                    vinStorage,
                    'Delivered'
                );
            }

            const response = await profileService.deleteVehicle(vinStorage);
            if (response.status == 200 || response.status == '200') {
                if (updateVehiclesData) {
                    updateVehiclesData(false);
                }
                onRemove();
                resetContext();
            }
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 2500);
    };
    const onModalClose = () => {
        resetContext();

        setTimeout(() => {
            const deleteButton = document.getElementById(
                `delete-vehicle-btn-${identification}`
            );
            deleteButton?.focus();
        }, 1000);
    };

    useEffect(() => {
        if (
            carouselContentFragment?.connectedServicesLabel &&
            carouselContentFragment?.activeLabel
        ) {
            setShowCta(true);
        }
        localStorage.removeItem(VEHICLE_CARD_STORAGE_KEY);
    }, []);

    return (
        <>
            {slideContent && (
                <Slide
                    data={data}
                    carouselContentFragment={carouselContentFragment}
                    isOrderCard={false}
                    slideContent={slideContent}
                    secondaryButtonLink={data.ctaHref}
                    isLoading={isLoading}
                    onCardRemove={onCardRemove}
                    onModalClose={onModalClose}
                    booking={booking}
                    showCta={showCta}
                >
                    {
                        <div className="fmc-cards__menu-links">
                            {carouselContentFragment?.connectedServicesLabel &&
                            carouselContentFragment?.activeLabel ? (
                                <div
                                    className="fmc-cards__menu-link"
                                    data-testid="card-menu-link-1"
                                >
                                    <SecondaryButton
                                        internal
                                        link={`${findPathByAlias(
                                            'ConnectedServicesView'
                                        )}?vin=${data.description}`}
                                        aria-label={
                                            carouselContentFragment.connectedServicesLabel
                                        }
                                        className="fmc-type--body2"
                                        isFromCardMenu
                                        onClick={() => {
                                            setLocalStorageWithSelectedVIN(
                                                data.description
                                            );
                                            fireAnalytics(
                                                carouselContentFragment.connectedServicesLabel
                                            );
                                        }}
                                    >
                                        <span className="span-styling">
                                            {
                                                carouselContentFragment.connectedServicesLabel
                                            }
                                            <div className="span-second-row">
                                                {
                                                    carouselContentFragment.activeLabel
                                                }
                                            </div>
                                        </span>

                                        <img
                                            src={
                                                './icons/smart-tile-caret--right.svg'
                                            }
                                        />
                                    </SecondaryButton>
                                </div>
                            ) : null}
                        </div>
                    }
                </Slide>
            )}
        </>
    );
};

export default VehicleSlide;
