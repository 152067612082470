import { useContent } from '@hooks/use-server-content';

export interface NotificationBannerData {
    notificationSuccessText: string;
    notificationFailureText: string;
    specialAlertBodyCopy: string;
    specialAlertCtaHref: string;
    specialAlertCtaLabelText: string;
    specialAlertCtaAriaLabel: string;
    notificationSubscribeSuccessText: string;
    notificationUnsubscribeSuccessText: string;
}

export const useNotificationBannerContent =
    (): NotificationBannerData | null => {
        const [content, getValueByName] = useContent(
            'common',
            'notification-banner'
        );
        const contentFragment: any = {};
        if (content) {
            content?.elements.forEach((element) => {
                contentFragment[`${element.name}`] = getValueByName(
                    `${element.name}`
                );
            });
        }

        if (!Object.keys(contentFragment).length) return null;

        return contentFragment as NotificationBannerData;
    };
