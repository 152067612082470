import React, { ReactElement } from 'react';

export interface CloseButtonProps {
    onClick: () => void;
    ref: any;
    id: string;
}

const CloseButton = (props: CloseButtonProps): ReactElement => {
    return (
        <button
            id={props.id}
            className="close-button"
            data-testid="close-button"
            aria-label="Close button"
            onClick={props.onClick}
            ref={props.ref}
        >
            <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="14.0001"
                    y="0.0606689"
                    width="1.5"
                    height="19.6294"
                    transform="rotate(45 14.0001 0.0606689)"
                    fill="black"
                />
                <rect
                    y="1.06067"
                    width="1.5"
                    height="19.6294"
                    transform="rotate(-45 0 1.06067)"
                    fill="black"
                />
            </svg>
        </button>
    );
};

export default CloseButton;
