import React from 'react';
import './activity-indicator.scss';
interface ActivityIndicatorProps {
    className?: string;
}
export const ActivityIndicator = (props: ActivityIndicatorProps) => {
    return (
        <div
            className={`fds-activity-indicator fds-activity-indicator--circular fds-color__bg--light fds-flex ${props.className}`}
            data-testid="loading-spinner"
        >
            <div
                className="fds-activity-indicator__progress-wrapper fds-activity-indicator__center"
                role="progressbar"
            >
                <div className="fds-activity-indicator__progress-line">
                    <svg
                        className="fds-activity-indicator__progress-line_svg"
                        viewBox="25 25 50 50"
                    >
                        <circle
                            className="fds-activity-indicator__progress-line_line--indeterminate"
                            cx="50"
                            cy="50"
                            r="23"
                            fill="none"
                            strokeMiterlimit="10"
                        />
                    </svg>
                </div>
            </div>
            <div
                className="fds-activity-indicator__notification"
                aria-live="polite"
                aria-relevant="additions"
            ></div>
        </div>
    );
};
