import HttpService from '@services/http-service/http-service';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';

interface SubscriptionsServiceData {
    paidSubscriptions: number;
    paidSubscriptionsExpiring: number;
    freeSubscriptions: number;
    freeSubscriptionsExpiring: number;
}

export default class SubscriptionsService {
    private httpService: any;
    private appConfig = new AppConfigurationService();
    private dslUrl = this.appConfig.getAppConfiguration().dslUrl
        ? this.appConfig.getAppConfiguration().dslUrl
        : DSL_BASE_URL;
    private subscriptionsUrl: string = this.dslUrl + DSL_API_PATH.SUBSCRIPTIONS;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getSubscriptions(
        vin: string
    ): Promise<SubscriptionsServiceData> {
        const config = {
            headers: {
                ...this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                vin,
            },
        };

        const url = `${this.subscriptionsUrl}?language=${this.appConfig.currentLanguageRegionCapitalizedCode}&countryCode=${this.appConfig.current3LetterCountryCapitalizedCode}`;

        let response;
        try {
            response = await this.httpService.get(url, true, config);
        } catch (e) {
            return undefined;
        }
        return response?.data;
    }
}
