import { useEffect, useState } from 'react';
import { EU_COUNTRIES } from '@constants';
import { useExperienceContent } from '@hooks/use-server-content';
import { DynamicDropdownContent } from '@common/dynamic-dropdown/dynamic-dropdown';

export const useDynamicDropdownContent = (
    countryCode
): DynamicDropdownContent | null => {
    const [content, setContent] = useState<DynamicDropdownContent | null>(null);
    const [fragmentArgs, setFragmentArgs] = useState({
        category: '',
        name: '',
        componentName: '',
        brandOverride: '',
    });
    const isEU = EU_COUNTRIES.includes(countryCode);

    useEffect(() => {
        if (isEU) {
            setFragmentArgs({
                category: 'common',
                name: 'phone-number-dropdown',
                componentName: 'dynamicphonenumberdr',
                brandOverride: '',
            });
        }
    }, [isEU]);

    const [experienceFragmentDynamicDropdown] =
        useExperienceContent<DynamicDropdownContent>(
            fragmentArgs.category,
            fragmentArgs.name,
            fragmentArgs.componentName,
            fragmentArgs.brandOverride
        );

    useEffect(() => {
        if (experienceFragmentDynamicDropdown) {
            setContent(experienceFragmentDynamicDropdown);
        }
    }, [experienceFragmentDynamicDropdown]);

    return content;
};
