import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import SmartTile, { SmartTileProps } from '../smart-tile/smart-tile';
import PreferredDealerModal from './preferred-dealer-modal';
import { AddVehicleContent } from '@sections/add-vehicle/hooks/use-add-vehicle';
import { VehicleAttributes } from '@models/vehicle-attributes';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { PreferredDealerModalContentProps } from '@smart-tiles/smart-tile-preferred-dealer/hook/use-preferred-dealer-modal-content';
import './smart-tile-preferred-dealer.scss';
import { useSideDrawerContext } from '@contexts/sideDrawerContext';
import PreferredDealerDrawer from '@views/preferred-dealer-view/components/preferred-dealer-drawer/preferred-dealer-drawer';

interface Props {
    hasVehicles?: boolean;
    analyticEventName: string;
    ctaTitle: string;
    useAddVehicleContent: () => void;
    setIsLoading?: any;
    addVehicleContent: AddVehicleContent;
    preferredDealerModalContent: PreferredDealerModalContentProps;
    garageVehicles: VehicleAttributes[];
    fromPreferredDealerParam: boolean;
    openAddVehicleModal: boolean;
    handleAddVehicleModal?: Dispatch<SetStateAction<boolean>>;
}

const SmartTilePreferredDealer = (props: Props & SmartTileProps) => {
    const {
        dynamicTitle,
        staticCTATitle,
        dynamicCTATitle,
        hasVehicles,
        addVehicleContent,
        analyticEventName,
        iconPath,
        staticSubCopy,
        setIsLoading,
        garageVehicles,
    } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { setDrawerContext } = useSideDrawerContext();
    const appConfig = new AppConfigurationService();
    const isFord = appConfig.brand === 'ford';
    const isNa: boolean = appConfig.isRegionNA();

    const displayModal = () => {
        if (isFord && isNa)
            setDrawerContext({
                drawerType: {
                    name: 'preferred-dealer-drawer',
                    children: <PreferredDealerDrawer />,
                },
            });

        if (!isFord || !isNa) setIsModalVisible(true);
    };
    const closeModal = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (props.fromPreferredDealerParam && props.currentVehiclesCount > 0) {
            displayModal();
        }
    }, [props.fromPreferredDealerParam]);

    return (
        <SmartTile
            {...props}
            className="smart-tile-preferred-dealer"
            header={dynamicTitle || ''}
            ctaTitle={hasVehicles ? staticCTATitle : dynamicCTATitle}
            displayModal={() => {
                displayModal();
            }}
            analyticEventTitle="Preferred Dealer"
            analyticEventName={analyticEventName}
            displayAddVehicleModal={!hasVehicles}
            isModalOpen={isModalVisible}
            addVehicleContent={addVehicleContent}
            setIsLoading={setIsLoading}
            setCurrentVehiclesCount={props.setCurrentVehiclesCount}
            vehiclesOnOrder={props.vehiclesOnOrder}
            garageVehicles={garageVehicles}
            openAddVehicleModal={props.openAddVehicleModal}
            handleAddVehicleModal={props.handleAddVehicleModal}
            setIsNewVehicleAdded={props.setIsNewVehicleAdded}
        >
            <img
                className="icon"
                alt=""
                src={process.env.REACT_APP_AEM_BASE_URL + iconPath}
            />
            {isFord ? (
                <div className="smart-tile__content--container">
                    <div className="smart-tile__text--container">
                        <h6 className={`fmc-type--subtitle1`}>
                            {dynamicTitle || ''}
                        </h6>
                        <h6 className={`fmc-type--heading6`}>
                            {dynamicTitle || ''}
                        </h6>
                    </div>
                    <img src={'./icons/smart-tile-caret--right.svg'} alt="" />
                    <PreferredDealerModal
                        isVisible={isModalVisible}
                        onClose={closeModal}
                        {...props}
                        preferredDealerModalContent={
                            props.preferredDealerModalContent
                        }
                    />
                </div>
            ) : (
                <>
                    <h6 className={`fmc-type--subtitle1`}>
                        {dynamicTitle || ''}
                    </h6>
                    <h6 className={`fmc-type--heading6`}>
                        {dynamicTitle || ''}
                    </h6>
                    <div
                        className="fmc-type--body1"
                        data-testid="preferred-dealer-tile-static-subcopy"
                    >
                        {staticSubCopy}
                    </div>
                    <PreferredDealerModal
                        isVisible={isModalVisible}
                        onClose={closeModal}
                        {...props}
                        preferredDealerModalContent={
                            props.preferredDealerModalContent
                        }
                    />
                </>
            )}
        </SmartTile>
    );
};

export default SmartTilePreferredDealer;
