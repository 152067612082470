import React, { useEffect, useState } from 'react';
import { FdsChevron } from '@common/chevron/fds-chevron';
import { useDisclosuresLinkContent } from './hook/use-disclosures-link-content';
import './disclosures-link.scss';

export const DisclosuresLink = () => {
    const disclosuresLinkContent = useDisclosuresLinkContent();
    const [panelIsOpen, setPanelIsOpen] = useState<boolean>(true);

    useEffect(() => {
        setPanelIsOpen(disclosuresLinkContent?.defaultStateOpen);
    }, [disclosuresLinkContent?.defaultStateOpen]);

    return (
        <>
            {disclosuresLinkContent && !disclosuresLinkContent.hide && (
                <div className="disclosures-button-section">
                    <div className="disclosures-container">
                        <div className="disclosures-button">
                            <button
                                className="disclosures-button-link"
                                onClick={() => {
                                    setPanelIsOpen(!panelIsOpen);
                                }}
                                tabIndex={0}
                            >
                                {disclosuresLinkContent.ctaLinkText}
                                <FdsChevron
                                    type="unfilled"
                                    direction={panelIsOpen ? 'up' : 'down'}
                                />
                            </button>
                            {panelIsOpen && (
                                <div className="disclosure-content">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: disclosuresLinkContent.content,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
