import React, { JSX } from 'react';
import { LinkTargetValues } from '@constants';
import SecondaryButton from '@common/secondary-button/secondary-button';

import './window-sticker-button.scss';

export interface WindowStickerProps {
    windowStickerButtonLabelText: string;
    windowStickerUrl: string;
    windowStickerAriaLabel: string;
    windowStickerTarget: LinkTargetValues;
    isLincoln: boolean;
    onClick?: () => void;
}

const WindowStickerButton = (props: WindowStickerProps): JSX.Element => {
    const {
        windowStickerButtonLabelText,
        windowStickerUrl,
        windowStickerAriaLabel,
        windowStickerTarget,
        isLincoln,
    }: WindowStickerProps = props;

    return (
        <>
            {windowStickerUrl && windowStickerButtonLabelText && (
                <div
                    className="window-sticker-button__container"
                    data-testid="window-sticker-button-container"
                >
                    <SecondaryButton
                        className="window-sticker-button"
                        dataTestId="window-sticker-button"
                        link={windowStickerUrl}
                        linkTarget={windowStickerTarget}
                        aria-label={windowStickerAriaLabel}
                        onClick={props.onClick}
                    >
                        <span>{windowStickerButtonLabelText}</span>
                        {isLincoln && (
                            <div className="lincoln-underline-button-overlay"></div>
                        )}
                    </SecondaryButton>
                </div>
            )}
        </>
    );
};

export default WindowStickerButton;
