import HttpService from '@services/http-service/http-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';

interface Dealer {
    dealerCode: string;
    email: string;
    mapURL: string;
    address: {
        street1: string;
        street2: string;
        street3: string;
        city: string;
        state: string;
        postalCode: string;
        country: string;
    };
    dealerPACode: string;
    phone: string;
    routeURL: string;
    url: string;
    name: string;
}

interface DealerInfo {
    orderingDealer: Dealer;
    primaryDealer: Dealer;
    stockingDealer: Dealer;
    sellingDealer: Dealer;
}

interface OrderInfo {
    transitDays: string;
    shippedDate: string;
    orderNumber: string;
    lastCompletedStatus: {
        status: string;
        statusDateTime: string;
        eta: {
            etaEndDate: string;
            etaReasonCode: string;
            etaStartDate: string;
        };
        primaryStatus: string;
    };
    currentBuildWeek: string;
    releaseDate: string;
    groupOrderType: string;
    market: string;
    producedDate: string;
    finalDeliveredDate: string;
    invoiceDate: string;
    customerName: string;
    productionVerificationDate: string;
    currentBuildDate: string;
    receiptDate: string;
    allocationRegion: string;
}

export interface VehicleInfo {
    bodyCode: string;
    configToken: string;
    imageToken: string;
    year: string;
    trim: string;
    model: string;
    vehicleLineDescription: string;
    make: string;
    vin: string;
    partsInfo: PartsInfo;
}

export interface GenericKeyValuePair {
    name: string;
    value: string;
}

interface PartsInfo {
    partClass: PartClass[];
}

export interface Part {
    description: string;
    wersCode: string;
    attribute: GenericKeyValuePair[];
    isVisible: string;
}

export interface PartClass {
    part: Part[];
    name: string;
}

export interface CustomerOrderTrackingDataResponse {
    ttl: string;
    status: string;
    customerOrder: CustomerOrderTrackingData;
}

export interface CustomerOrderTrackingData {
    dealerInfo: DealerInfo;
    vehicleInfo: VehicleInfo;
    orderInfo: OrderInfo;
}

export default class CustomerOrderTrackingService {
    private httpService: any;
    private url: string = DSL_BASE_URL + DSL_API_PATH.CUSTOMER_ORDER_TRACKING;
    private urlV2: string =
        DSL_BASE_URL + DSL_API_PATH.CUSTOMER_ORDER_TRACKING_CAT;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public getOrderTrackingStatus = async (
        vin: string,
        orderNumber: string,
        recaptchaToken?: string,
        isCanada?: boolean
    ): Promise<CustomerOrderTrackingDataResponse> => {
        const response = await this.httpService.get(this.url, false, {
            headers: {
                'recaptcha-token': recaptchaToken,
                vin: vin,
                orderNumber: orderNumber,
            },
            params: { isCanada: isCanada },
        });

        return response.status === 200 ? response.data : response.status;
    };

    public async getOrderTrackingStatusV2(
        vin: string,
        orderNumber: string,
        isCanada?: boolean
    ): Promise<CustomerOrderTrackingDataResponse> {
        let response: any;
        try {
            response = await this.httpService.get(this.urlV2, false, {
                headers: {
                    ...this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                    vin: vin,
                    orderNumber: orderNumber,
                },
                params: { isCanada: isCanada },
            });
        } catch (error) {
            return null;
        }

        return response?.data;
    }
}
