import React from 'react';

interface GradientBackgroundProps {
    primaryColor: string;
    secondaryColor: string;
    isMobile: boolean;
}

const GradientBackground = (props: GradientBackgroundProps) => {
    const size: number = props.isMobile ? 80 : 75;

    return (
        <svg
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="gradient-background"
        >
            <circle
                cx={size / 2}
                cy={size / 2}
                r={size / 2}
                fill={props.primaryColor}
                data-testid="gradient-background-circle"
            />
            <circle
                cx={size / 2}
                cy={size / 2}
                r={size / 2}
                fill="url(#paint0_linear_393_15849)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_393_15849"
                    x1="72.4719"
                    y1="1.70455"
                    x2="21.6797"
                    y2="85.4071"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        stop-color={props.primaryColor}
                        data-testid="stop-1"
                    />
                    <stop
                        offset="0.878323"
                        stop-color={props.secondaryColor}
                        stop-opacity="0"
                        data-testid="stop-2"
                    />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default GradientBackground;
