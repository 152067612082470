import { AxiosResponse } from 'axios';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import { RewardPointsBalance } from './loyalty-points-service.interface';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import HttpService from '../http-service/http-service';

export const mockPointsResponse: RewardPointsBalance = {
    ford: {
        consentAccepted: true,
        points: 5532,
        tierActivities: 5,
        firstConsentDate: '2021-01-01',
        pointsExpirationDate: '09/20/2024',
        tierCode: 'GOLD',
        tierStartDate: '2021-01-01',
        tierEndDate: '2022-01-01',
        tierName: 'Gold',
        tierIconUrl: 'https://tiericonurl.com',
        tierIconAltTag: 'Gold Tier',
    },
    lincoln: {
        consentAccepted: true,
        points: 5532,
        tierActivities: 5,
        firstConsentDate: '2021-01-01',
        pointsExpirationDate: '09/20/2024',
        tierCode: 'GOLD',
        tierStartDate: '2021-01-01',
        tierEndDate: '2022-01-01',
        tierName: 'Gold',
        tierIconUrl: 'https://tiericonurl.com',
        tierIconAltTag: 'Gold Tier',
    },
};

export default class LoyaltyPointsService {
    private httpService: any;

    constructor(httpService?: HttpService) {
        this.httpService = httpService;
    }

    async request(): Promise<RewardPointsBalance | null> {
        const { current3LetterCountryCode } = new AppConfigurationService();
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.AGGREGATE_POINTS;
        const params = {
            programCountry: current3LetterCountryCode.toUpperCase(),
            languageCode:
                appConfig.currentCountryCode === 'us'
                    ? 'en-us'
                    : appConfig.getLanguageRegionCode(),
        };
        const headers =
            this.httpService.getConsumerKeyAndAuthTokenRequestHeaders();
        const response = this.httpService.get(url, false, { params, headers });
        return response.then(this.parsePointsResponse);
    }

    private parsePointsResponse(
        response: AxiosResponse<RewardPointsBalance>
    ): RewardPointsBalance | null {
        if (response.data) {
            return response.data;
        }
        return null;
    }
}
