import React, { useEffect, useRef, useState } from 'react';
import { HamburgerCollapse } from 'react-animated-burgers';

import { KEYBOARD_KEYS } from '@constants';
import { useExperienceContent } from '@hooks/use-server-content';
import { HeaderFragment } from '@models/experiencefragments/header';
import { Link } from '@common/index';
import EuSidebarMenu from '@sections/eu-header/eu-sidebar-menu/eu-sidebar-menu';
import { SiteSearch } from '../search/site-search';
import { EuLogin } from './eu-login/eu-login';
import './eu-header.scss';

export const EuHeader = () => {
    const euHeaderRef = useRef<HTMLElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeDesktopSubNav, setActiveDesktopSubNav] = useState<string>('');
    const [experienceContent] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header'
    );

    const keyUpHandler = (e) => {
        const isTabPressed: boolean = e.key === 'Tab' || e.keyCode === 9;

        isTabPressed &&
            !euHeaderRef.current.contains(document.activeElement) &&
            setActiveDesktopSubNav('');
    };

    const toggleMenu = () => {
        const bodyElem = document.querySelector('body');
        bodyElem.classList.toggle('no-scroll-sm');

        setMenuOpen(!menuOpen);
    };

    const handleClick = () => {
        if (activeDesktopSubNav) {
            const containerElem = document.querySelector('.show-sub-nav');
            containerElem?.classList?.toggle('visible-xs');
        }
    };

    const toggleDesktopMenuKeyboard = (e, subNavTitle: string) => {
        const toggleKeyPressed: boolean =
            e.key === ' ' || e.key === KEYBOARD_KEYS.ENTER;

        if (toggleKeyPressed && activeDesktopSubNav === subNavTitle) {
            e.preventDefault();
            setActiveDesktopSubNav('');
        } else if (toggleKeyPressed) {
            e.preventDefault();
            setActiveDesktopSubNav(subNavTitle);
        }

        handleClick();
    };

    const toggleDesktopMenuClick = (subNavTitle: string) => {
        if (activeDesktopSubNav === subNavTitle) {
            setActiveDesktopSubNav('');
        } else {
            setActiveDesktopSubNav(subNavTitle);
        }

        handleClick();
    };

    useEffect(() => {
        if (activeDesktopSubNav) {
            document.body.classList.add('open');
        } else {
            document.body.classList.remove('open');
        }
    }, [activeDesktopSubNav]);

    useEffect(() => {
        document.addEventListener('keyup', keyUpHandler, false);

        return () => {
            document.removeEventListener('keyup', keyUpHandler, false);
        };
    }, []);

    return (
        <header ref={euHeaderRef} className="eu-header">
            <div className="eu-header-nav" id="eu-header">
                {experienceContent && (
                    <>
                        <div className="eyebrow">
                            <div className="visible-in-mobile">
                                <HamburgerCollapse
                                    className="mobile menu"
                                    isActive={menuOpen}
                                    toggleButton={toggleMenu}
                                    barColor="#666666"
                                    buttonWidth={18}
                                    data-testid="menu-toggle"
                                    aria-expanded={menuOpen}
                                />
                                <EuSidebarMenu
                                    isOpen={menuOpen}
                                    className="mobile"
                                    toggleMenu={toggleMenu}
                                />
                            </div>
                            <div className="ford-logo">
                                <a
                                    tabIndex={0}
                                    href={experienceContent.logo.url}
                                    target={
                                        experienceContent.logo.targetBlank
                                            ? '_blank'
                                            : '_self'
                                    }
                                    className="eu-logo-link"
                                    aria-label={
                                        experienceContent.logo.ariaLabel
                                    }
                                    rel="noopener noreferrer"
                                >
                                    <span
                                        style={{
                                            backgroundImage: `url(${process.env.REACT_APP_AEM_BASE_URL}${experienceContent.logo.logoPath})`,
                                        }}
                                        className={'eu-ford-logo'}
                                    />
                                </a>
                            </div>

                            <div className="thin-header-content">
                                {experienceContent['main-nav-right']
                                    .utilityLinks &&
                                    experienceContent[
                                        'main-nav-right'
                                    ]?.utilityLinks?.map((nav, index) => {
                                        return (
                                            <Link
                                                key={index}
                                                href={nav.url}
                                                aria-label={nav.title}
                                                data-testid={`utility-link-${index}`}
                                            >
                                                <span className="eyebrow-text">
                                                    {nav.title}
                                                </span>
                                            </Link>
                                        );
                                    })}
                            </div>
                            <div
                                className={`nav-right ${
                                    menuOpen ? 'sidebar-open' : ''
                                }`}
                            >
                                {experienceContent['main-nav-right'].signIn && (
                                    <EuLogin
                                        content={
                                            experienceContent['main-nav-right']
                                                .signIn
                                        }
                                    />
                                )}

                                {experienceContent['main-nav-right'].search && (
                                    <a className="eu-site-search-container">
                                        <SiteSearch
                                            content={
                                                experienceContent[
                                                    'main-nav-right'
                                                ].search
                                            }
                                        />
                                    </a>
                                )}
                            </div>
                        </div>

                        <div className="block-header">
                            <div className="nav-header"></div>
                            <div className="left-nav">
                                {experienceContent['main-nav-left'] &&
                                    experienceContent['main-nav-left']?.map(
                                        (navSub, index) => {
                                            if (
                                                !navSub.categories &&
                                                navSub.noFlyout
                                            ) {
                                                return (
                                                    <nav
                                                        key={index}
                                                        aria-label={
                                                            navSub.ariaLabel
                                                        }
                                                        className={`${
                                                            activeDesktopSubNav ===
                                                            navSub.title
                                                                ? 'tab-active'
                                                                : ''
                                                        }`}
                                                    >
                                                        <span className="desktop">
                                                            <Link
                                                                href={
                                                                    navSub.url
                                                                }
                                                                className="desktop first-header-link"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {navSub.title}
                                                            </Link>
                                                        </span>
                                                    </nav>
                                                );
                                            } else if (
                                                navSub.categories &&
                                                !navSub.noFlyout
                                            ) {
                                                return (
                                                    <nav
                                                        key={index}
                                                        aria-label={
                                                            navSub.ariaLabel
                                                        }
                                                        className={`${
                                                            activeDesktopSubNav ===
                                                            navSub.title
                                                                ? 'tab-active'
                                                                : ''
                                                        }`}
                                                    >
                                                        <button
                                                            key={index}
                                                            tabIndex={0}
                                                            className={`desktop ${
                                                                activeDesktopSubNav ===
                                                                navSub.title
                                                                    ? 'active'
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                toggleDesktopMenuClick(
                                                                    navSub.title
                                                                )
                                                            }
                                                            onKeyDown={(e) => {
                                                                toggleDesktopMenuKeyboard(
                                                                    e,
                                                                    navSub.title
                                                                );
                                                            }}
                                                        >
                                                            <span>
                                                                <Link
                                                                    className="main-nav"
                                                                    href={
                                                                        navSub.url
                                                                    }
                                                                    aria-label={
                                                                        navSub.ariaLabel
                                                                    }
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                >
                                                                    {
                                                                        navSub.title
                                                                    }
                                                                </Link>
                                                            </span>
                                                        </button>
                                                        <div
                                                            className={`subNav${index} desktop-sub-nav ${
                                                                activeDesktopSubNav ===
                                                                navSub.title
                                                                    ? 'show-sub-nav'
                                                                    : 'hide-nav'
                                                            }`}
                                                        >
                                                            <div
                                                                className="background-overlay"
                                                                onClick={() => {
                                                                    setActiveDesktopSubNav(
                                                                        ''
                                                                    );
                                                                }}
                                                            />
                                                            <ul className="main-nav-item sidebar-sub-nav">
                                                                {navSub.categories &&
                                                                    navSub.categories.map(
                                                                        (
                                                                            subNav,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    className="eu-sub-nav-title-container"
                                                                                >
                                                                                    <span
                                                                                        className={
                                                                                            'eu-drawer-title'
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            subNav.categoryTitle
                                                                                        }
                                                                                    </span>

                                                                                    <ul>
                                                                                        {subNav.categoryItems &&
                                                                                            subNav.categoryItems.map(
                                                                                                (
                                                                                                    sideNav,
                                                                                                    inx
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <li
                                                                                                            key={
                                                                                                                inx
                                                                                                            }
                                                                                                            className="sub-nav-link"
                                                                                                        >
                                                                                                            <Link
                                                                                                                target={
                                                                                                                    sideNav.targetBlank
                                                                                                                        ? '_blank'
                                                                                                                        : ''
                                                                                                                }
                                                                                                                href={
                                                                                                                    sideNav.url
                                                                                                                }
                                                                                                                aria-label={
                                                                                                                    sideNav.ariaLabel
                                                                                                                }
                                                                                                            >
                                                                                                                <span className="sub-nav-title">
                                                                                                                    {
                                                                                                                        sideNav.title
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </Link>
                                                                                                        </li>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </ul>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                            </ul>
                                                        </div>
                                                    </nav>
                                                );
                                            }
                                        }
                                    )}
                            </div>

                            <div className="mobile-fill" />
                        </div>
                    </>
                )}
            </div>
        </header>
    );
};
