import React, { HTMLProps } from 'react';

export interface TooltipProps extends HTMLProps<HTMLDivElement> {
    color: string;
    dataTestId: string;
}

const TooltipIcon = (props: TooltipProps) => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid={props.dataTestId}
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.5999 6.00039C11.5999 9.08039 9.0799 11.6004 5.9999 11.6004C2.9199 11.6004 0.399902 9.08039 0.399902 6.00039C0.399902 2.92039 2.9199 0.400391 5.9999 0.400391C9.0799 0.400391 11.5999 2.92039 11.5999 6.00039ZM6.5999 3.40039C6.5999 3.72039 6.3199 4.00039 5.9999 4.00039C5.6799 4.00039 5.3999 3.72039 5.3999 3.40039C5.3999 3.08039 5.6799 2.80039 5.9999 2.80039C6.3199 2.80039 6.5999 3.08039 6.5999 3.40039ZM7.1999 8.00039H6.3999V4.80039H4.7999V5.60039H5.5999V8.00039H4.7999V8.80039H7.1999V8.00039ZM5.9999 1.20039C8.6399 1.20039 10.7999 3.36039 10.7999 6.00039C10.7999 8.64039 8.6399 10.8004 5.9999 10.8004C3.3599 10.8004 1.1999 8.64039 1.1999 6.00039C1.1999 3.36039 3.3599 1.20039 5.9999 1.20039Z"
                fill={props.color}
                data-testid={`${props.dataTestId}-path`}
            />
        </svg>
    );
};

export default TooltipIcon;
