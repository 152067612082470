import HttpService from '@services/http-service/http-service';
import { BRAND, DSL_API_PATH, DSL_BASE_URL } from '@constants';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

export interface FeatureGuideResponse {
    guideUrl: string;
}

export default class DaosService {
    private httpService: any;
    private url: string = DSL_BASE_URL + DSL_API_PATH.FEATURE_GUIDE;
    private appConfig = new AppConfigurationService();

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getFeatureGuide(vin: string): Promise<FeatureGuideResponse> {
        let response: any;
        const languageRegionCode = this.appConfig.getLanguageRegionCode();
        try {
            response = await this.httpService.get(this.url, false, {
                headers: {
                    vin: vin,
                },
                params: {
                    languageRegion: languageRegionCode,
                    brand: this.appConfig.getBrand(),
                },
            });
        } catch (error) {
            return null;
        }

        return response?.data;
    }
}
