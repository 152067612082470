import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import mapStyles from './mapStyles';
import serverSideService from '@/services/server-side-service/server-side-service';
import { EUDealer } from '@models/preferred-dealer';
import { useWindowSize } from '@hooks/use-window-size';
import { TABLET_BREAKPOINT } from '@services/support-constants';
import './eu-map.scss';

interface EuMapProps {
    dealers: EUDealer[];
    selectedDealer: EUDealer;
    isDealerIndexValue?: number;
    isPcflow?: boolean;
    zoomControl?: boolean;
    fullscreenControl?: boolean;
    zoomControlPosition?: string;
    fullscreenControlPosition?: string;
    leftShiftDegree?: number; // added for left shift degree
    upShiftDegree?: number; // added for up shift degree
    zoom?: number; // added for zoom level
    selectedDealerCode?: string | undefined;
    onDealerClick?: Dispatch<SetStateAction<EUDealer>>;

    //**implement on dealerclick functionality */
    newUIDesign?: boolean; // TODO: once the new design has been implemented in all markets and the old design files have been removed, this props should be removed.
}
export const EuMap = (props: EuMapProps) => {
    const mapRef = useRef<google.maps.Map>();
    const { dealers, selectedDealer } = props;
    let center;

    center =
        selectedDealer === undefined
            ? {
                  lat:
                      dealers.length > 0
                          ? dealers[0].location?.lat || dealers[0].Latitude
                          : 0,
                  lng:
                      dealers.length > 0
                          ? dealers[0].location?.lng || dealers[0].Longitude
                          : 0,
              }
            : selectedDealer.location || {
                  lat: selectedDealer.Latitude,
                  lng: selectedDealer.Longitude,
              };

    // Use the leftShiftDegree prop to shift the center of the map
    // Use the upShiftDegree prop to shift the center of the map to up
    if (props.leftShiftDegree || props.upShiftDegree) {
        center = {
            lat: (center.lat || center.Latitude) - (props.upShiftDegree || 0),
            lng:
                (center.lng || center.Longitude) - (props.leftShiftDegree || 0),
        };
    }
    const isMobileView: boolean = useWindowSize().width < TABLET_BREAKPOINT;

    const mapContainerStyle = {
        width: '100%',
        height: '100%',
    };

    const positionMap: Record<string, google.maps.ControlPosition> = {
        BOTTOM_CENTER: google.maps.ControlPosition.BOTTOM_CENTER,
        BOTTOM_LEFT: google.maps.ControlPosition.BOTTOM_LEFT,
        BOTTOM_RIGHT: google.maps.ControlPosition.BOTTOM_RIGHT,
        LEFT_BOTTOM: google.maps.ControlPosition.LEFT_BOTTOM,
        LEFT_CENTER: google.maps.ControlPosition.LEFT_CENTER,
        LEFT_TOP: google.maps.ControlPosition.LEFT_TOP,
        RIGHT_BOTTOM: google.maps.ControlPosition.RIGHT_BOTTOM,
        RIGHT_CENTER: google.maps.ControlPosition.RIGHT_CENTER,
        RIGHT_TOP: google.maps.ControlPosition.RIGHT_TOP,
        TOP_CENTER: google.maps.ControlPosition.TOP_CENTER,
        TOP_LEFT: google.maps.ControlPosition.TOP_LEFT,
        TOP_RIGHT: google.maps.ControlPosition.TOP_RIGHT,
    };

    const mapPositionToValue = (
        position: string
    ): google.maps.ControlPosition =>
        positionMap[position] || google.maps.ControlPosition.TOP_CENTER;

    const locationCoordinate = {
        lat:
            dealers.length > 0
                ? dealers[0].location?.lat || dealers[0].Latitude
                : 0,
        lng:
            dealers.length > 0
                ? dealers[0].location?.lng || dealers[0].Longitude
                : 0,
    };

    const getIcon = (isLocation?: boolean) => {
        if (isLocation) {
            return {
                url: './icons/map-marker-current-location.png',
                scaledSize: new window.google.maps.Size(40, 50),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(20, 20),
                fillColor: 'red',
            };
        } else {
            return {
                url: './icons/map-marker-dealer-location.png',
                scaledSize: new window.google.maps.Size(40, 50),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(20, 20),
                fillColor: 'red',
            };
        }
    };
    const getLabel = (i: number) => {
        if (props.isDealerIndexValue) {
            return props.isDealerIndexValue.toString();
        } else {
            return (i + 1).toString();
        }
    };
    const getZoom = () => {
        switch (true) {
            case props.zoom != null:
                return props.zoom;
            case props.dealers.length === 1:
                return 10;
            case props.dealers.length === 5:
                return 11;
            default:
                return 10;
        }
    };

    const getOptions = {
        styles: mapStyles,
        disableDefaultUI: true,
        zoomControl: props.zoomControl != null ? props.zoomControl : true,
        fullscreenControl:
            props.fullscreenControl != null ? props.fullscreenControl : true,
        ...(props.zoomControlPosition && {
            zoomControlOptions: {
                position: mapPositionToValue(props.zoomControlPosition),
            },
        }),
        ...(props.fullscreenControlPosition && {
            fullscreenControlOptions: {
                position: mapPositionToValue(props.fullscreenControlPosition),
            },
        }),
    };

    const getSelectedDealerPosition = (index: number) => {
        const div = document.getElementById('dealerScrollView-' + index);
        div?.scrollIntoView({ block: 'start', inline: 'nearest' });
        if (serverSideService.isClientSide()) {
            const topHeaderHeight =
                document.getElementById('eu-header')?.clientHeight || 0;
            const PcBannerHeight =
                document.getElementById('osbBannerId')?.clientHeight || 0;
            const PcHeaderHeight =
                document.getElementById('pc-header-section')?.clientHeight || 0;
            if (!isMobileView) {
                if (dealers.length === index + 1) {
                    props.isPcflow
                        ? window.scrollTo({
                              top:
                                  PcBannerHeight +
                                  PcHeaderHeight +
                                  topHeaderHeight +
                                  10,
                              behavior: 'smooth',
                          })
                        : window.scrollTo({
                              top: 300,
                              behavior: 'smooth',
                          });
                } else {
                    props.isPcflow
                        ? window.scrollTo({
                              top:
                                  PcBannerHeight +
                                  PcHeaderHeight +
                                  topHeaderHeight +
                                  10,
                              behavior: 'smooth',
                          })
                        : window.scrollTo(0, 0);
                }
            } else {
                const topPos = div?.offsetTop;
                if (topPos != undefined) {
                    props.isPcflow
                        ? window.scrollTo({
                              top: topPos - 300,
                              behavior: 'smooth',
                          })
                        : window.scrollTo({
                              top: topPos - 170,
                              behavior: 'smooth',
                          });
                }
            }
        }
    };
    return (
        <div className="eu-map-container">
            <GoogleMap
                ref={mapRef}
                mapContainerStyle={mapContainerStyle}
                zoom={getZoom()}
                center={center}
                options={getOptions}
            >
                <Marker
                    key={'location'}
                    position={locationCoordinate}
                    icon={getIcon(true)}
                />
                {dealers.map((item, i) => {
                    return (
                        <React.Fragment key={item.DealerID}>
                            <Marker
                                key={item.DealerID}
                                position={
                                    item.location || {
                                        lat: item.Latitude,
                                        lng: item.Longitude,
                                    }
                                }
                                label={{
                                    text: getLabel(i),
                                    color: '#ffffff',
                                    fontFamily: 'FordF1Regular',
                                    fontSize: '20px',
                                    fontWeight: '500',
                                    className: 'eu-map-marker-position',
                                }}
                                icon={getIcon(false)}
                                onClick={() => {
                                    if (props.newUIDesign) {
                                        props.onDealerClick &&
                                            props.onDealerClick(item);
                                    } else {
                                        getSelectedDealerPosition(i);
                                    }
                                }}
                            />
                        </React.Fragment>
                    );
                })}
            </GoogleMap>
        </div>
    );
};
