import React from 'react';

export interface MobileCarouselIndicatorProps {
    workingIndex: number;
    items: string[];
}

const MobileCarouselIndicators = (
    props: MobileCarouselIndicatorProps
): JSX.Element => {
    const { items, workingIndex } = props;

    return (
        <div
            className="fmc-carousel-indicator__container--mobile"
            data-testid="mobile-carousel-indicators"
        >
            {items.map((image, index) => {
                if (index <= 4) {
                    const dotIsActive: boolean =
                        (workingIndex === index && workingIndex <= 3) ||
                        (index === 3 &&
                            workingIndex >= 3 &&
                            workingIndex !== items.length - 1) ||
                        (workingIndex === items.length - 1 && index === 4);

                    const isMicroDot: boolean =
                        (items.length > 5 && index === 0 && workingIndex > 2) ||
                        (items.length > 5 &&
                            index === 4 &&
                            workingIndex < items.length - 2);

                    return (
                        <button
                            className={`fmc-carousel-indicator__dots ${
                                dotIsActive ? 'dot--active' : 'dot--inactive'
                            } ${isMicroDot ? 'dot--small' : ''}`}
                            data-testid="mobile-carousel-indicator-dot"
                        ></button>
                    );
                }
            })}
        </div>
    );
};

export default MobileCarouselIndicators;
